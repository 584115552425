import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import styled from 'styled-components'
import Box from 'components/molecules/Box'
import RowButton from 'components/atoms/RowButton'
import Modal from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import DragDropList from 'components/molecules/DragDropList'
import ProgressLoader from 'components/atoms/ProgressLoader'
import EditableText from 'components/molecules/EditableText'
import NavigationPrompt from 'react-router-navigation-prompt'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'
import idx from 'idx'
import { deepEqual } from 'fast-equals'

export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

export const UpdateButton = styled(Button)`
  width: 140px;
`

export const ReflectionsSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Reflections = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const ReflectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export function ReflectionForm({
  className,
  handleChange,
  values,
  errors,
  touched,
  title,
  description,
  progress,
  updateProgress,
  isConfirmUpdateModalOpen,
  setIsConfirmUpdateModalOpen,
  handleSubmit,
  onDragEnd,
  onAddReflection,
  onDeleteReflection,
  reflections,
  disableEditting,
}) {
  const handleCloseConfirmationModal = useCallback(
    () => setIsConfirmUpdateModalOpen(false),
    []
  )

  const hasUnsavedChanges = useMemo(
    () => {
      return !deepEqual(values.reflections, reflections)
    },
    [values.reflections, reflections, progress, updateProgress]
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <NavigationPrompt when={hasUnsavedChanges}>
        {({ onConfirm, onCancel }) => (
          <Modal open>
            <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
          </Modal>
        )}
      </NavigationPrompt>

      <ConfirmUpdatesModal
        open={isConfirmUpdateModalOpen}
        onClose={handleCloseConfirmationModal}
      >
        <ConfirmUpdatesForm
          onCancel={handleCloseConfirmationModal}
          onSubmit={handleSubmit}
          title="Confirm reflection update"
          content={
            <React.Fragment>
              All reflection changes will be <br /> updated and visible in the
              app.
            </React.Fragment>
          }
        />
      </ConfirmUpdatesModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h3">{title}</Typography>
          {description && (
            <DescriptionText variant="body1">{description}</DescriptionText>
          )}
        </HeaderLeft>
        {!disableEditting && (
          <UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!hasUnsavedChanges}
          >
            Update
          </UpdateButton>
        )}
      </StyledHeader>
      {progress.inProgress &&
      (!values.reflections || values.reflections.length === 0) ? (
        <ProgressLoader fullWidth />
      ) : (
        <React.Fragment>
          {!disableEditting && (
            <RowButton onClick={onAddReflection}>+ Add reflection</RowButton>
          )}

          <ReflectionsSection>
            <DragDropList onDragEnd={onDragEnd}>
              {(provided) => (
                <Reflections>
                  {values.reflections
                    ? values.reflections.map((reflection, reflectionIndex) => {
                        const isTouched = idx(
                          touched,
                          (_) => _.reflections[reflectionIndex]
                        )
                        const reflectionErrors = idx(
                          errors,
                          (_) => _.reflections[reflectionIndex]
                        )
                        return (
                          <Box
                            key={reflection.uuid || reflectionIndex}
                            draggableId={reflection.uuid || reflectionIndex}
                            draggableIndex={reflectionIndex}
                            isDraggable={!disableEditting}
                            errors={
                              isTouched && reflectionErrors
                                ? Object.values(reflectionErrors)
                                : []
                            }
                            headerContent={
                              <ReflectionHeader>
                                {disableEditting ? (
                                  <Typography variant="h4">
                                    {reflection.reflection}
                                  </Typography>
                                ) : (
                                  <>
                                    <EditableText
                                      value={reflection.reflection}
                                      name={`reflections[${reflectionIndex}].reflection`}
                                      onChange={handleChange}
                                      placeholder="Reflection text"
                                    />
                                    <Button
                                      type="button"
                                      variant="text"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        onDeleteReflection(reflectionIndex)
                                      }
                                    >
                                      Delete reflection
                                    </Button>
                                  </>
                                )}
                              </ReflectionHeader>
                            }
                          />
                        )
                      })
                    : null}
                  {provided.placeholder}
                </Reflections>
              )}
            </DragDropList>
          </ReflectionsSection>
        </React.Fragment>
      )}
    </StyledForm>
  )
}

ReflectionForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  disableEditting: PropTypes.bool,
  description: PropTypes.node,
  isConfirmUpdateModalOpen: PropTypes.bool,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  onDragEnd: PropTypes.func,
  onAddReflection: PropTypes.func,
  progress: PropTypes.object,
  updateProgress: PropTypes.object,
  onDeleteReflection: PropTypes.func,
  reflections: PropTypes.arrayOf(PropTypes.object),
}

ReflectionForm.defaultProps = {
  values: {},
  progress: {},
  errors: {},
  touched: {},
  title: 'Custom Reflections',
}

export default ReflectionForm
