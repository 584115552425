import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@weareroam/cake-ui-v1/Button'

import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import SettingsPageContainer from 'components/molecules/SettingsPageContainer'
import DetailedSwitch from 'components/molecules/DetailedSwitch'

export const StyledLayout = styled(ChnnlDashboardLayout)``

export const Content = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const StyledButton = styled(Button)`
  && {
    width: 100%;
  }
`

export function Notifications({ actions }) {
  const [isSelected, setSelected] = useState(true)
  const handleUpdateClick = useCallback(
    () => {
      actions.updateProfileNotifications({ monthlyReport: isSelected })
    },
    [isSelected]
  )
  useEffect(() => {
    actions.getProfileNotifications()
  }, [])

  return (
    <StyledLayout>
      <SettingsPageContainer title="Email notifications">
        <Content>
          <DetailedSwitch
            isSelected={isSelected}
            onChange={setSelected}
            title="Receive monthly reports"
            id="organisation-monthly-reports-toggle"
            description={`Receive monthly reports via email on\nhow all chnnl organisations are doing`}
          />
        </Content>
        <StyledButton
          size="medium"
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleUpdateClick}
        >
          Update
        </StyledButton>
      </SettingsPageContainer>
    </StyledLayout>
  )
}

Notifications.propTypes = {
  actions: PropTypes.object,
}

Notifications.defaultProps = {
  actions: {},
}

export default Notifications
