import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Bar,
  Cell,
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import theme from 'styles/theme'
import GraphFooter from 'components/atoms/GraphFooter'
import Typography from '@weareroam/cake-ui-v1/Typography'

const StyledContainer = styled(ResponsiveContainer)`
  margin-top: ${({ theme }) => theme.spacing.md}px;
  margin-left: -${({ theme }) => theme.spacing.xl}px;
  svg {
    overflow: visible;
  }
`

export const StyledLabel = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

// eslint-disable-next-line
function XAxisTick({ x, y, stroke, payload }) {
  return (
    <g transform={`translate(${x * 1.01},${y})`}>
      <text x={0} y={0} dy={24} width={24} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  )
}

export function ActivityBarGraph({ data, month, className }) {
  return (
    <>
      <StyledContainer width="100%" height={200} className={className}>
        <BarChart data={data} barCategoryGap={0}>
          <XAxis
            stroke={theme.palette.tertiary.main}
            dataKey="name"
            interval={1}
            tickSize={0}
            tick={<XAxisTick />}
          />
          <YAxis
            stroke={theme.palette.tertiary.main}
            tickSize={0}
            tickFormatter={(val) => (val === 0 ? '' : val)}
            tickMargin={10}
            width={100}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Bar dataKey="value" barSize={12} radius={[3, 3, 0, 0]}>
            {data.map((entry, index) => {
              return (
                <Cell
                  key={index}
                  fill={
                    index % 2 === 0
                      ? theme.palette.quaternary.main
                      : theme.palette.quaternary.light
                  }
                />
              )
            })}
          </Bar>
        </BarChart>
      </StyledContainer>
      <GraphFooter label="Response total" month={month} />
    </>
  )
}

ActivityBarGraph.propTypes = {
  data: PropTypes.array,
  month: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

ActivityBarGraph.defaultProps = {
  data: [],
}

export default ActivityBarGraph
