import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from 'components/atoms/Link'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ChevronRight from '@weareroam/cake-ui-v1-icons/ChevronRight'

export const Content = styled.div`
  display: flex;
  padding: 18px 0;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: ${({ width }) => width}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.accents.light}};

  svg {
    transition: transform 0.2s ease;
  }
  &:hover svg {
    transform: translateX(3px);
  }
`

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.main};
`

export function ChevronLink({ to, label, width }) {
  return (
    <Link to={to}>
      <Content width={width}>
        <Label variant="h4">{label}</Label>
        <ChevronRight />
      </Content>
    </Link>
  )
}

ChevronLink.defaultProps = {
  width: 350,
}

ChevronLink.propTypes = {
  to: PropTypes.string.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  children: PropTypes.node,
}

export default ChevronLink
