import { SAUCE_API_URL } from 'config'
import {
  ROLE_SUPER_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
} from 'constants/form'
import axios from 'axios'
import { addToast } from 'modules/toasts/actions'
import { redirect } from 'modules/route'
import {
  SIGN_UP_ORGANISATION,
  ORG_DASHBOARD_OVERVIEW,
  LOGIN,
  CHNNL_DASHBOARD_ORGANISATIONS,
  ORG_DASHBOARD_GROUPS,
} from 'constants/routes'
import { replaceRouteParams } from 'utils/routes'
import { addOrganisation } from 'modules/organisations/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { hashPassword } from '../../utils/authentication'
import { MINIMUM_VIEWPORT_WIDTH } from 'styles/theme'

// Actions
export const API_LOGIN_REQUEST = 'API_LOGIN_REQUEST'
export const API_LOGIN_SUCCESS = 'API_LOGIN_SUCCESS'
export const API_LOGIN_ERROR = 'API_LOGIN_ERROR'

export const API_LOGOUT_REQUEST = 'API_LOGOUT_REQUEST'
export const API_LOGOUT_SUCCESS = 'API_LOGOUT_SUCCESS'
export const API_LOGOUT_ERROR = 'API_LOGOUT_ERROR'

export const API_RESET_PASSWORD_REQUEST_REQUEST =
  'API_RESET_PASSWORD_REQUEST_REQUEST'
export const API_RESET_PASSWORD_REQUEST_SUCCESS =
  'API_RESET_PASSWORD_REQUEST_SUCCESS'
export const API_RESET_PASSWORD_REQUEST_ERROR =
  'API_RESET_PASSWORD_REQUEST_ERROR'

export const API_RESET_PASSWORD_REQUEST = 'API_RESET_PASSWORD_REQUEST'
export const API_RESET_PASSWORD_SUCCESS = 'API_RESET_PASSWORD_SUCCESS'
export const API_RESET_PASSWORD_ERROR = 'API_RESET_PASSWORD_ERROR'

export const API_REFRESH_SESSION_REQUEST = 'API_REFRESH_SESSION_REQUEST'
export const API_REFRESH_SESSION_SUCCESS = 'API_REFRESH_SESSION_SUCCESS'
export const API_REFRESH_SESSION_ERROR = 'API_REFRESH_SESSION_ERROR'

export const API_VERIFY_ACCOUNT_REQUEST = 'API_VERIFY_ACCOUNT_REQUEST'
export const API_VERIFY_ACCOUNT_SUCCESS = 'API_VERIFY_ACCOUNT_SUCCESS'
export const API_VERIFY_ACCOUNT_ERROR = 'API_VERIFY_ACCOUNT_ERROR'

export const API_CHANGE_PASSWORD_REQUEST = 'API_CHANGE_PASSWORD_REQUEST'
export const API_CHANGE_PASSWORD_SUCCESS = 'API_CHANGE_PASSWORD_SUCCESS'
export const API_CHANGE_PASSWORD_ERROR = 'API_CHANGE_PASSWORD_ERROR'

export const SAVE_AUTH_INFO = 'SAVE_AUTH_INFO'

// Action creators
export function saveAuthInfo({ token, refreshToken, role }) {
  return {
    type: SAVE_AUTH_INFO,
    data: {
      token,
      refreshToken,
      role,
    },
  }
}

export function login({ email, password }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGIN_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/auth/login`, {
        email,
        password: hashPassword(password),
      })

      const { token, refreshToken, role, organisation } = response.data.data[0]

      dispatch(saveAuthInfo({ token, refreshToken, role }))

      dispatch({
        type: API_LOGIN_SUCCESS,
      })

      switch (role) {
        case ROLE_SUPER_ADMIN:
          // Redirect super admins to the chnnl dashboard
          dispatch(redirect(CHNNL_DASHBOARD_ORGANISATIONS))
          break
        case ROLE_GROUP_ADMIN:
          // redirect group admins to the group dashboard
          if (organisation) {
            dispatch(
              redirect(
                replaceRouteParams(ORG_DASHBOARD_GROUPS, {
                  orgUuid: organisation.uuid,
                })
              )
            )
          }
          break
        default:
          // Redirect all other users to the organisation dashboard
          if (organisation) {
            dispatch(
              redirect(
                replaceRouteParams(ORG_DASHBOARD_OVERVIEW, {
                  orgUuid: organisation.uuid,
                })
              )
            )
          }
          break
      }
    } catch (error) {
      dispatch({ type: API_LOGIN_ERROR })
      dispatch(
        addToast({
          message:
            'Your email address and password do not match, please try again or contact your administrator.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function verifyAccount({ firstName, lastName, password, token }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_VERIFY_ACCOUNT_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/verify`,
        {
          token,
          firstName,
          lastName,
          password: hashPassword(password),
        }
      )

      dispatch({ type: API_VERIFY_ACCOUNT_SUCCESS })

      const {
        organisation,
        token: verifiedToken,
        role,
        refreshToken,
      } = response.data.data[0]

      dispatch(saveAuthInfo({ token: verifiedToken, refreshToken, role }))
      const orgUuid = organisation.uuid

      if (role === ROLE_ORG_OWNER && !organisation.logo) {
        // org owners have a 2 step sign up process
        // prepopulate name for org on the next form then redirect
        dispatch(addOrganisation(organisation))
        dispatch(
          redirect(replaceRouteParams(SIGN_UP_ORGANISATION, { orgUuid }))
        )
      } else if (role === ROLE_GROUP_ADMIN) {
        dispatch(
          redirect(replaceRouteParams(ORG_DASHBOARD_GROUPS, { orgUuid }))
        )
      } else {
        // redirect other users to their org dashboard
        dispatch(
          redirect(replaceRouteParams(ORG_DASHBOARD_OVERVIEW, { orgUuid }))
        )
      }
    } catch (error) {
      dispatch({ type: API_VERIFY_ACCOUNT_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error verifying your account. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function logout() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGOUT_REQUEST })

    try {
      await axios.post(`${SAUCE_API_URL}/auth/logout`)
      dispatch({ type: API_LOGOUT_SUCCESS })
    } catch (error) {
      dispatch({ type: API_LOGOUT_ERROR })
    }

    dispatch(redirect(LOGIN))
  }
}

export function refreshSession() {
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_REFRESH_SESSION_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/auth/refresh`, {
        refreshToken: getState().authentication.refreshToken,
      })

      const { token, refreshToken } = response.data.data[0]

      dispatch(saveAuthInfo({ token, refreshToken }))

      dispatch({
        type: API_REFRESH_SESSION_SUCCESS,
      })

      return true
    } catch (error) {
      dispatch({
        type: API_REFRESH_SESSION_ERROR,
        error,
      })

      return false
    }
  }
}

export function resetPasswordRequest(email) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_PASSWORD_REQUEST_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/auth/requestnew`, {
        email,
      })
      dispatch({ type: API_RESET_PASSWORD_REQUEST_SUCCESS })

      return response
    } catch (error) {
      dispatch({ type: API_RESET_PASSWORD_REQUEST_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error sending a reset password email. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )

      return null
    }
  }
}

export function resetPassword(password, token) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_PASSWORD_REQUEST })

    try {
      await axios.post(`${SAUCE_API_URL}/auth/reset`, {
        token,
        passwordHash: hashPassword(password),
      })

      dispatch({ type: API_RESET_PASSWORD_SUCCESS })

      dispatch(
        addToast({
          message: 'Your password has successfully been reset!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
      
      // pretty filthy but don't need to cater for resizing
      const WINDOW_WIDTH = window.innerWidth;

      if (WINDOW_WIDTH > MINIMUM_VIEWPORT_WIDTH) {
        dispatch(redirect(LOGIN))
      }
    } catch (error) {
      dispatch({ type: API_RESET_PASSWORD_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error resetting your password. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function changePassword(currentPass, newPass) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CHANGE_PASSWORD_REQUEST })

    try {
      await axios.put(`${SAUCE_API_URL}/auth/update`, {
        password: hashPassword(currentPass),
        passwordHash: hashPassword(newPass),
      })

      dispatch({ type: API_CHANGE_PASSWORD_SUCCESS })
      dispatch(
        addToast({
          message: 'Your password has successfully been changed!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_CHANGE_PASSWORD_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error changing your password. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
