import { createSelector } from 'reselect'
import { capitalize } from '@weareroam/cake-ui-v1/utils/helpers'

export const getGroupsList = createSelector(
  (state) => state.groups.entities,
  (groups) => Object.values(groups)
)

export const getGroupById = createSelector(
  [(state, groupUuid) => state.groups.entities[groupUuid]],
  (group) => {
    return group || {}
  }
)

export const getGroupMemberStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Migration Pending'
    case 'clicked':
      return 'Text Clicked'
    default:
      return capitalize(status)
  }
}
