import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TableCell from '@weareroam/cake-ui-v1/TableCell'

export const StyledTableCell = styled(TableCell)`
  && {
    vertical-align: middle;
    padding: ${({ theme }) => theme.spacing.sm}px;
    border-bottom: 0;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};

    &:last-child {
      padding-right: ${({ theme }) => theme.spacing.sm}px;
    }

    &:first-of-type {
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }

    &:last-of-type {
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
    }
  }
`

export function DataTableBodyCell({ children, className, align }) {
  return (
    <StyledTableCell className={className} align={align}>
      {children}
    </StyledTableCell>
  )
}

DataTableBodyCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  align: PropTypes.string,
}

export default DataTableBodyCell
