import React, { useCallback, useRef, useState } from 'react'
import HorizActionsIcon from '@weareroam/cake-ui-v1-icons/MoreHoriz'
import VertActionsIcon from '@weareroam/cake-ui-v1-icons/MoreVert'
import PropTypes from 'prop-types'
import Menu from '@weareroam/cake-ui-v1/Menu'
import MenuItem from 'components/molecules/MenuItem'
import styled from 'styled-components'

export const StyledButton = styled.button`
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  color: ${({ theme, color }) => theme.palette[color].main};
  font-size: 0;
`

export const StyledVertIcon = styled(VertActionsIcon)`
  cursor: pointer;
  color: inherit;
`

export const StyledHorizIcon = styled(HorizActionsIcon)`
  cursor: pointer;
  color: inherit;
`

export function MoreActions({
  actions,
  direction,
  className,
  color,
  children,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const buttonRef = useRef(null)
  const handleOnClick = useCallback(
    () => {
      setIsMenuOpen(!isMenuOpen)
    },
    [isMenuOpen]
  )

  return (
    <React.Fragment>
      <StyledButton
        type="button"
        aria-haspopup="true"
        onClick={handleOnClick}
        className={className}
        color={color}
        ref={buttonRef}
        data-qa="action-menu-button"
      >
        {direction === 'horizontal' ? <StyledHorizIcon /> : <StyledVertIcon />}
      </StyledButton>
      <Menu
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={handleOnClick}
        transitionDuration={{ enter: 300, exit: 0 }}
      >
        {actions &&
          actions.length &&
          actions.map(({ key, onClick, label, ...menuProps }) => (
            <MenuItem
              key={key}
              onClick={() => {
                handleOnClick()
                onClick()
              }}
              {...menuProps}
            >
              {label}
            </MenuItem>
          ))}
        {children}
      </Menu>
    </React.Fragment>
  )
}

MoreActions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.array,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  color: PropTypes.oneOf(['primary', 'tertiary']),
}

MoreActions.defaultProps = {
  direction: 'horizontal',
  color: 'tertiary',
}

export default MoreActions
