import { useCallback, useState, useEffect, useRef } from 'react'
import { INCIDENT_TYPE_ALL } from 'constants/form'
import { PAGINATION_LIMIT } from 'constants/pagination'
import useDebouncedCallback from 'use-debounce/lib/callback'

export default function useIncidents({
  filters,
  organisation,
  onDeleteIncident,
  onApproveIncidents,
  onEditIncident,
  onRevertIncident,
  onFetchIncidents,
  incidentModalUuid,
}) {
  const [incidentType, setIncidentType] = useState(INCIDENT_TYPE_ALL)
  const [searchFilter, setSearchFilter] = useState('')
  const [pagination, setPagination] = useState({
    start: 0,
    count: 0,
    limit: PAGINATION_LIMIT,
  })
  const [incidentsMarkedToApprove, setIncidentsMarkedToApprove] = useState([])
  const prevSearch = useRef(null)

  const handleEditSubmit = useCallback(
    (form) => {
      onEditIncident({
        ...form,
        incidentUuid: incidentModalUuid,
      })
    },
    [incidentModalUuid]
  )

  const handleDeleteIncident = useCallback(
    async () => {
      await onDeleteIncident(incidentModalUuid)
      await fetchCallback()
    },
    [incidentModalUuid]
  )

  const handleRevertIncident = useCallback(
    () => {
      onRevertIncident(incidentModalUuid)
    },
    [incidentModalUuid]
  )

  const handleApproveIncidents = useCallback(
    async () => {
      await onApproveIncidents(incidentsMarkedToApprove)
      setIncidentsMarkedToApprove([])
    },
    [incidentsMarkedToApprove]
  )

  const handleChangeIncidentType = useCallback((e) => {
    setIncidentType(e.target.value)
  }, [])

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
    prevSearch.current = e.target.value
  }, [])

  const handleChangeMarkedToApprove = useCallback(
    (e) => {
      const clickedUuid = e.target.value

      if (incidentsMarkedToApprove.includes(clickedUuid)) {
        // remove the uuid from the array
        setIncidentsMarkedToApprove(
          incidentsMarkedToApprove.filter((uuid) => uuid !== clickedUuid)
        )
      } else {
        // append the uuid to the array
        setIncidentsMarkedToApprove([...incidentsMarkedToApprove, clickedUuid])
      }
    },
    [incidentsMarkedToApprove]
  )

  const handleChangePagination = useCallback(
    (newPageIndex, pagination) => {
      setPagination({
        ...pagination,
        start: newPageIndex * PAGINATION_LIMIT,
      })
    },
    [pagination.start]
  )

  // Search for incidents and refetch when filters or pagination changes
  const fetchCallback = async () => {
    const response = await onFetchIncidents(
      {
        start: pagination.start,
        limit: PAGINATION_LIMIT,
        searchFilter,
        incidentType,
      },
      filters,
      organisation
    )
    if (response) {
      setPagination(response)
    }
  }

  const debouncedFetch = useDebouncedCallback(fetchCallback, 350)

  // Use debounced fetch for search filter change.
  useEffect(
    () => {
      // Don't run this on initial mount.
      if (prevSearch.current !== null) {
        debouncedFetch()
      }
    },
    [searchFilter]
  )

  // Don't debounce the search on button clicks
  useEffect(
    () => {
      fetchCallback()
    },
    [pagination.start, incidentType, filters.value]
  )

  return {
    handleEditSubmit,
    handleDeleteIncident,
    handleApproveIncidents,
    incidentType,
    searchFilter,
    handleChangeIncidentType,
    handleChangeSearchFilter,
    handleRevertIncident,
    pagination,
    handleChangePagination,
    incidentsMarkedToApprove,
    handleChangeMarkedToApprove,
  }
}
