import { getTokenExpirationTime, refreshBuffer } from 'utils/authentication'
import { refreshSession } from 'modules/authentication/actions'
import { differenceInMinutes } from 'date-fns'

export function refreshSessionMiddleware({ getState }) {
  return (next) => async (action) => {
    // only worry about expiring token for async actions
    if (typeof action === 'function') {
      const token = getState().authentication.token

      if (token) {
        // decode jwt so that we know if and when it expires
        const tokenExpiration = getTokenExpirationTime(token)
        const minutesUntilRefresh = differenceInMinutes(
          tokenExpiration,
          Date.now()
        )

        const shouldRefresh = minutesUntilRefresh < refreshBuffer

        if (shouldRefresh) {
          // make sure we are not already refreshing the token and it hasn't errored
          if (
            !getState().progress.refreshSession.inProgress &&
            !getState().progress.refreshSession.hasError
          ) {
            await refreshSession()
          }
          return next(action)
        }
      }
    }

    return next(action)
  }
}

export default refreshSessionMiddleware
