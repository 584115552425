import React from 'react'

export function Eye(props) {
  return (
    <svg width="22px" height="15px" viewBox="0 0 22 15" {...props}>
      <defs>
        <path
          d="M12,5 C7,5 2.73,8.11 1,12.5 C2.73,16.89 7,20 12,20 C17,20 21.27,16.89 23,12.5 C21.27,8.11 17,5 12,5 Z M12,17.5 C9.24,17.5 7,15.26 7,12.5 C7,9.74 9.24,7.5 12,7.5 C14.76,7.5 17,9.74 17,12.5 C17,15.26 14.76,17.5 12,17.5 Z M12,9.5 C10.34,9.5 9,10.84 9,12.5 C9,14.16 10.34,15.5 12,15.5 C13.66,15.5 15,14.16 15,12.5 C15,10.84 13.66,9.5 12,9.5 Z"
          id="path-1"
        />
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="molecule-/-Text-Field-/-Filled-/-Actove-with-Input"
          transform="translate(-293.000000, -29.000000)"
        >
          <g id="Group">
            <g
              id="atom-/-Icon-/-Visibility-On"
              transform="translate(292.000000, 24.000000)"
            >
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Mask" fillRule="nonzero" />
              <g
                id="✱-/-Color-/-Primary"
                mask="url(#mask-2)"
                fill="#3574FA"
                fillRule="evenodd"
              >
                <rect id="Rectangle-13" x="0" y="0" width="24" height="24" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Eye
