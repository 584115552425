import React from 'react'
import PropTypes from 'prop-types'
import ChipInput from 'components/molecules/ChipInput'
import MessageContainer from 'components/molecules/MessageContainer'
import {
  StyledFields,
  StyledTitle,
  StyledDescription,
  StyledFooterText,
  StyledAutosuggest,
  Actions,
  ActionButton,
  InnerContent,
  StyledSeatsRemaining,
  StyledForm,
  StyledInputActions,
  StyledProgressLoader,
  StyledSelectAndTextInput,
} from './InviteMembersForm.styles'
import { getFormattedMobileNumber } from 'utils/formatting.ts'
import { isValidMobileNumberForRegion } from 'utils/validation'

export function InviteMembersForm({
  className,
  values,
  errors,
  progress,
  setFieldValue,
  onAddNumber,
  onFieldFocus,
  onPasteNumbers,
  handleBlur,
  handleChange,
  handleSubmit,
  onDeleteNumber,
  touched,
  groups,
  organisationCountries,
  onCancel,
  onClickClearAll,
  getInvitationProgress,
  seatsRemaining,
}) {
  return (
    <StyledForm noValidate autoComplete="off" className={className}>
      <StyledTitle variant="h2">Invite organisation members</StyledTitle>
      <StyledDescription variant="body1">
        Members are app users and will not have access to the organisation
        dashboard. Members must be assigned to a group on invitation.
      </StyledDescription>
      <InnerContent>
        {Number.isInteger(seatsRemaining) ? (
          seatsRemaining > 0 ? (
            <StyledSeatsRemaining fullWidth themeColor="primary">
              {seatsRemaining} seats left in organisation
            </StyledSeatsRemaining>
          ) : (
            <StyledSeatsRemaining fullWidth themeColor="error">
              There are no remaining seats in your organisation. Please contact
              a chnnl admin
            </StyledSeatsRemaining>
          )
        ) : null}
        <StyledFields>
          <StyledAutosuggest
            placeholder="Search for a group to invite members to"
            value={values.group}
            name="group"
            onChange={(e, { newValue }) => {
              setFieldValue('group', newValue)
            }}
            error={touched.group && !!errors.group}
            onBlur={handleBlur}
            helperText={touched.group ? errors.group : ''}
            suggestions={groups.map((group) => ({
              label: group.name,
              value: group.uuid,
            }))}
            noneSelectedHelperText="This group does not exist"
          />
          <div onPaste={onPasteNumbers}>
            <StyledSelectAndTextInput
              type="text"
              selectInputName="region"
              textInputName="phone"
              onFocus={onFieldFocus}
              selectValue={values.region}
              textInputValue={values.phone}
              error={errors.phone}
              touched={touched.phone}
              selections={organisationCountries}
              onChange={handleChange}
              onBlur={handleBlur}
              onAdd={onAddNumber}
              variant="outlined"
              size="extra-small"
              placeholder="Member Phone Number"
              flags
            />
            <br />
            <ChipInput
              value={values.numbers}
              textValue={values.numberText}
              onAdd={(number) => onAddNumber(number)}
              onDelete={(number) => onDeleteNumber(number)}
              error={(touched.phone || touched.numbers) && !!errors.numbers}
              name="numbers"
              onBlur={handleBlur}
              disabled
            />
          </div>
          <StyledInputActions>
            <ActionButton onClick={onClickClearAll}>clear all</ActionButton>
          </StyledInputActions>
          <MessageContainer
            seatsRemaining={seatsRemaining}
            progress={progress}
            errors={errors}
            touched={touched}
            values={values}
          />
        </StyledFields>
        <Actions>
          <ActionButton
            variant="outlined"
            size="small"
            type="button"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </ActionButton>
          {getInvitationProgress.inProgress ? (
            <ActionButton
              type="submit"
              variant="contained"
              size="small"
              color="primary"
              disabled
            >
              <StyledProgressLoader />{' '}
            </ActionButton>
          ) : (
            <ActionButton
              variant="contained"
              size="small"
              color="primary"
              onClick={handleSubmit}
              disabled={
                values.numbers.length === 0 &&
                !isValidMobileNumberForRegion(
                  getFormattedMobileNumber(values.phone, values.region)
                )
              }
            >
              Send invite
            </ActionButton>
          )}
        </Actions>
        <StyledFooterText variant="body1">
          We will protect the phone number of all users and any reports will be
          anonymized. Please see{' '}
          <a
            href="https://www.chnnl.app/assets/64fd631270/Chnnl-Terms-of-Use-and-Privacy-Policy-.pdf"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            Privacy Policy
          </a>{' '}
          for more information or{' '}
          <a
            href="https://www.chnnl.app/contact-us/"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            get in touch
          </a>
          ?
        </StyledFooterText>
      </InnerContent>
    </StyledForm>
  )
}

InviteMembersForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  onFieldFocus: PropTypes.func,
  onPasteNumbers: PropTypes.func,
  errors: PropTypes.object,
  reset: PropTypes.func,
  failedInvites: PropTypes.arrayOf(PropTypes.object),
  successfulInvites: PropTypes.arrayOf(PropTypes.object),
  touched: PropTypes.object,
  values: PropTypes.object,
  onAddNumber: PropTypes.func,
  onDeleteNumber: PropTypes.func,
  handleNumberInputChange: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  organisationCountries: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  getInvitationProgress: PropTypes.object,
  onClickClearAll: PropTypes.func,
  seatsRemaining: PropTypes.number,
  progress: PropTypes.object,
}

InviteMembersForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  progress: {},
  groups: [],
  organisationCountries: [],
}

export default InviteMembersForm
