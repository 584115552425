import React from 'react'
import PropTypes from 'prop-types'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import AppLayout from 'layouts/AppLayout'
import styled from 'styled-components'
import PathwaysForm from 'components/organisms/PathwaysForm'

export const StyledHeader = styled.header``

export function Pathways({
  onSubmitPathways,
  pathways,
  hasEditAccess,
  getPathwaysProgress,
  onResetPathway,
  updatePathwaysProgress,
}) {
  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <PathwaysForm
            disableEditting={!hasEditAccess}
            onSubmit={onSubmitPathways}
            onResetPathway={onResetPathway}
            pathways={pathways}
            progress={getPathwaysProgress}
            updateProgress={updatePathwaysProgress}
          />
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Pathways.propTypes = {
  onSubmitPathways: PropTypes.func,
  getPathwaysProgress: PropTypes.object,
  pathways: PropTypes.array,
  onResetPathway: PropTypes.func,
  hasEditAccess: PropTypes.bool,
  updatePathwaysProgress: PropTypes.object,
}

export default Pathways
