import * as authActions from 'modules/authentication/actions'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    ({ authentication: { API_RESET_PASSWORD_SUCCESS } } = { authentication: { API_RESET_PASSWORD_SUCCESS: false } }) => {
      return {
        resetPasswordSuccess: API_RESET_PASSWORD_SUCCESS
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withHandlers({
    onSubmit: ({ actions, location }) => ({ password }) => {
      const token = location.search.replace('?token=', '')
      actions.resetPassword(password, token)
    },
  })
)
