import { groupListSchema, groupMemberSchema } from './schema'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import { changeOrgDetails } from 'modules/organisations/actions'
import idx from 'idx'

// Actions
export const API_GET_GROUPS_REQUEST = 'API_GET_GROUPS_REQUEST'
export const API_GET_GROUPS_SUCCESS = 'API_GET_GROUPS_SUCCESS'
export const API_GET_GROUPS_ERROR = 'API_GET_GROUPS_ERROR'
export const API_GET_GROUP_MEMBERS_REQUEST = 'API_GET_GROUP_MEMBERS_REQUEST'
export const API_GET_GROUP_MEMBERS_SUCCESS = 'API_GET_GROUP_MEMBERS_SUCCESS'
export const API_GET_GROUP_MEMBERS_ERROR = 'API_GET_GROUP_MEMBERS_ERROR'
export const API_DELETE_GROUP_REQUEST = 'API_DELETE_GROUP_REQUEST'
export const API_DELETE_GROUP_SUCCESS = 'API_DELETE_GROUP_SUCCESS'
export const API_DELETE_GROUP_ERROR = 'API_DELETE_GROUP_ERROR'
export const API_CREATE_GROUP_REQUEST = 'API_CREATE_GROUP_REQUEST'
export const API_CREATE_GROUP_SUCCESS = 'API_CREATE_GROUP_SUCCESS'
export const API_CREATE_GROUP_ERROR = 'API_CREATE_GROUP_ERROR'
export const API_UPDATE_GROUP_REQUEST = 'API_UPDATE_GROUP_REQUEST'
export const API_UPDATE_GROUP_SUCCESS = 'API_UPDATE_GROUP_SUCCESS'
export const API_UPDATE_GROUP_ERROR = 'API_UPDATE_GROUP_ERROR'
export const API_INVITE_MEMBERS_TO_GROUP_RESET =
  'API_INVITE_MEMBERS_TO_GROUP_RESET'
export const API_INVITE_MEMBERS_TO_GROUP_REQUEST =
  'API_INVITE_MEMBERS_TO_GROUP_REQUEST'
export const API_INVITE_MEMBERS_TO_GROUP_SUCCESS =
  'API_INVITE_MEMBERS_TO_GROUP_SUCCESS'
export const API_INVITE_MEMBERS_TO_GROUP_ERROR =
  'API_INVITE_MEMBERS_TO_GROUP_ERROR'
export const API_RESEND_MEMBER_INVITATION_REQUEST =
  'API_RESEND_MEMBER_INVITATION_REQUEST'
export const API_RESEND_MEMBER_INVITATION_SUCCESS =
  'API_RESEND_MEMBER_INVITATION_SUCESS'
export const API_RESEND_MEMBER_INVITATION_ERROR =
  'API_RESEND_MEMBER_INVITATION_ERROR'
export const API_RESEND_MULTI_MEMBER_INVITATION_REQUEST =
  'API_RESEND_MULTI_MEMBER_INVITATION_REQUEST'
export const API_RESEND_MULTI_MEMBER_INVITATION_SUCCESS =
  'API_RESEND_MULTI_MEMBER_INVITATION_SUCESS'
export const API_RESEND_MULTI_MEMBER_INVITATION_ERROR =
  'API_RESEND_MULTI_MEMBER_INVITATION_ERROR'

// Action creators
export function getGroups(organisationUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUPS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/group`
      )
      const data = normalize(response.data.data, groupListSchema)

      dispatch({
        type: API_GET_GROUPS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_GROUPS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the groups for this organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getGroupMembers(organisationUuid, groupUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUP_MEMBERS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/group/${groupUuid}/members`
      )
      const responseData = idx(response, (_) => _.data.data[0])
      const data = {
        ...responseData,
        members: normalize(responseData.members, [groupMemberSchema]).entities
          .members,
      }
      dispatch({ type: API_GET_GROUP_MEMBERS_SUCCESS, data })
    } catch (error) {
      dispatch({ type: API_GET_GROUP_MEMBERS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching this particular group. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function createGroup({ orgUuid, name, newAdmins, admins }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CREATE_GROUP_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group`,
        {
          name,
          admins: {
            email: newAdmins,
            userUuid: admins,
          },
        }
      )
      const data = normalize(response.data.data, groupListSchema)

      dispatch({ type: API_CREATE_GROUP_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The group was successfully created!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_CREATE_GROUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error creating the group. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function deleteGroup({ orgUuid, groupUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_GROUP_REQUEST })

    try {
      await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}`
      )

      dispatch({
        type: API_DELETE_GROUP_SUCCESS,
        data: {
          uuid: groupUuid,
        },
      })

      dispatch(
        addToast({
          message: 'The group was successfully deleted',
          type: TOAST_TYPE_SUCCESS,
        })
      )

      return true
    } catch (error) {
      dispatch({ type: API_DELETE_GROUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error deleting the group. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )

      return false
    }
  }
}

export function resendInvitation({ groupUuid, orgUuid, userUuid }) {
  return async (dispatch) => {
    dispatch({ type: API_RESEND_MEMBER_INVITATION_REQUEST })
    try {
      const { data } = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/resend/${userUuid}`
      )
      const result = data.data[0]
      if (result && result.success) {
        dispatch({ type: API_RESEND_MEMBER_INVITATION_SUCCESS })
        dispatch(
          addToast({
            message: 'The user reinvitation successfully sent.',
            type: TOAST_TYPE_SUCCESS,
          })
        )

        return result.success
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      dispatch({ type: API_RESEND_MEMBER_INVITATION_ERROR, error })
      dispatch(
        addToast({
          message:
            error.message ||
            'There was an error reinviting the user. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function broadcastInvitations({ groupUuid, orgUuid, userUuids }) {
  return async (dispatch) => {
    dispatch({ type: API_RESEND_MULTI_MEMBER_INVITATION_REQUEST })
    try {
      const { data } = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/resend/`,
        {
          userUuids,
        }
      )
      const result = data.data[0]
      if (result && result.success) {
        dispatch({ type: API_RESEND_MULTI_MEMBER_INVITATION_SUCCESS })
        dispatch(
          addToast({
            message: `The successfully sent ${userUuids.length} invitations.`,
            type: TOAST_TYPE_SUCCESS,
          })
        )
        return result.success
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      dispatch({ type: API_RESEND_MULTI_MEMBER_INVITATION_ERROR, error })
      dispatch(
        addToast({
          message:
            error.message ||
            'There was an error reinviting users. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function inviteMembersToGroupReset() {
  return function dispatcher(dispatch) {
    dispatch({ type: API_INVITE_MEMBERS_TO_GROUP_RESET })
  }
}

export function inviteMembersToGroup({ groupUuid, numbers, orgUuid }) {
  return async function dispatcher(dispatch, getState) {
    dispatch({ type: API_INVITE_MEMBERS_TO_GROUP_REQUEST })

    try {
      const state = getState()
      const seatsOccupied = state.organisations.entities[orgUuid].seatsOccupied
      const contactCount = numbers.length
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/invite`,
        {
          groupUuid,
          numbers: numbers,
        }
      )
      const failedInvites = response.data.data.filter(
        (invite) => !invite.success
      )
      const successfulInvites = response.data.data.filter(
        (invite) => invite.success
      )
      if (failedInvites.length > 0) {
        throw new Error(
          failedInvites
            .map((invite) => {
              return `${invite.message}: ${invite.value}`
            })
            .join(', ')
        )
      } else {
        dispatch({
          type: API_INVITE_MEMBERS_TO_GROUP_SUCCESS,
        })
        dispatch(
          changeOrgDetails({
            orgUuid,
            seatsOccupied:
              seatsOccupied + contactCount - Object.keys(failedInvites).length,
          })
        )
      }

      if (successfulInvites.length > 0) {
        dispatch(
          addToast({
            message: `${successfulInvites.length} invitation${
              successfulInvites.length === 1 ? ' was' : 's were'
            } sent.`,
            type: TOAST_TYPE_SUCCESS,
          })
        )
      }
    } catch (error) {
      dispatch({ type: API_INVITE_MEMBERS_TO_GROUP_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error inviting the members to this group. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )

      return { failedInvites: [], successfulInvites: [] }
    }
  }
}

export function updateGroup({ groupUuid, name, orgUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_GROUP_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}`,
        {
          name,
        }
      )

      const data = normalize(response.data.data, groupListSchema)

      dispatch({ type: API_UPDATE_GROUP_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The group has been successfully updated',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_GROUP_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error updating this group. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
