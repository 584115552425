import React from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Link from 'components/atoms/Link'
import { LOGIN } from 'constants/routes'
import styled from 'styled-components'

export const StyledContent = styled.div`
  text-align: center;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function PageNotFound() {
  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">Page not found</StyledTitle>
        <Link to={LOGIN}>Login</Link>
      </StyledContent>
    </DefaultLayout>
  )
}

export default PageNotFound
