import React from 'react'
import { PropTypes } from 'prop-types'
import StatusTag from 'components/atoms/StatusTag'
import styled from 'styled-components'
import { Typography } from '@weareroam/cake-ui-v1'

export const StyledMessageContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm}px;
`

export const StyledPhoneMessage = styled(StatusTag)`
  text-transform: none;
  padding: ${({ theme }) => theme.spacing.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`

export const MessageContainer = ({
  seatsRemaining,
  errors,
  touched,
  values,
  progress,
}) => {
  return (
    <StyledMessageContainer>
      {touched.phone && errors.numbers ? (
        <StyledPhoneMessage fullWidth themeColor="error">
          {errors.numbers}{' '}
          {values.numbers.length > seatsRemaining
            ? `Please remove ${values.numbers.length -
                seatsRemaining} phone contact recipients.`
            : ''}
        </StyledPhoneMessage>
      ) : null}
      {touched.phone && !progress.inProgress && progress.hasError ? (
        <StyledPhoneMessage fullWidth themeColor="error">
          <Typography>{progress.error.message}</Typography>
        </StyledPhoneMessage>
      ) : null}
    </StyledMessageContainer>
  )
}

MessageContainer.propTypes = {
  className: PropTypes.string,
  failedInvites: PropTypes.arrayOf(PropTypes.object),
  successfulInvites: PropTypes.arrayOf(PropTypes.object),
  seatsRemaining: PropTypes.number,
  progress: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
}

MessageContainer.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  progress: {},
}

export default MessageContainer
