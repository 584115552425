import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import * as wellbeingActions from 'modules/wellbeing/actions'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        lineChart: state.wellbeing.moodMonthly,
        barChart: state.wellbeing.moodOverall,
        reflectionReport: state.wellbeing.reflectionReport,
        attributeReport: state.wellbeing.attributeReport,
        error: state.wellbeing.error,
        orgUuid: match.params.orgUuid,
        groupUuid: match.params.groupUuid,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...organisationsActions,
        ...wellbeingActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  )
)
