import { SORT_DIRECTION_ASC } from 'constants/form'

/**
 * This returns a function that compares the 2 objects by the specified `key` param
 * @param {String} key       The object key to sort by
 * @param {String} direction The direction of the sort
 */
export function makeCompareFunc(key, direction) {
  const directionMultiplier = direction === SORT_DIRECTION_ASC ? 1 : -1

  return (a, b) => {
    if (a[key] > b[key]) {
      return 1 * directionMultiplier
    }

    if (a[key] < b[key]) {
      return -1 * directionMultiplier
    }

    return 0
  }
}

/**
 * Returns a sorted list of items.
 * @param {Array}    items        The array of items to sort
 * @param {String}   key          The key to compare by
 * @param {String}   direction    The direction of the sort
 * @returns {Array}               The sorted array of items
 */
export function sortItems(items, key = 'name', direction = SORT_DIRECTION_ASC) {
  const sortedItems = [...items]
  const compareFunc = makeCompareFunc(key, direction)

  sortedItems.sort(compareFunc)

  return sortedItems
}
