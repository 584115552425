import initialState from 'reducers/initialState'
import {
  API_GET_JOURNALS_SUCCESS,
  API_REVERT_JOURNAL_ERROR,
  API_EDIT_JOURNAL_SUCCESS,
  API_REVERT_JOURNAL_REQUEST,
  API_HIDE_JOURNAL_SUCCESS,
  API_APPROVE_JOURNALS_SUCCESS,
} from './actions'

export default function journalsReducer(state = initialState.journals, action) {
  switch (action.type) {
    case API_GET_JOURNALS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.journals,
        },
      }

    case API_REVERT_JOURNAL_REQUEST: {
      const { journalUuid } = action.data
      const journalEntry = state.entities[journalUuid]

      // Optimistically revert journal on request
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.journalUuid]: {
            ...journalEntry,
            editedEntry: null,
          },
        },
      }
    }

    case API_HIDE_JOURNAL_SUCCESS:
    case API_EDIT_JOURNAL_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.uuid]: {
            ...action.data,
          },
        },
      }
    case API_APPROVE_JOURNALS_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities.journals,
        },
      }
    }
    case API_REVERT_JOURNAL_ERROR: {
      const { journalUuid, editedEntry } = action.data
      const journalEntry = state.entities[journalUuid]

      // Revert journal back if the api request fails
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.journalUuid]: {
            ...journalEntry,
            editedEntry,
          },
        },
      }
    }

    default:
      return state
  }
}
