import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@weareroam/cake-ui-v1/CircularProgress'
import styled from 'styled-components'

export const StyledProgressLoader = styled.div`
  display: ${({ fullWidth }) => (fullWidth ? 'block' : 'inline-block')};
  margin: ${({ theme, fullWidth }) => (fullWidth ? theme.spacing.xl : 0)}px auto;
  text-align: center;
`

export const StyledLoader = styled(CircularProgress)``

export function ProgressLoader({
  className,
  fullWidth,
  color,
  size,
  thickness,
}) {
  return (
    <StyledProgressLoader fullWidth={fullWidth}>
      <StyledLoader
        className={className}
        color={color}
        size={size}
        thickness={thickness}
      />
    </StyledProgressLoader>
  )
}

ProgressLoader.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number,
  color: PropTypes.string,
}

ProgressLoader.defaultProps = {
  color: 'primary',
}

export default ProgressLoader
