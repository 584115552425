import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledKeyContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Left = styled.div`
  flex-basis: 33.33%;
`

export const Middle = styled.div`
  flex-basis: 33.33%;
`

export const Right = styled.div`
  flex-basis: 33.33%;
`

export const Name = styled(Typography)``
export const Position = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`
export const ContactNumber = styled(Typography)``
export const Extension = styled(Typography)``
export const Email = styled(Typography)``
export const Website = styled(Typography)``

export function KeyContact({
  name,
  position,
  contactNumber,
  extension,
  email,
  website,
}) {
  return (
    <StyledKeyContact>
      {(position || name) && (
        <Left>
          {name && <Name variant="body1">{name}</Name>}
          {position && <Position variant="body2">{position}</Position>}
        </Left>
      )}
      {contactNumber && (
        <Middle>
          <ContactNumber variant="body1">{contactNumber}</ContactNumber>
          {extension && <Extension variant="body1">Extn {extension}</Extension>}
        </Middle>
      )}
      {(email || website) && (
        <Right>
          {email && (
            <Email variant="body1">
              <a href={`mailto:${email}`}>{email}</a>
            </Email>
          )}
          {website && (
            <Website variant="body1">
              <a href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </a>
            </Website>
          )}
        </Right>
      )}
    </StyledKeyContact>
  )
}

KeyContact.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  contactNumber: PropTypes.string,
  extension: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
}

export default KeyContact
