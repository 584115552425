import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import EditIcon from '@weareroam/cake-ui-v1-icons/Edit'
import DeleteIcon from '@weareroam/cake-ui-v1-icons/Delete'

export const StyledEditActions = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};

  svg {
    cursor: pointer;
    margin-left: ${({ theme }) => theme.spacing.md}px;
    color: ${({ theme }) => theme.palette.tertiary.light};
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export function EditActions({ onEdit, onDelete, className }) {
  return (
    <StyledEditActions className={className}>
      {onEdit && <EditIcon onClick={onEdit} />}
      {onDelete && <DeleteIcon onClick={onDelete} />}
    </StyledEditActions>
  )
}

EditActions.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
}

export default EditActions
