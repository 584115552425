import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  PATHWAY_TYPE_POSITIVE,
  PATHWAY_TYPE_LOW_RISK,
  PATHWAY_TYPE_HIGH_RISK,
} from 'constants/ui'
import MoreActions from 'components/molecules/MoreActions'
import MenuItem from 'components/molecules/MenuItem'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledPathwayHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.lg}px
    ${({ theme }) => theme.spacing.md}px ${({ theme }) => theme.spacing.lg}px
    ${({ theme }) => theme.spacing.lg}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  border-left: 9px solid
    ${({ variant, theme }) => {
      switch (variant) {
        case PATHWAY_TYPE_LOW_RISK:
          return theme.palette.quaternary.dark
        case PATHWAY_TYPE_HIGH_RISK:
          return theme.palette.error.main
        case PATHWAY_TYPE_POSITIVE:
        default:
          return theme.palette.quaternary.main
      }
    }};
`

export const Left = styled.div``
export const Right = styled.div``

export const Title = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`
export const Message = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export function PathwayHeader({ title, message, onReset, variant, value }) {
  const handleOnReset = useCallback(
    () => {
      if (onReset) {
        onReset(value)
      }
    },
    [value]
  )

  return (
    <StyledPathwayHeader variant={variant}>
      <Left>
        <Title variant="h4">{title}</Title>
        <Message variant="body1">{message}</Message>
      </Left>
      <Right>
        {onReset && (
          <MoreActions>
            <MenuItem onClick={handleOnReset}>Reset to default</MenuItem>
          </MoreActions>
        )}
      </Right>
    </StyledPathwayHeader>
  )
}

PathwayHeader.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onReset: PropTypes.func,
  variant: PropTypes.oneOf([
    PATHWAY_TYPE_POSITIVE,
    PATHWAY_TYPE_LOW_RISK,
    PATHWAY_TYPE_HIGH_RISK,
  ]),
  value: PropTypes.string,
}

export default PathwayHeader
