import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { Button, Grid } from '@weareroam/cake-ui-v1'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import GroupInnerLayout from 'layouts/GroupInnerLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import GroupMemberInfo from 'components/molecules/GroupMemberInfo'
import SignupLink from 'components/molecules/SignupLink'
import MemberTable from 'components/organisms/MemberTable'
import authorisedRoles from 'components/hoc/authorisedRoles'
import { ROLE_ORG_OWNER, ROLE_SUPER_ADMIN } from 'constants/form'
import Modal from 'components/molecules/Modal'

export const StyledLayout = styled(AdminDashboardLayout)``
export const StyledTitle = styled(Grid)`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`
export const MemberCount = styled(Typography)`
  && {
    display: inline-flex;
    margin-left: ${({ theme }) => theme.spacing.xs}px;
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`
export const StyledDeleteUrlModal = styled(Modal)``

export const StyledActionBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-content: center;
`

export const ConfirmationTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const ConfirmationBody = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledDisclaimer = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`
export const StyledMemberInfo = styled(GroupMemberInfo)`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

const ProtectedMemberTable = authorisedRoles(
  [ROLE_ORG_OWNER, ROLE_SUPER_ADMIN],
  true
)(MemberTable)

export function Members({
  group,
  groupUuid,
  createUrl,
  archiveUrl,
  groupSignUpLink,
  getSignUpLinkProgress,
  createSignUpLinkProgress,
  archiveSignUpLinkProgress,
}) {
  const { memberCount } = group
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  useEffect(() => {}, [memberCount, group.members])

  const handleToggle = useCallback(
    () => {
      if (!!groupSignUpLink && !groupSignUpLink.linkUrl) {
        createUrl()
      } else {
        setIsConfirmationModalOpen(true)
      }
    },
    [groupSignUpLink]
  )

  return (
    <StyledLayout noPadding>
      <StyledDeleteUrlModal
        open={isConfirmationModalOpen}
        hasCloseButton
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <ConfirmationTitle variant="h2">{`Delete Link`}</ConfirmationTitle>
        <ConfirmationBody variant="body1">
          After the link is deactivated users who try to use this link will not
          be able to sign up. You'll have to create and distribute a new link to
          enable self-sign up again.
        </ConfirmationBody>
        <StyledActionBox>
          <Button
            type="button"
            variant="contained"
            color="tertiary"
            size="small"
            onClick={async () => {
              const result = await archiveUrl()
              if (result) {
                setIsConfirmationModalOpen(false)
              }
            }}
          >
            Delete
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setIsConfirmationModalOpen(false)
            }}
          >
            Cancel
          </Button>
        </StyledActionBox>
      </StyledDeleteUrlModal>
      <GroupInnerLayout>
        <ContentContainer>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <Grid item md={7} lg={6}>
              <StyledTitle>
                <Typography variant="subtitle1">Members</Typography>
                <MemberCount variant="h4">{memberCount}</MemberCount>
              </StyledTitle>
              <StyledDisclaimer variant="body1">
                {`You'll need a minimum of 20 members to generate reports`}
              </StyledDisclaimer>
            </Grid>
            <Grid item md={5} lg={6}>
              <SignupLink
                groupUuid={groupUuid}
                link={groupSignUpLink && groupSignUpLink.linkUrl}
                onChange={handleToggle}
                getProgress={getSignUpLinkProgress}
                progress={createSignUpLinkProgress}
                deletionProgress={archiveSignUpLinkProgress}
              />
            </Grid>
          </Grid>
          <StyledMemberInfo data={group} />
          <ProtectedMemberTable
            members={group && group.members}
            groupUuid={groupUuid}
          />
        </ContentContainer>
      </GroupInnerLayout>
    </StyledLayout>
  )
}

Members.propTypes = {
  group: PropTypes.object,
  groupUuid: PropTypes.string,
  groupSignUpLink: PropTypes.object,
  getSignUpLinkProgress: PropTypes.object,
  createSignUpLinkProgress: PropTypes.object,
  archiveSignUpLinkProgress: PropTypes.object,
  createUrl: PropTypes.func,
  archiveUrl: PropTypes.func,
}

Members.defaultProps = {
  group: {},
  groupSignUpLink: {},
}

export default Members
