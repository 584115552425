import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import Typography from '@weareroam/cake-ui-v1/Typography'
import MessagingHistory from 'components/organisms/MessagingHistory'

export const StyledHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const StyledDisclaimer = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export function Groups({
  hasEditAccess,
  messages,
  actions,
  match: {
    params: { orgUuid },
  },
}) {
  return (
    <AdminDashboardLayout>
      <ContentContainer>
        {hasEditAccess ? (
          <>
            <StyledHeader>
              <Typography variant="h2">Messaging</Typography>
              <Description variant="body1">
                Sending messages to your team promotes a sense of progress and
                empathy
              </Description>
            </StyledHeader>
            <MessagingHistory
              orgUuid={orgUuid}
              messages={messages}
              actions={actions}
            />
          </>
        ) : (
          <>
            <Typography variant="h2">Messaging</Typography>
            <StyledDisclaimer variant="body1">
              To view and send messages to your organisation, please ask your
              administrator for edit access
            </StyledDisclaimer>
          </>
        )}
      </ContentContainer>
    </AdminDashboardLayout>
  )
}

Groups.propTypes = {
  onSendMessage: PropTypes.func,
  hasEditAccess: PropTypes.bool,
  groups: PropTypes.array,
  messages: PropTypes.array,
  actions: PropTypes.object,
  match: PropTypes.object,
}

Groups.defaultProps = {
  messages: [],
  actions: {},
}

export default Groups
