import * as authActions from 'modules/authentication/actions'
import * as reflectionActions from 'modules/reflections/actions'
import { getReflectionsList } from 'modules/reflections/selectors'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        reflections: getReflectionsList(state),
        progress: state.progress.getReflections,
        getBoostersProgress: state.progress.getOrganisationBoosters,
        updateBoostersProgress: state.progress.updateOrganisationBoosters,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...reflectionActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { orgUuid },
        },
      } = this.props

      this.props.actions.getReflections(orgUuid)
    },
  }),
  withHandlers({
    onSubmitReflections: ({ actions, match }) => ({ reflections }) => {
      actions.updateOrganisationReflections({
        orgUuid: match.params.orgUuid,
        reflections,
      })
    },
  })
)
