import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FullChnnlLogo } from 'assets/images/svg'

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const StyledLogo = styled(FullChnnlLogo)`
  width: 120px;
  height: 120px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export function DefaultLayout({ children }) {
  return (
    <StyledLayout>
      <Inner>
        <StyledLogo />
        <div>{children}</div>
      </Inner>
    </StyledLayout>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
}

export default DefaultLayout
