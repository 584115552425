import {
  API_GET_WELLBEING_MOOD_SUCCESS,
  API_GET_WELLBEING_MONTHLY_MOOD_SUCCESS,
  API_GET_WELLBEING_REFLECTION_REPORT_SUCCESS,
  API_GET_WELLBEING_ATTRIBUTE_REPORT_SUCCESS,
  API_GET_WELLBEING_REFLECTION_REPORT_ERROR,
  API_GET_WELLBEING_REFLECTION_REPORT_WARNING,
} from './actions'
import initialState from 'reducers/initialState'

export default function wellbeingReducer(
  state = initialState.wellbeing,
  action
) {
  switch (action.type) {
    case API_GET_WELLBEING_MOOD_SUCCESS:
      return {
        ...state,
        moodOverall: action.data,
      }
    case API_GET_WELLBEING_MONTHLY_MOOD_SUCCESS:
      return {
        ...state,
        moodMonthly: action.data,
      }
    case API_GET_WELLBEING_REFLECTION_REPORT_SUCCESS:
      return {
        ...state,
        reflectionReport: action.data,
      }
    case API_GET_WELLBEING_ATTRIBUTE_REPORT_SUCCESS:
      return {
        ...state,
        attributeReport: action.data,
      }
    case API_GET_WELLBEING_REFLECTION_REPORT_WARNING:
      return {
        ...state,
        warning: action.warning,
      }
    case API_GET_WELLBEING_REFLECTION_REPORT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
