import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledIcon = styled.svg``

export function Logo({ className }) {
  return (
    <StyledIcon
      width="65px"
      height="18px"
      viewBox="0 0 65 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <defs>
        <polygon
          id="path-1"
          points="0 0.072447205 64.9923719 0.072447205 64.9923719 17.9932919 0 17.9932919"
        />
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="molecule-/-super-admin-header"
          transform="translate(-29.000000, -23.000000)"
        >
          <g
            id="Chnnl-Logo-Grey-RGB"
            transform="translate(29.000000, 23.000000)"
          >
            <g id="Group-4">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path
                d="M64.8928843,0.072447205 L64.1874463,1.05719255 L63.4950083,0.072447205 L63.3910083,0.072447205 L63.3910083,1.6112795 L63.5813884,1.6112795 L63.5813884,0.494496894 L64.1485537,1.30349068 L64.2306364,1.30349068 L64.8020992,0.494496894 L64.8020992,1.6112795 L64.9923719,1.6112795 L64.9923719,0.072447205 L64.8928843,0.072447205 Z M62.0753223,0.072447205 L62.0753223,0.252670807 L62.5469752,0.252670807 L62.5469752,1.6112795 L62.7547603,1.6112795 L62.7547603,0.252670807 L63.2222231,0.252670807 L63.2222231,0.072447205 L62.0753223,0.072447205 Z"
                id="Fill-1"
                fill="#828797"
                mask="url(#mask-2)"
              />
              <path
                d="M0,11.411441 C0,7.5603354 2.71775207,4.82288199 6.64600826,4.82288199 C9.11246281,4.82288199 11.3734959,6.05247205 12.4013554,8.2795528 L9.98034711,9.60193789 C9.27233058,8.39560248 8.08471074,7.60684472 6.69177686,7.60684472 C4.54484298,7.60684472 2.96894215,9.25390062 2.96894215,11.411441 C2.96894215,13.5688696 4.49928926,15.2158137 6.60023967,15.2158137 C7.97050413,15.2158137 9.34098347,14.403913 9.93479339,13.2439752 L12.3554793,14.6824099 C11.3049504,16.6775031 9.11246281,17.9998882 6.55468595,17.9998882 C2.69497521,17.9998882 0,15.2390683 0,11.411441"
                id="Fill-3"
                fill="#828797"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M14.2361818,0.0903354037 L17.1367934,0.0903354037 L17.1367934,6.74832298 C17.7990413,5.65803727 19.2377438,4.79962733 21.0650496,4.79962733 C23.5543884,4.79962733 25.8839669,6.47004969 25.8839669,10.0426584 L25.8839669,17.7213913 L22.9149174,17.7213913 L22.9149174,10.854559 C22.9149174,8.69713043 21.795843,7.5603354 20.1971653,7.5603354 C18.4386198,7.5603354 17.2052314,8.92911801 17.2052314,10.9009565 L17.2052314,17.7213913 L14.2361818,17.7213913 L14.2361818,0.0903354037"
              id="Fill-5"
              fill="#828797"
            />
            <path
              d="M28.5866777,5.12440994 L31.5099587,5.12440994 L31.5099587,6.88751553 C32.172314,5.65792547 33.5882397,4.79973913 35.4153306,4.79973913 C37.9276612,4.79973913 40.2799091,6.47004969 40.2799091,10.0426584 L40.2799091,17.7213913 L37.2879752,17.7213913 L37.2879752,10.8778137 C37.2879752,8.72038509 36.1691157,7.5603354 34.570438,7.5603354 C32.7891157,7.5603354 31.5556198,8.95226087 31.5556198,10.9242112 L31.5556198,17.7213913 L28.5866777,17.7213913 L28.5866777,5.12440994"
              id="Fill-6"
              fill="#828797"
            />
            <g
              id="Group-10"
              transform="translate(42.867769, 0.000000)"
              fill="#828797"
            >
              <path
                d="M0.0925041322,5.12440994 L3.01567769,5.12440994 L3.01567769,6.88751553 C3.67792562,5.65792547 5.09406612,4.79973913 6.92094215,4.79973913 C9.43327273,4.79973913 11.7855207,6.47004969 11.7855207,10.0426584 L11.7855207,17.7213913 L8.79380165,17.7213913 L8.79380165,10.8778137 C8.79380165,8.72038509 7.67472727,7.5603354 6.07604959,7.5603354 C4.2945124,7.5603354 3.06144628,8.95226087 3.06144628,10.9242112 L3.06144628,17.7213913 L0.0925041322,17.7213913 L0.0925041322,5.12440994"
                id="Fill-7"
              />
              <path
                d="M19.406314,15.1521988 C18.1958099,14.850559 17.3963636,13.7432795 17.3963636,11.9217019 L17.3963636,0.0903354037 L14.4047521,0.0903354037 L14.4047521,12.7569689 C14.4047521,16.3295776 16.7568926,17.9997764 19.2692231,17.9997764 C19.3156364,17.9997764 19.3605455,17.9969814 19.406314,17.9957516 L19.406314,15.1521988"
                id="Fill-9"
              />
            </g>
          </g>
        </g>
      </g>
    </StyledIcon>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
