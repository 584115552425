import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ContentContainer from 'components/atoms/ContentContainer'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import GroupInnerLayout from 'layouts/GroupInnerLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import MessagingHistory from 'components/organisms/MessagingHistory'

export const StyledLayout = styled(AdminDashboardLayout)``

export const StyledDisclaimer = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export function Messaging({
  hasEditAccess,
  messages,
  actions,
  match: {
    params: { orgUuid, groupUuid },
  },
  groups,
}) {
  return (
    <StyledLayout noPadding>
      <GroupInnerLayout>
        <ContentContainer>
          {hasEditAccess ? (
            <>
              <MessagingHistory
                orgUuid={orgUuid}
                groupUuid={groupUuid}
                groups={groups}
                messages={messages}
                actions={actions}
              />
            </>
          ) : (
            <>
              <Typography variant="h2">Messaging</Typography>
              <StyledDisclaimer variant="body1">
                To view and send messages to your organisation, please ask your
                administrator for edit access
              </StyledDisclaimer>
            </>
          )}
        </ContentContainer>
      </GroupInnerLayout>
    </StyledLayout>
  )
}

Messaging.propTypes = {
  hasEditAccess: PropTypes.bool,
  messages: PropTypes.array,
  actions: PropTypes.object,
  groups: PropTypes.array,
  match: PropTypes.object,
}

Messaging.defaultProps = {
  groups: [],
}

export default Messaging
