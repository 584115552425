import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Button from '@weareroam/cake-ui-v1/Button'
import { PASSWORD_MIN_LENGTH } from 'config'
import styled from 'styled-components'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const StyledInput = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledButton = styled(Button)`
  && {
    margin: ${({ theme }) => theme.spacing.sm}px;
    padding: ${({ theme }) => theme.spacing.sm}px;
    margin-top: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function ChangePasswordForm({
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) {
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledInput
        id="oldPassword"
        name="oldPassword"
        label="Old Password"
        type="password"
        value={values.oldPassword || ''}
        InputLabelProps={{ shrink: true }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.oldPassword && !!errors.oldPassword}
        helperText={touched.oldPassword ? errors.oldPassword : ''}
      />
      <StyledInput
        id="newPassword"
        name="newPassword"
        label="New Password"
        type="password"
        value={values.newPassword || ''}
        InputLabelProps={{ shrink: true }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.newPassword && !!errors.newPassword}
        helperText={
          (touched.newPassword ? errors.newPassword : '') ||
          `Needs to be at least ${PASSWORD_MIN_LENGTH} characters long`
        }
      />
      <StyledInput
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        value={values.confirmPassword || ''}
        InputLabelProps={{ shrink: true }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.confirmPassword && !!errors.confirmPassword}
        helperText={touched.confirmPassword ? errors.confirmPassword : ''}
      />
      <StyledButton
        size="small"
        variant="contained"
        type="submit"
        color="primary"
      >
        Update
      </StyledButton>
    </StyledForm>
  )
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
}

ChangePasswordForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default ChangePasswordForm
