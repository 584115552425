import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import * as exportActions from 'modules/export/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        organisations: getOrganisationsList(state),
        getOrganisationsProgress: state.progress.getOrganisations,
        organisationEntities: state.organisations.entities,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...exportActions,
        ...organisationsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withState(
    'isNewOrganisationModalOpen',
    'setIsNewOrganisationModalOpen',
    false
  ),
  withState('isResendModalOpen', 'setIsResendModalOpen', false),
  withState(
    'isDeleteOrganisationModalOpen',
    'setIsDeleteOrganisationModalOpen',
    false
  ),
  withState('modalOrganisationId', 'setModalOrganisationId', null),
  lifecycle({
    componentDidMount() {
      this.props.actions.getOrganisations()
    },
  }),
  withHandlers({
    onExport: ({ actions }) => () => {
      actions.exportAllOrgData()
    },
    onOrgExport: ({ actions }) => (orgUuid) => {
      actions.exportOrgData({ orgUuid })
    },
    onInviteNewOrganisation: ({ actions, setIsNewOrganisationModalOpen }) => ({
      name,
      email,
      incidentsContactName,
      incidentsContactEmail,
      numberOfSeats,
      industry,
    }) => {
      actions.createOrganisation({
        name,
        orgOwnerEmail: email,
        incidentsContactName,
        incidentsContactEmail,
        seats: numberOfSeats,
        industry,
      })

      setIsNewOrganisationModalOpen(false)
    },
    onResendInvitation: ({
      actions,
      setIsResendModalOpen,
      modalOrganisationId,
    }) => ({ email }) => {
      actions.resendOrganisationInvitation({ uuid: modalOrganisationId, email })
      setIsResendModalOpen(false)
    },
    onDeleteOrganisation: ({
      actions,
      modalOrganisationId,
      setIsDeleteOrganisationModalOpen,
    }) => (e) => {
      e.preventDefault()
      actions.deleteOrganisation(modalOrganisationId)
      setIsDeleteOrganisationModalOpen(false)
    },
  })
)
