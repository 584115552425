import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import PasswordField from 'components/molecules/PasswordField'
import Button from '@weareroam/cake-ui-v1/Button'
import { PASSWORD_MIN_LENGTH } from 'config'
import styled from 'styled-components'

export const StyledName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledPassword = styled(PasswordField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;

    input {
      background-color: transparent !important;
    }
  }
`

export function SignupForm({
  className,
  errors,
  handleSubmit,
  values,
  touched,
  handleChange,
  handleBlur,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <StyledName
        id="firstName"
        name="firstName"
        label="First name"
        type="text"
        value={values.firstName}
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={touched.firstName ? errors.firstName : ''}
        error={touched.firstName && !!errors.firstName}
        fullWidth
      />
      <StyledName
        id="lastName"
        name="lastName"
        label="Last name"
        type="text"
        value={values.lastName}
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={touched.lastName ? errors.lastName : ''}
        error={touched.lastName && !!errors.lastName}
        fullWidth
      />
      <StyledPassword
        name="password"
        label="Password"
        error={touched.password && !!errors.password}
        value={values.password}
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={
          (touched.password ? errors.password : '') ||
          `Needs to be at least ${PASSWORD_MIN_LENGTH} characters long`
        }
        fullWidth
      />
      <Button
        variant="contained"
        type="submit"
        fullWidth
        color="primary"
        size="large"
        id="create-account-button"
      >
        Create account
      </Button>
    </form>
  )
}

SignupForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
}

SignupForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default SignupForm
