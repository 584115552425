import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import admins from 'modules/admins/reducer'
import activity from 'modules/activity/reducer'
import authentication from 'modules/authentication/reducer'
import boosters from 'modules/boosters/reducer'
import countries from 'modules/countries/reducer'
import groups from 'modules/groups/reducer'
import groupSignUpLinks from 'modules/groupSignUpLink/reducer'
import journals from 'modules/journals/reducer'
import incidents from 'modules/speakUp/reducer'
import keyContacts from 'modules/keyContacts/reducer'
import messaging from 'modules/messaging/reducer'
import organisations from 'modules/organisations/reducer'
import orgCountries from 'modules/orgCountries/reducer'
import pathways from 'modules/pathways/reducer'
import progress from 'modules/progress'
import reflections from 'modules/reflections/reducer'
import branchData from 'modules/selfSignUp/reducer'
import survey from 'modules/survey/reducer'
import toasts from 'modules/toasts/reducer'
import user from 'modules/user/reducer'
import wellbeing from 'modules/wellbeing/reducer'
import chatbot from 'modules/chatbot/reducer'
import { history } from 'store/history'

const reducers = combineReducers({
  router: connectRouter(history),
  activity,
  admins,
  authentication,
  groups,
  groupSignUpLinks,
  boosters,
  branchData,
  countries,
  journals,
  incidents,
  keyContacts,
  messaging,
  organisations,
  orgCountries,
  pathways,
  progress,
  reflections,
  survey,
  toasts,
  user,
  wellbeing,
  chatbot,
})

export default reducers
