import { useMemo } from 'react'
import { differenceInMonths, subMonths } from 'date-fns'

/**
 * Returns all the months from the current date until the specified date
 * Usually used in the month filters throughout the dashboard
 * @param   {Date}  date  The date to go back to
 * @returns {Array}       The array of dates
 */
export default function useMonthsSinceDate(date) {
  return useMemo(() => {
    const numMonthsSiceDate = Math.abs(differenceInMonths(new Date(), date)) + 1

    return Array(numMonthsSiceDate)
      .fill()
      .map((v, monthIndex) => {
        return subMonths(new Date(), monthIndex)
      })
  }, [date])
}
