import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from 'components/molecules/Box'
import Tab from 'components/molecules/Tab'
import ReflectionReport from 'components/organisms/ReflectionReport'
import AttributeReport from 'components/organisms/AttributeReport'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import DropdownFilter from 'components/molecules/DropdownFilter'
import FilterLabel from 'components/atoms/FilterLabel'
import MenuItem from 'components/molecules/MenuItem'
import styled from 'styled-components'
import { MONTH_FILTER_FORMAT } from 'constants/date'
import {
  MOOD_OVERALL,
  MOOD_CHEERFUL,
  MOOD_CALM,
  MOOD_ACTIVE,
  MOOD_RESTED,
  MOOD_INTERESTED,
  ATTRIBUTE_GENDER,
  ATTRIBUTE_AGE_RANGE,
  ATTRIBUTE_ETHNICITY,
} from 'constants/form'
import { format } from 'date-fns'

export const Tabs = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const Reports = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
`

export const AttributeMoodFilters = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.md}px 0
    ${({ theme }) => theme.spacing.lg}px;
`

export const AttributeMoodButtons = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing.sm}px;
`

export function WellbeingReports({
  filteredMonth,
  attributeReport,
  reflectionReport,
  onChangeMood,
  mood,
  error,
  attribute,
  onChangeAttribute,
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const customReflections = useMemo(
    () => {
      if (reflectionReport) {
        return reflectionReport.filter(
          (report) => report.reflection.organisationId != null
        )
      }
    },
    [reflectionReport]
  )
  const standardReflections = useMemo(
    () => {
      if (reflectionReport) {
        return reflectionReport.filter(
          (report) => report.reflection.organisationId == null
        )
      }
    },
    [reflectionReport]
  )

  return (
    <Box>
      <Tabs>
        <Tab
          activeText={`for ${format(filteredMonth, MONTH_FILTER_FORMAT)}`}
          isActive={activeTabIndex === 0}
          onClick={() => setActiveTabIndex(0)}
        >
          Wellbeing vs Reflection report
        </Tab>
        <Tab
          activeText={`for ${format(filteredMonth, MONTH_FILTER_FORMAT)}`}
          isActive={activeTabIndex === 1}
          onClick={() => setActiveTabIndex(1)}
        >
          Wellbeing vs Attribute report
        </Tab>
      </Tabs>
      <Reports>
        {/* Reflection report tab content */}
        {activeTabIndex === 0 && (
          <>
            <ReflectionReport
              title="Custom reflections"
              type="CUSTOM"
              data={customReflections}
            />
            <ReflectionReport
              title="Standard reflections"
              type="STANDARD"
              error={error}
              data={standardReflections}
            />
          </>
        )}
        {/* Attribute report tab content */}
        {activeTabIndex === 1 && (
          <React.Fragment>
            <DropdownFilter
              label="View attribute:"
              value={attribute}
              onChange={onChangeAttribute}
            >
              <MenuItem color="primary" value={ATTRIBUTE_GENDER}>
                Gender
              </MenuItem>
              <MenuItem color="primary" value={ATTRIBUTE_AGE_RANGE}>
                Age range
              </MenuItem>
              <MenuItem color="primary" value={ATTRIBUTE_ETHNICITY}>
                Ethnicity
              </MenuItem>
            </DropdownFilter>

            <AttributeMoodFilters>
              <FilterLabel>Filter by mood: </FilterLabel>
              <AttributeMoodButtons>
                <ButtonSwitch
                  value={MOOD_OVERALL}
                  isActive={mood === MOOD_OVERALL}
                  onClick={onChangeMood}
                >
                  Overall
                </ButtonSwitch>
                <ButtonSwitch
                  value={MOOD_CHEERFUL}
                  isActive={mood === MOOD_CHEERFUL}
                  onClick={onChangeMood}
                >
                  Cheerful
                </ButtonSwitch>
                <ButtonSwitch
                  value={MOOD_CALM}
                  isActive={mood === MOOD_CALM}
                  onClick={onChangeMood}
                >
                  Calm
                </ButtonSwitch>
                <ButtonSwitch
                  value={MOOD_ACTIVE}
                  isActive={mood === MOOD_ACTIVE}
                  onClick={onChangeMood}
                >
                  Active
                </ButtonSwitch>
                <ButtonSwitch
                  value={MOOD_RESTED}
                  isActive={mood === MOOD_RESTED}
                  onClick={onChangeMood}
                >
                  Rested
                </ButtonSwitch>
                <ButtonSwitch
                  value={MOOD_INTERESTED}
                  isActive={mood === MOOD_INTERESTED}
                  onClick={onChangeMood}
                >
                  Interested
                </ButtonSwitch>
              </AttributeMoodButtons>
            </AttributeMoodFilters>
            <AttributeReport data={attributeReport} />
          </React.Fragment>
        )}
      </Reports>
    </Box>
  )
}

WellbeingReports.propTypes = {
  filteredMonth: PropTypes.string,
  attributeReport: PropTypes.arrayOf(PropTypes.object),
  reflectionReport: PropTypes.arrayOf(PropTypes.object),
  onChangeMood: PropTypes.func,
  mood: PropTypes.string,
  attribute: PropTypes.string,
  error: PropTypes.object,
  onChangeAttribute: PropTypes.func,
}

export default WellbeingReports
