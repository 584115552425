import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import EditActions from 'components/molecules/EditActions'

export const StyledPathwayItem = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding: ${({ theme }) => theme.spacing.lg}px;

  &:last-of-type {
    border-bottom: none;
  }
`

export const Left = styled.div`
  width: 25%;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.lg}px;
`

export const Right = styled.div`
  padding-right: 100px;
  flex-grow: 1;
`

export const Position = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const Details = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  display: flex;
`

export const DetailsLeft = styled.div`
  flex-basis: 30%;
  margin-right: ${({ theme }) => theme.spacing.md}px;
`

export const DetailsRight = styled.div`
  flex-basis: 70%;
`

export const StyledEditActions = styled(EditActions)`
  position: absolute;
  right: ${({ theme }) => theme.spacing.md}px;
  top: 50%;
  transform: translateY(-50%);
`

export function PathwayItem({
  fullName,
  position,
  contactNumber,
  extension,
  email,
  website,
  description,
  onEdit,
  onDelete,
  value,
  disabled,
}) {
  const handleOnEdit = useCallback(
    () => {
      if (onEdit) {
        onEdit(value)
      }
    },
    [value]
  )

  const handleOnDelete = useCallback(
    () => {
      if (onDelete) {
        onDelete(value)
      }
    },
    [value]
  )

  return (
    <StyledPathwayItem>
      {!disabled && (
        <StyledEditActions onEdit={handleOnEdit} onDelete={handleOnDelete} />
      )}
      <Left>
        <Typography variant="body1">{fullName}</Typography>
        <Position variant="body2">{position}</Position>
      </Left>
      <Right>
        <Details>
          <DetailsLeft>
            {contactNumber && (
              <React.Fragment>
                <Typography variant="body1">{contactNumber}</Typography>
                {extension && (
                  <Typography variant="body1">Extn {extension}</Typography>
                )}
              </React.Fragment>
            )}
          </DetailsLeft>
          <DetailsRight>
            {email && (
              <Typography variant="body1">
                <a href={`mailto:${email}`}>{email}</a>
              </Typography>
            )}
            {website && (
              <Typography variant="body1">
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {website}
                </a>
              </Typography>
            )}
          </DetailsRight>
        </Details>
        {description && <Typography variant="body1">{description}</Typography>}
      </Right>
    </StyledPathwayItem>
  )
}

PathwayItem.propTypes = {
  fullName: PropTypes.string,
  position: PropTypes.string,
  contactNumber: PropTypes.string,
  extension: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
  description: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}

export default PathwayItem
