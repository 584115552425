import { compose, defaultProps } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      title: initialValues.title || '',
      message: initialValues.message || '',
    }),
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please enter a pathway title'),
      message: Yup.string().required('Please enter a pathway message'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  })
)
