import { initialProgressState } from 'modules/progress'
import {
  getUserRole,
  getRefreshToken,
  getSessionToken,
} from 'utils/authentication'

const initialState = {
  progress: {
    login: {
      ...initialProgressState,
    },
    register: {
      ...initialProgressState,
    },
    resetPassword: {
      ...initialProgressState,
    },
    newPassword: {
      ...initialProgressState,
    },
    getOrganisations: {
      ...initialProgressState,
    },
    getOrganisationAdmins: {
      ...initialProgressState,
    },
    getOrgCountries: {
      ...initialProgressState,
    },
    updateOrganisation: {
      ...initialProgressState,
    },
    uploadOrganisationLogo: {
      ...initialProgressState,
    },
    getGroups: {
      ...initialProgressState,
    },
    getOrganisationBoosters: {
      ...initialProgressState,
    },
    getJournals: {
      ...initialProgressState,
    },
    getIncidents: {
      ...initialProgressState,
    },
    getMessages: {
      ...initialProgressState,
    },
    getReflections: {
      ...initialProgressState,
    },
    getActivity: {
      ...initialProgressState,
    },
    getGroupMembers: {
      ...initialProgressState,
    },
    getKeyContacts: {
      ...initialProgressState,
    },
    getSettings: {
      ...initialProgressState,
    },
    getPathways: {
      ...initialProgressState,
    },
    updatePathways: {
      ...initialProgressState,
    },
    getSurvey: {
      ...initialProgressState,
    },
    inviteUser: {
      ...initialProgressState,
    },
    reinviteGroupMember: {
      ...initialProgressState,
    },
    resendMultipleMemberInvites: {
      ...initialProgressState,
    },
    inviteGroupMembers: {
      ...initialProgressState,
    },
    createGroup: {
      ...initialProgressState,
    },
    addBooster: {
      ...initialProgressState,
    },
    getSignUpLink: {
      ...initialProgressState,
    },
    createSignUpLink: {
      ...initialProgressState,
    },
    archiveSignUpLink: {
      ...initialProgressState,
    },
    deleteBooster: {
      ...initialProgressState,
    },
    updateBooster: {
      ...initialProgressState,
    },
    updateOrganisationBoosters: {
      ...initialProgressState,
    },
    updateGroupBoosters: {
      ...initialProgressState,
    },
    updateProfile: {
      ...initialProgressState,
    },
    updatePathway: {
      ...initialProgressState,
    },
    refreshSession: {
      ...initialProgressState,
    },
    deleteGroup: {
      ...initialProgressState,
    },
    getBranchData: {
      ...initialProgressState,
    },
    sendSms: {
      ...initialProgressState,
    },
    sendMessage: {
      ...initialProgressState,
    },
    getRedFlagChats: {
      ...initialProgressState,
    },
  },
  authentication: {
    role: getUserRole(),
    token: getSessionToken(),
    refreshToken: getRefreshToken(),
  },
  user: {
    email: '',
    firstName: '',
    lastName: '',
    role: null,
    assignedGroups: [],
    organisation: {
      uuid: '',
      name: '',
      logo: '',
    },
    notifications: {
      monthlyReport: false,
      emailUpdate: false,
    },
  },

  activity: {
    seats: {},
    highLevel: {},
    devices: {
      rows: [],
    },
    responses: {
      dailyChart: {
        rows: [],
      },
      time: {
        rows: [],
      },
    },
  },
  admins: {
    entities: {},
  },
  boosters: {
    entities: {},
  },
  branchData: {
    entities: {},
  },
  countries: {
    entities: {},
  },
  groups: {
    entities: {},
    selected: {
      active: 0,
      expiringSoon: [],
      pending: [],
    },
  },
  groupSignUpLinks: {},
  journals: {
    entities: {},
  },
  incidents: {
    entities: {},
  },
  keyContacts: {
    entities: {},
    orderedIds: [],
  },
  organisations: {
    entities: {},
  },
  orgCountries: {
    entities: {},
  },
  pathways: {
    entities: {},
  },
  reflections: {
    entities: {},
    orderedIds: [],
  },
  survey: {
    typesOfDays: {
      entities: {},
      orderedIds: [],
    },
    departments: {
      entities: {},
      orderedIds: [],
    },
  },
  toasts: {
    messages: [],
  },
  wellbeing: {
    moodOverall: [],
    moodMonthly: [],
    reflectionReport: [],
    attributeReport: [],
    error: null,
  },
  messaging: {
    entities: {},
  },
  chatbot: {
    redFlagChats: {},
    chatHistory: {},
  },
}

export default initialState
