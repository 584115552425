import { compose } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      name: '',
      incidentsContactName: '',
      incidentsContactEmail: '',
      numberOfSeats: 100,
      industry: 'General',
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(50, 'Must be less than 50 characters long')
        .required('Please enter your organisation name'),
      email: Yup.string()
        .required("Please enter the organisation owner's email address")
        .email('Please enter a valid email address'),
      incidentsContactName: Yup.string().required(
        "Please enter contact person's name"
      ),
      incidentsContactEmail: Yup.string()
        .required("Please enter contact person's email address")
        .email('Please enter a valid email address'),
      industry: Yup.string().required('Please select the industry'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  })
)
