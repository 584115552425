import * as authActions from 'modules/authentication/actions'
import * as pathwayActions from 'modules/pathways/actions'
import { getPathwaysList } from 'modules/pathways/selectors'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { deepEqual } from 'fast-equals'
import { getTrueObject } from 'utils/validation'
import { hasEditRights } from 'modules/authentication/selectors'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        pathways: getPathwaysList(state),
        getPathwaysProgress: state.progress.getPathways,
        updatePathwaysProgress: state.progress.updatePathways,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...pathwayActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getPathways(match.params.orgUuid)
    },
  }),
  withHandlers({
    onSubmitPathways: ({ actions, match, pathways: pathwayProps }) => (
      pathways
    ) => {
      pathways.forEach((pathway, index) => {
        const pathwayProp = pathwayProps[index]
        if (!deepEqual(pathway, pathwayProp)) {
          const { title, message, items, uuid } = pathway
          const newItems = items.map((item) => getTrueObject(item))

          actions.updatePathway({
            orgUuid: match.params.orgUuid,
            pathwayUuid: uuid,
            title,
            message,
            items: newItems,
          })
        }
      })
    },
    onResetPathway: ({ actions, match }) => (pathwayUuid) => {
      actions.resetPathway({ orgUuid: match.params.orgUuid, pathwayUuid })
    },
  })
)
