import {
  parsePhoneNumber,
  getCountryCallingCode,
  CountryCode,
} from 'libphonenumber-js/mobile'

export const truncateString = (text: string, length: number) => {
  if (text && text.length > length) {
    return `${text.substr(0, 25)}...`
  } else {
    return text
  }
}

export const getFormattedMobileNumber = (
  phoneNumber: string,
  region: CountryCode
) => {
  let number = phoneNumber
  const code = getCountryCallingCode(region).toString()
  if (phoneNumber.startsWith(code, 0)) {
    number = phoneNumber.substr(code.length, phoneNumber.length)
  }

  try {
    const parsedNumber = parsePhoneNumber(number, region)
    if (parsedNumber) {
      const formatedNumber = parsedNumber
        .formatInternational()
        .replace(/\s+/g, '')
      if (formatedNumber) {
        return formatedNumber
      }
    }
    return number
  } catch (error) {
    return phoneNumber
  }
}
