import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import idx from 'idx'
import { getQueryParams, getPaginationResult, getArrayFilters } from 'utils/api'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { messageListSchema } from './schema'
// Actions

// Send
export const API_MESSAGE_REQUEST = 'API_MESSAGE_REQUEST'
export const API_MESSAGE_SUCCESS = 'API_MESSAGE_SUCCESS'
export const API_MESSAGE_ERROR = 'API_MESSAGE_ERROR'
export const API_MESSAGE_RESET = 'API_MESSAGE_RESET'

// Get
export const API_GROUP_MESSAGE_REQUEST = 'API_GROUP_MESSAGE_REQUEST'
export const API_GROUP_MESSAGE_SUCCESS = 'API_GROUP_MESSAGE_SUCCESS'
export const API_GROUP_MESSAGE_ERROR = 'API_GROUP_MESSAGE_ERROR'
export const API_GROUP_MESSAGE_RESET = 'API_GROUP_MESSAGE_RESET'

const descSort = '&options=%7Bsort:%7BcreatedAt:DESC%7D%7D'

// Not currently used, but leaving as documentation for existing API and potential future features..
export function messageOrganisation({ organisationUuid, message }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_MESSAGE_REQUEST })
    const payload = { message }

    try {
      await axios.post(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/notification`,
        payload
      )
      dispatch({
        type: API_MESSAGE_SUCCESS,
      })
      dispatch(
        addToast({
          message: 'Your message has been sent',
          type: TOAST_TYPE_SUCCESS,
        })
      )

      setTimeout(() => {
        dispatch({ type: API_MESSAGE_RESET })
      }, 3000)
    } catch (error) {
      dispatch({ type: API_MESSAGE_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error sending the message to your organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function messageGroups({ organisationUuid, groupUuids, message }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_MESSAGE_REQUEST })
    const payload = groupUuids.map((groupUuid) => ({
      groupUuid,
      message,
    }))

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/notification/batch`,
        payload
      )
      const data = idx(response, (_) => _.data.data)

      const successfulMessages = data
        .filter(({ success }) => success)
        .map(({ entity }) => entity)

      if (!successfulMessages.length) {
        throw new Error('No messages sent were successful')
      }

      const normalizedMessages = normalize(
        successfulMessages,
        messageListSchema
      )
      dispatch({
        type: API_MESSAGE_SUCCESS,
        data: normalizedMessages,
      })

      // could be more useful and tell them what groups failed specifically, but this should suffice for now
      const failedResponsesCount = data.filter(({ success }) => !success).length

      if (failedResponsesCount > 0) {
        dispatch(
          addToast({
            message: `There was an error sending the message for ${failedResponsesCount} groups. Please try again`,
            type: TOAST_TYPE_ERROR,
          })
        )
      }

      dispatch(
        addToast({
          message: 'Your message has been sent',
          type: TOAST_TYPE_SUCCESS,
        })
      )

      setTimeout(() => {
        dispatch({ type: API_MESSAGE_RESET })
      }, 3000)
    } catch (error) {
      dispatch({ type: API_MESSAGE_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error sending the message for these groups. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getGroupMessageHistory({
  organisationUuid,
  groupUuid,
  params,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GROUP_MESSAGE_REQUEST })
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/group/${groupUuid}/notification?${getQueryParams(
          params
        )}${descSort}`
      )

      const data = normalize(
        idx(response, (_) => _.data.data),
        messageListSchema
      )

      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GROUP_MESSAGE_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_MESSAGE_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching your message history. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getOrgMessageHistory({ organisationUuid, params, filters }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GROUP_MESSAGE_REQUEST })
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/notification?${getArrayFilters(
          filters
        )}&${getQueryParams(params)}${descSort}`
      )

      const data = normalize(response.data.data, messageListSchema)

      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GROUP_MESSAGE_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_MESSAGE_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching your message history. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
