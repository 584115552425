import React from 'react'
import PropTypes from 'prop-types'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import AppLayout from 'layouts/AppLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import styled from 'styled-components'
import ProgressLoader from 'components/atoms/ProgressLoader'
import ReflectionsForm from 'components/organisms/ReflectionsForm'

export const StyledHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const ReflectionList = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Reflection = styled(Typography)`
  padding: ${({ theme }) => theme.spacing.lg}px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export function Reflections({
  reflections,
  progress,
  onSubmitReflections,
  getBoostersProgress,
  updateBoostersProgress,
}) {
  const industryReflections = reflections.filter(
    ({ organisationId }) => organisationId == null
  )
  const customReflections = reflections.filter(
    ({ organisationId }) => organisationId != null
  )

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <StyledHeader>
            <Typography variant="h2">Reflections</Typography>
            <Description variant="body1">
              Reflections are questions asked at the end of the survey to help
              pinpoint positive or negative areas
            </Description>
          </StyledHeader>
          {progress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : (
            <>
              <ReflectionsForm
                onSubmit={onSubmitReflections}
                disableEditting={false}
                reflections={customReflections}
                progress={getBoostersProgress}
                updateProgress={updateBoostersProgress}
                description={
                  <Description variant="body1">
                    Custom reflections for custom insights
                  </Description>
                }
              />
              <StyledHeader>
                <Typography variant="h3">
                  Industry standard reflections
                </Typography>
                <Description variant="body1">
                  Standard reflections allow us to provide meta analysis across
                  industries
                </Description>
              </StyledHeader>
              <ReflectionList>
                {industryReflections
                  .filter(({ organisationId }) => organisationId == null)
                  .map((reflection) => {
                    return (
                      <Reflection key={reflection.uuid} variant="h4">
                        {reflection.reflection}
                      </Reflection>
                    )
                  })}
              </ReflectionList>
            </>
          )}
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Reflections.propTypes = {
  reflections: PropTypes.arrayOf(PropTypes.object),
  progress: PropTypes.object,
  onSubmitReflections: PropTypes.func,
  getBoostersProgress: PropTypes.func,
  updateBoostersProgress: PropTypes.func,
}

Reflections.defaultProps = {
  progress: {},
  reflections: [],
}

export default Reflections
