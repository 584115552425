import { useCallback, useState, useEffect, useRef } from 'react'
import { PAGINATION_LIMIT } from 'constants/pagination'
import useDebouncedCallback from 'use-debounce/lib/callback'

export default function useChatbot({
  filters,
  sortOptions,
  onFetchChatbot,
  chatbotModalUuid,
  onViewChatHistory,
  chatHistory,
}) {
  const [searchFilter, setSearchFilter] = useState('')
  const [pagination, setPagination] = useState({
    start: 0,
    count: 0,
    limit: PAGINATION_LIMIT,
  })
  const [viewingChatHistory, setViewingChatHistory] = useState(null)
  const prevSearch = useRef(null)

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
    prevSearch.current = e.target.value
  }, [])

  const handleChangePagination = useCallback(
    (newPageIndex, pagination) => {
      setPagination({
        ...pagination,
        start: newPageIndex * PAGINATION_LIMIT,
      })
    },
    [pagination.start]
  )

  // Search for chat history and re-fetch when filters or pagination changes
  const fetchCallback = async () => {
    const response = await onFetchChatbot(
      {
        start: pagination.start,
        limit: PAGINATION_LIMIT,
        searchFilter,
        sortOptions,
      },
      filters
    )
    if (response) {
      setPagination(response)
    }
  }

  const debouncedFetch = useDebouncedCallback(fetchCallback, 350)

  useEffect(
    () => {
      const foundChatHistory = chatHistory.find(
        (item) => item.uuid === chatbotModalUuid
      )
      if (foundChatHistory) {
        setViewingChatHistory(foundChatHistory.data)
      } else if (chatbotModalUuid && !foundChatHistory) {
        onViewChatHistory(chatbotModalUuid)
      }
    },
    [chatbotModalUuid, chatHistory]
  )

  // Use debounced fetch for search filter change.
  useEffect(
    () => {
      // Don't run this on initial mount.
      if (prevSearch.current !== null) {
        debouncedFetch()
      }
    },
    [searchFilter]
  )

  // Don't debounce the search on button clicks
  useEffect(
    () => {
      fetchCallback()
    },
    [pagination.start, filters.value]
  )

  return {
    searchFilter,
    sortOptions,
    handleChangeSearchFilter,
    pagination,
    handleChangePagination,
    viewingChatHistory,
  }
}
