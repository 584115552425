import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledTableButton = styled.button`
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.sm}px;
  background-color: transparent;
  text-align: center;
  color: ${({ theme, isActive }) =>
    !isActive ? theme.palette.text.primary : theme.palette.primary.main};
  cursor: pointer;
  outline: 0;
  border: 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: ${({ isActive, theme }) =>
    isActive ? theme.palette.primary.main : 'transparent'};

  &:hover {
    border-color: ${({ isActive, theme }) =>
      !isActive ? theme.palette.tertiary.main : theme.palette.primary.main};
  }
`

export function TableButton({ className, children, onClick, value, isActive }) {
  const handleOnClick = useCallback((e) => {
    if (onClick) {
      onClick(e.target.value)
    }
  }, [])

  return (
    <StyledTableButton
      className={className}
      onClick={handleOnClick}
      isActive={isActive}
      value={value}
    >
      {children}
    </StyledTableButton>
  )
}

TableButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.string,
  isActive: PropTypes.bool,
}

export default TableButton
