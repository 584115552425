import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@weareroam/cake-ui-v1/Button'
import FormControl from '@weareroam/cake-ui-v1/FormControl'
import InputLabel from '@weareroam/cake-ui-v1/InputLabel'
import MenuItem from '@weareroam/cake-ui-v1/MenuItem'
import Select from '@weareroam/cake-ui-v1/Select'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Dropzone from 'components/molecules/Dropzone'
import FilePreview from 'components/molecules/FilePreview'
import styled from 'styled-components'
import Box from 'components/molecules/Box'
import Modal from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import NavigationPrompt from 'react-router-navigation-prompt'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'

export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const StyledName = styled(TextField)`
  && {
    width: 50%;
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
    padding-right: ${({ theme }) => theme.spacing.xs}px;
  }
`

export const StyledFormControlIncidentsContact = styled(FormControl)`
  && {
    width: 50%;
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
    padding-left: ${({ theme }) => theme.spacing.xs}px;
  }
`

export const StyledUploadTitle = styled(Typography)``

export const StyledDropzone = styled(Dropzone)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledFilePreview = styled(FilePreview)``

export const StyledUploadBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const UploadBoxContent = styled.div`
  padding: ${({ theme }) => theme.spacing.lg}px;
`

export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

export const StyledLogo = styled.img`
  height: 40px;
`

export const UpdateButton = styled(Button)`
  width: 140px;
`

export function OrganisationDetailsForm({
  className,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  uploadTotal,
  uploadProgress,
  isConfirmUpdateModalOpen,
  setIsConfirmUpdateModalOpen,
  handleSubmit,
  organisation,
  keyContacts,
  onImageUploadError,
}) {
  const handleCloseConfirmUpdatesModal = useCallback(() => {
    setIsConfirmUpdateModalOpen(false)
  }, [])

  const hasUnsavedChanges = useMemo(
    () => {
      return (
        (values.logo && typeof values.logo === 'object') ||
        values.organisationName !== organisation.name ||
        (organisation.incidentsContact
          ? organisation.incidentsContact.uuid !== values.incidentsContactUuid
          : values.incidentsContactUuid)
      )
    },
    [
      organisation.name,
      values.organisationName,
      values.incidentsContactUuid,
      values.logo,
    ]
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <NavigationPrompt when={hasUnsavedChanges}>
        {({ onConfirm, onCancel }) => (
          <Modal open>
            <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
          </Modal>
        )}
      </NavigationPrompt>
      <ConfirmUpdatesModal
        open={isConfirmUpdateModalOpen}
        onClose={handleCloseConfirmUpdatesModal}
      >
        <ConfirmUpdatesForm
          onCancel={handleCloseConfirmUpdatesModal}
          onSubmit={handleSubmit}
          title="Confirm organisation updates"
          content={
            <React.Fragment>
              All organisation changes will be <br /> updates and visible in the
              app.
            </React.Fragment>
          }
        />
      </ConfirmUpdatesModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h2">Organisation details</Typography>
        </HeaderLeft>
        <UpdateButton
          variant="contained"
          type="submit"
          color="primary"
          size="small"
          disabled={!hasUnsavedChanges}
        >
          Update
        </UpdateButton>
      </StyledHeader>

      <StyledName
        id="organisationName"
        name="organisationName"
        label="Organisation name"
        type="text"
        value={values.organisationName || ''}
        InputLabelProps={{ shrink: true }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.organisationName && !!errors.organisationName}
        helperText={touched.organisationName ? errors.organisationName : ''}
      />

      <StyledFormControlIncidentsContact>
        <InputLabel id="incidentsContactUuidLabel">
          Contact for incidents reporting
        </InputLabel>
        <Select
          id="incidentsContactUuid"
          name="incidentsContactUuid"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.incidentsContactUuid}
          error={touched.incidentsContact && !!errors.incidentsContact}
        >
          {keyContacts.map((value, index) => {
            return (
              <MenuItem key={value.uuid} value={value.uuid}>
                {value.fullName}
              </MenuItem>
            )
          })}
        </Select>
      </StyledFormControlIncidentsContact>

      <StyledUploadBox
        headerContent={
          <StyledUploadTitle variant="h4">Organisation logo</StyledUploadTitle>
        }
      >
        <UploadBoxContent>
          <StyledDropzone
            accept={['image/png', 'image/jpeg']}
            onDrop={(drop) => {
              if (drop[0]) {
                setFieldValue('logo', drop[0])
              } else {
                onImageUploadError()
              }
            }}
            name="logo"
          />

          {/* Either logo is a url from API (string) or an object from selecting a new logo */}
          {typeof values.logo === 'string' ? (
            <StyledLogo src={values.logo} alt="Logo" />
          ) : (
            values.logo && (
              <StyledFilePreview
                name={values.logo.name}
                total={uploadTotal}
                progress={uploadProgress}
              />
            )
          )}
        </UploadBoxContent>
      </StyledUploadBox>
    </StyledForm>
  )
}

OrganisationDetailsForm.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
  uploadTotal: PropTypes.number,
  uploadProgress: PropTypes.number,
  isConfirmUpdateModalOpen: PropTypes.bool,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  onDragEnd: PropTypes.func,
  organisation: PropTypes.object,
  keyContacts: PropTypes.array,
  onImageUploadError: PropTypes.func,
}

OrganisationDetailsForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  keyContactsProgress: {},
  organisation: {},
}

export default OrganisationDetailsForm
