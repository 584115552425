import React from 'react'
import PropTypes from 'prop-types'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import DropdownButton from 'components/molecules/DropdownButton'
import MenuItem from 'components/molecules/MenuItem'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import Button from '@weareroam/cake-ui-v1/Button'
import ExportIcon from '@weareroam/cake-ui-v1-icons/ImportExport'
import DropdownFilter from 'components/molecules/DropdownFilter'
import ContentContainer from 'components/atoms/ContentContainer'
import WellbeingCharts from 'components/organisms/WellbeingCharts'
import WellbeingReports from 'components/organisms/WellbeingReports'
import styled from 'styled-components'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import useWellbeing from 'hooks/useWellbeing'
import { API_YEAR_MONTH_FORMAT, MONTH_FILTER_FORMAT } from 'constants/date'
import { format } from 'date-fns'
import { TOGGLE_ALL } from 'constants/form'

export const StyledLayout = styled(ChnnlDashboardLayout)``
export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

export const ExportButton = styled(Button)`
  && {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`

export const AllOrganisationsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export function Wellbeing({
  organisations,
  lineChart,
  barChart,
  getJournalsProgress,
  reflectionReport,
  attributeReport,
  error,
  actions,
}) {
  const {
    filteredEntities: filteredOrganisations,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(
    organisations,
    'organisation',
    getJournalsProgress ? getJournalsProgress.initialLoad : null
  )
  const {
    attribute,
    attributeMood,
    filteredMonth,
    months,
    graphMood,
    handleChangeGraphMood,
    handleChangeFilteredMonth,
    handleSetAttributeMood,
    handleSetAttribute,
  } = useWellbeing({
    onFetchMood: actions.getWellbeingMoodChart,
    onFetchMoodMonthly: actions.getWellbeingMonthlyMoodChart,
    onFetchReflectionReport: actions.getWellbeingReflectionReport,
    onFetchAttributeReport: actions.getWellbeingAttributeReport,
    filteredOrganisations,
  })

  return (
    <StyledLayout>
      <ContentContainer>
        <StyledHeader>
          <DropdownButton
            name="organisations"
            value={filteredOrganisations}
            onChange={handleChangeFilteredEntities}
            renderValue={renderFilterLabel}
          >
            <MenuItem value={TOGGLE_ALL}>
              <Checkbox
                color="primary"
                checked={filteredOrganisations.length === organisations.length}
              />
              <AllOrganisationsLabel primary="All organisations" />
            </MenuItem>
            {organisations.map((organisation) => {
              return (
                <MenuItem key={organisation.uuid} value={organisation.uuid}>
                  <Checkbox
                    color="primary"
                    checked={filteredOrganisations.includes(organisation.uuid)}
                  />
                  <ListItemText primary={organisation.name} />
                </MenuItem>
              )
            })}
          </DropdownButton>

          <HeaderRight>
            <ExportButton variant="text" color="primary" type="button">
              <ExportIcon /> Export data
            </ExportButton>
            <DropdownFilter
              label="View:"
              id="organisations-month-selector"
              value={filteredMonth}
              onChange={handleChangeFilteredMonth}
              name="month"
            >
              {months.map((month, index) => {
                return (
                  <MenuItem
                    key={index}
                    color="primary"
                    value={format(month, API_YEAR_MONTH_FORMAT)}
                  >
                    {format(month, MONTH_FILTER_FORMAT)}
                  </MenuItem>
                )
              })}
            </DropdownFilter>
          </HeaderRight>
        </StyledHeader>

        <WellbeingCharts
          lineChart={lineChart}
          barChart={barChart}
          onChangeMood={handleChangeGraphMood}
          mood={graphMood}
          error={error}
          warning={reflectionReport && reflectionReport.warningMessage}
          filteredMonth={filteredMonth}
          title="All organisation's"
        />

        <WellbeingReports
          reflectionReport={reflectionReport}
          attributeReport={attributeReport}
          error={error}
          filteredMonth={filteredMonth}
          onChangeMood={handleSetAttributeMood}
          mood={attributeMood}
          attribute={attribute}
          onChangeAttribute={handleSetAttribute}
        />
      </ContentContainer>
    </StyledLayout>
  )
}

Wellbeing.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  lineChart: PropTypes.arrayOf(PropTypes.object),
  barChart: PropTypes.arrayOf(PropTypes.object),
  getJournalsProgress: PropTypes.object,
  reflectionReport: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  attributeReport: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.object,
}

Wellbeing.defaultProps = {
  organisations: [],
  actions: {},
}

export default Wellbeing
