import React from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import {
  Actions,
  ActionButton,
  AllGroups,
  CharCount,
  GroupSelectionError,
  GroupSelectionInfo,
  StyledCheckboxControl,
  StyledFields,
  StyledForm,
  StyledGroups,
  StyledText,
  StyledTextArea,
} from './SendMessageForm.components'

export function SendMessageForm({
  handleSubmit,
  className,
  values,
  errors,
  handleBlur,
  handleChange,
  isAssigningToGroup,
  groups,
  toggleAssignedToGroupCheckbox,
  toggleAllAssignedToGroupCheckbox,
  touched,
  onCancel,
}) {
  const copyOptions = React.useMemo(
    () => {
      let headerText = 'Send a message'
      let buttonText = 'Send'

      if (!isAssigningToGroup) {
        buttonText = groups.length > 1 ? 'Select groups' : buttonText
      } else if (isAssigningToGroup) {
        headerText = groups.length > 1 ? 'Select groups' : headerText
        buttonText = 'Confirm send'
      }

      return {
        headerText,
        buttonText,
      }
    },
    [isAssigningToGroup, groups]
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <div>
        <Typography variant="h2">{copyOptions.headerText}</Typography>

        {!isAssigningToGroup ? (
          <StyledFields>
            <StyledTextArea
              id="message"
              name="message"
              type="text"
              value={values.message || ''}
              InputProps={{
                disableUnderline: true,
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.message && !!errors.message}
              helperText={touched.message ? errors.message : ''}
              fullWidth
              multiline
              rows={5}
              placeholder="Enter a message to send to your team"
            />
            <CharCount variant="body2">
              <i>{idx(values, (_) => _.message.length) || 0} / 240</i>
            </CharCount>
          </StyledFields>
        ) : groups.length > 1 ? (
          <>
            <StyledFields>
              <AllGroups>
                <StyledCheckboxControl
                  label="All groups"
                  onChange={toggleAllAssignedToGroupCheckbox}
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.groupsSelected.length === groups.length}
                    />
                  }
                />
              </AllGroups>

              <StyledGroups>
                {groups.map((group) => {
                  return (
                    <StyledCheckboxControl
                      key={group.uuid}
                      label={group.name}
                      name="groupsSelected[]"
                      onChange={() => toggleAssignedToGroupCheckbox(group.uuid)}
                      value={group.uuid}
                      control={
                        <Checkbox
                          color="primary"
                          checked={values.groupsSelected.includes(group.uuid)}
                        />
                      }
                    />
                  )
                })}
              </StyledGroups>
            </StyledFields>
          </>
        ) : (
          <>
            <StyledText variant="body1">
              Your team will recieve the following message:
              <br />
              <i>{values.message}</i>
            </StyledText>
          </>
        )}
      </div>

      <Actions>
        <ActionButton
          variant="outlined"
          size="small"
          type="button"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </ActionButton>
        <ActionButton
          size="small"
          variant="contained"
          type="submit"
          color="primary"
        >
          {copyOptions.buttonText}
        </ActionButton>
      </Actions>
      {isAssigningToGroup && groups.length > 1 ? (
        touched.groupsSelected && errors.groupsSelected ? (
          <GroupSelectionError>
            <Typography variant="body2">
              Please select at least one group to send a message to
            </Typography>
          </GroupSelectionError>
        ) : (
          <GroupSelectionInfo>
            <Typography variant="body2">
              Your message will be sent to all team members in the selected
              groups
            </Typography>
          </GroupSelectionInfo>
        )
      ) : null}
    </StyledForm>
  )
}

SendMessageForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  isAssigningToGroup: PropTypes.bool,
  setIsAssigningToGroup: PropTypes.func,
  toggleAllAssignedToGroupCheckbox: PropTypes.func,
  toggleAssignedToGroupCheckbox: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
}

SendMessageForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  groups: [],
}

export default SendMessageForm
