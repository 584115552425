import React from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Button from '@weareroam/cake-ui-v1/Button'
import ResetPasswordForm from 'components/organisms/ResetPasswordForm'
import PropTypes from 'prop-types'
import Link from 'components/atoms/Link'
import { LOGIN } from 'constants/routes'
import styled from 'styled-components'

export const StyledContent = styled.div`
  text-align: center;
  width: 330px;
  padding-left: ${({ theme }) => theme.spacing.md}px;
  padding-right: ${({ theme }) => theme.spacing.md}px;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const StyledForm = styled(ResetPasswordForm)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const DesktopOnly = `
display: block;

@media (max-width: 1024px) {
  display: none;
}
`

export const DesktopOnlyButton = styled.span`
  ${DesktopOnly}
`

export const DesktopOnlyText = styled.p`
  ${DesktopOnly}
`

export function ResetPassword({ onSubmit, requestSent, onLoginLinkClick }) {
  return (
    <DefaultLayout>
      <StyledContent>
        {requestSent ? (
          <>
            <StyledTitle variant="h5">Password reset requested</StyledTitle>
            <StyledText variant="body1">
              A link to reset your password has been sent to your email address.
            </StyledText>
            <DesktopOnlyButton>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                size="large"
                id="login-link-button"
                onClick={onLoginLinkClick}
              >
                Back to login
              </Button>
            </DesktopOnlyButton>
          </>
        ) : (
          <>
            <StyledTitle variant="h5">Reset password</StyledTitle>
            <StyledText variant="body1">
              Reset your password using your email
            </StyledText>
            <StyledForm onSubmit={onSubmit} />
            <DesktopOnlyText>
              Wrong place? <Link to={LOGIN}>Back to login</Link>
            </DesktopOnlyText>
          </>
        )}
      </StyledContent>
    </DefaultLayout>
  )
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func,
  requestSent: PropTypes.bool,
  onLoginLinkClick: PropTypes.func,
}

export default ResetPassword
