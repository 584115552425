import { compose, withHandlers } from 'recompose'
import { withFormik } from 'formik'
import { validEmail } from 'utils/validation'

export default compose(
  withFormik({
    mapPropsToValues: () => ({ name: '', admins: [], textInput: '' }),
    validate: ({ admins, textInput }) => {
      const errors = {}

      if (validEmail(textInput)) {
        return {}
      } else if (admins.length === 0) {
        errors.admins = 'Please enter existing or new admins'
      } else if (admins.length > 0 && textInput.length > 0) {
        errors.admins = 'Please enter a valid email'
      }

      return errors
    },
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
  withHandlers({
    onAddChip: ({ setFieldValue, values, admins }) => (chip) => {
      // check if chip is already in the list
      const alreadyExists = values.admins.some(
        (admin) => admin.trim().toLowerCase() === chip.trim().toLowerCase()
      )

      // Don't add to the list if it already exists
      if (alreadyExists) {
        return
      }

      const isEmail = validEmail(chip)

      if (isEmail) {
        // if it's an email, add to the list
        setFieldValue('admins', [...values.admins, chip])
        setFieldValue('textInput', '')
      } else {
        // Else check if it is a name in the admins list
        const isAdmin = admins.some(
          (admin) =>
            admin.label.trim().toLowerCase() === chip.trim().toLowerCase()
        )

        if (isAdmin) {
          setFieldValue('admins', [...values.admins, chip])
          setFieldValue('textInput', '')
        }
      }
    },
    onDeleteChip: ({ values, setFieldValue }) => (chip) => {
      setFieldValue('admins', values.admins.filter((value) => value !== chip))
    },
  })
)
