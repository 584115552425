import axios from 'axios'
import { normalize } from 'normalizr'
import { SAUCE_API_URL } from 'config'
import { TOAST_TYPE_ERROR } from 'constants/ui'
import { addToast } from 'modules/toasts/actions'
import { countryListSchema } from './schema'

// Actions
export const API_GET_COUNTRIES_REQUEST = 'API_GET_COUNTRIES_REQUEST'
export const API_GET_COUNTRIES_SUCCESS = 'API_GET_COUNTRIES_SUCCESS'
export const API_GET_COUNTRIES_ERROR = 'API_GET_COUNTRIES_ERROR'

// Action creators
export function getCountries() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_COUNTRIES_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/country`)
      const data = normalize(response.data.data, countryListSchema)

      dispatch({
        type: API_GET_COUNTRIES_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_COUNTRIES_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the countries.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
