import { REFRESH_TOKEN_KEY, SESSION_KEY, ROLE_KEY, APP_SALT } from 'config'
import store from 'store2'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import { differenceInMinutes } from 'date-fns'

export const refreshBuffer = 30 // in minutes

export const saveUserRole = (role) => {
  store.set(ROLE_KEY, role)
}

export const getUserRole = () => {
  return store.get(ROLE_KEY)
}

export const saveSessionToken = (sessionToken) => {
  store.set(SESSION_KEY, sessionToken)
}

export const getSessionToken = () => {
  return store.get(SESSION_KEY)
}

export const saveRefreshToken = (refreshToken) => {
  store.set(REFRESH_TOKEN_KEY, refreshToken)
}

export const getRefreshToken = () => {
  return store.get(REFRESH_TOKEN_KEY)
}

export const setSessionHeaders = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const hashPassword = (password) => {
  if (!password || !APP_SALT) {
    return ''
  }

  const encryptedPassword = HmacSHA256(password, APP_SALT)
  return String(encryptedPassword)
}

export const getTokenExpirationTime = (token) => {
  try {
    const jwt = jwtDecode(token)
    const expirationTimestamp = jwt.exp * 1000 // Multiply by 1000 for fractional seconds

    return expirationTimestamp
  } catch (error) {
    return Date.now()
  }
}

export const isValidToken = (token) => {
  const expirationTimestamp = getTokenExpirationTime(token)
  const minutesUntilExpiration = differenceInMinutes(
    expirationTimestamp,
    Date.now()
  )

  if (minutesUntilExpiration > 0) {
    return true
  }

  return false
}

export const hasSessionHeaders = () => {
  return !!axios.defaults.headers.common.Authorization
}

export const clearSession = () => {
  axios.defaults.headers.common.Authorization = ''
  store.clearAll()
}
