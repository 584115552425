import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledStatusTag = styled.div`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.sm}px;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  line-height: ${({ theme }) => theme.typography.pxToRem(18)};
  text-transform: capitalize;
  background-color: ${({ theme, themeColor }) =>
    theme.palette[themeColor].light};
  color: ${({ theme, themeColor }) => theme.palette[themeColor].main};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  text-align: left;
`

export function StatusTag({ className, themeColor, children, fullWidth }) {
  return (
    <StyledStatusTag
      className={className}
      themeColor={themeColor}
      fullWidth={fullWidth}
    >
      {children}
    </StyledStatusTag>
  )
}

StatusTag.propTypes = {
  className: PropTypes.string,
  themeColor: PropTypes.oneOf([
    'primary',
    'quinary',
    'success',
    'warning',
    'error',
  ]),
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
}

StatusTag.defaultProps = {
  themeColor: 'primary',
}

export default StatusTag
