import { refreshSession } from 'modules/authentication/actions'
import { getAdminProfile } from 'modules/user/actions'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { hasSessionHeaders } from 'utils/authentication'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        refreshSessionProgress: state.progress.refreshSession,
        role: state.authentication.role,
        sessionToken: state.authentication.token,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        refreshSession,
        getAdminProfile,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      if (hasSessionHeaders()) {
        this.props.actions.getAdminProfile()
      }
    },
  })
)
