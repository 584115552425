import * as authActions from 'modules/authentication/actions'
import * as boosterActions from 'modules/boosters/actions'
import { getOrganisationBoostersList } from 'modules/boosters/selectors'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { hasEditRights } from 'modules/authentication/selectors'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        boosterCategories: getOrganisationBoostersList(
          state,
          match.params.orgUuid
        ),
        hasEditAccess: state.user.canEdit || hasEditRights(state),
        getBoostersProgress: state.progress.getOrganisationBoosters,
        updateBoostersProgress: state.progress.updateOrganisationBoosters,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...boosterActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getOrganisationBoosters(match.params.orgUuid)
    },
  }),
  withHandlers({
    onSubmitBoosters: ({ actions, match }) => ({ boosterCategories }) => {
      actions.updateOrganisationBoosters({
        orgUuid: match.params.orgUuid,
        boosters: boosterCategories,
      })
    },
  })
)
