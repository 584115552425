import { createSelector } from 'reselect'

export const getTypesOfDaysList = createSelector(
  [
    (state) => state.survey.typesOfDays.entities,
    (state) => state.survey.typesOfDays.orderedIds,
  ],
  (typesOfDays, orderedIds) => orderedIds.map((id) => typesOfDays[id])
)

export const getDepartmentsList = createSelector(
  [
    (state) => state.survey.departments.entities,
    (state) => state.survey.departments.orderedIds,
  ],
  (departments, orderedIds) => orderedIds.map((id) => departments[id])
)
