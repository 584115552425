import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@weareroam/cake-ui-v1/Typography'
import CloseIcon from '@weareroam/cake-ui-v1-icons/Close'
import styled, { keyframes } from 'styled-components'
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_PRIMARY,
} from 'constants/ui'

export const entryAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const exitAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`

export const StyledToast = styled.div`
  transition: bottom 0.3s ease;
  opacity: 0;
  width: 600px;
  display: flex;
  z-index: 999;
  position: fixed;
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing.sm}px;
  justify-content: space-between;
  bottom: ${({ index }) => index * 65 + 20}px;
  padding: ${({ theme }) => theme.spacing.md}px;
  background-color: ${({ theme, type }) => {
    switch (type) {
      case TOAST_TYPE_ERROR:
        return theme.palette.error.bright
      case TOAST_TYPE_SUCCESS:
        return theme.palette.success.main
      case TOAST_TYPE_PRIMARY:
        return theme.palette.primary.main
      default:
        return 'white'
    }
  }};
  left: ${({ theme }) => theme.spacing.md}px;

  &.open {
    animation: ${entryAnimation} 0.3s linear forwards;
  }
  &.closed {
    animation: ${exitAnimation} 0.3s linear forwards;
  }

  @media (max-width: 1024px) {
    width: 95%;
  }
`

export const StyledMessage = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  font-size: 16px;
`

export function Toast({ closed, handleCloseClick, data, index }) {
  return (
    <StyledToast
      id={data.id}
      index={index}
      type={data.type}
      className={closed ? 'closed' : 'open'}
    >
      <StyledMessage>{data.message}</StyledMessage>
      <StyledCloseIcon color="secondary" onClick={handleCloseClick} />
    </StyledToast>
  )
}

Toast.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.oneOf([TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR]),
  }),
  index: PropTypes.number,
  onClose: PropTypes.func,
  closed: PropTypes.bool,
  handleCloseClick: PropTypes.func,
}

Toast.defaultProps = {
  type: TOAST_TYPE_SUCCESS,
}

export default Toast
