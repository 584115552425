import * as authActions from 'modules/authentication/actions'
import * as organisationActions from 'modules/organisations/actions'
import { isChnnlAdmin } from 'modules/authentication/selectors'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  withRouter,
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        isChnnlAdmin: isChnnlAdmin(state),
        organisation: state.organisations.entities[match.params.orgUuid],
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...organisationActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        actions,
        match: {
          params: { orgUuid },
        },
      } = this.props

      actions.getOrganisation(orgUuid)
    },
  }),
  withHandlers({
    onLogout: ({ actions }) => () => {
      actions.logout()
    },
  })
)
