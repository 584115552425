import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledTooltipItem = styled.div``

export const Value = styled.span``
export const Suffix = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export function TooltipItem({ value, suffix, className }) {
  return (
    <StyledTooltipItem className={className}>
      {value !== null && <Value>{value}&nbsp;</Value>}
      {suffix && <Suffix>{suffix}</Suffix>}
    </StyledTooltipItem>
  )
}

TooltipItem.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.string,
}

export default TooltipItem
