import { useState, useCallback } from 'react'

export default function useJournalModals() {
  const [isDeleteJournalModalOpen, setIsDeleteJournalModalOpen] = useState(
    false
  )
  const [isApproveJournalsModalOpen, setIsApproveJournalsModalOpen] = useState(
    false
  )
  const [isJournalEntryModalOpen, setIsJournalEntryModalOpen] = useState(false)
  const [journalModalUuid, setJournalModalUuid] = useState(null)

  const handleOpenDeleteJournalModal = useCallback((uuid) => {
    setJournalModalUuid(uuid)
    setIsDeleteJournalModalOpen(true)
  }, [])

  const handleCloseDeleteJournalModal = useCallback(() => {
    setIsDeleteJournalModalOpen(false)
    setJournalModalUuid(null)
  }, [])

  const handleOpenApproveJournalsModal = useCallback(() => {
    setIsApproveJournalsModalOpen(true)
  }, [])

  const handleCloseApproveJournalsModal = useCallback(() => {
    setIsApproveJournalsModalOpen(false)
  }, [])

  const handleOpenEntryJournalsModal = useCallback((uuid) => {
    setJournalModalUuid(uuid)
    setIsJournalEntryModalOpen(true)
  }, [])

  const handleCloseEntryJournalsModal = useCallback(() => {
    setIsJournalEntryModalOpen(false)
    setJournalModalUuid(null)
  }, [])

  return {
    isDeleteJournalModalOpen,
    isApproveJournalsModalOpen,
    isJournalEntryModalOpen,
    journalModalUuid,
    handleOpenDeleteJournalModal,
    handleCloseDeleteJournalModal,
    handleOpenApproveJournalsModal,
    handleCloseApproveJournalsModal,
    handleOpenEntryJournalsModal,
    handleCloseEntryJournalsModal,
  }
}
