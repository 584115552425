import {
  API_GET_REFLECTIONS_SUCCESS,
  API_ADD_ORGANISATION_REFLECTION_SUCCESS,
} from './actions'
import initialState from 'reducers/initialState'

export default function reflectionsReducer(
  state = initialState.reflections,
  action
) {
  switch (action.type) {
    case API_GET_REFLECTIONS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.reflections,
        },
        orderedIds: action.data.result,
      }
    case API_ADD_ORGANISATION_REFLECTION_SUCCESS:
      return {
        ...state,
        entities: action.data.entities.reflections,
        orderedIds: action.data.result,
      }

    default:
      return state
  }
}
