import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledTooltip = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: ${({ theme }) => theme.palette.text.primary};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing.sm}px;
  text-align: center;
`

export function Tooltip({ children }) {
  return <StyledTooltip>{children}</StyledTooltip>
}

Tooltip.propTypes = {
  children: PropTypes.node,
}

export default Tooltip
