import * as authActions from 'modules/authentication/actions'
import * as groupsActions from 'modules/groups/actions'
import { getGroupsList } from 'modules/groups/selectors'
import * as adminsActions from 'modules/admins/actions'
import { getVerifiedGroupAdmins } from 'modules/admins/selectors'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { validEmail } from 'utils/validation'
import { ROLE_GROUP_ADMIN } from 'constants/form'
import { hasEditRights } from 'modules/authentication/selectors'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        groups: getGroupsList(state),
        getGroupsProgress: state.progress.getGroups,
        admins: getVerifiedGroupAdmins(state),
        groupEntities: state.groups.entities,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...groupsActions,
        ...adminsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match, role } = this.props

      actions.getGroups(match.params.orgUuid)
      if (role !== ROLE_GROUP_ADMIN) {
        actions.getOrgAdmins(match.params.orgUuid)
      }
    },
  }),
  withState('isNewGroupModalOpen', 'setIsNewGroupModalOpen', false),
  withState('isDeleteGroupModalOpen', 'setIsDeleteGroupModalOpen', false),
  withState('modalGroupId', 'setModalGroupId', null),
  withHandlers({
    onNewGroupFormSubmit: ({
      actions,
      match,
      setIsNewGroupModalOpen,
      admins: existingAdminList,
    }) => ({ admins, name, textInput }) => {
      // New admins will be emails from the input
      const newAdminsFiltered = admins.filter((admin) => validEmail(admin))
      // Existing admins will be names. Find the uuids
      const existingAdminNames = admins.filter((admin) => !validEmail(admin))
      const existingAdmins = existingAdminNames.map((name) => {
        const foundAdmin = existingAdminList.find((a) => a.fullName === name)
        return foundAdmin.userUuid
      })

      const newAdmins = validEmail(textInput)
        ? newAdminsFiltered.concat([textInput])
        : newAdminsFiltered

      actions.createGroup({
        orgUuid: match.params.orgUuid,
        admins: existingAdmins,
        newAdmins,
        name,
      })
      setIsNewGroupModalOpen(false)
    },
    onDeleteGroupFormSubmit: ({
      actions,
      setIsDeleteGroupModalOpen,
      match,
      modalGroupId,
    }) => (e) => {
      e.preventDefault()
      actions.deleteGroup({
        orgUuid: match.params.orgUuid,
        groupUuid: modalGroupId,
      })
      setIsDeleteGroupModalOpen(false)
    },
  })
)
