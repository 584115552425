import { compose, withState, withHandlers } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withState('isAddBoosterModalOpen', 'setIsAddBoosterModalOpen', false),
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  withState('editBoosterIndex', 'setEditBoosterIndex', null),
  withState('boosterCategoryIndex', 'setBoosterCategoryIndex', null),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ reflections = [] }) => ({
      reflections: reflections.map((reflection, index) => {
        reflection.order = index + 1
        return reflection
      }),
    }),
    validationSchema: Yup.object().shape({
      reflections: Yup.array().of(
        Yup.object().shape({
          reflection: Yup.string().required(
            'Please enter a reflection description'
          ),
        })
      ),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      if (props.isConfirmUpdateModalOpen) {
        // If the confirmation modal is already open, submit the form
        setSubmitting(true)
        // If form has no error
        if (props.onSubmit) {
          props.onSubmit(values)
        }

        props.setIsConfirmUpdateModalOpen(false)
      } else {
        // Open confirmation modal before submitting the form
        props.setIsConfirmUpdateModalOpen(true)
      }
    },
  }),
  withHandlers({
    onAddReflection: ({ values, setFieldValue }) => () => {
      setFieldValue('reflections', [
        {
          order: 1,
          reflection: '',
        },
        ...values.reflections.map((reflection, index) => ({
          ...reflection,
          order: index + 2,
        })),
      ])
    },
    onDeleteReflection: ({ values, setFieldValue }) => (categoryIndex) => {
      setFieldValue(
        'reflections',
        values.reflections
          .filter((reflection, index) => index !== categoryIndex)
          .map((reflection, index) => ({
            ...reflection,
            order: index + 1,
          }))
      )
    },
    onDragEnd: ({ values, setFieldValue }) => (result) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      // Return the reordered list
      const reorderedList = Array.from(values.reflections)
      const [removed] = reorderedList.splice(result.source.index, 1)
      reorderedList.splice(result.destination.index, 0, removed)

      const listWithOrder = reorderedList.map((reflection, index) => ({
        ...reflection,
        order: index + 1,
      }))

      setFieldValue('reflections', listWithOrder)
    },
  })
)
