import { compose, defaultProps } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => {
      return Object.assign(
        {
          subHeading: initialValues.subHeading || '',
          heading: initialValues.heading || '',
          email: initialValues.email || '',
          contact: initialValues.contact || '',
          extension: initialValues.extension || '',
          website: initialValues.website || '',
          description: initialValues.description || '',
          escalationOption: initialValues.escalationOption || false,
          createdAt: initialValues.createdAt || '',
          updatedAt: initialValues.updatedAt || '',
          deletedAt: initialValues.deletedAt || '',
        },
        initialValues.uuid && {
          uuid: initialValues.uuid,
        }
      )
    },
    validationSchema: Yup.object().shape({
      subHeading: Yup.string().required('Please enter a subheading'),
      heading: Yup.string().required('Please enter a heading'),
      email: Yup.string().email('Please enter a valid email'),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      // pass values to our onSubmit prop
      if (onSubmit) {
        onSubmit(values)
      }
    },
  })
)
