import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'

const StyledActions = styled.div`
  display: flex;
  justify-content: center;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.md}px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function DeleteOrgCountryForm({
  className,
  onCancel,
  onSubmit,

  orgCountry,
}) {
  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()

    if (onSubmit) {
      onSubmit()
    }
  }, [])

  return (
    <form
      onSubmit={handleOnSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <StyledTitle variant="h4">
        Are you sure you would like to remove{' '}
        {orgCountry && orgCountry.country
          ? orgCountry.country.name
          : 'this country'}
        ?
      </StyledTitle>
      <StyledText variant="body1">
        This country will not be selectable by users.
      </StyledText>
      <StyledActions>
        <Button
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}
        >
          No, cancel
        </Button>
        <Button variant="contained" type="submit" size="small" color="primary">
          Yes, delete
        </Button>
      </StyledActions>
    </form>
  )
}

DeleteOrgCountryForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,

  orgCountry: PropTypes.object,
}

DeleteOrgCountryForm.defaultProps = {
  orgCountry: {},
}

export default DeleteOrgCountryForm
