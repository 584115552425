import initialState from 'reducers/initialState'
import { API_GET_COUNTRIES_SUCCESS } from './actions'

export default function countriesReducer(
  state = initialState.countries,
  action
) {
  switch (action.type) {
    case API_GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.countries,
        },
      }
    default:
      return state
  }
}
