import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import SearchField from 'components/atoms/SearchField'
import MenuItem from 'components/molecules/MenuItem'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import DropdownButton from 'components/molecules/DropdownButton'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import JournalTask from 'components/molecules/JournalTask'
import ProgressLoader from 'components/atoms/ProgressLoader'
import JournalSubmit from 'components/atoms/Icons/JournalSubmit'
import SortHeading from 'components/molecules/SortHeading'
import ContentContainer from 'components/atoms/ContentContainer'
import Modal from 'components/molecules/Modal'
import ApproveJournalsForm from 'components/organisms/ApproveJournalsForm'
import JournalEntryForm from 'components/organisms/JournalEntryForm'
import DeleteJournalForm from 'components/organisms/DeleteJournalForm'
import Badge from '@weareroam/cake-ui-v1/Badge'
import Pagination from 'components/molecules/Pagination'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import useJournals from 'hooks/useJournals'
import useJournalModals from 'hooks/useJournalModals'
import styled from 'styled-components'
import {
  JOURNAL_TYPE_ALL,
  JOURNAL_TYPE_TO_REVIEW,
  JOURNAL_TYPE_SHARED,
  JOURNAL_TYPE_HIDDEN,
  JOURNAL_RED_FLAGGED,
  SORT_DIRECTION_ASC,
  TOGGLE_ALL,
} from 'constants/form'

export const StyledLayout = styled(ChnnlDashboardLayout)``
export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderLeft = styled.div``
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`
export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

const AllOrganisationsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const JournalsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const JournalHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 5%;
  }
  &:nth-child(2) {
    width: 65%;
  }
  &:nth-child(3) {
    width: 17.5%;
  }
  &:nth-child(4) {
    width: 13.5%;
  }
  &:nth-child(5) {
    width: 1.5%;
  }
`

export const StyledBadge = styled(Badge)``

export const StyledBadgeContainer = styled.div`
  opacity: ${({ faded }) => (faded ? 1 : 0.4)};
  cursor: ${({ faded }) => (faded ? 'pointer' : null)};
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export const JournalsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const DeleteModal = styled(Modal)`
  width: 500px;
`

export const ApproveModal = styled(Modal)`
  width: 500px;
`

export const EntryModal = styled(Modal)`
  width: 700px;
`

export function Journals({
  organisations,
  getJournalsProgress,
  getOrgsProgress,
  actions,
  journals,
}) {
  const {
    filteredEntities: filteredOrganisations,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(
    organisations,
    'organisation',
    !getOrgsProgress.inProgress
  )

  const {
    isDeleteJournalModalOpen,
    isApproveJournalsModalOpen,
    isJournalEntryModalOpen,
    journalModalUuid,
    handleOpenDeleteJournalModal,
    handleCloseDeleteJournalModal,
    handleOpenApproveJournalsModal,
    handleCloseApproveJournalsModal,
    handleOpenEntryJournalsModal,
    handleCloseEntryJournalsModal,
  } = useJournalModals()

  const {
    handleEditSubmit,
    handleDeleteJournal,
    handleApproveJournals,
    journalType,
    searchFilter,
    pagination,
    handleChangePagination,
    handleRevertJournal,
    handleChangeJournalType,
    handleChangeSearchFilter,
    journalsMarkedToApprove,
    handleChangeJournalsMarkedToApprove,
  } = useJournals({
    journals,
    filters: { key: 'orgs', value: filteredOrganisations },
    sortOptions: { createdAt: 'DESC' },
    journalModalUuid,
    onDeleteJournal: useCallback(async (values) => {
      await actions.deleteJournal(values)
      handleCloseDeleteJournalModal()
    }, []),
    onApproveJournals: useCallback(async (values) => {
      await actions.approveJournals(values)
      handleCloseApproveJournalsModal()
    }, []),
    onEditJournal: useCallback(async (values) => {
      await actions.editJournal(values)
      handleCloseEntryJournalsModal()
    }, []),
    onRevertJournal: useCallback(async (values) => {
      await actions.revertJournal(values)
      handleCloseEntryJournalsModal()
    }, []),
    onFetchJournals: actions.getJournals,
  })

  const editingJournal = useMemo(
    () => {
      if (!journals) {
        return null
      }

      return journals.find((journal) => journal.uuid === journalModalUuid)
    },
    [journals, journalModalUuid]
  )

  const canApproveJournals = useMemo(
    () => {
      return journalsMarkedToApprove && journalsMarkedToApprove.length > 0
    },
    [journalsMarkedToApprove]
  )

  const openJournalApproveModal = useCallback(
    () => {
      if (canApproveJournals) {
        handleOpenApproveJournalsModal()
      }
    },
    [canApproveJournals]
  )

  return (
    <StyledLayout>
      <DeleteModal
        open={isDeleteJournalModalOpen}
        onClose={handleCloseDeleteJournalModal}
      >
        <DeleteJournalForm
          onCancel={handleCloseDeleteJournalModal}
          onSubmit={handleDeleteJournal}
        />
      </DeleteModal>

      <EntryModal
        open={isJournalEntryModalOpen}
        onClose={handleCloseEntryJournalsModal}
      >
        {editingJournal && (
          <JournalEntryForm
            groupName={editingJournal.group.name}
            organisationName={editingJournal.organisation.name}
            date={editingJournal.forDate}
            createdAt={editingJournal.createdAt}
            originalEntry={editingJournal.originalEntry}
            onCancel={handleCloseEntryJournalsModal}
            onSubmit={handleEditSubmit}
            onRevert={handleRevertJournal}
            initialEntry={
              editingJournal.editedEntry || editingJournal.originalEntry
            }
          />
        )}
      </EntryModal>

      <ApproveModal
        open={isApproveJournalsModalOpen}
        onClose={handleCloseApproveJournalsModal}
      >
        <ApproveJournalsForm
          onCancel={handleCloseApproveJournalsModal}
          onSubmit={handleApproveJournals}
        />
      </ApproveModal>

      <ContentContainer>
        <StyledHeader>
          <HeaderLeft>
            <DropdownButton
              name="organisations"
              value={filteredOrganisations}
              onChange={handleChangeFilteredEntities}
              renderValue={renderFilterLabel}
            >
              <MenuItem value={TOGGLE_ALL}>
                <Checkbox
                  color="primary"
                  checked={
                    filteredOrganisations.length === organisations.length
                  }
                />
                <AllOrganisationsLabel primary="All organisations" />
              </MenuItem>
              {organisations.map((organisation) => {
                return (
                  <MenuItem key={organisation.uuid} value={organisation.uuid}>
                    <Checkbox
                      color="primary"
                      checked={filteredOrganisations.includes(
                        organisation.uuid
                      )}
                    />
                    <ListItemText primary={organisation.name} />
                  </MenuItem>
                )
              })}
            </DropdownButton>
          </HeaderLeft>
          <HeaderRight>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              name="searchFilter"
              onChange={handleChangeSearchFilter}
            />
            <Filters>
              <ButtonSwitch
                value={JOURNAL_TYPE_ALL}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_ALL}
                onClick={handleChangeJournalType}
              >
                All entries
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_RED_FLAGGED}
                name="journalType"
                isActive={journalType === JOURNAL_RED_FLAGGED}
                onClick={handleChangeJournalType}
              >
                Red flagged
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_TYPE_TO_REVIEW}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_TO_REVIEW}
                onClick={handleChangeJournalType}
              >
                To review
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_TYPE_SHARED}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_SHARED}
                onClick={handleChangeJournalType}
              >
                Shared
              </ButtonSwitch>
              <ButtonSwitch
                value={JOURNAL_TYPE_HIDDEN}
                name="journalType"
                isActive={journalType === JOURNAL_TYPE_HIDDEN}
                onClick={handleChangeJournalType}
              >
                Hidden
              </ButtonSwitch>
            </Filters>
          </HeaderRight>
        </StyledHeader>

        <JournalsHeader>
          <JournalHeaderItem>
            <StyledBadgeContainer faded={canApproveJournals}>
              <StyledBadge
                badgeContent={journalsMarkedToApprove.length}
                onClick={openJournalApproveModal}
              >
                <JournalSubmit />
              </StyledBadge>
            </StyledBadgeContainer>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>Journal entry</SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>
              <strong>Org</strong> / Group
            </SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Date</strong> / Submitted
            </SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem />
        </JournalsHeader>

        {getJournalsProgress.inProgress &&
        (!journals || journals.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <React.Fragment>
            <JournalsList>
              {journals.map((journal, index) => {
                let journalStatus

                if (journal.hidden) {
                  journalStatus = JOURNAL_TYPE_HIDDEN
                } else if (journal.approved) {
                  journalStatus = JOURNAL_TYPE_SHARED
                } else {
                  journalStatus = JOURNAL_TYPE_TO_REVIEW
                }

                return (
                  <JournalTask
                    key={journal.uuid}
                    dataQa={`journal-entry-${index}`}
                    entry={journal.editedEntry || journal.originalEntry}
                    redFlagged={journal.redFlagged}
                    hasEntryBeenEdited={!!journal.editedEntry}
                    organisationName={
                      journal.organisation
                        ? journal.organisation.name
                        : 'No organisation'
                    }
                    groupName={journal.group ? journal.group.name : 'No group'}
                    date={journal.forDate}
                    createdAt={journal.createdAt}
                    status={journalStatus}
                    checked={journalsMarkedToApprove.includes(journal.uuid)}
                    value={journal.uuid}
                    onCheck={handleChangeJournalsMarkedToApprove}
                    onClick={handleOpenEntryJournalsModal}
                    name="markedToApprove"
                    actions={[
                      {
                        key: 'delete-journal',
                        label: 'Delete entry',
                        onClick: () => {
                          handleOpenDeleteJournalModal(journal.uuid)
                        },
                      },
                      {
                        key: 'change-hidden',
                        onClick: () => {
                          actions.changeJournalHidden({
                            journalUuid: journal.uuid,
                            hidden: !journal.hidden,
                          })
                        },
                        label: `${
                          journal.hidden ? 'Un-hide' : 'Hide'
                        } from organisation`,
                      },
                    ]}
                  />
                )
              })}
            </JournalsList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </React.Fragment>
        )}
      </ContentContainer>
    </StyledLayout>
  )
}

Journals.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  getJournalsProgress: PropTypes.object,
  getOrgsProgress: PropTypes.object,
  actions: PropTypes.object,
  journals: PropTypes.arrayOf(PropTypes.object),
}

Journals.defaultProps = {
  organisations: [],
  getJournalsProgress: {},
  getOrgsProgress: {},
  actions: {},
  journals: [],
}

export default Journals
