import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import EyeIcon from 'components/atoms/Icons/Eye'
import styled from 'styled-components'

export const StyledPasswordField = styled.div`
  position: relative;
`

export const StyledIcon = styled(EyeIcon)`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  cursor: pointer;
`

export const StyledTextField = styled(TextField)`
  && {
    input {
      background-color: transparent !important;
    }
  }
`

export function PasswordField({
  value,
  helperText,
  onBlur,
  onChange,
  name,
  error,
  label,
  className,
}) {
  const [isVisible, setIsVisible] = useState(false)
  const handleToggleVisibility = useCallback(
    () => {
      setIsVisible(!isVisible)
    },
    [isVisible]
  )

  return (
    <StyledPasswordField className={className}>
      <StyledTextField
        name={name}
        label={label}
        type={isVisible ? 'text' : 'password'}
        value={value}
        onBlur={onBlur}
        error={error}
        onChange={onChange}
        helperText={helperText}
        fullWidth
      />
      <StyledIcon onClick={handleToggleVisibility} />
    </StyledPasswordField>
  )
}

PasswordField.propTypes = {
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  helperText: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
}

export default PasswordField
