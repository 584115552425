import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import DetailedSwitch from 'components/molecules/DetailedSwitch'
import Typography from '@weareroam/cake-ui-v1/Typography'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import OrgSettingsLayout from 'layouts/OrgSettingsLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import styled from 'styled-components'

export const StyledLayout = styled(AdminDashboardLayout)``
export const StyledHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.unit * 8}px;
`
export const Content = styled.div`
  max-width: 372px;
`
export const StyledSeparator = styled.hr`
  height: 0;
  border-top: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  margin-top: ${({ theme }) => theme.spacing.xl}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export function Notifications({ actions }) {
  const [monthlyReportsActive, handleMonthlyReportChange] = useState(false)
  const [companyNewsActive, handleCompanyNewsChange] = useState(false)
  const hasChanged = useRef(null)
  useEffect(() => {
    actions.getProfileNotifications()
  }, [])
  useEffect(
    () => {
      if (hasChanged.current) {
        actions.updateProfileNotifications({
          emailUpdate: companyNewsActive,
          monthlyReportsActive: monthlyReportsActive,
        })
      } else {
        hasChanged.current = true
      }
    },
    [companyNewsActive, monthlyReportsActive]
  )

  return (
    <StyledLayout noPadding>
      <OrgSettingsLayout>
        <ContentContainer>
          <StyledHeader>
            <Typography variant="h2">Email notifications</Typography>
          </StyledHeader>
          <Content>
            <DetailedSwitch
              title="Receive monthly reports"
              isSelected={monthlyReportsActive}
              onChange={handleMonthlyReportChange}
              description="Receive monthly reports via email on how your organisation is doing"
            />
            <StyledSeparator />
            <DetailedSwitch
              isSelected={companyNewsActive}
              onChange={handleCompanyNewsChange}
              title="chnnl news and product updates"
              description="Stay updated with the latest from chnnl"
            />
          </Content>
        </ContentContainer>
      </OrgSettingsLayout>
    </StyledLayout>
  )
}

Notifications.propTypes = {
  actions: PropTypes.object,
}

Notifications.defaultProps = {
  actions: {},
}

export default Notifications
