import React from 'react'
import { history } from 'store/history'
import { ConnectedRouter } from 'connected-react-router'
import { Redirect, Switch, Route } from 'react-router-dom'
import { useFeature } from '@optimizely/react-sdk'
import {
  LOGIN,
  SIGN_UP,
  RESET_PASSWORD,
  NEW_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_ORGANISATION,
  CHNNL_DASHBOARD,
  CHNNL_DASHBOARD_ORGANISATIONS,
  CHNNL_DASHBOARD_WELLBEING,
  CHNNL_DASHBOARD_JOURNALS,
  CHNNL_DASHBOARD_SPEAKUP,
  CHNNL_DASHBOARD_CHATBOT,
  CHNNL_DASHBOARD_SETTINGS,
  CHNNL_DASHBOARD_SETTINGS_PROFILE,
  CHNNL_DASHBOARD_SETTINGS_PASSWORD,
  CHNNL_DASHBOARD_SETTINGS_NOTIFICATIONS,
  ORG_DASHBOARD,
  ORG_DASHBOARD_OVERVIEW,
  ORG_DASHBOARD_GROUPS,
  ORG_DASHBOARD_JOURNALS,
  ORG_DASHBOARD_SPEAKUP,
  ORG_DASHBOARD_APP,
  ORG_DASHBOARD_APP_ORGANISATION,
  ORG_DASHBOARD_APP_SURVEY,
  ORG_DASHBOARD_APP_REFLECTIONS,
  ORG_DASHBOARD_APP_BOOSTERS,
  ORG_DASHBOARD_APP_PATHWAYS,
  ORG_DASHBOARD_MESSAGING,
  ORG_DASHBOARD_MESSAGING_ORGANISATION,
  ORG_DASHBOARD_SETTINGS,
  ORG_DASHBOARD_SETTINGS_ADMINS,
  ORG_DASHBOARD_SETTINGS_GENERAL,
  ORG_DASHBOARD_SETTINGS_PROFILE,
  ORG_DASHBOARD_SETTINGS_NOTIFICATIONS,
  GROUP_DASHBOARD,
  GROUP_DASHBOARD_WELLBEING,
  GROUP_DASHBOARD_BOOSTERS,
  GROUP_DASHBOARD_MESSAGING,
  GROUP_DASHBOARD_MEMBERS,
  GROUP_DASHBOARD_SETTINGS,
  ORG_DASHBOARD_APP_CONTACTS,
  APP_TOKEN_DEEPLINK,
  SIGN_UP_LINK_EXPIRED,
  SIGN_ME_UP,
  SIGN_UP_SUCCESS,
} from 'constants/routes'
import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_ADMIN,
} from 'constants/form'
import { replaceRouteParams } from 'utils/routes'
import PrivateRoute from 'components/organisms/PrivateRoute'
import Login from 'views/Login'
import ResetPassword from 'views/ResetPassword'
import NewPassword from 'views/NewPassword'
import Signup from 'views/Signup'
import PageNotFound from 'views/PageNotFound/PageNotFound'
import SignUpLinkExpired from 'views/SignUpLinkExpired/SignUpLinkExpired'
import ResetPasswordSuccess from 'views/ResetPassword/Success'
import SignupOrganisationOnboarding from 'views/Signup/Organisation'
import ChnnlOrganisations from 'views/Dashboard/Chnnl/Organisations'
import ChnnlWellbeing from 'views/Dashboard/Chnnl/Wellbeing'
import ChnnlJournals from 'views/Dashboard/Chnnl/Journals'
import ChnnlSpeakUp from 'views/Dashboard/Chnnl/SpeakUp'
import ChnnlChatbot from 'views/Dashboard/Chnnl/Chatbot'
import ChnnlSettings from 'views/Dashboard/Chnnl/Settings'
import ChnnlSettingsProfile from 'views/Dashboard/Chnnl/Settings/Profile'
import ChnnlSettingsPassword from 'views/Dashboard/Chnnl/Settings/Password'
import ChnnlSettingsNotifications from 'views/Dashboard/Chnnl/Settings/Notifications'
import OrganisationOverview from 'views/Dashboard/Organisation/Overview'
import OrganisationMessaging from 'views/Dashboard/Organisation/Messaging'
import OrganisationGroups from 'views/Dashboard/Organisation/Groups'
import OrganisationJournals from 'views/Dashboard/Organisation/Journals'
import OrganisationSpeakUp from 'views/Dashboard/Organisation/SpeakUp'
import OrganisationBoosters from 'views/Dashboard/Organisation/App/Boosters'
import OrganisationPathways from 'views/Dashboard/Organisation/App/Pathways'
import OrganisationAppOrganisation from 'views/Dashboard/Organisation/App/Organisation'
import OrganisationAppContacts from 'views/Dashboard/Organisation/App/Contacts'
import OrganisationSurvey from 'views/Dashboard/Organisation/App/Survey'
import OrganisationReflections from 'views/Dashboard/Organisation/App/Reflections'
import OrganisationReflectionsV1 from 'views/Dashboard/Organisation/App/ReflectionsV1'
import OrganisationSettingsAdmin from 'views/Dashboard/Organisation/Settings/Admins'
import OrganisationSettingsGeneral from 'views/Dashboard/Organisation/Settings/General'
import OrganisationSettingsProfile from 'views/Dashboard/Organisation/Settings/Profile'
import OrganisationSettingsNotifications from 'views/Dashboard/Organisation/Settings/Notifications'
import GroupWellbeing from 'views/Dashboard/Organisation/Groups/Wellbeing'
import GroupBoosters from 'views/Dashboard/Organisation/Groups/Boosters'
import GroupMessaging from 'views/Dashboard/Organisation/Groups/Messaging'
import GroupMembers from 'views/Dashboard/Organisation/Groups/Members'
import GroupSettings from 'views/Dashboard/Organisation/Groups/Settings'
import Toasts from 'components/organisms/Toasts'
import ScreenSizeMessage from 'components/atoms/ScreenSizeMessage'
import AppDownloadRedirect from 'components/atoms/AppDownloadRedirect'
import SignMeUp from 'views/SignMeUp'
import SignUpSuccess from 'views/SignUpSuccess'

export function App() {
  const [reflectionToggleEnabled] = useFeature('dashboard_toggle_reflections')
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            {/* Redirect landing page */}
            <Route exact path="/" component={() => <Redirect to={LOGIN} />} />
            <Route path={APP_TOKEN_DEEPLINK} component={AppDownloadRedirect} />
            <Route path={SIGN_UP_LINK_EXPIRED} component={SignUpLinkExpired} />
            <Route path={SIGN_ME_UP} component={SignMeUp} />
            <Route path={SIGN_UP_SUCCESS} component={SignUpSuccess} />
            {/* Account stuff */}
            <Route exact path={LOGIN} component={Login} />
            <Route exact path={SIGN_UP} component={Signup} />
            <PrivateRoute
              exact
              path={SIGN_UP_ORGANISATION}
              component={SignupOrganisationOnboarding}
            />
            <Route exact path={RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={NEW_PASSWORD} component={NewPassword} />
            <Route
              exact
              path={RESET_PASSWORD_SUCCESS}
              component={ResetPasswordSuccess}
            />

            {/* chnnl */}
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD}
              component={() => <Redirect to={CHNNL_DASHBOARD_ORGANISATIONS} />}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_ORGANISATIONS}
              component={ChnnlOrganisations}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_WELLBEING}
              component={ChnnlWellbeing}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_JOURNALS}
              component={ChnnlJournals}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_SPEAKUP}
              component={ChnnlSpeakUp}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_CHATBOT}
              component={ChnnlChatbot}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_SETTINGS}
              component={ChnnlSettings}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_SETTINGS_PROFILE}
              component={ChnnlSettingsProfile}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_SETTINGS_PASSWORD}
              component={ChnnlSettingsPassword}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />
            <PrivateRoute
              exact
              path={CHNNL_DASHBOARD_SETTINGS_NOTIFICATIONS}
              component={ChnnlSettingsNotifications}
              authorisedRoles={[ROLE_SUPER_ADMIN]}
            />

            {/* Organisations */}
            <PrivateRoute
              exact
              path={ORG_DASHBOARD}
              component={({ match }) => (
                <Redirect
                  to={replaceRouteParams(CHNNL_DASHBOARD_ORGANISATIONS, {
                    orgUuid: match.params.orgUuid,
                  })}
                />
              )}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_OVERVIEW}
              component={OrganisationOverview}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_GROUPS}
              component={OrganisationGroups}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
                ROLE_GROUP_ADMIN,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_JOURNALS}
              component={OrganisationJournals}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_SPEAKUP}
              component={OrganisationSpeakUp}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP}
              component={({ match }) => (
                <Redirect
                  to={replaceRouteParams(ORG_DASHBOARD_APP_ORGANISATION, {
                    orgUuid: match.params.orgUuid,
                  })}
                />
              )}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_MESSAGING}
              component={({ match }) => (
                <Redirect
                  to={replaceRouteParams(ORG_DASHBOARD_MESSAGING_ORGANISATION, {
                    orgUuid: match.params.orgUuid,
                  })}
                />
              )}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_MESSAGING_ORGANISATION}
              component={OrganisationMessaging}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP_BOOSTERS}
              component={OrganisationBoosters}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP_PATHWAYS}
              component={OrganisationPathways}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP_ORGANISATION}
              component={OrganisationAppOrganisation}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP_CONTACTS}
              component={OrganisationAppContacts}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP_SURVEY}
              component={OrganisationSurvey}
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_APP_REFLECTIONS}
              component={
                reflectionToggleEnabled
                  ? OrganisationReflections
                  : OrganisationReflectionsV1
              }
              authorisedRoles={[
                ROLE_SUPER_ADMIN,
                ROLE_ORG_ADMIN,
                ROLE_ORG_OWNER,
              ]}
            />

            {/* Org settings */}
            <PrivateRoute
              exact
              path={ORG_DASHBOARD_SETTINGS}
              component={({ match, role }) => {
                if (role === ROLE_GROUP_ADMIN) {
                  return (
                    <Redirect
                      to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_PROFILE, {
                        orgUuid: match.params.orgUuid,
                      })}
                    />
                  )
                }

                return (
                  <Redirect
                    to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_GENERAL, {
                      orgUuid: match.params.orgUuid,
                    })}
                  />
                )
              }}
            />

            <PrivateRoute
              exact
              path={ORG_DASHBOARD_SETTINGS_ADMINS}
              component={OrganisationSettingsAdmin}
            />

            <PrivateRoute
              exact
              path={ORG_DASHBOARD_SETTINGS_GENERAL}
              component={OrganisationSettingsGeneral}
            />

            <PrivateRoute
              exact
              path={ORG_DASHBOARD_SETTINGS_PROFILE}
              component={OrganisationSettingsProfile}
            />

            <PrivateRoute
              exact
              path={ORG_DASHBOARD_SETTINGS_NOTIFICATIONS}
              component={OrganisationSettingsNotifications}
            />

            {/* Group */}
            <PrivateRoute
              exact
              path={GROUP_DASHBOARD}
              component={({ match }) => (
                <Redirect
                  to={replaceRouteParams(GROUP_DASHBOARD_WELLBEING, {
                    groupUuid: match.params.groupUuid,
                    orgUuid: match.params.orgUuid,
                  })}
                />
              )}
            />

            <PrivateRoute
              exact
              path={GROUP_DASHBOARD_WELLBEING}
              component={GroupWellbeing}
            />

            <PrivateRoute
              exact
              path={GROUP_DASHBOARD_BOOSTERS}
              component={GroupBoosters}
            />

            <PrivateRoute
              exact
              path={GROUP_DASHBOARD_MESSAGING}
              component={GroupMessaging}
            />

            <PrivateRoute
              exact
              path={GROUP_DASHBOARD_MEMBERS}
              component={GroupMembers}
            />

            <PrivateRoute
              exact
              path={GROUP_DASHBOARD_SETTINGS}
              component={GroupSettings}
            />

            {/* 404 */}
            <Route component={PageNotFound} />
          </Switch>
          <ScreenSizeMessage />
        </div>
      </ConnectedRouter>
      <Toasts />
    </div>
  )
}

export default App
