import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Table from '@weareroam/cake-ui-v1/Table'
import DataTableBodyCell from 'components/atoms/DataTableBodyCell'
import DataTableHeadCell from 'components/atoms/DataTableHeadCell'
import TableRow from '@weareroam/cake-ui-v1/TableRow'
import DataTableRow from 'components/atoms/DataTableRow'
import TableBody from '@weareroam/cake-ui-v1/TableBody'
import TableHead from '@weareroam/cake-ui-v1/TableHead'
import PercentageTag from 'components/atoms/PercentageTag'
import styled from 'styled-components'

export const StyledValueCell = styled(DataTableBodyCell).attrs({
  align: 'center',
})`
  && {
    width: 15%;
  }
`

export const SpacerRow = styled(TableRow)`
  && {
    height: 20px;
  }
`

export const getAttributeName = (row) => {
  if (row.gender) return 'gender'
  if (row.age) return 'age'
  if (row.ethnicity) return 'ethnicity'
  return ''
}

export function AttributeReport({ data }) {
  const attributeName = data.length ? getAttributeName(data[0]) : ''
  const sortedData = useMemo(
    () => {
      return data.filter((item) => item[attributeName] !== undefined)
    },
    [data]
  )

  return (
    <Table>
      <TableHead>
        <TableRow>
          <DataTableHeadCell>
            {attributeName
              ? attributeName[0].toUpperCase() + attributeName.substring(1)
              : null}
          </DataTableHeadCell>
          <DataTableHeadCell>Participants</DataTableHeadCell>
          <DataTableHeadCell>Total responses</DataTableHeadCell>
          <DataTableHeadCell>Avg score</DataTableHeadCell>
          <DataTableHeadCell>Effect %</DataTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <SpacerRow />
        {sortedData.map((row, index) => {
          return (
            <DataTableRow key={index}>
              <DataTableBodyCell>{row[attributeName]}</DataTableBodyCell>
              <StyledValueCell>{row.totalUniqueParticipants}</StyledValueCell>
              <StyledValueCell>{row.totalResponses}</StyledValueCell>
              <StyledValueCell>{row.averageMood.toFixed(2)}</StyledValueCell>
              <StyledValueCell>
                <PercentageTag variant={row.effect >= 0 ? 'light' : 'dark'}>
                  {row.effect}
                </PercentageTag>
              </StyledValueCell>
            </DataTableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

AttributeReport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

AttributeReport.defaultProps = {
  data: [],
}

export default AttributeReport
