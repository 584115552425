import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import Button from '@weareroam/cake-ui-v1/Button'
import MenuItem from 'components/molecules/MenuItem'
import ExportIcon from '@weareroam/cake-ui-v1-icons/ImportExport'
import DropdownFilter from 'components/molecules/DropdownFilter'
import WellbeingCharts from 'components/organisms/WellbeingCharts'
import WellbeingReports from 'components/organisms/WellbeingReports'
import ActivitySeatInfo from 'components/molecules/ActivitySeatInfo'
import ActivityHighLevel from 'components/molecules/ActivityHighLevel'
import ActivityResponses from 'components/organisms/ActivityResponses'
import styled from 'styled-components'
import useWellbeing from 'hooks/useWellbeing'
import { API_YEAR_MONTH_FORMAT, MONTH_FILTER_FORMAT } from 'constants/date'
import {
  OVERVIEW_TYPE_WELLBEING,
  OVERVIEW_TYPE_APP_ACTIVITY,
} from 'constants/form'
import { format } from 'date-fns'

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const ReportTypes = styled.div``

export function Overview(
  {
    actions,
    activity,
    lineChart,
    barChart,
    onExport,
    reflectionReport,
    attributeReport,
    error,
    orgUuid,
  } = { activity: {} }
) {
  const overviewType = OVERVIEW_TYPE_WELLBEING
  const currentOrganisation = useMemo(() => {
    return [orgUuid]
  }, [])
  const {
    attribute,
    attributeMood,
    filteredMonth,
    months,
    graphMood,
    handleChangeGraphMood,
    handleChangeFilteredMonth,
    handleSetAttributeMood,
    handleSetAttribute,
  } = useWellbeing({
    onFetchMood: actions.getWellbeingMoodChart,
    onFetchMoodMonthly: actions.getWellbeingMonthlyMoodChart,
    onFetchReflectionReport: actions.getWellbeingReflectionReport,
    onFetchAttributeReport: actions.getWellbeingAttributeReport,
    filteredOrganisations: currentOrganisation,
  })
  return (
    <AdminDashboardLayout>
      <ContentContainer>
        <StyledHeader>
          <div>
            <Button
              variant="text"
              color="primary"
              type="button"
              onClick={onExport}
            >
              <ExportIcon /> Export all organisation data
            </Button>
          </div>
          <DropdownFilter
            label="View:"
            value={filteredMonth}
            onChange={handleChangeFilteredMonth}
            name="month"
          >
            {months.map((month, index) => {
              return (
                <MenuItem
                  key={index}
                  color="primary"
                  value={format(month, API_YEAR_MONTH_FORMAT)}
                >
                  {format(month, MONTH_FILTER_FORMAT)}
                </MenuItem>
              )
            })}
          </DropdownFilter>
        </StyledHeader>

        {overviewType === OVERVIEW_TYPE_WELLBEING && (
          <React.Fragment>
            <WellbeingCharts
              lineChart={lineChart}
              barChart={barChart}
              onChangeMood={handleChangeGraphMood}
              mood={graphMood}
              warning={reflectionReport && reflectionReport.warningMessage}
              error={error}
              filteredMonth={filteredMonth}
              title="Your organisation's"
            />

            <WellbeingReports
              reflectionReport={reflectionReport}
              attributeReport={attributeReport}
              filteredMonth={filteredMonth}
              onChangeMood={handleSetAttributeMood}
              mood={attributeMood}
              attribute={attribute}
              onChangeAttribute={handleSetAttribute}
            />
          </React.Fragment>
        )}
        {overviewType === OVERVIEW_TYPE_APP_ACTIVITY && (
          <>
            <ActivitySeatInfo data={activity.seats} />
            <ActivityHighLevel
              month={filteredMonth}
              data={activity.highLevel}
            />
            <ActivityResponses
              month={filteredMonth}
              data={activity.responses}
            />
          </>
        )}
      </ContentContainer>
    </AdminDashboardLayout>
  )
}

Overview.propTypes = {
  actions: PropTypes.object,
  lineChart: PropTypes.arrayOf(PropTypes.object),
  barChart: PropTypes.arrayOf(PropTypes.object),
  reflectionReport: PropTypes.arrayOf(PropTypes.object),
  attributeReport: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  orgUuid: PropTypes.string,
  onExport: PropTypes.func,
}

Overview.defaultProps = {
  actions: {},
}

export default Overview
