import { useState, useMemo, useCallback } from 'react'
import {
  SORT_ALPHABETICAL,
  SORT_LEAST_MEMBERS,
  SORT_MOST_MEMBERS,
  SORT_LEAST_ADMINS,
  SORT_MOST_ADMINS,
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from 'constants/form'
import { sortItems } from 'utils/sort'

export function useEntitiesSort(entities) {
  const [sortField, setSortField] = useState(SORT_ALPHABETICAL)

  const handleChangeSortField = useCallback((e) => {
    setSortField(e.target.value)
  }, [])

  const sortedEntities = useMemo(
    () => {
      let key
      let direction = SORT_DIRECTION_ASC

      switch (sortField) {
        case SORT_ALPHABETICAL:
          key = 'name'
          break
        case SORT_LEAST_MEMBERS:
          key = 'memberCount'
          break
        case SORT_MOST_MEMBERS:
          key = 'memberCount'
          direction = SORT_DIRECTION_DESC
          break
        case SORT_LEAST_ADMINS:
          key = 'adminCount'
          break
        case SORT_MOST_ADMINS:
          key = 'adminCount'
          direction = SORT_DIRECTION_DESC
          break
        default:
          key = 'name'
          break
      }

      return sortItems(entities, key, direction)
    },
    [entities, sortField]
  )

  return {
    sortField,
    handleChangeSortField,
    sortedEntities,
  }
}

export default useEntitiesSort
