import { schema } from 'normalizr'

export const typesOfDaySchema = new schema.Entity(
  'typesOfDays',
  {},
  { idAttribute: 'uuid' }
)
export const typesOfDayListSchema = [typesOfDaySchema]

export const departmentSchema = new schema.Entity(
  'departments',
  {},
  { idAttribute: 'uuid' }
)
export const departmentListSchema = [departmentSchema]
