import { compose, withState } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ pathways }) => ({
      pathways: pathways || [],
    }),
    validationSchema: Yup.object().shape({
      // Currently example validation. API not available yet
      pathways: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Title is required'),
          message: Yup.string().required('Message is required'),
        })
      ),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      if (props.isConfirmUpdateModalOpen) {
        // If the confirmation modal is already open, submit the form
        setSubmitting(true)
        // If form has no error
        if (props.onSubmit) {
          props.onSubmit(values.pathways)
        }

        props.setIsConfirmUpdateModalOpen(false)
      } else {
        // Open confirmation modal before submitting the form
        props.setIsConfirmUpdateModalOpen(true)
      }
    },
  })
)
