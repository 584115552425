import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledTag = styled.div`
  display: inline-block;
  border-radius: 8px;
  background-color: ${({ variant, theme }) =>
    variant === 'light'
      ? theme.palette.quaternary.light
      : theme.palette.quaternary.xdark};
  color: ${({ theme, variant }) =>
    variant === 'light'
      ? theme.palette.quaternary.xdark
      : theme.palette.text.secondary};
  padding: ${({ theme }) => theme.spacing.xs}px
    ${({ theme }) => theme.spacing.sm}px;
`

export function PercentageTag({ variant, children }) {
  return <StyledTag variant={variant}>{children}%</StyledTag>
}

PercentageTag.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.node,
}

export default PercentageTag
