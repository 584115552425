import { useState, useCallback } from 'react'

export default function useMemberModals() {
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)
  const [isDeleMemberModalOpen, setIsDeleMemberModalOpen] = useState(false)
  const [isMultiResendModalOpen, setIsMultiResendModalOpen] = useState(false)
  const [memberUuid, setMemberUuid] = useState(null)

  const handleOpenDeleteMemberModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsDeleMemberModalOpen(true)
  }, [])

  const handleCloseDeleteMemberModal = useCallback(() => {
    setIsDeleMemberModalOpen(false)
    setMemberUuid(null)
  }, [])

  const handleOpenMultipleResendModal = useCallback(() => {
    setIsMultiResendModalOpen(true)
  }, [])

  const handleCloseMultipleResendModal = useCallback(() => {
    setIsMultiResendModalOpen(false)
  }, [])

  const handleOpenSingleResendModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsResendModalOpen(true)
  }, [])

  const handleCloseSingleResendModal = useCallback(() => {
    setIsResendModalOpen(false)
  }, [])

  const handleOpenEditMemberModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsEditMemberModalOpen(true)
  }, [])

  const handleCloseEditMemberModal = useCallback(() => {
    setIsEditMemberModalOpen(false)
    setMemberUuid(null)
  }, [])

  return {
    handleOpenEditMemberModal,
    handleCloseEditMemberModal,
    handleOpenDeleteMemberModal,
    handleCloseDeleteMemberModal,
    handleOpenSingleResendModal,
    handleCloseSingleResendModal,
    handleOpenMultipleResendModal,
    handleCloseMultipleResendModal,
    isEditMemberModalOpen,
    isMultiResendModalOpen,
    isResendModalOpen,
    isDeleMemberModalOpen,
    memberUuid,
  }
}
