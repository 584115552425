import React from 'react'

export function SpeakUp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28px"
      height="28px"
      viewBox="0 0 120 90"
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g
          id="Layer_1-2"
          data-name="Layer 1"
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="9px"
        >
          <path
            className="cls-1"
            d="M44,65.56l4.87,15.31a1.91,1.91,0,0,1-1.83,2.49H35A1.9,1.9,0,0,1,33.22,82l-9-27.82"
          />
          <path
            className="cls-1"
            d="M80.2,12.75V7.33A3.46,3.46,0,0,0,76.87,4H74.41a3,3,0,0,0-3,2.55l-.11.76-.59.47C53.6,21.61,39.87,22.61,19.28,22.61a8.12,8.12,0,0,0-6.51,3.06l-.58.81H7.34c-1.66,0-3.34.9-3.34,2.63V47.37a3.18,3.18,0,0,0,3.34,3h4.77l.58.71c2.58,3.16,5.74,3.16,10.1,3.16H24.2l16.51.87,3.14.56A55.07,55.07,0,0,1,70.76,68.73l.67.58v.89c0,1.3,1.57,2.28,3,2.28h2.46a3.17,3.17,0,0,0,3.33-3V29.18"
          />
          <line className="cls-1" x1="80.2" y1="7.33" x2="80.2" y2="29.18" />
          <line
            className="cls-1"
            x1="90.72"
            y1="32.39"
            x2="108.99"
            y2="14.12"
          />
          <line
            className="cls-1"
            x1="90.72"
            y1="46.69"
            x2="108.99"
            y2="64.96"
          />
          <line
            className="cls-1"
            x1="93.59"
            y1="39.21"
            x2="120.96"
            y2="39.21"
          />
        </g>
      </g>
    </svg>
  )
}

export default SpeakUp
