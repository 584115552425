import React from 'react'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { withRouter } from 'react-router-dom'
import { MOBILE_FRIENDLY_ROUTES } from '../../../constants/routes';

const StyledContent = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.accents.dark};

  @media (min-width: 1024px) {
    display: none;
  }
`

const StyledMessage = styled(Typography)`
  padding: 30px;
  max-width: 600px;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const ScreenSizeMessage = ({ location: { pathname } }) => {
  if (MOBILE_FRIENDLY_ROUTES.includes(pathname)) {
    return null;
  }
  
  return (
    <StyledContent>
      <StyledMessage variant="body1">
        Sorry this dashboard is made to be viewed at a larger resolution, if you
        are on an tablet, please rotate to Landscape
      </StyledMessage>
    </StyledContent>
  )
}

export default withRouter(ScreenSizeMessage)
