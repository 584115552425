import React from 'react'
import PropTypes from 'prop-types'
import ChnnlAdminHeader from 'components/molecules/ChnnlAdminHeader'
import ChnnlAdminSidebar from 'components/molecules/ChnnlAdminSidebar'
import styled from 'styled-components'

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
`
export const IEWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`
export const StyledSidebar = styled(ChnnlAdminSidebar)`
  width: 60px;
`
export const Main = styled.div`
  display: flex;
  flex-grow: 1;
`
export const StyledHeader = styled(ChnnlAdminHeader)`
  height: 60px;
`
export const StyledContent = styled.div`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing.xl}px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.main};
`

export function ChnnlDashboardLayout({
  user,
  children,
  onLogout,
  className,
  noSidebar,
}) {
  return (
    <StyledLayout className={className}>
      <IEWrapper>
        <StyledHeader user={user} onLogout={onLogout} />
        <Main>
          {!noSidebar && <StyledSidebar />}
          <StyledContent noSidebar={noSidebar}>{children}</StyledContent>
        </Main>
      </IEWrapper>
    </StyledLayout>
  )
}

ChnnlDashboardLayout.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  noSidebar: PropTypes.bool,
}

export default ChnnlDashboardLayout
