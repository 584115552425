import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from 'components/molecules/Box'

import Typography from '@weareroam/cake-ui-v1/Typography'

export const Graphs = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const GraphTitle = styled.div`
  display: flex;
  align-items: center;
`
export const GraphBoxContent = styled.div`
  display: flex;
`
export const GraphBox = styled.div`
  flex-basis: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg}px;
  border-right: 1px solid
    ${({ theme, noLine }) =>
      noLine ? 'transparent' : theme.palette.tertiary.light};
`
export const SectionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const SectionValue = styled(Typography)`
  && {
    color: ${({ theme, colour }) =>
      colour ? theme.palette[colour].main : theme.palette.text.main};
  }
`
export const MonthLabel = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export function ActivitySeatInfo({ data }) {
  return (
    <Graphs
      headerContent={
        <GraphTitle>
          <Typography variant="h4">Seats in organisation</Typography>
        </GraphTitle>
      }
    >
      <GraphBoxContent>
        <GraphBox>
          <SectionLabel>Occupied</SectionLabel>
          <SectionValue variant="h2" colour="primary">
            {data.occupied}
          </SectionValue>
        </GraphBox>

        <GraphBox>
          <SectionLabel>Available</SectionLabel>
          <SectionValue variant="h2" colour="quaternary">
            {data.available}
          </SectionValue>
        </GraphBox>

        <GraphBox>
          <SectionLabel>Total</SectionLabel>
          <SectionValue variant="h2">{data.total}</SectionValue>
        </GraphBox>

        <GraphBox noLine>
          <SectionLabel>Expiring this month</SectionLabel>
          <SectionValue variant="h2" colour="error">
            {data.expiring}
          </SectionValue>
        </GraphBox>
      </GraphBoxContent>
    </Graphs>
  )
}

ActivitySeatInfo.propTypes = {
  data: PropTypes.shape({
    occupied: PropTypes.number,
    available: PropTypes.number,
    expiring: PropTypes.number,
    total: PropTypes.number,
  }),
}

ActivitySeatInfo.defaultProps = {
  data: {},
}

export default ActivitySeatInfo
