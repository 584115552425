import { compose, withState, withHandlers } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ organisation, keyContacts }) => ({
      organisationName: organisation ? organisation.name : '',
      logo: organisation ? organisation.logo : null,
      keyContacts,
      incidentsContactUuid:
        organisation && organisation.incidentsContact
          ? organisation.incidentsContact.uuid
          : '',
    }),
    validationSchema: Yup.object().shape({
      organisationName: Yup.string()
        .required('Please enter an organisation name')
        .max(50, 'Must be less than 50 characters long'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      if (props.isConfirmUpdateModalOpen) {
        // If the confirmation modal is already open, submit the form
        setSubmitting(true)
        // If form has no error
        if (props.onSubmit) {
          props.onSubmit(values)
        }

        props.setIsConfirmUpdateModalOpen(false)
      } else {
        // Open confirmation modal before submitting the form
        props.setIsConfirmUpdateModalOpen(true)
      }
    },
  }),
  withHandlers({
    onCloseAddKeyContactModal: ({
      setEditKeyContactIndex,
      setIsAddKeyContactModalOpen,
    }) => () => {
      setEditKeyContactIndex(null)
      setIsAddKeyContactModalOpen(false)
    },
  })
)
