import * as authActions from 'modules/authentication/actions'
import { isChnnlAdmin, hasEditRights } from 'modules/authentication/selectors'
import * as adminsActions from 'modules/admins/actions'
import { getAdminsList } from 'modules/admins/selectors'
import * as groupsActions from 'modules/groups/actions'
import { getGroupsList } from 'modules/groups/selectors'
import * as organisationActions from 'modules/organisations/actions'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  ADMIN_PERMISSION_CAN_EDIT,
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
} from 'constants/form'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        admins: getAdminsList(state),
        adminEntities: state.admins.entities,
        groups: getGroupsList(state),
        getAdminsProgress: state.progress.getOrganisationAdmins,
        orgUuid: match.params.orgUuid,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
        isOrgOwner: state.authentication.role === ROLE_ORG_OWNER,
        canTransferOrgOwnership:
          isChnnlAdmin(state) || state.authentication.role === ROLE_ORG_OWNER,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...adminsActions,
        ...groupsActions,
        ...organisationActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withState('editUserId', 'setEditUserId', null),
  withState('isInviteNewAdminModalOpen', 'setIsInviteNewAdminModalOpen', false),
  withState('resendInviteUserId', 'setResendInviteUserId', null),
  withState('resendInviteModalOpen', 'setResendInviteModalOpen', false),
  withState('isDeleteAdminModalOpen', 'setIsDeleteAdminModalOpen', false),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getOrgAdmins(match.params.orgUuid)
      actions.getGroups(match.params.orgUuid)
    },
  }),
  withHandlers({
    onInviteNewAdminSubmit: ({
      actions,
      match,
      setIsInviteNewAdminModalOpen,
      editUserId,
      adminEntities,
    }) => ({
      inEditMode,
      email,
      role,
      assignedToGroups,
      orgAdminPermission,
      groupAdminPermission,
    }) => {
      if (inEditMode) {
        let canEdit

        switch (role) {
          case ROLE_ORG_OWNER:
            canEdit = true
            break
          case ROLE_GROUP_ADMIN:
            canEdit = groupAdminPermission === ADMIN_PERMISSION_CAN_EDIT
            break
          case ROLE_ORG_ADMIN:
            canEdit = orgAdminPermission === ADMIN_PERMISSION_CAN_EDIT
            break
          default:
            canEdit = false
            break
        }

        const user = adminEntities[editUserId]

        if (role !== user.role) {
          actions.changeAdminRole({
            orgUuid: match.params.orgUuid,
            canEdit,
            adminUuid: user.userUuid,
            assignedGroups:
              role === ROLE_GROUP_ADMIN ? assignedToGroups : undefined,
            role,
          })
        } else {
          actions.updateAdmin({
            role,
            orgUuid: match.params.orgUuid,
            canEdit,
            adminUuid: user.uuid,
            assignedGroups: assignedToGroups,
          })
        }
      } else {
        switch (role) {
          case ROLE_ORG_ADMIN:
            actions.inviteOrgAdmin({
              email,
              orgUuid: match.params.orgUuid,
              canEdit: orgAdminPermission === ADMIN_PERMISSION_CAN_EDIT,
            })
            break
          case ROLE_GROUP_ADMIN:
            actions.inviteGroupAdminToGroups({
              email,
              canEdit: groupAdminPermission === ADMIN_PERMISSION_CAN_EDIT,
              orgUuid: match.params.orgUuid,
              groups: assignedToGroups,
            })
            break
          default:
            break
        }
      }

      setIsInviteNewAdminModalOpen(false)
    },
    handleResendInvitationConfirm: ({
      actions,
      match,
      setResendInviteModalOpen,
    }) => async (e, { role, email, uuid }) => {
      e.preventDefault()

      switch (role) {
        case ROLE_ORG_ADMIN:
          await actions.resendAdminInvitation({
            email,
            uuid,
            orgUuid: match.params.orgUuid,
          })
          break
        case ROLE_GROUP_ADMIN:
          await actions.resendAdminInvitation({
            uuid,
            isGroupAdmin: true,
            orgUuid: match.params.orgUuid,
          })
          break
        case ROLE_ORG_OWNER:
        default:
          await actions.resendOrganisationInvitation({
            email,
            uuid: match.params.orgUuid,
          })
          break
      }

      setResendInviteModalOpen(false)
    },
    handleDeleteAdmin: ({
      match,
      actions,
      orgUuid,
      setIsDeleteAdminModalOpen,
    }) => async ({ role, uuid }) => {
      switch (role) {
        case ROLE_GROUP_ADMIN:
          await actions.deleteGroupAdmin({ uuid, orgUuid })
          break
        case ROLE_ORG_ADMIN:
          await actions.deleteOrgAdmin({ uuid, orgUuid })
          break
        default:
          break
      }
      actions.getOrgAdmins(match.params.orgUuid)
      actions.getGroups(match.params.orgUuid)
      setIsDeleteAdminModalOpen(false)
    },
  })
)
