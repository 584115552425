import { compose, defaultProps } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => {
      return Object.assign(
        {
          fullName: initialValues.fullName || '',
          position: initialValues.position || '',
          email: initialValues.email || '',
          contactNumber: initialValues.contactNumber || '',
          extension: initialValues.extension || '',
          website: initialValues.website || '',
          description: initialValues.description || '',
        },
        initialValues.uuid && {
          uuid: initialValues.uuid,
        }
      )
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required('Please enter a name'),
      position: Yup.string().required('Please enter a position'),
      email: Yup.string().email('Please enter a valid email'),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      // pass values to our onSubmit prop
      if (onSubmit) {
        onSubmit(values)
      }
    },
  })
)
