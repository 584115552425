import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { getGroupsList } from 'modules/groups/selectors'

export default compose(
  connect(
    (state, props) => {
      const { groupUuid } = props
      const nextProps = {
        getMessagesProgress: state.progress.getMessages,
        getGroupsProgress: state.progress.getGroupsProgress,
      }

      // if component is not just rendering for single group scope, we need the groups list for the filters
      if (!groupUuid) {
        const groups = getGroupsList(state)
        return {
          ...nextProps,
          groups,
        }
      }
      return nextProps
    },
    null
  ),
  lifecycle({
    componentDidMount() {
      const { actions, orgUuid } = this.props

      actions.getGroups(orgUuid)
    },
  })
)
