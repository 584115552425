import initialState from 'reducers/initialState'
import { SAVE_AUTH_INFO, API_LOGOUT_SUCCESS, API_RESET_PASSWORD_SUCCESS } from './actions'
import {
  saveSessionToken,
  saveRefreshToken,
  setSessionHeaders,
  saveUserRole,
  clearSession,
} from 'utils/authentication'

export default function authenticationReducer(
  state = initialState.authentication,
  action
) {
  switch (action.type) {
    case API_LOGOUT_SUCCESS:
      clearSession()

      return {
        ...state,
        role: '',
        token: '',
        refreshToken: '',
      }
    case SAVE_AUTH_INFO: {
      const { role, token, refreshToken } = action.data

      // Save tokens and role to local storage
      saveSessionToken(token)
      saveRefreshToken(refreshToken)
      setSessionHeaders(token)

      if (role) {
        saveUserRole(role)
      }

      return {
        ...state,
        role: role || state.role,
        token,
        refreshToken,
      }
    }
    case API_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        API_RESET_PASSWORD_SUCCESS: true
      }
    }
    default:
      return state
  }
}
