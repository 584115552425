import { compose, mapProps } from 'recompose'
import { withRouter } from 'react-router-dom'

export default compose(
  withRouter,
  mapProps((props) => {
    return {
      ...props,
      orgUuid: props.match.params.orgUuid,
    }
  })
)
