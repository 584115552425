import { compose, withHandlers } from 'recompose'
import { withFormik } from 'formik'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { required, isValidMobileNumberForRegion } from 'utils/validation'
import { getFormattedMobileNumber } from 'utils/formatting.ts'

export const splitValidNumbers = (numbers, values) => {
  const uniqueNumbers = Array.from(new Set(numbers)).filter(
    (number) => number !== ''
  )

  return [
    uniqueNumbers.filter((number) => {
      if (isValidMobileNumberForRegion(number, values.region)) {
        return true
      } else {
        return false
      }
    }), // Valid Numbers
    uniqueNumbers.filter(
      (number) => !isValidMobileNumberForRegion(number, values.region)
    ), // Invalid Numbers
  ]
}

export default compose(
  withRouter,
  connect((state, { match }) => {
    let organisationCountries = []
    if (
      state.organisations.entities &&
      state.organisations.entities[match.params.orgUuid] &&
      state.organisations.entities[match.params.orgUuid].orgCountries
    ) {
      organisationCountries = state.organisations.entities[
        match.params.orgUuid
      ].orgCountries
        .map((country) => ({
          ...country.country,
          order: country.preferenceOrder,
        }))
        .sort((a, b) => a.order - b.order)
    }
    return {
      orgUuid: match.params.orgUuid,
      region: organisationCountries[0] && organisationCountries[0].isoCode,
      organisationCountries,
      getInvitationProgress: state.progress.inviteGroupMembers,
    }
  }),
  withFormik({
    mapPropsToValues: ({ region }) => ({
      group: '',
      numbers: [],
      phone: '',
      region: region || '',
      wrongNumbers: [],
    }),
    validateOnChange: false,
    validateOnBlur: false,
    validate: ({ group, numbers, wrongNumbers, phone }, { seatsRemaining }) => {
      const errors = {}
      if (numbers.length === 0 && phone.length === 0) {
        errors.numbers = 'Please enter at least one phone number and hit enter'
      } else if (numbers.length > seatsRemaining) {
        errors.numbers = `You have exceeded the organisation's seat limit.`
      }
      if (required(group)) {
        errors.group = 'Please select a group'
      }
      // Validate multiple numbers
      if (wrongNumbers.length > 0) {
        errors.numbers = `The phone numbers you have entered is invalid: \n${wrongNumbers.join(
          ', '
        )}`
      }
      return errors
    },
    handleSubmit: (
      values,
      { props: { onSubmit }, setSubmitting, validateForm, setFieldValue }
    ) => {
      setSubmitting(true)

      let numbers = values.numbers.map((number) =>
        getFormattedMobileNumber(number, values.region)
      )

      if (values.phone) {
        numbers.push(getFormattedMobileNumber(values.phone, values.region))
      }

      const [correctNumbers, wrongNumbers] = splitValidNumbers(numbers, values)

      setFieldValue('numbers', correctNumbers)
      setFieldValue('phone', wrongNumbers.join(' '))
      setFieldValue('wrongNumbers', wrongNumbers)

      if (wrongNumbers.length > 0 || correctNumbers.length === 0) {
        return validateForm()
      }

      if (onSubmit) {
        onSubmit({
          ...values,
          numbers: correctNumbers,
          phone: wrongNumbers,
          wrongNumbers,
        })
      }
    },
  }),
  withHandlers({
    onFieldFocus: ({ setFieldTouched }) => (name) => {
      setFieldTouched(name)
    },
    onAddNumber: ({ values, setFieldValue, validateForm, setFieldTouched }) => (
      chip
    ) => {
      // split the numbers by newline or space character (for copy pasting large lists of emails)
      const number = getFormattedMobileNumber(
        chip.replace(' ', ''),
        values.region
      )
      // If field is empty just return
      if (!number) {
        return
      }
      const [correctNumbers, wrongNumbers] = splitValidNumbers([number], values)
      setFieldValue('wrongNumbers')
      setFieldValue('phone', wrongNumbers.join(' '))
      setFieldValue('numbers', [...values.numbers, ...correctNumbers])
      setFieldTouched('phone')
      return validateForm()
    },
    onPasteNumbers: ({ values, setFieldValue }) => (event) => {
      event.preventDefault()
      const numbers = event.clipboardData
        .getData('text')
        .split(/[\r\n]+/)
        .map((number) => getFormattedMobileNumber(number, values.region))
      const [correctNumbers, wrongNumbers] = splitValidNumbers(numbers, values)
      setFieldValue('numbers', [...values.numbers, ...correctNumbers])
      setFieldValue('phone', wrongNumbers.join(' '))
      setFieldValue('wrongNumbers', wrongNumbers)
    },
    onDeleteNumber: ({ values, setFieldValue }) => (chip) => {
      setFieldValue(
        'numbers',
        values.numbers.filter((numbers) => chip !== numbers)
      )
    },
    onClickClearAll: ({ setFieldValue, setErrors }) => (event) => {
      event.preventDefault()
      setErrors({})
      setFieldValue('wrongNumbers', [])
      setFieldValue('numbers', [])
      setFieldValue('phone', '')
    },
  })
)
