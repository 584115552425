import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { PAGINATION_LIMIT } from 'constants/pagination'

export const StyledPagination = styled.div`
  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  li {
    &.disabled {
      display: none;
    }

    &.previous,
    &.next {
      font-weight: ${({ theme }) => theme.weights.bold};
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;

    &.selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`

export function Pagination({ onPageChange, pagination, className }) {
  const handleOnChange = useCallback(
    ({ selected }) => {
      if (onPageChange) {
        onPageChange(selected, pagination)
      }
    },
    [pagination.start, pagination.count]
  )

  const totalPages = Math.ceil(pagination.count / PAGINATION_LIMIT)
  const pageNumber = Math.floor(pagination.start / PAGINATION_LIMIT)

  return totalPages > 0 ? (
    <StyledPagination className={className}>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        onPageChange={handleOnChange}
        pageCount={totalPages}
        forcePage={pageNumber}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        activeLinkClassName="selected"
        disableInitialCallback
      />
    </StyledPagination>
  ) : null
}

Pagination.defaultProps = {
  pagination: {},
}

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  pagination: PropTypes.shape({
    start: PropTypes.number,
    count: PropTypes.number,
  }),
  className: PropTypes.string,
}

export default Pagination
