import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile'
import * as Sentry from '@sentry/react'

export function required(value) {
  return !value
}

export function isEmpty(value) {
  return !Array.isArray(value) || !value.length
}

export function validName(value) {
  return value && /^[A-Za-z ]+$/i.test(value)
}

export function validUUID(value) {
  return (
    value &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      value
    )
  ) // eslint-disable-line max-len
}

export function validEmail(value) {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}

export function validUrl(value) {
  return (
    value &&
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/i.test(
      value
    )
  )
}

export function validMoney(value) {
  return value && !/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/i.test(value)
}

export function validNumber(value) {
  return value && !/^([0-9]+)$/i.test(value)
}

export function numberRange(min, max, value) {
  const v = parseInt(value, 10)
  return v >= min && v <= max
}

export function ipAddress(value) {
  return (
    value &&
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value
    )
  ) // eslint-disable-line max-len
}

export function samePassword(value1, value2) {
  return value1 === value2
}

export function withinMinLength(length, value) {
  return !(value.length < length)
}

export function withinMaxLength(length, value) {
  return !(value.length > length)
}

export function exactLength(length, value) {
  return value.length !== length
}

export function lengthInRange(min, max, value) {
  return value.length >= min && value.length <= max
}

export function minLength(length, value) {
  return value.length > length
}

export const getTrueObject = (obj) => {
  if (!obj) return {}
  const oKeys = Object.keys(obj)

  if (oKeys && oKeys.length > 0) {
    const newObj = Object.assign(obj)
    oKeys.forEach((key) => {
      const value = obj[key]
      if (!value) {
        newObj[key] = null
      }
    })

    return newObj
  }

  return {}
}

export const isValidMobileNumberForRegion = (phoneNumber, region) => {
  try {
    const number = parsePhoneNumberFromString(phoneNumber, region)
    const numberType = number.getType()
    if (number) {
      return (
        number.isValid() &&
        (numberType === 'MOBILE' || numberType === 'FIXED_LINE_OR_MOBILE')
      )
    }
  } catch (error) {
    Sentry.captureException(error)
    return false
  }
}
