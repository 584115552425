import { createSelector } from 'reselect'

export const getCountriesList = createSelector(
  (state) => state.countries.entities,
  (countries) => Object.values(countries)
)

export const getCountryById = createSelector(
  [(state, countryUuid) => state.countries.entities[countryUuid]],
  (country) => {
    return country || {}
  }
)
