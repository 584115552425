import * as authActions from 'modules/authentication/actions'
import * as exportActions from 'modules/export/actions'
import * as groupActions from 'modules/groups/actions'
import { getGroupsList } from 'modules/groups/selectors'
import * as journalActions from 'modules/journals/actions'
import { getJournalsList } from 'modules/journals/selectors'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        groups: getGroupsList(state),
        journals: getJournalsList(state),
        getJournalsProgress: state.progress.getJournals,
        getGroupsProgress: state.progress.getGroups,
        organisation: match.params.orgUuid,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...groupActions,
        ...exportActions,
        ...journalActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withHandlers({
    onExport: ({ actions, organisation: orgUuid }) => () => {
      actions.exportJournalData({ orgUuid })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getGroups(match.params.orgUuid)
    },
  })
)
