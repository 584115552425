import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from 'components/molecules/Tooltip'
import TooltipItem from 'components/molecules/TooltipItem'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import theme from 'styles/theme'
import FaceHappy from 'components/atoms/Icons/FaceHappy'
import FaceUpset from 'components/atoms/Icons/FaceUpset'
import FaceNeutral from 'components/atoms/Icons/FaceNeutral'
import idx from 'idx'
import styled from 'styled-components'

export const StyledContainer = styled(ResponsiveContainer)`
  .recharts-yAxis {
    .recharts-label {
      text-anchor: end;
    }
  }

  svg {
    overflow: visible;
  }
`

export function WellbeingLineChart({
  xAxisLabel,
  yAxisLabel,
  data,
  className,
}) {
  return (
    <StyledContainer width="100%" height={300} className={className}>
      <LineChart data={data} margin={{ left: 0 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal />

        <XAxis
          stroke={theme.palette.tertiary.main}
          dataKey="name"
          tickSize={0}
          tickMargin={10}
          height={50}
        >
          <Label
            fill={theme.palette.tertiary.main}
            value={xAxisLabel}
            offset={0}
            position="insideBottomLeft"
          />
        </XAxis>

        <YAxis
          domain={[0, 5]}
          stroke={theme.palette.tertiary.main}
          tickSize={0}
          ticks={[0, 1, 2, 3, 4, 5]}
          tickFormatter={(val) =>
            Math.floor(val) === 0 ? '' : Math.floor(val)
          }
          tickMargin={10}
          width={110}
        >
          <Label
            value={yAxisLabel}
            offset={20}
            position="insideTop"
            angle={-90}
            fill={theme.palette.tertiary.main}
          />
        </YAxis>

        <Tooltip
          content={(values) => {
            const wellbeingAverage = idx(values, (_) =>
              Number(_.payload[0].value)
            )
            const members = idx(values, (_) => _.payload[0].payload.members)

            return (
              <CustomTooltip>
                {wellbeingAverage >= 3 ? (
                  <FaceHappy />
                ) : wellbeingAverage >= 2 ? (
                  <FaceNeutral />
                ) : (
                  <FaceUpset />
                )}
                <TooltipItem value={wellbeingAverage} suffix="average" />
                <TooltipItem value={members} suffix="members" />
              </CustomTooltip>
            )
          }}
        />

        <Line
          type="monotone"
          dataKey="value"
          stroke={theme.palette.quaternary.main}
          strokeWidth={3}
          dot={false}
          activeDot={{
            stroke: theme.palette.quaternary.main,
            fill: theme.palette.secondary.main,
            strokeWidth: 5,
            r: 5,
          }}
        />
      </LineChart>
    </StyledContainer>
  )
}

WellbeingLineChart.propTypes = {
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}

WellbeingLineChart.defaultProps = {
  data: [],
}

export default WellbeingLineChart
