import React from 'react'

export function Wellbeing(props) {
  return (
    <svg width="26px" height="24px" viewBox="0 0 26 24" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="molecule-/-chnnl-admin-side-nav-/-journal"
          transform="translate(-20.000000, -43.000000)"
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
        >
          <g id="noun_Heart_619638" transform="translate(21.000000, 44.000000)">
            <path
              d="M12.408766,21.8646353 L11.9239149,21.3972 C11.6119149,21.0964471 4.2467234,13.9832 1.20970213,9.99628235 C-0.247148936,8.08409412 -0.239744681,5.18009412 1.22731915,2.93428235 C2.71251064,0.660776471 5.16868085,-0.279011765 7.79489362,0.419552941 C11.0479149,1.28531765 12.0615319,3.85025882 12.9556596,6.11315294 C13.6682553,7.91611765 14.3415319,9.61969412 16.0314894,10.4688941 L16.3764255,10.6213412 C16.8809362,10.8051059 17.411234,10.8982824 17.9530213,10.8982824 C20.5197447,10.8982824 22.6077447,8.78136471 22.6077447,6.17915294 C22.6077447,3.57694118 20.5194894,1.46002353 17.9530213,1.46002353 C16.2293617,1.46002353 14.6542979,2.41844706 13.8421277,3.96103529 C13.6761702,4.27628235 13.2893617,4.39534118 12.978383,4.22736471 C12.6671489,4.05912941 12.5497021,3.66701176 12.7156596,3.35202353 C13.7502128,1.38677647 15.7570213,0.165905882 17.9530213,0.165905882 C21.2236596,0.165905882 23.8843404,2.86336471 23.8843404,6.17915294 C23.8843404,7.62157647 23.3805957,8.94727059 22.5421277,9.98463529 C22.5285957,10.0045647 22.5142979,10.0237176 22.4982128,10.0423529 L12.408766,21.8646353 Z M6.14017021,1.49211765 C4.58885106,1.49211765 3.20578723,2.24917647 2.29148936,3.64889412 C1.1213617,5.43969412 1.09225532,7.72484706 2.22,9.20531765 C4.75761702,12.5361176 10.6026383,18.3112471 12.3206809,19.9897176 L19.0659574,12.0862824 C18.7054468,12.1561647 18.3331915,12.1924 17.9530213,12.1924 C17.2554894,12.1924 16.572,12.0707529 15.9217021,11.8310824 L15.8849362,11.8160706 L15.480766,11.6367059 C13.3419574,10.5706118 12.5433191,8.5492 11.7707234,6.59456471 C10.9123404,4.42277647 10.1017021,2.37185882 7.47089362,1.67148235 C7.01846809,1.55112941 6.57293617,1.49211765 6.14017021,1.49211765 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Wellbeing
