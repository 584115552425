import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import * as wellbeingActions from 'modules/wellbeing/actions'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        organisations: getOrganisationsList(state),
        lineChart: state.wellbeing.moodMonthly,
        barChart: state.wellbeing.moodOverall,
        reflectionReport: state.wellbeing.reflectionReport,
        attributeReport: state.wellbeing.attributeReport,
        error: state.wellbeing.error,
        getJournalsProgress: state.progress.getOrganisations,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...organisationsActions,
        ...wellbeingActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      this.props.actions.getOrganisations()
    },
  })
)
