import initialState from 'reducers/initialState'
import {
  API_GET_KEY_CONTACTS_SUCCESS,
  API_UPDATE_KEY_CONTACTS_SUCCESS,
} from './actions'

export default function keyContactsReducer(
  state = initialState.keyContacts,
  action
) {
  switch (action.type) {
    case API_UPDATE_KEY_CONTACTS_SUCCESS:
    case API_GET_KEY_CONTACTS_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.keyContacts,
        },
        orderedIds: action.data.result,
      }

    default:
      return state
  }
}
