import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Button from '@weareroam/cake-ui-v1/Button'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { format } from 'date-fns'
import { JOURNAL_ENTRY_DATE_TIME, JOURNAL_ENTRY_TIMEZONE } from 'constants/date'

export const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  justify-content: space-between;
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const Date = styled(Typography)`
  && {
    display: flex;
    align-items: center;
  }
`

export const Timezone = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledTextArea = styled(TextField)`
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }

  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const ActionsLeft = styled.div``
export const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export function MessageDetail({ message, onCancel }) {
  return (
    <div>
      <StyledHeader>
        <Typography variant="body2">
          <strong>{message.groupName}</strong>
        </Typography>
        <Date variant="body2" component="div">
          {format(message.createdAt, JOURNAL_ENTRY_DATE_TIME)}
          <Timezone variant="body2">
            {format(message.createdAt, JOURNAL_ENTRY_TIMEZONE)}
          </Timezone>
        </Date>
      </StyledHeader>
      <StyledTextArea
        id="entry"
        name="entry"
        type="text"
        value={message.message}
        InputProps={{
          disableUnderline: true,
        }}
        disabled
        fullWidth
        multiline
        rows={5}
      />
      <Actions>
        <ActionsLeft />
        <ActionsRight>
          <Button
            variant="outlined"
            type="button"
            color="primary"
            fullWidth
            size="small"
            onClick={() => setTimeout(onCancel, 300)}
          >
            Cancel
          </Button>
        </ActionsRight>
      </Actions>
    </div>
  )
}

MessageDetail.propTypes = {
  onCancel: PropTypes.func,
  message: PropTypes.object,
}

MessageDetail.defaultProps = {
  message: {},
}

export default MessageDetail
