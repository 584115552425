import * as React from 'react'
import { connect } from 'react-redux'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import { FEATURE_KEY } from '../config'
import { getUser } from '../modules/user/selectors'

const optimizely = createInstance({ sdkKey: FEATURE_KEY })

interface FeatureProviderPropTypes {
  user: any
}

const FeatureProvider: React.FC<FeatureProviderPropTypes> = ({ children, user }) => {
  const id = React.useMemo(() => {
    return user.uuid || 'UNDEFINED'
  }, [user])

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{ id }}
      timeout={200}
    >
      {children}
    </OptimizelyProvider>
  )
}

export default connect(
  (state: any) => {
    return { user: getUser(state)}
  },
  null
)(FeatureProvider)
