import { compose } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialEntry }) => ({
      entry: initialEntry || '',
    }),
    validationSchema: Yup.object().shape({
      entry: Yup.string().required('Please enter your incident entry'),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      // pass values to our onSubmit prop
      if (onSubmit) {
        onSubmit(values)
      }
    },
  })
)
