import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR } from 'constants/ui'
import { redFlagChatsListSchema, chatHistorySchemaListSchema } from './schema'
import { getQueryParams, getArrayFilters, getPaginationResult } from 'utils/api'

// Actions
export const API_GET_REDFLAGCHAT_REQUEST = 'API_GET_REDFLAGCHAT_REQUEST'
export const API_GET_REDFLAGCHAT_SUCCESS = 'API_GET_REDFLAGCHAT_SUCCESS'
export const API_GET_REDFLAGCHAT_ERROR = 'API_GET_REDFLAGCHAT_ERROR'

export const API_GET_CHATHISTORY_REQUEST = 'API_GET_CHATHISTORY_REQUEST'
export const API_GET_CHATHISTORY_SUCCESS = 'API_GET_CHATHISTORY_SUCCESS'
export const API_GET_CHATHISTORY_ERROR = 'API_GET_CHATHISTORY_ERROR'

export function getRedFlagChats(params = {}, filters) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_REDFLAGCHAT_REQUEST })

    try {
      const options = []
      if (params.sortOptions) {
        // produces "sort:{createdAt:DESC}"
        options.push(
          JSON.stringify({ sort: params.sortOptions })
            .replaceAll('"', '')
            .replace('{', '')
            .replace(/}$/, '')
        )
      }
      const optionsString = `{${options.join(',')}}`

      const response = await axios.get(
        `${SAUCE_API_URL}/chatbot/redflagged?${getArrayFilters(
          filters
        )}&${getQueryParams(params)}&options=${encodeURIComponent(
          optionsString
        )}`
      )
      const data = normalize(response.data.data, redFlagChatsListSchema)
      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GET_REDFLAGCHAT_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_REDFLAGCHAT_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching red flagged chat history. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getSessionChatHistory(chatSessionUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_CHATHISTORY_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/chatbot/history/${chatSessionUuid}`
      )
      const data = normalize(response.data.data, chatHistorySchemaListSchema)

      const structuredData = {
        [chatSessionUuid]: {
          uuid: chatSessionUuid,
          data: Object.values(data.entities.chatHistory),
        },
      }

      dispatch({
        type: API_GET_CHATHISTORY_SUCCESS,
        data: structuredData,
      })

      return true
    } catch (error) {
      dispatch({ type: API_GET_CHATHISTORY_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching chat history. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
