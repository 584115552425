import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import * as journalActions from 'modules/journals/actions'
import { getJournalsList } from 'modules/journals/selectors'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        organisations: getOrganisationsList(state),
        journals: getJournalsList(state),
        getJournalsProgress: state.progress.getJournals,
        getOrgsProgress: state.progress.getOrganisations,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...organisationsActions,
        ...journalActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      this.props.actions.getOrganisations()
    },
  })
)
