import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MONTH_FORMAT } from 'constants/date'
import Typography from '@weareroam/cake-ui-v1/Typography'

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.md}px;
  padding-left: ${({ theme }) => theme.spacing.xl}px;
  padding-right: ${({ theme }) => theme.spacing.xl}px;
`
export const StyledLabel = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`
export const StyledBar = styled.span`
  width: 15px;
  height: 2px;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  background-color: ${({ theme }) => theme.palette.quaternary.main};
`

export const GraphFooter = ({ label, month }) => (
  <StyledFooter>
    <StyledLabel>
      <StyledBar />
      {label}
    </StyledLabel>
    <StyledLabel>{format(month, MONTH_FORMAT)}</StyledLabel>
  </StyledFooter>
)

GraphFooter.propTypes = {
  label: PropTypes.string,
  month: PropTypes.string,
}

export default GraphFooter
