import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { JOURNAL_TASK_DATE, JOURNAL_TASK_TIME } from 'constants/date'
import { format } from 'date-fns'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'

export const StyledChatSessionTask = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

export const JournalState = styled.div`
  width: 5%;
  z-index: 2;
`

export const JournalEntry = styled.div`
  width: ${({ readOnly }) => (readOnly ? '75%' : '60%')};
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }

  svg {
    width: 25px;
    height: 25px;
  }
`

export const JournalFrom = styled.div`
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const JournalDate = styled.div`
  text-align: right;
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const JournalActions = styled.div`
  width: 5%;
  text-align: right;
  z-index: 2;
`

export const EntryText = styled(Typography)`
  && {
    display: inline;
  }
`

export const EditText = styled(Typography)`
  && {
    display: inline;
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`

export function ChatSessionTask({
  dataQa,
  className,
  entry,
  organisationName,
  groupName,
  createdAt,
  updatedAt,
  onClick,
  value,
  readOnly,
}) {
  const handleOnClick = useCallback(
    () => {
      if (onClick) {
        onClick(value)
      }
    },
    [value]
  )

  return (
    <StyledChatSessionTask className={className} data-qa={dataQa}>
      {!readOnly && <ClickArea onClick={handleOnClick} />}
      <JournalEntry readOnly={readOnly}>
        <EntryText variant="body1" component="span">
          {entry}
        </EntryText>
      </JournalEntry>
      <JournalFrom>
        <Typography variant="body2">
          <strong>{organisationName}</strong>
        </Typography>
        <Typography variant="body2">{groupName}</Typography>
      </JournalFrom>
      <JournalDate>
        <Typography variant="body2">
          <strong>
            {format(createdAt, JOURNAL_TASK_DATE)}{' '}
            {format(createdAt, JOURNAL_TASK_TIME)}
          </strong>
        </Typography>
        <Typography variant="body2">
          {format(updatedAt, JOURNAL_TASK_DATE)}{' '}
          {format(updatedAt, JOURNAL_TASK_TIME)}
        </Typography>
      </JournalDate>
    </StyledChatSessionTask>
  )
}

ChatSessionTask.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  readOnly: PropTypes.bool,
  entry: PropTypes.string,
  organisationName: PropTypes.string,
  groupName: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ChatSessionTask
