/**
 * Replaces all dynamic route parameters with the specified string
 * @param {string} route        The route with a dynamic parameter. E.g. /test/:uuid
 * @param {object} params       Object with the parameter as the key and replacement string as the value. E.g. {uuid: 123-321}
 * @returns {string}            The route with the replaced parameter keys
 */
export function replaceRouteParams(route, params) {
  return Object.keys(params).reduce((acc, key) => {
    const value = params[key]
    return acc.split(`:${key}`).join(value)
  }, route)
}

/**
 * Parses the search string from react router
 * Comes in the format '?example1=test&example2=test'
 * @param  {string} search  The search param string
 * @return {Object}         The object with params and values
 */
export function parseSearchString(search) {
  if (!search.length) {
    return {}
  }

  // Remove the ? from the start of the string
  const cleanString = search[0] === '?' ? search.slice(1) : search
  // Split up the query params
  const params = cleanString.split('&')

  // Build the return object
  return params.reduce((acc, param) => {
    const keyValue = param.split('=')

    if (keyValue.length < 2) {
      return acc
    }

    const key = keyValue[0]
    const value = keyValue[1]

    return {
      ...acc,
      [decodeURIComponent(key)]: decodeURIComponent(value),
    }
  }, {})
}
