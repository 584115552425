import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import styled from 'styled-components'
import Box from 'components/molecules/Box'
import RowButton from 'components/atoms/RowButton'
import Modal from 'components/molecules/Modal'
import BoosterItemForm from 'components/organisms/BoosterItemForm'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import DragDropList from 'components/molecules/DragDropList'
import Booster from 'components/molecules/Booster'
import ProgressLoader from 'components/atoms/ProgressLoader'
import EditableText from 'components/molecules/EditableText'
import NavigationPrompt from 'react-router-navigation-prompt'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'
import idx from 'idx'
import { deepEqual } from 'fast-equals'
import { CHATBOT_CATEGORY_TYPE } from 'constants/form'

export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const AddBoosterModal = styled(Modal)`
  width: 500px;
`

export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

export const UpdateButton = styled(Button)`
  width: 140px;
`

export const BoosterCategoriesSection = styled.section``

export const BoosterCategories = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const AddBoosterButton = styled(RowButton)`
  border: none;
`

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export function BoostersForm({
  className,
  handleChange,
  values,
  errors,
  touched,
  title,
  description,
  progress,
  updateProgress,
  isAddBoosterModalOpen,
  setIsAddBoosterModalOpen,
  isConfirmUpdateModalOpen,
  setIsConfirmUpdateModalOpen,
  handleSubmit,
  setEditBoosterIndex,
  boosterCategoryIndex,
  setBoosterCategoryIndex,
  editBoosterIndex,
  onAddBoosterSubmit,
  onDeleteBoosterItem,
  onDragEnd,
  onAddBoosterCategory,
  onCloseAddBoosterModal,
  onDeleteBoosterCategory,
  boosterCategories,
  disableEditting,
}) {
  const handleCloseConfirmationModal = useCallback(
    () => setIsConfirmUpdateModalOpen(false),
    []
  )

  const handleAddBoosterItem = useCallback((value) => {
    setBoosterCategoryIndex(value)
    setIsAddBoosterModalOpen(true)
  }, [])

  const handleEditBoosterItem = useCallback(
    ({ categoryIndex, boosterIndex }) => {
      setBoosterCategoryIndex(categoryIndex)
      setEditBoosterIndex(boosterIndex)
      setIsAddBoosterModalOpen(true)
    },
    []
  )

  const handleDeleteBoosterItem = useCallback(
    ({ categoryIndex, boosterIndex }) => {
      onDeleteBoosterItem({
        boosterCategoryIndex: categoryIndex,
        boosterItemIndex: boosterIndex,
      })
    },
    []
  )

  const hasUnsavedChanges = useMemo(
    () => {
      return !deepEqual(values.boosterCategories, boosterCategories)
    },
    [values.boosterCategories, boosterCategories, progress, updateProgress]
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <NavigationPrompt when={hasUnsavedChanges}>
        {({ onConfirm, onCancel }) => (
          <Modal open>
            <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
          </Modal>
        )}
      </NavigationPrompt>
      <AddBoosterModal
        hasCloseButton
        open={isAddBoosterModalOpen}
        onClose={onCloseAddBoosterModal}
      >
        <BoosterItemForm
          onSubmit={onAddBoosterSubmit}
          inEditMode={editBoosterIndex !== null}
          initialValues={
            boosterCategoryIndex !== null && values.boosterCategories
              ? values.boosterCategories[boosterCategoryIndex].boosters[
                  editBoosterIndex
                ]
              : undefined
          }
          showEscalationOption={
            boosterCategoryIndex !== null && values.boosterCategories
              ? values.boosterCategories[boosterCategoryIndex].categoryType ===
                CHATBOT_CATEGORY_TYPE
              : false
          }
          onCancel={onCloseAddBoosterModal}
        />
      </AddBoosterModal>

      <ConfirmUpdatesModal
        open={isConfirmUpdateModalOpen}
        onClose={handleCloseConfirmationModal}
      >
        <ConfirmUpdatesForm
          onCancel={handleCloseConfirmationModal}
          onSubmit={handleSubmit}
          title="Confirm booster updates"
          content={
            <React.Fragment>
              All booster changes will be <br /> updated and visible in the app.
            </React.Fragment>
          }
        />
      </ConfirmUpdatesModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h2">{title}</Typography>
          {description && (
            <DescriptionText variant="body1">{description}</DescriptionText>
          )}
        </HeaderLeft>
        {!disableEditting && (
          <UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!hasUnsavedChanges}
          >
            Update
          </UpdateButton>
        )}
      </StyledHeader>

      {progress.inProgress &&
      (!values.boosterCategories || values.boosterCategories.length === 0) ? (
        <ProgressLoader fullWidth />
      ) : (
        <React.Fragment>
          {!disableEditting && (
            <RowButton onClick={onAddBoosterCategory}>
              + Add booster category
            </RowButton>
          )}

          <BoosterCategoriesSection>
            <DragDropList onDragEnd={onDragEnd}>
              {(provided) => (
                <BoosterCategories>
                  {values.boosterCategories
                    ? values.boosterCategories.map(
                        (category, categoryIndex) => {
                          const isTouched = idx(
                            touched,
                            (_) => _.boosterCategories[categoryIndex]
                          )
                          const boosterErrors = idx(
                            errors,
                            (_) => _.boosterCategories[categoryIndex]
                          )
                          return (
                            <Box
                              key={category.uuid || categoryIndex}
                              draggableId={category.uuid}
                              draggableIndex={categoryIndex}
                              isDraggable={!disableEditting}
                              errors={
                                isTouched && boosterErrors
                                  ? Object.values(boosterErrors)
                                  : []
                              }
                              headerContent={
                                <CategoryHeader>
                                  {category.categoryType ===
                                    CHATBOT_CATEGORY_TYPE || disableEditting ? (
                                    <Typography variant="h4">
                                      {category.categoryName}
                                    </Typography>
                                  ) : (
                                    <>
                                      <EditableText
                                        value={category.categoryName}
                                        name={`boosterCategories[${categoryIndex}].categoryName`}
                                        onChange={handleChange}
                                        placeholder="New booster category"
                                      />
                                      <Button
                                        type="button"
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() =>
                                          onDeleteBoosterCategory(categoryIndex)
                                        }
                                      >
                                        Delete category
                                      </Button>
                                    </>
                                  )}
                                </CategoryHeader>
                              }
                              footerContent={
                                disableEditting ? null : (
                                  <AddBoosterButton
                                    value={categoryIndex}
                                    onClick={handleAddBoosterItem}
                                  >
                                    + Add item
                                  </AddBoosterButton>
                                )
                              }
                            >
                              {category.boosters.map(
                                (booster, boosterIndex) => (
                                  <Booster
                                    key={booster.uuid}
                                    heading={booster.heading}
                                    subheading={booster.subHeading}
                                    contactNumber={booster.contact}
                                    extension={booster.extension}
                                    email={booster.email}
                                    website={booster.website}
                                    description={booster.description}
                                    value={{
                                      categoryIndex,
                                      boosterIndex,
                                    }}
                                    showEscalationOption={
                                      category.categoryType ===
                                      CHATBOT_CATEGORY_TYPE
                                    }
                                    escalationOption={booster.escalationOption}
                                    disabled={disableEditting}
                                    onEdit={handleEditBoosterItem}
                                    onDelete={handleDeleteBoosterItem}
                                  />
                                )
                              )}
                            </Box>
                          )
                        }
                      )
                    : null}
                  {provided.placeholder}
                </BoosterCategories>
              )}
            </DragDropList>
          </BoosterCategoriesSection>
        </React.Fragment>
      )}
    </StyledForm>
  )
}

BoostersForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  disableEditting: PropTypes.bool,
  description: PropTypes.node,
  isAddBoosterModalOpen: PropTypes.bool,
  setIsAddBoosterModalOpen: PropTypes.func,
  isConfirmUpdateModalOpen: PropTypes.bool,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  onDeleteBoosterItem: PropTypes.func,
  editBoosterIndex: PropTypes.number,
  setEditBoosterIndex: PropTypes.func,
  boosterCategoryIndex: PropTypes.number,
  setBoosterCategoryIndex: PropTypes.func,
  onAddBoosterSubmit: PropTypes.func,
  onDragEnd: PropTypes.func,
  onAddBoosterCategory: PropTypes.func,
  progress: PropTypes.object,
  updateProgress: PropTypes.object,
  onCloseAddBoosterModal: PropTypes.func,
  onDeleteBoosterCategory: PropTypes.func,
  boosterCategories: PropTypes.arrayOf(PropTypes.object),
}

BoostersForm.defaultProps = {
  values: {},
  progress: {},
  errors: {},
  touched: {},
  title: 'Boosters',
}

export default BoostersForm
