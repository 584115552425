import { createSelector } from 'reselect'

export const getBoostersList = createSelector(
  (state) => state.boosters.entities,
  (boosters) => Object.values(boosters)
)

export const getGroupBoostersList = createSelector(
  [getBoostersList, (state, groupUuid) => groupUuid],
  (boosters, groupUuid) => {
    return boosters.filter((booster) => {
      return booster.groupUuid === groupUuid
    })
  }
)

export const getOrganisationBoostersList = createSelector(
  [getBoostersList, (state, orgUuid) => orgUuid],
  (boosters, orgUuid) => {
    return boosters.filter((booster) => {
      // Only get boosters part of the organisation but not in a group
      return booster.orgUuid === orgUuid && !booster.groupUuid
    })
  }
)
