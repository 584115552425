import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as groupsActions from 'modules/groups/actions'
import { isChnnlAdmin, hasEditRights } from 'modules/authentication/selectors'
import { ROLE_ORG_OWNER } from 'constants/form'

export default compose(
  withRouter,
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        orgUuid: match.params.orgUuid,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
        members: state.groups.selected && state.groups.selected.members,
        getGroupMembersProgress: state.progress.getGroupMembers,
        singleResendProgress: state.progress.reinviteGroupMember,
        multipleResendProgress: state.progress.resendMultipleMemberInvites,
        canTransferOrgOwnership:
          isChnnlAdmin(state) || state.authentication.role === ROLE_ORG_OWNER,
      }
    },
    (dispatch) => {
      const actions = {
        ...groupsActions,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  )
)
