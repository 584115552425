import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { typesOfDayListSchema, departmentListSchema } from './schema'

// Actions
export const API_GET_SURVEY_QUESTIONS_REQUEST =
  'API_GET_SURVEY_QUESTIONS_REQUEST'
export const API_GET_SURVEY_QUESTIONS_SUCCESS =
  'API_GET_SURVEY_QUESTIONS_SUCCESS'
export const API_GET_SURVEY_QUESTIONS_ERROR = 'API_GET_SURVEY_QUESTIONS_ERROR'

export const API_UPDATE_SURVEY_QUESTIONS_REQUEST =
  'API_UPDATE_SURVEY_QUESTIONS_REQUEST'
export const API_UPDATE_SURVEY_QUESTIONS_SUCCESS =
  'API_UPDATE_SURVEY_QUESTIONS_SUCCESS'
export const API_UPDATE_SURVEY_QUESTIONS_ERROR =
  'API_UPDATE_SURVEY_QUESTIONS_ERROR'

export const API_CREATE_SURVEY_QUESTIONS_REQUEST =
  'API_CREATE_SURVEY_QUESTIONS_REQUEST'
export const API_CREATE_SURVEY_QUESTIONS_SUCCESS =
  'API_CREATE_SURVEY_QUESTIONS_SUCCESS'
export const API_CREATE_SURVEY_QUESTIONS_ERROR =
  'API_CREATE_SURVEY_QUESTIONS_ERROR'

// Action creators
export function getSurveyQuestions(orgUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_SURVEY_QUESTIONS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/day`
      )

      const dayTypeData = normalize(
        response.data.data[0].dayTypes,
        typesOfDayListSchema
      )
      const departmentData = normalize(
        response.data.data[0].departments,
        departmentListSchema
      )

      dispatch({
        type: API_GET_SURVEY_QUESTIONS_SUCCESS,
        data: {
          typesOfDays: dayTypeData,
          departments: departmentData,
        },
      })
    } catch (error) {
      dispatch({ type: API_GET_SURVEY_QUESTIONS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the survey questions. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function updateSurveyQuestions({ orgUuid, dayTypes, departments }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_SURVEY_QUESTIONS_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/day`,
        {
          dayTypes,
          departments,
        }
      )
      const dayTypeData = normalize(
        response.data.data[0].dayTypes,
        typesOfDayListSchema
      )
      const departmentData = normalize(
        response.data.data[0].departments,
        departmentListSchema
      )

      dispatch({
        type: API_UPDATE_SURVEY_QUESTIONS_SUCCESS,
        data: {
          typesOfDays: dayTypeData,
          departments: departmentData,
        },
      })

      dispatch(
        addToast({
          message: 'Survey questions have been updated.',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_SURVEY_QUESTIONS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error updating the types of days. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
