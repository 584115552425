import React from 'react'
import PropTypes from 'prop-types'
import RadioComponent from '@weareroam/cake-ui-v1/Radio'
import CheckedIcon from 'components/atoms/Icons/CheckedRadio'
import UncheckedIcon from 'components/atoms/Icons/UncheckedRadio'
import styled from 'styled-components'

export const StyledRadio = styled(RadioComponent)``

export function Radio({ className, ...rest }) {
  return (
    <StyledRadio
      className={className}
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      {...rest}
    />
  )
}

Radio.propTypes = {
  className: PropTypes.string,
}

export default Radio
