import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import {
  hasSessionHeaders,
  isValidToken,
  clearSession,
  setSessionHeaders,
} from 'utils/authentication'
import PageNotFound from 'views/PageNotFound'
import { LOGIN } from 'constants/routes'
import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_ADMIN,
} from 'constants/form'

export function PrivateRoute({
  component: Component,
  actions,
  refreshSessionProgress,
  role,
  sessionToken,
  authorisedRoles,
  ...rest
}) {
  if (sessionToken) {
    // Refresh the session and set the headers when the user hard reloads the page
    if (!hasSessionHeaders()) {
      setSessionHeaders(sessionToken)

      if (isValidToken(sessionToken)) {
        actions.refreshSession()
      }
    }

    // Clear session tokens once it is invalid
    if (!isValidToken(sessionToken)) {
      clearSession()
    }
  }

  const isAuthenticated =
    hasSessionHeaders() || refreshSessionProgress.inProgress

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          // If authenticated, check if their role can access this route
          if (!authorisedRoles.includes(role)) {
            return <PageNotFound />
          }

          return <Component role={role} {...props} />
        }

        return <Redirect to={LOGIN} />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  refreshSessionProgress: PropTypes.object,
  role: PropTypes.string,
  actions: PropTypes.object,
  sessionToken: PropTypes.string,
  authorisedRoles: PropTypes.arrayOf(PropTypes.string),
}

PrivateRoute.defaultProps = {
  actions: {},
  refreshSessionProgress: {},
  authorisedRoles: [
    ROLE_SUPER_ADMIN,
    ROLE_ORG_OWNER,
    ROLE_GROUP_ADMIN,
    ROLE_ORG_ADMIN,
  ],
}

export default PrivateRoute
