import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { journalListSchema } from './schema'
import { getQueryParams, getArrayFilters, getPaginationResult } from 'utils/api'
import idx from 'idx'

import {
  JOURNAL_TYPE_ALL,
  JOURNAL_RED_FLAGGED,
  JOURNAL_TYPE_TO_REVIEW,
  JOURNAL_TYPE_SHARED,
  JOURNAL_TYPE_HIDDEN,
} from 'constants/form'

// Actions
export const API_GET_JOURNALS_REQUEST = 'API_GET_JOURNALS_REQUEST'
export const API_GET_JOURNALS_SUCCESS = 'API_GET_JOURNALS_SUCCESS'
export const API_GET_JOURNALS_ERROR = 'API_GET_JOURNALS_ERROR'

export const API_DELETE_JOURNAL_REQUEST = 'API_DELETE_JOURNAL_REQUEST'
export const API_DELETE_JOURNAL_SUCCESS = 'API_DELETE_JOURNAL_SUCCESS'
export const API_DELETE_JOURNAL_ERROR = 'API_DELETE_JOURNAL_ERROR'

export const API_APPROVE_JOURNALS_REQUEST = 'API_APPROVE_JOURNALS_REQUEST'
export const API_APPROVE_JOURNALS_SUCCESS = 'API_APPROVE_JOURNALS_SUCCESS'
export const API_APPROVE_JOURNALS_ERROR = 'API_APPROVE_JOURNALS_ERROR'

export const API_HIDE_JOURNAL_REQUEST = 'API_HIDE_JOURNAL_REQUEST'
export const API_HIDE_JOURNAL_SUCCESS = 'API_HIDE_JOURNAL_SUCCESS'
export const API_HIDE_JOURNAL_ERROR = 'API_HIDE_JOURNAL_ERROR'

export const API_EDIT_JOURNAL_REQUEST = 'API_EDIT_JOURNAL_REQUEST'
export const API_EDIT_JOURNAL_SUCCESS = 'API_EDIT_JOURNAL_SUCCESS'
export const API_EDIT_JOURNAL_ERROR = 'API_EDIT_JOURNAL_ERROR'

export const API_REVERT_JOURNAL_REQUEST = 'API_REVERT_JOURNAL_REQUEST'
export const API_REVERT_JOURNAL_SUCCESS = 'API_REVERT_JOURNAL_SUCCESS'
export const API_REVERT_JOURNAL_ERROR = 'API_REVERT_JOURNAL_ERROR'

export const getJournalFilters = (type) => {
  switch (type) {
    case JOURNAL_TYPE_HIDDEN:
      return 'filters:{hidden:$eq_true}'
    case JOURNAL_RED_FLAGGED:
      return 'filters:{redFlagged:$eq_true}'
    case JOURNAL_TYPE_SHARED:
      return 'filters:{approved:$eq_true,hidden:$eq_false}'
    case JOURNAL_TYPE_TO_REVIEW:
      return 'filters:{approved:$eq_false,hidden:$eq_false}'
    case JOURNAL_TYPE_ALL:
    default:
      return ''
  }
}

// Action creators
export function getOrgJournals(params = {}, filters, organisation) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_JOURNALS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${organisation}/journal?${getArrayFilters(
          filters
        )}&${getQueryParams(params)}`
      )
      const data = normalize(response.data.data, journalListSchema)
      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GET_JOURNALS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_JOURNALS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the journals. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getJournals(params = {}, filters) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_JOURNALS_REQUEST })

    try {
      const options = []
      const filterOptions = getJournalFilters(params.journalType)
      if (filterOptions) {
        options.push(filterOptions)
      }
      if (params.sortOptions) {
        // produces "sort:{createdAt:DESC}"
        options.push(
          JSON.stringify({ sort: params.sortOptions })
            .replaceAll('"', '')
            .replace('{', '')
            .replace(/}$/, '')
        )
      }
      const optionsString = `{${options.join(',')}}`

      const response = await axios.get(
        `${SAUCE_API_URL}/journal?${getArrayFilters(filters)}&${getQueryParams(
          params
        )}&options=${encodeURIComponent(optionsString)}`
      )
      const data = normalize(response.data.data, journalListSchema)
      const pagination = getPaginationResult(response.data)

      dispatch({
        type: API_GET_JOURNALS_SUCCESS,
        data,
      })

      return pagination
    } catch (error) {
      dispatch({ type: API_GET_JOURNALS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the journals. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function deleteJournal(journalUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_JOURNAL_REQUEST })

    try {
      await axios.delete(`${SAUCE_API_URL}/journal/${journalUuid}`)

      dispatch({
        type: API_DELETE_JOURNAL_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Journal entry has been deleted',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_DELETE_JOURNAL_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function approveJournals(entries) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_APPROVE_JOURNALS_REQUEST })

    try {
      const response = await axios.post(`${SAUCE_API_URL}/journal/approve`, {
        entries,
      })
      const data = normalize(response.data.data, journalListSchema)

      dispatch({
        type: API_APPROVE_JOURNALS_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Journals have been approved',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_APPROVE_JOURNALS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function editJournal({ journalUuid, entry }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_EDIT_JOURNAL_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/journal/${journalUuid}/edit`,
        {
          editedEntry: entry,
        }
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_EDIT_JOURNAL_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: 'Journal entry has been edited',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_EDIT_JOURNAL_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function revertJournal(journalUuid) {
  return async function dispatcher(dispatch, getState) {
    dispatch({
      type: API_REVERT_JOURNAL_REQUEST,
      data: {
        journalUuid,
      },
    })

    const state = getState()
    const { editedEntry } = state.journals.entities[journalUuid]

    try {
      await axios.put(`${SAUCE_API_URL}/journal/${journalUuid}/edit`, {
        editedEntry: null,
      })

      dispatch({
        type: API_REVERT_JOURNAL_SUCCESS,
      })

      dispatch(
        addToast({
          message: 'Journal entry has been reverted',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({
        type: API_REVERT_JOURNAL_ERROR,
        error,
        data: {
          journalUuid,
          editedEntry,
        },
      })
      dispatch(
        addToast({
          message:
            'There was an error reverting the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function changeJournalHidden({ journalUuid, hidden }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_HIDE_JOURNAL_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/journal/${journalUuid}/hide`,
        {
          hidden,
        }
      )
      const data = idx(response, (_) => _.data.data[0])

      dispatch({
        type: API_HIDE_JOURNAL_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: `Journal entry has been ${hidden ? 'hidden' : 'un-hidden'}`,
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_HIDE_JOURNAL_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error hiding the journal entry. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
