import {
  getProfileNotifications,
  updateProfileNotifications,
} from 'modules/user/actions'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default compose(
  connect(
    // mapStateToProps
    () => {
      return {}
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        getProfileNotifications,
        updateProfileNotifications,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  )
)
