import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

export function DragDropList({ onDragEnd, children, disabled }) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={disabled}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>{children(provided, snapshot)}</div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DragDropList.propTypes = {
  children: PropTypes.func,
  onDragEnd: PropTypes.func,
  disabled: PropTypes.bool,
}

export default DragDropList
