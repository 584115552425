import * as Sentry from '@sentry/react'

import './polyfills'
import 'assets/styles/fonts.css'

import React from 'react'
import ReactDOM from 'react-dom'
import App from 'views/App'
import { MuiThemeProvider } from '@weareroam/cake-ui-v1/styles'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import FeatureProvider from 'providers/FeatureProvider'
import GlobalStyle from 'styles/GlobalStyles'
import configureStore from 'store/configureStore'
import theme from 'styles/theme'
import * as serviceWorker from './serviceWorker'
import { ENV, SENTRY_DSN } from './config'

if (ENV !== 'local') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    debug: false,
  })
}

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <FeatureProvider>
            <App />
            <GlobalStyle />
          </FeatureProvider>
        </React.Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
