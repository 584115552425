import React from 'react'

export function Journals(props) {
  return (
    <svg width="20px" height="22px" viewBox="0 0 20 22" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="molecule-/-chnnl-admin-side-nav-/-wellbeing"
          transform="translate(-21.000000, -113.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g
            id="noun_diary_1094877"
            transform="translate(21.000000, 113.000000)"
          >
            <path
              d="M17.7777778,0 L4.44444444,0 C3.22222222,0 2.22222222,0.99 2.22222222,2.2 L2.22222222,4.4 L1.11111111,4.4 C0.444444444,4.4 0,4.84 0,5.5 C0,6.16 0.444444444,6.6 1.11111111,6.6 L2.22222222,6.6 L2.22222222,9.9 L1.11111111,9.9 C0.444444444,9.9 0,10.34 0,11 C0,11.66 0.444444444,12.1 1.11111111,12.1 L2.22222222,12.1 L2.22222222,15.4 L1.11111111,15.4 C0.444444444,15.4 0,15.84 0,16.5 C0,17.16 0.444444444,17.6 1.11111111,17.6 L2.22222222,17.6 L2.22222222,19.8 C2.22222222,21.01 3.22222222,22 4.44444444,22 L17.7777778,22 C19,22 20,21.01 20,19.8 L20,2.2 C20,0.99 19,0 17.7777778,0 Z M4.44444444,19.8 L4.44444444,17.6 L5.55555556,17.6 C6.22222222,17.6 6.66666667,17.16 6.66666667,16.5 C6.66666667,15.84 6.22222222,15.4 5.55555556,15.4 L4.44444444,15.4 L4.44444444,12.1 L5.55555556,12.1 C6.22222222,12.1 6.66666667,11.66 6.66666667,11 C6.66666667,10.34 6.22222222,9.9 5.55555556,9.9 L4.44444444,9.9 L4.44444444,6.6 L5.55555556,6.6 C6.22222222,6.6 6.66666667,6.16 6.66666667,5.5 C6.66666667,4.84 6.22222222,4.4 5.55555556,4.4 L4.44444444,4.4 L4.44444444,2.2 L17.7777778,2.2 L17.7777778,19.8 L4.44444444,19.8 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Journals
