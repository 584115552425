import * as Sentry from '@sentry/react'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR } from 'constants/ui'
import axios from 'axios'
import { BRANCH_KEY, SAUCE_API_URL } from 'config'
import branch from 'branch-sdk'

// Actions
export const API_GET_BRANCH_DATA = 'API_GET_BRANCH_DATA'
export const API_GET_BRANCH_DATA_SUCCESS = 'API_GET_BRANCH_DATA_SUCCESS'
export const API_GET_BRANCH_DATA_ERROR = 'API_GET_BRANCH_DATA_ERROR'

export const API_SEND_SMS = 'API_SEND_SMS'
export const API_SEND_SMS_SUCCESS = 'API_SEND_SMS_SUCCESS'
export const API_SEND_SMS_ERROR = 'API_SEND_SMS_ERROR'

export const sendSMS = (phoneNumber, data) => {
  return async function dispatcher(dispatch) {
    try {
      dispatch({
        type: API_SEND_SMS,
      })
      const link = data.custom_object
      if (!link) {
        throw new Error(
          'Looks like something went wrong. Try clicking on the link to this page again.'
        )
      }
      const response = await axios.post(
        `${SAUCE_API_URL}/auth/send-sign-up-sms`,
        { phoneNumber, deeplink: data['~referring_link'] }
      )
      if (response) {
        dispatch({
          type: API_SEND_SMS_SUCCESS,
        })
      }
    } catch (error) {
      dispatch({ type: API_SEND_SMS_ERROR, error })
      Sentry.captureException(error)
      dispatch(
        addToast({
          message: error.message,
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export const initBranch = () => {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_BRANCH_DATA })
    branch.init(BRANCH_KEY, async (error, data) => {
      try {
        if (error) {
          if (error.message === 'Request timed out') {
            throw new Error('Request blocked by client, probably adblock.')
          }
          throw new Error(error)
        }
        if (data && data.data_parsed) {
          if (!data.data_parsed.custom_object) {
            throw new Error(
              'Looks like something went wrong. Try clicking on the link to this page again.'
            )
          }
          dispatch({
            type: API_GET_BRANCH_DATA_SUCCESS,
            data: data.data_parsed,
          })
        } else {
          throw new Error(
            'Looks like the request has timed out. Your browser might have an popup blocker.'
          )
        }
      } catch (error) {
        Sentry.captureException(error)
        dispatch({ type: API_GET_BRANCH_DATA_ERROR, error })
        dispatch(
          addToast({
            message: error.message,
            type: TOAST_TYPE_ERROR,
          })
        )
      }
    })
  }
}
