import { createMuiTheme } from '@weareroam/cake-ui-v1/styles'

const heavy = 900
const bold = 700
const semibold = 600
const medium = 500
const regular = 400
const ultra = 200

export const MINIMUM_VIEWPORT_WIDTH = 1024;

const spacingUnit = 8
export const spacing = {
  unit: spacingUnit,
  xxl: spacingUnit * 10, // 80
  xl: spacingUnit * 6, // 48
  lg: spacingUnit * 4, // 32
  mdlg: spacingUnit * 3, // 24
  md: spacingUnit * 2, // 16
  sm: spacingUnit, // 8
  xs: spacingUnit / 2, // 4
  xxs: spacingUnit / 4, // 2
}

const palette = {
  primary: {
    main: '#2985FC', // blue
    light: '#F0F4FD',
  },
  secondary: {
    main: '#FFFFFF', // white
  },
  tertiary: {
    dark: '#828797', // dark grey
    main: '#ADB9CB', // grey
    light: '#DAE1EE', // light grey
  },
  quaternary: {
    xdark: '#334739',
    dark: '#5C8267',
    main: '#ABD5B8', // pale green
    light: '#D4EEDC',
    xlight: '#CDF7DA',
  },
  quinary: {
    main: '#000000',
    light: '#E0E3E7',
  },
  success: {
    main: '#5C8267',
    light: '#EAF2EE',
  },
  warning: {
    main: '#F26522',
    light: 'rgba(242, 101, 34, 0.1)',
  },
  error: {
    main: '#D11441',
    bright: '#FF025D',
    light: 'rgba(237, 58, 80, 0.2)',
  },
  background: {
    main: '#FAFBFD',
  },
  chip: {
    main: '#ECF0F8',
  },
  text: {
    main: '#000000',
    secondary: '#FFFFFF',
  },
  accents: {
    main: '#ECECEC',
    light: '#EFEFEF',
    dark: '#001224',
  },
}

const primaryFont = "'Axiforma', 'Helvetica', 'Arial', 'sans-serif'"

const theme = createMuiTheme({
  palette,
  spacing,
  weights: {
    heavy,
    bold,
    medium,
    regular,
    ultra,
  },
  typography: {
    useNextVariants: true,
    fontFamily: primaryFont,
  },
})

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontFamily: primaryFont,
  body1: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: regular,
    lineHeight: theme.typography.pxToRem(18),
  },
  body2: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: regular,
    lineHeight: theme.typography.pxToRem(16),
  },
  subtitle1: {
    fontWeight: regular,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(33),
  },
  h1: {},
  h2: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: regular,
    lineHeight: theme.typography.pxToRem(43),
  },
  h3: {},
  h4: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: regular,
  },
  h5: {
    fontWeight: bold,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(33),
  },
  h6: {},
}

// Overrides are assigned here so we can use theme.breakpoints
// Override cake ui components here based off classes api
theme.overrides = {
  MuiBadge: {
    badge: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.quaternary.main,
      width: 18,
      height: 18,
      top: -8,
      right: -8,
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItemText: {
    root: {
      padding: 0,
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  MuiMenu: {
    paper: {
      maxHeight: 300,
      overflowY: 'auto',
      '&.menu-no-shadow': {
        // this is used in the DropdownFilter component
        boxShadow: 'none',
        border: `1px solid ${palette.tertiary.light}`,
      },
    },
  },
  MuiMenuItem: {
    root: {
      paddingTop: spacing.sm,
      paddingBottom: spacing.sm,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  MuiTableRow: {
    root: {
      verticalAlign: 'top',
    },
    head: {
      height: 36,
    },
  },
  MuiTableCell: {
    root: {
      paddingLeft: 0,
    },
    body: {
      paddingTop: spacing.md + spacing.xs,
      paddingBottom: spacing.md + spacing.xs,
      '& p:first-of-type': {
        marginTop: spacing.xs,
      },
    },
    head: {
      color: palette.text.primary,
    },
  },
  MuiFormLabel: {
    root: {
      color: palette.tertiary.dark,
    },
    filled: {
      color: palette.tertiary.main,
    },
    focused: {
      color: `${palette.tertiary.main} !important`,
    },
    error: {
      color: `${palette.error.main} !important`,
    },
  },
  MuiInput: {
    focused: {
      '& [type="password"]': {
        background: 'rgba(53, 116, 250, 0.03) !important',
      },
    },
    input: {
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
    underline: {
      '&:hover:not($disabled):before': {
        // underline color when hovered
        borderColor: palette.primary.main,
      },
    },
  },
  MuiCheckbox: {
    colorPrimary: {
      color: palette.tertiary.main,
    },
    checked: {
      color: palette.primary.main,
    },
  },
  MuiFormHelperText: {
    root: {
      color: palette.tertiary.main,
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: '#DAE1EE',
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      borderRadius: 8,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: semibold,
      fontFamily: primaryFont,
      whiteSpace: 'nowrap',
    },
    outlined: {
      fontWeight: semibold,
    },
    outlinedPrimary: {
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
    },
    contained: {
      boxShadow: 'none',
    },
    containedSecondary: {
      backgroundColor: palette.quaternary.main,
      color: palette.secondary.main,
    },
    sizeSmall: {
      fontSize: theme.typography.pxToRem(15),
      padding: '4px 25px',
    },
    sizeLarge: {
      padding: '12px 16px',
    },
    text: {
      fontWeight: regular,
    },
    textSecondary: {
      color: palette.tertiary.dark,
    },
  },
}

export default theme
