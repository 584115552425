import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { keyContactListSchema } from './schema'

// Actions
export const API_GET_KEY_CONTACTS_REQUEST = 'API_GET_KEY_CONTACTS_REQUEST'
export const API_GET_KEY_CONTACTS_SUCCESS = 'API_GET_KEY_CONTACTS_SUCCESS'
export const API_GET_KEY_CONTACTS_ERROR = 'API_GET_KEY_CONTACTS_ERROR'

export const API_UPDATE_KEY_CONTACTS_REQUEST = 'API_UPDATE_KEY_CONTACTS_REQUEST'
export const API_UPDATE_KEY_CONTACTS_SUCCESS = 'API_UPDATE_KEY_CONTACTS_SUCCESS'
export const API_UPDATE_KEY_CONTACTS_ERROR = 'API_UPDATE_KEY_CONTACTS_ERROR'

// Action creators
export function getKeyContacts(orgUuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_KEY_CONTACTS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/contacts`
      )
      const data = normalize(response.data.data, keyContactListSchema)

      dispatch({
        type: API_GET_KEY_CONTACTS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_KEY_CONTACTS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the key contacts. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function updateKeyContacts({ orgUuid, contacts, hideToast }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_KEY_CONTACTS_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/contacts`,
        {
          contacts,
        }
      )

      const data = normalize(response.data.data, keyContactListSchema)

      dispatch({
        type: API_UPDATE_KEY_CONTACTS_SUCCESS,
        data,
      })

      if (!hideToast) {
        dispatch(
          addToast({
            message: 'Key contacts have been updated.',
            type: TOAST_TYPE_SUCCESS,
          })
        )
      }
    } catch (error) {
      dispatch({ type: API_UPDATE_KEY_CONTACTS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error updating the key contacts. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
