import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { reflectionListSchema } from './schema'

// Actions
export const API_GET_REFLECTIONS_REQUEST = 'API_GET_REFLECTIONS_REQUEST'
export const API_GET_REFLECTIONS_SUCCESS = 'API_GET_REFLECTIONS_SUCCESS'
export const API_GET_REFLECTIONS_ERROR = 'API_GET_REFLECTIONS_ERROR'

export const API_ADD_ORGANISATION_REFLECTION_REQUEST =
  'API_ADD_ORGANISATION_REFLECTION_REQUEST'
export const API_ADD_ORGANISATION_REFLECTION_SUCCESS =
  'API_ADD_ORGANISATION_REFLECTION_SUCCESS'
export const API_ADD_ORGANISATION_REFLECTION_ERROR =
  'API_ADD_ORGANISATION_REFLECTION_ERROR'

// Action creators
export function getReflections(organisationId) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_REFLECTIONS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/v2/reflection?organisation_id=${organisationId}`
      )
      const data = normalize(response.data.data, reflectionListSchema)

      dispatch({
        type: API_GET_REFLECTIONS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_REFLECTIONS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error fetching the reflections. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function updateOrganisationReflections({ orgUuid, reflections }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_ADD_ORGANISATION_REFLECTION_REQUEST })
    try {
      const response = await axios.patch(
        `${SAUCE_API_URL}/organisation/reflection`,
        {
          orgUuid,
          reflections,
        }
      )
      const data = normalize(response.data.data, reflectionListSchema)
      dispatch({
        type: API_ADD_ORGANISATION_REFLECTION_SUCCESS,
        data,
      })
      dispatch(
        addToast({
          message: 'Reflection successfully added.',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_ADD_ORGANISATION_REFLECTION_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error adding the reflection for this organisation. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
