import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { history } from 'store/history'
import { refreshSessionMiddleware } from 'middleware/refreshSession'
import { authMiddleware } from 'middleware/authMiddleware'
import reducers from 'reducers'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunkMiddleware from 'redux-thunk'

export const middlewares = [
  // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
  reduxImmutableStateInvariant(),
  routerMiddleware(history),
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  thunkMiddleware,
]

export default function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools

  const store = createStore(
    reducers,
    { ...(initialState || {}) },
    composeEnhancers(
      applyMiddleware(refreshSessionMiddleware, authMiddleware, ...middlewares)
    )
  )

  return store
}
