import React from 'react'
import PropTypes from 'prop-types'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import ExportIcon from '@weareroam/cake-ui-v1-icons/ImportExport'
import Typography from '@weareroam/cake-ui-v1/Typography'
import DropdownButton from 'components/molecules/DropdownButton'
import Button from '@weareroam/cake-ui-v1/Button'
import SearchField from 'components/atoms/SearchField'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import MenuItem from 'components/molecules/MenuItem'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import IncidentTask from 'components/molecules/IncidentTask'
import SortHeading from 'components/molecules/SortHeading'
import ProgressLoader from 'components/atoms/ProgressLoader'
import Pagination from 'components/molecules/Pagination'
import useIncidents from 'hooks/useIncidents'
import { TOGGLE_ALL, SORT_DIRECTION_ASC } from 'constants/form'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`
export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const ExportButton = styled(Button)`
  && {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`
export const Right = styled.div`
  display: flex;
  align-items: center;
`

const AllGroupsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const IncidentsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const IncidentHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 75%;
  }
  &:nth-child(2) {
    width: 12.5%;
  }
  &:nth-child(3) {
    width: 12.5%;
  }
  & > div {
    justify-content: ${({ align }) => align || 'center'};
  }
`

export const IncidentsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export function Incidents({
  groups,
  onExport,
  incidents,
  organisation,
  getIncidentsProgress,
  getGroupsProgress,
  actions,
}) {
  const {
    filteredEntities: filteredGroups,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(groups, 'group', !getGroupsProgress.inProgress)

  const {
    searchFilter,
    handleChangeSearchFilter,
    pagination,
    handleChangePagination,
  } = useIncidents({
    organisation,
    filters: { key: 'groups', value: filteredGroups },
    onFetchIncidents: actions.getOrgIncidents,
  })

  return (
    <AdminDashboardLayout>
      <ContentContainer>
        <StyledHeader>
          <Typography variant="h2">Incidents</Typography>
          <Description variant="body1">
            Entries have been willingly shared by your members and <br /> may
            have been edited for increased anonymity.
          </Description>
        </StyledHeader>
        <Filters>
          <DropdownButton
            value={filteredGroups}
            onChange={handleChangeFilteredEntities}
            renderValue={renderFilterLabel}
          >
            <MenuItem value={TOGGLE_ALL}>
              <Checkbox
                color="primary"
                checked={filteredGroups.length === groups.length}
              />
              <AllGroupsLabel primary="All groups" />
            </MenuItem>
            {groups.map((group) => {
              return (
                <MenuItem key={group.uuid} value={group.uuid}>
                  <Checkbox
                    color="primary"
                    checked={filteredGroups.includes(group.uuid)}
                  />
                  <ListItemText primary={group.name} />
                </MenuItem>
              )
            })}
          </DropdownButton>
          <Right>
            <ExportButton variant="text" color="primary" onClick={onExport}>
              <ExportIcon /> Export
            </ExportButton>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              onChange={handleChangeSearchFilter}
            />
          </Right>
        </Filters>

        <IncidentsHeader>
          <IncidentHeaderItem align="flex-start">
            <SortHeading>Incident entry</SortHeading>
          </IncidentHeaderItem>
          <IncidentHeaderItem>
            <SortHeading>
              <strong>Org</strong> / Group
            </SortHeading>
          </IncidentHeaderItem>
          <IncidentHeaderItem align="flex-end">
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Date</strong> / Time
            </SortHeading>
          </IncidentHeaderItem>
        </IncidentsHeader>

        {getIncidentsProgress.inProgress &&
        (!incidents || incidents.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <React.Fragment>
            <IncidentsList>
              {incidents.map((incident) => {
                return (
                  <IncidentTask
                    key={incident.uuid}
                    readOnly
                    entry={incident.editedEntry}
                    type={incident.incidentTypes
                      .map((e) => e.description)
                      .join(', ')}
                    organisationName={incident.organisation.name}
                    groupName={incident.group.name}
                    date={incident.incidentDatetime}
                    value={incident.uuid}
                  />
                )
              })}
            </IncidentsList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </React.Fragment>
        )}
      </ContentContainer>
    </AdminDashboardLayout>
  )
}

Incidents.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  incidents: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.object,
  getIncidentsProgress: PropTypes.object,
  getGroupsProgress: PropTypes.object,
  organisation: PropTypes.string,
  onExport: PropTypes.func,
}

Incidents.defaultProps = {
  groups: [],
  incidents: [],
  actions: {},
  getIncidentsProgress: {},
  getGroupsProgress: {},
}

export default Incidents
