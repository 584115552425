import idx from 'idx'
import { PAGINATION_LIMIT } from 'constants/pagination'

export const getQueryParams = (params) => {
  if (!params) {
    return
  }
  let qString = ''
  let paramFields = params

  if ((paramFields.start || paramFields.start === 0) && paramFields.limit) {
    qString += `start=${params.start}&limit=${params.limit}&count=true`
    delete paramFields.start
    delete paramFields.limit
  }

  if (paramFields.searchFilter) {
    qString += `&search=%25${paramFields.searchFilter}%25`
    delete paramFields.searchFilter
  }

  return qString
}

export const getArrayFilters = (filters) => {
  return filters && filters.value && filters.value.length
    ? `${filters.key}=%5B${filters.value.map((f) => `"${f}"`)}%5D`
    : ''
}

export const getPaginationResult = (result) => {
  return {
    start: idx(result, (_) => _.pagination.start) || 0,
    limit: idx(result, (_) => _.pagination.limit) || PAGINATION_LIMIT,
    count: result.count || 0,
  }
}
