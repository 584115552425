import {
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
} from 'constants/form'

export function getAdminRoleThemeColor(role) {
  switch (role) {
    case ROLE_ORG_OWNER:
      return 'quinary'
    case ROLE_ORG_ADMIN:
      return 'primary'
    case ROLE_GROUP_ADMIN:
      return 'success'
    default:
      return 'success'
  }
}

export function getAdminRoleLabel(role) {
  switch (role) {
    case ROLE_ORG_OWNER:
      return 'Owner'
    case ROLE_ORG_ADMIN:
      return 'Organisation'
    case ROLE_GROUP_ADMIN:
      return 'Group'
    default:
      return '-'
  }
}
