import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import AppLayout from 'layouts/AppLayout'
import OrganisationDetailsForm from 'components/organisms/OrganisationDetailsForm'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledDisclaimer = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export function Organisation({
  onUpdateOrganisationDetails,
  organisation,
  keyContacts,
  keyContactsProgress,
  uploadTotal,
  uploadProgress,
  onImageUploadError,
  hasEditAccess,
}) {
  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          {hasEditAccess ? (
            <OrganisationDetailsForm
              onSubmit={onUpdateOrganisationDetails}
              organisation={organisation}
              keyContacts={keyContacts}
              keyContactsProgress={keyContactsProgress}
              uploadTotal={uploadTotal}
              uploadProgress={uploadProgress}
              onImageUploadError={onImageUploadError}
            />
          ) : (
            <>
              <Typography variant="h2">Organisation details</Typography>
              <StyledDisclaimer variant="body1">
                To view and edit organisation details, please ask your
                administrator for edit access
              </StyledDisclaimer>
            </>
          )}
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Organisation.propTypes = {
  onUpdateOrganisationDetails: PropTypes.func,
  organisation: PropTypes.object,
  hasEditAccess: PropTypes.bool,
  keyContacts: PropTypes.arrayOf(PropTypes.object),
  keyContactsProgress: PropTypes.object,
  uploadTotal: PropTypes.number,
  uploadProgress: PropTypes.number,
  onImageUploadError: PropTypes.func,
}

Organisation.defaultProps = {
  organisation: {},
  keyContacts: [],
}

export default Organisation
