import React from 'react'
import PropTypes from 'prop-types'
import SelectComponent from '@weareroam/cake-ui-v1/Select'
import InputLabel from '@weareroam/cake-ui-v1/InputLabel'
import FormControl from '@weareroam/cake-ui-v1/FormControl'
import DropdownIcon from '@weareroam/cake-ui-v1-icons/ExpandMore'
import styled from 'styled-components'

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  text-align: left;
`

export const StyledSelect = styled(SelectComponent)`
  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export function Select({
  name,
  onChange,
  value,
  children,
  label,
  error,
  className,
  multiple,
  ...rest
}) {
  return (
    <StyledFormControl error={error}>
      <InputLabel shrink htmlFor="age-label-placeholder">
        {label}
      </InputLabel>
      <StyledSelect
        IconComponent={DropdownIcon}
        value={value}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
          getContentAnchorEl: null,
        }}
        onChange={onChange}
        displayEmpty={false}
        name={name}
        multiple={multiple}
        className={className}
        {...rest}
      >
        {children}
      </StyledSelect>
    </StyledFormControl>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
  className: PropTypes.string,
  multiple: PropTypes.bool,
}

export default Select
