import React, { useCallback } from 'react'

import idx from 'idx'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'

import Box from 'components/molecules/Box'
import Modal from 'components/molecules/Modal'
import RowButton from 'components/atoms/RowButton'
import KeyContact from 'components/molecules/KeyContact'
import DragDropList from 'components/molecules/DragDropList'
import ProgressLoader from 'components/atoms/ProgressLoader'
import KeyContactForm from 'components/organisms/KeyContactForm'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'

import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import AppLayout from 'layouts/AppLayout'

export const StyledContent = styled(ContentContainer)``

export const DeleteConfirmModal = styled(Modal)``

export const KeyContacts = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const AddKeyContactModal = styled(Modal)`
  width: 500px;
`

export const KeyContactsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};

  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0;
  }
`

export const Contacts = ({
  keyContactList,
  deleteConfirmId,
  keyContactsProgress,
  setEditKeyContactIndex,
  onDragEnd,
  hasEditAccess,
  setDeleteConfirmId,
  setIsAddKeyContactModalOpen,
  editKeyContactIndex,
  onAddKeyContactSubmit,
  onDeleteContactConfirm,
  onCloseAddKeyContactModal,
  isAddKeyContactModalOpen,
}) => {
  const handleOpenKeyContactModal = useCallback(() => {
    setIsAddKeyContactModalOpen(true)
  }, [])

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <StyledContent>
          <AddKeyContactModal
            hasCloseButton
            open={isAddKeyContactModalOpen}
            onClose={onCloseAddKeyContactModal}
          >
            <KeyContactForm
              onSubmit={onAddKeyContactSubmit}
              inEditMode={editKeyContactIndex !== null}
              initialValues={idx(keyContactList, (_) => _[editKeyContactIndex])}
              onCancel={onCloseAddKeyContactModal}
            />
          </AddKeyContactModal>
          <DeleteConfirmModal
            hasCloseButton
            open={!!deleteConfirmId}
            onClose={() => setDeleteConfirmId(null)}
          >
            <ConfirmUpdatesForm
              confirmText="Yes, delete"
              onSubmit={onDeleteContactConfirm}
              onCancel={() => setDeleteConfirmId(null)}
              title="Are you sure you would like to delete&nbsp;this&nbsp;contact?"
              footerText="Remember to press 'update' once you have made all your changes."
            />
          </DeleteConfirmModal>
          <Typography variant="h2">Key contacts</Typography>
          <KeyContactsText variant="body1">
            Key contacts are for users that need to get in touch with someone
            from your organisation.
          </KeyContactsText>
          {keyContactsProgress && keyContactsProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : (
            <React.Fragment>
              <DragDropList onDragEnd={onDragEnd}>
                {(provided) => (
                  <KeyContacts>
                    {keyContactList
                      ? keyContactList.map((keyContact, index) => {
                          return (
                            <Box
                              key={keyContact.uuid}
                              draggableId={keyContact.uuid}
                              draggableIndex={index}
                              isDraggable={hasEditAccess}
                              isEditable={hasEditAccess}
                              headerContent={
                                <KeyContact
                                  name={keyContact.fullName}
                                  position={keyContact.position}
                                  contactNumber={keyContact.contactNumber}
                                  extension={keyContact.extension}
                                  email={keyContact.email}
                                  website={keyContact.website}
                                />
                              }
                              onEditClick={() => {
                                setEditKeyContactIndex(index)
                                setIsAddKeyContactModalOpen(true)
                              }}
                              onDeleteClick={() =>
                                setDeleteConfirmId(keyContact.uuid)
                              }
                            />
                          )
                        })
                      : null}
                    {provided.placeholder}
                  </KeyContacts>
                )}
              </DragDropList>
              {hasEditAccess && (
                <RowButton onClick={handleOpenKeyContactModal}>
                  + Add key contact
                </RowButton>
              )}
            </React.Fragment>
          )}
        </StyledContent>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Contacts.propTypes = {
  keyContactsProgress: PropTypes.shape({
    inProgress: PropTypes.bool,
  }),
  values: PropTypes.object,
  deleteConfirmId: PropTypes.string,
  setDeleteConfirmId: PropTypes.func,
  setFieldValue: PropTypes.func,
  keyContactList: PropTypes.arrayOf(PropTypes.object),
  onAddKeyContactSubmit: PropTypes.func,
  setIsAddKeyContactModalOpen: PropTypes.func,
  onDragEnd: PropTypes.func,
  setEditKeyContactIndex: PropTypes.func,
  isAddKeyContactModalOpen: PropTypes.bool,
  editKeyContactIndex: PropTypes.number,
  onDeleteContactConfirm: PropTypes.func,
  hasEditAccess: PropTypes.bool,
  onCloseAddKeyContactModal: PropTypes.func,
}

export default Contacts
