import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { Link } from 'react-router-dom'
import StatusTag from 'components/atoms/StatusTag'
import MoreActions from 'components/molecules/MoreActions'
import styled from 'styled-components'
import { truncateString } from 'utils/formatting.ts'

export const StyledEntityCard = styled.div`
  position: relative;
  display: inline-flex;
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.md + theme.spacing.sm}px;
  box-shadow: 0 1px 5px 0 rgba(202, 202, 202, 0.5);
  text-decoration: none;
  min-height: 210px;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  transition: box-shadow 0.4s ease;

  &:hover {
    box-shadow: 2px 6px 7px 0 rgba(197, 197, 197, 0.25),
      0 2px 3px 0 rgba(35, 38, 43, 0.05), 0 0 1px 0 rgba(28, 35, 50, 0.14),
      inset 0 1px 1px 0 #ffffff;
  }
`

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const StyledLogo = styled.div`
  max-height: 40px;
  max-width: 100%;

  img {
    height: 40px;
  }
`

export const StyledContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  pointer-events: none;
`

export const StyledFooter = styled.footer`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-self: end;
`

export const StyledFooterText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

export const StyledInfo = styled.div`
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledActions = styled(MoreActions)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  cursor: pointer;
  pointer-events: auto;
`

export const StyledTitle = styled(Typography)`
  && {
    word-break: break-word;
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const StyledSubtitle = styled(Typography)`
  && {
    margin-bottom: 0;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export function EntityCard({
  className,
  to,
  title,
  subtitle,
  logo,
  status,
  footerText,
  actions,
  id,
  dataQa,
}) {
  return (
    <StyledEntityCard className={className} id={id} data-qa={dataQa}>
      <StyledLink to={to} />
      <StyledContent>
        <StyledInfo>
          {logo && (
            <StyledLogo>
              <img src={logo} alt="Logo" />
            </StyledLogo>
          )}
          {title && (
            <StyledTitle variant="h4">{truncateString(title, 25)}</StyledTitle>
          )}
          {subtitle && (
            <StyledSubtitle variant="body1">{subtitle}</StyledSubtitle>
          )}
          {status && <StatusTag themeColor="success">{status}</StatusTag>}
        </StyledInfo>
        <StyledFooter>
          <StyledFooterText variant="body2">{footerText}</StyledFooterText>
          {actions && actions.length > 0 && <StyledActions actions={actions} />}
        </StyledFooter>
      </StyledContent>
    </StyledEntityCard>
  )
}

EntityCard.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.string,
  actions: PropTypes.array,
  status: PropTypes.string,
  footerText: PropTypes.node,
  id: PropTypes.string,
  dataQa: PropTypes.string,
}

export default EntityCard
