import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import SearchField from 'components/atoms/SearchField'
import Badge from '@weareroam/cake-ui-v1/Badge'
import ContentContainer from 'components/atoms/ContentContainer'
import JournalSubmit from 'components/atoms/Icons/JournalSubmit'
import ProgressLoader from 'components/atoms/ProgressLoader'
import MenuItem from 'components/molecules/MenuItem'
import Modal from 'components/molecules/Modal'
import DropdownButton from 'components/molecules/DropdownButton'
import IncidentTask from 'components/molecules/IncidentTask'
import SortHeading from 'components/molecules/SortHeading'
import ApproveIncidentsForm from 'components/organisms/ApproveIncidentsForm'
import IncidentEntryForm from 'components/organisms/IncidentEntryForm'
import DeleteIncidentForm from 'components/organisms/DeleteIncidentForm'
import Pagination from 'components/molecules/Pagination'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import useIncidents from 'hooks/useIncidents'
import useIncidentModals from 'hooks/useIncidentModals'
import styled from 'styled-components'
import {
  INCIDENT_TYPE_ALL,
  INCIDENT_TYPE_TO_REVIEW,
  INCIDENT_TYPE_SHARED,
  INCIDENT_TYPE_HIDDEN,
  SORT_DIRECTION_ASC,
  TOGGLE_ALL,
} from 'constants/form'

export const StyledLayout = styled(ChnnlDashboardLayout)``
export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderLeft = styled.div``
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`
export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

const AllOrganisationsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const IncidentsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const IncidentHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 5%;
  }
  &:nth-child(2) {
    width: 65%;
  }
  &:nth-child(3) {
    width: 12.5%;
  }
  &:nth-child(4) {
    width: 12.5%;
  }
  &:nth-child(5) {
    width: 5%;
  }
  & > div {
    justify-content: ${({ align }) => align || 'center'};
  }
`

export const StyledBadge = styled(Badge)``

export const StyledBadgeContainer = styled.div`
  opacity: ${({ faded }) => (faded ? 1 : 0.4)};
  cursor: ${({ faded }) => (faded ? 'pointer' : null)};
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export const IncidentsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const DeleteModal = styled(Modal)`
  width: 500px;
`

export const ApproveModal = styled(Modal)`
  width: 500px;
`

export const EntryModal = styled(Modal)`
  width: 700px;
`

export function SpeakUp({
  organisations,
  getIncidentsProgress,
  getOrgsProgress,
  actions,
  incidents,
}) {
  const {
    filteredEntities: filteredOrganisations,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(
    organisations,
    'organisation',
    !getOrgsProgress.inProgress
  )

  const {
    isDeleteIncidentModalOpen,
    isApproveIncidentsModalOpen,
    isIncidentEntryModalOpen,
    incidentModalUuid,
    handleOpenDeleteIncidentModal,
    handleCloseDeleteIncidentModal,
    handleOpenApproveIncidentsModal,
    handleCloseApproveIncidentsModal,
    handleOpenEntryIncidentsModal,
    handleCloseEntryIncidentsModal,
  } = useIncidentModals()

  const {
    handleApproveIncidents,
    handleChangeIncidentType,
    handleChangeMarkedToApprove,
    handleChangePagination,
    handleChangeSearchFilter,
    handleDeleteIncident,
    handleEditSubmit,
    handleRevertIncident,
    incidentsMarkedToApprove,
    incidentType,
    pagination,
    searchFilter,
  } = useIncidents({
    incidents,
    filters: { key: 'orgs', value: filteredOrganisations },
    incidentModalUuid,
    onDeleteIncident: useCallback(async (values) => {
      await actions.deleteIncident(values)
      handleCloseDeleteIncidentModal()
    }, []),
    onApproveIncidents: useCallback(async (values) => {
      await actions.approveIncidents(values)
      handleCloseApproveIncidentsModal()
    }, []),
    onEditIncident: useCallback(async (values) => {
      await actions.editIncident(values)
      handleCloseEntryIncidentsModal()
    }, []),
    onRevertIncident: useCallback(async (values) => {
      await actions.revertIncident(values)
      handleCloseEntryIncidentsModal()
    }, []),
    onFetchIncidents: actions.getIncidentReports,
  })

  const editingIncident = useMemo(
    () => {
      if (!incidents) {
        return null
      }

      return incidents.find((report) => report.uuid === incidentModalUuid)
    },
    [incidents, incidentModalUuid]
  )

  const canApproveReports = useMemo(
    () => {
      return incidentsMarkedToApprove && incidentsMarkedToApprove.length > 0
    },
    [incidentsMarkedToApprove]
  )

  const openIncidentApproveModal = useCallback(
    () => {
      if (canApproveReports) {
        handleOpenApproveIncidentsModal()
      }
    },
    [canApproveReports]
  )

  return (
    <StyledLayout>
      <DeleteModal
        open={isDeleteIncidentModalOpen}
        onClose={handleCloseDeleteIncidentModal}
      >
        <DeleteIncidentForm
          onCancel={handleCloseDeleteIncidentModal}
          onSubmit={handleDeleteIncident}
        />
      </DeleteModal>

      <EntryModal
        open={isIncidentEntryModalOpen}
        onClose={handleCloseEntryIncidentsModal}
      >
        {editingIncident && (
          <IncidentEntryForm
            groupName={editingIncident.group.name}
            organisationName={editingIncident.organisation.name}
            date={editingIncident.incidentDatetime}
            originalEntry={editingIncident.originalEntry}
            onCancel={handleCloseEntryIncidentsModal}
            onSubmit={handleEditSubmit}
            onRevert={handleRevertIncident}
            initialEntry={
              editingIncident.editedEntry || editingIncident.originalEntry
            }
          />
        )}
      </EntryModal>

      <ApproveModal
        open={isApproveIncidentsModalOpen}
        onClose={handleCloseApproveIncidentsModal}
      >
        <ApproveIncidentsForm
          onCancel={handleCloseApproveIncidentsModal}
          onSubmit={handleApproveIncidents}
        />
      </ApproveModal>

      <ContentContainer>
        <StyledHeader>
          <HeaderLeft>
            <DropdownButton
              name="organisations"
              value={filteredOrganisations}
              onChange={handleChangeFilteredEntities}
              renderValue={renderFilterLabel}
            >
              <MenuItem value={TOGGLE_ALL}>
                <Checkbox
                  color="primary"
                  checked={
                    filteredOrganisations.length === organisations.length
                  }
                />
                <AllOrganisationsLabel primary="All organisations" />
              </MenuItem>
              {organisations.map((organisation) => {
                return (
                  <MenuItem key={organisation.uuid} value={organisation.uuid}>
                    <Checkbox
                      color="primary"
                      checked={filteredOrganisations.includes(
                        organisation.uuid
                      )}
                    />
                    <ListItemText primary={organisation.name} />
                  </MenuItem>
                )
              })}
            </DropdownButton>
          </HeaderLeft>
          <HeaderRight>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              name="searchFilter"
              onChange={handleChangeSearchFilter}
            />
            <Filters>
              <ButtonSwitch
                value={INCIDENT_TYPE_ALL}
                name="entryType"
                isActive={incidentType === INCIDENT_TYPE_ALL}
                onClick={handleChangeIncidentType}
              >
                All entries
              </ButtonSwitch>
              <ButtonSwitch
                value={INCIDENT_TYPE_TO_REVIEW}
                name="entryType"
                isActive={incidentType === INCIDENT_TYPE_TO_REVIEW}
                onClick={handleChangeIncidentType}
              >
                To review
              </ButtonSwitch>
              <ButtonSwitch
                value={INCIDENT_TYPE_SHARED}
                name="entryType"
                isActive={incidentType === INCIDENT_TYPE_SHARED}
                onClick={handleChangeIncidentType}
              >
                Shared
              </ButtonSwitch>
              <ButtonSwitch
                value={INCIDENT_TYPE_HIDDEN}
                name="entryType"
                isActive={incidentType === INCIDENT_TYPE_HIDDEN}
                onClick={handleChangeIncidentType}
              >
                Hidden
              </ButtonSwitch>
            </Filters>
          </HeaderRight>
        </StyledHeader>

        <IncidentsHeader>
          <IncidentHeaderItem>
            <StyledBadgeContainer faded={canApproveReports}>
              <StyledBadge
                badgeContent={incidentsMarkedToApprove.length}
                onClick={openIncidentApproveModal}
              >
                <JournalSubmit />
              </StyledBadge>
            </StyledBadgeContainer>
          </IncidentHeaderItem>
          <IncidentHeaderItem align="flex-start">
            <SortHeading>Incident entry</SortHeading>
          </IncidentHeaderItem>
          <IncidentHeaderItem>
            <SortHeading>
              <strong>Org</strong> / Group
            </SortHeading>
          </IncidentHeaderItem>
          <IncidentHeaderItem align="flex-end">
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Date</strong> / Time
            </SortHeading>
          </IncidentHeaderItem>
          <IncidentHeaderItem />
        </IncidentsHeader>

        {getIncidentsProgress.inProgress &&
        (!incidents || incidents.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <React.Fragment>
            <IncidentsList>
              {incidents.map((incident, index) => {
                let incidentStatus

                if (incident.hidden) {
                  incidentStatus = INCIDENT_TYPE_HIDDEN
                } else if (incident.approved) {
                  incidentStatus = INCIDENT_TYPE_SHARED
                } else {
                  incidentStatus = INCIDENT_TYPE_TO_REVIEW
                }
                return (
                  <IncidentTask
                    key={incident.uuid}
                    dataQa={`incident-entry-${index}`}
                    entry={incident.editedEntry || incident.originalEntry}
                    type={incident.incidentTypes
                      .map((e) => e.description)
                      .join(', ')}
                    hasEntryBeenEdited={!!incident.editedEntry}
                    organisationName={incident.organisation.name}
                    groupName={incident.group.name}
                    date={incident.incidentDatetime}
                    status={incidentStatus}
                    checked={incidentsMarkedToApprove.includes(incident.uuid)}
                    value={incident.uuid}
                    onCheck={handleChangeMarkedToApprove}
                    onClick={handleOpenEntryIncidentsModal}
                    name="markedToApprove"
                    actions={[
                      {
                        key: 'delete-incident',
                        label: 'Delete incident report',
                        onClick: () => {
                          handleOpenDeleteIncidentModal(incident.uuid)
                        },
                      },
                      {
                        key: 'change-hidden',
                        onClick: () => {
                          actions.changeIncidentHidden(incident)
                        },
                        label: `${
                          incident.hidden ? 'Un-hide' : 'Hide'
                        } from organisation`,
                      },
                    ]}
                  />
                )
              })}
            </IncidentsList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </React.Fragment>
        )}
      </ContentContainer>
    </StyledLayout>
  )
}

SpeakUp.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  getIncidentsProgress: PropTypes.object,
  getOrgsProgress: PropTypes.object,
  actions: PropTypes.object,
  incidents: PropTypes.arrayOf(PropTypes.object),
}

SpeakUp.defaultProps = {
  organisations: [],
  getIncidentsProgress: {},
  getOrgsProgress: {},
  actions: {},
  incidentReports: [],
}

export default SpeakUp
