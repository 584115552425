import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import ContentContainer from 'components/atoms/ContentContainer'
import AppLayout from 'layouts/AppLayout'
import SurveyQuestionsForm from 'components/organisms/SurveyQuestionsForm'

export function Survey({
  onUpdateSurveyQuestions,
  getSurveyProgress,
  departments,
  typesOfDays,
  hasEditAccess,
}) {
  const initialValues = useMemo(
    () => ({
      typesOfDays,
      departments,
    }),
    [departments, typesOfDays]
  )

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <SurveyQuestionsForm
            disableEditting={!hasEditAccess}
            onSubmit={onUpdateSurveyQuestions}
            progress={getSurveyProgress}
            initialValues={initialValues}
          />
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Survey.propTypes = {
  onUpdateSurveyQuestions: PropTypes.func,
  initialValues: PropTypes.object,
  getSurveyProgress: PropTypes.object,
  hasEditAccess: PropTypes.bool,
  departments: PropTypes.arrayOf(PropTypes.object),
  typesOfDays: PropTypes.arrayOf(PropTypes.object),
}

export default Survey
