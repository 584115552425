import React from 'react'
import PropTypes from 'prop-types'
import OrgAdminHeader from 'components/organisms/OrgAdminHeader'
import styled from 'styled-components'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import { StyledContent as ChnnlContent } from 'layouts/ChnnlDashboardLayout/ChnnlDashboardLayout'

export const StyledLayout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.main};
`

export const IEWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledContent = styled.div`
  padding: ${({ theme, noPadding }) => (noPadding ? 0 : theme.spacing.xl)}px
    ${({ theme, noPadding }) => (noPadding ? 0 : theme.spacing.lg)}px;
  flex-grow: 1;
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`

export const StyledChnnlLayout = styled(ChnnlDashboardLayout)`
  ${ChnnlContent} {
    padding: 0;
  }
`

export function AdminDashboardLayout({
  children,
  onLogout,
  isChnnlAdmin,
  className,
  noPadding,
  organisation,
}) {
  const AdminLayout = (
    <IEWrapper>
      <StyledLayout className={className}>
        <OrgAdminHeader
          onLogout={onLogout}
          logoSrc={organisation ? organisation.logo : ''}
          organisationCountry={
            organisation && organisation.defaultCountry
              ? organisation.defaultCountry.isoCode
              : ''
          }
          organisationName={organisation ? organisation.name : ''}
          organisationInviteToken={
            organisation && organisation.inviteTokens
              ? organisation.inviteTokens[0].token
              : ''
          }
        />
        <StyledContent noPadding={noPadding}>{children}</StyledContent>
      </StyledLayout>
    </IEWrapper>
  )

  // chnnl admins should see this dashboard layout nested within their own dashboard layout.
  if (isChnnlAdmin) {
    return (
      <StyledChnnlLayout noSidebar onLogout={onLogout}>
        {AdminLayout}
      </StyledChnnlLayout>
    )
  }

  return AdminLayout
}

AdminDashboardLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isChnnlAdmin: PropTypes.bool,
  onLogout: PropTypes.func,
  noPadding: PropTypes.bool,
  organisation: PropTypes.object,
}

AdminDashboardLayout.defaultProps = {
  organisation: {},
}

export default AdminDashboardLayout
