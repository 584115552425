import { createSelector } from 'reselect'

export const getMessageList = createSelector(
  (state) => state.messaging.entities,
  (messages) => Object.values(messages)
)

export const getGroupMessagesList = createSelector(
  [getMessageList, (state, groupUuid) => groupUuid],
  (messages, groupUuid) => {
    return messages.filter((message) => {
      return message.groupUuid === groupUuid
    })
  }
)

export const getOrganisationMessagesList = createSelector(
  [getMessageList],
  (messages) => {
    return messages
  }
)
