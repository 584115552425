import * as groupsActions from 'modules/groups/actions'
import * as messagingActions from 'modules/messaging/actions'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getGroupsList } from 'modules/groups/selectors'
import { hasEditRights } from 'modules/authentication/selectors'
import { getOrganisationMessagesList } from 'modules/messaging/selectors'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      const groups = getGroupsList(state)

      return {
        groups,
        messages: getOrganisationMessagesList(state).map((message) => {
          // splicing in group names
          const groupUuid = message.groupUuid
          const group = groups.find(({ uuid }) => uuid === groupUuid) || {
            name: '',
          }
          return {
            ...message,
            groupName: group.name,
          }
        }),
        hasEditAccess: state.user.canEdit || hasEditRights(state),
      }
    },
    // mapDispatchToProps
    (dispatch, props) => {
      const {
        match: {
          params: { orgUuid: organisationUuid },
        },
      } = props

      const getMessages = (params, filters) =>
        messagingActions.getOrgMessageHistory({
          organisationUuid,
          params,
          filters,
        })

      const sendMessage = ({ message, groupsSelected: groupUuids }) =>
        messagingActions.messageGroups({
          organisationUuid,
          groupUuids,
          message,
        })

      const actions = {
        ...groupsActions,
        getMessages,
        sendMessage,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withHandlers({
    onSendMessage: ({
      actions,
      match: {
        params: { orgUuid: organisationUuid },
      },
    }) => ({ message, selectedGroups: groupUuids }) => {
      const payload = {
        message,
        organisationUuid,
        groupUuids,
      }
      actions.messageGroups(payload)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getGroups(match.params.orgUuid)
    },
  })
)
