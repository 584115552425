import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Button from '@weareroam/cake-ui-v1/Button'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledEmail = styled(TextField)``

export const StyledFields = styled.div`
  margin: ${({ theme }) => theme.spacing.lg}px 0
    ${({ theme }) => theme.spacing.xl}px;

  && {
    > * {
      margin-bottom: ${({ theme }) => theme.spacing.md}px;
    }
  }
`

export const ContactNumber = styled.div`
  display: flex;
`

export const Extension = styled(TextField)`
  && {
    width: 140px;
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const Actions = styled.footer`
  display: flex;
  width: 320px;
  margin: 0 auto;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export function KeyContactForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onCancel,
  inEditMode,
}) {
  const handleFormSubmit = useCallback((e) => {
    // this is a nested form, prevent propagation from bubbling up to the parent form
    e.stopPropagation()
    // pass along to formik handler
    handleSubmit(e)
  }, [])

  return (
    <form
      onSubmit={handleFormSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <Typography variant="h2">
        {inEditMode ? 'Edit contact' : 'Add new contact'}
      </Typography>
      <StyledFields>
        <TextField
          id="fullName"
          name="fullName"
          label="Full Name"
          type="text"
          value={values.fullName}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.fullName && !!errors.fullName}
          helperText={touched.fullName ? errors.fullName : ''}
          fullWidth
        />
        <TextField
          id="position"
          name="position"
          label="Position"
          type="text"
          value={values.position}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.position && !!errors.position}
          helperText={touched.position ? errors.position : ''}
          fullWidth
        />
        <StyledEmail
          id="email"
          name="email"
          label="Email Address"
          type="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.email && !!errors.email}
          helperText={touched.email ? errors.email : ''}
          fullWidth
        />
        <ContactNumber>
          <TextField
            id="contactNumber"
            name="contactNumber"
            label="Contact number"
            type="text"
            value={values.contactNumber}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.contactNumber && !!errors.contactNumber}
            helperText={touched.contactNumber ? errors.contactNumber : ''}
            fullWidth
          />
          <Extension
            id="extension"
            name="extension"
            label="Extension"
            type="text"
            value={values.extension}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.extension && !!errors.extension}
            helperText={touched.extension ? errors.extension : ''}
            fullWidth
          />
        </ContactNumber>
        <TextField
          id="website"
          name="website"
          label="Website"
          type="text"
          value={values.website}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.website && !!errors.website}
          helperText={touched.website ? errors.website : ''}
          fullWidth
        />
        <TextField
          id="description"
          name="description"
          label="Description"
          type="text"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.description && !!errors.description}
          helperText={touched.description ? errors.description : ''}
          fullWidth
        />
      </StyledFields>
      <Actions>
        <Button
          variant="outlined"
          type="button"
          color="primary"
          fullWidth
          size="small"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          color="primary"
          size="small"
        >
          {inEditMode ? 'Edit contact' : 'Add contact'}
        </Button>
      </Actions>
    </form>
  )
}

KeyContactForm.propTypes = {
  inEditMode: PropTypes.bool,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  onCancel: PropTypes.func,
}

KeyContactForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default KeyContactForm
