import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import AppPreviewImage from 'assets/images/app-preview.png'
import ChnnlLogoWhite from 'assets/images/chhnl-logo-white.png'
import ProgressLoader from 'components/atoms/ProgressLoader'
import { SIGN_UP_SUCCESS } from 'constants/routes'
import InputErrorStatusTag from 'components/atoms/InputErrorStatusTag'

import {
  StyledDefaultLayout,
  CustomLayout,
  StyledHeader,
  StyledTitle1,
  StyledText,
  StyledInfo,
  StyledChnnlLogo,
  StyledSignup,
  StyledForm,
  StyledSMSButton,
  DeskTopOnlyStyledImage,
  StyledImageContainer,
  StyledSelectAndTextInput,
} from './SignMeUp.components'

export function SignMeUp({
  handleblur,
  handleChange,
  handleSubmit,
  getBranchDataProgress,
  sendSMSProgress,
  values,
  touched,
  errors,
}) {
  const countries = [
    { name: 'New Zealand', isoCode: 'NZ', phoneCode: '64' },
    { name: 'Australia', isoCode: 'AU', phoneCode: '61' },
    { name: 'India', isoCode: 'IN', phoneCode: '91' },
    { name: 'Germany', isoCode: 'DE', phoneCode: '49' },
  ]

  return (
    <StyledDefaultLayout>
      <CustomLayout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <StyledSignup>
            <StyledInfo>
              <StyledHeader>
                <StyledTitle1 variant="h1" align="left">
                  Your safe space,{' '}
                </StyledTitle1>
                <StyledChnnlLogo src={ChnnlLogoWhite} />
              </StyledHeader>
              <StyledText variant="body1" paragraph align="left">
                Your organisation has partnered with chnnl, leading the way as
                an employer who takes mental health and wellbeing seriously.
              </StyledText>
              <StyledText variant="body1" paragraph align="left">
                To sign up, simply enter your mobile number and you’ll receive a
                text message shortly to access the app.
              </StyledText>
              <StyledText variant="body1" paragraph align="left">
                Remember, chnnl is 100% confidential, providing an easy to use
                platform to voice your thoughts, become aware of how you’re
                feeling, and get wellness advice. We hope you love it as much as
                we do!
              </StyledText>
            </StyledInfo>
            <StyledForm onSubmit={handleSubmit}>
              <StyledSelectAndTextInput
                selectInputName="region"
                textInputName="phone"
                selectValue={values.region}
                textInputValue={values.phone}
                error={errors.phone}
                touched={touched.phone}
                selections={countries}
                onChange={handleChange}
                onBlur={handleblur}
                placeholder="Your Phone Number"
                flags
              />
              <InputErrorStatusTag error={errors.phone} />
              <StyledSMSButton
                color="primary"
                variant="contained"
                size="medium"
                type="submit"
              >
                {getBranchDataProgress.inProgress ||
                sendSMSProgress.inProgress ? (
                  <ProgressLoader size={20} thickness={5} color="secondary" />
                ) : (
                  'Get the App'
                )}
              </StyledSMSButton>
            </StyledForm>
            {sendSMSProgress.isComplete && sendSMSProgress.success && (
              <Redirect to={SIGN_UP_SUCCESS} />
            )}
          </StyledSignup>
        </div>
        <StyledImageContainer>
          <DeskTopOnlyStyledImage
            src={AppPreviewImage}
            alt="app-landing-preview"
          />
        </StyledImageContainer>
      </CustomLayout>
    </StyledDefaultLayout>
  )
}

SignMeUp.propTypes = {
  handleblur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  getBranchDataProgress: PropTypes.object,
  sendSMSProgress: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
}

SignMeUp.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default SignMeUp
