import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MenuItem from 'components/molecules/MenuItem'
import { Input, FormControl, ListItemText } from '@weareroam/cake-ui-v1'
import Select from 'components/molecules/Select'
import { CountryFlags } from 'constants/CountryFlags'

export const BorderColorSelector = ({ focused, theme, error, variant }) => {
  let color = 'white'
  switch (variant) {
    case 'outlined':
      color = theme.palette.tertiary.dark
      break
    case 'filled':
    default:
      color = 'white'
      break
  }
  if (focused === 'true') {
    color = theme.palette.tertiary.dark
  }

  if (error === 'true') {
    color = theme.palette.error.main
  }

  return color
}

export const BorderWidthSelector = ({ focused, variant }) => {
  let width = 1
  if (variant === 'standard') {
    width = 2
  }
  if (focused === 'true') {
    width = 2
  }
  return width
}

export const PaddingSelector = ({ size, theme }) => {
  switch (size) {
    case 'extra-small':
      return '0px'
    case 'small':
      return `${theme.spacing.sm}px`
    case 'medium':
      return `${theme.spacing.md}px`
    case 'large':
      return `${theme.spacing.lg}px`
    default:
      return `${theme.spacing.sm}px`
  }
}

export const StyledSelectAndTextInput = styled.div`
  && {
    display: flex;
    margin-top: ${({ theme }) => theme.spacing.lg}px;
    border-bottom-left-radius: ${({ theme, variant }) =>
      variant === 'outlined' ? 0 : theme.spacing.sm}px;
    border-bottom-right-radius: ${({ theme, variant }) =>
      variant === 'outlined' ? 0 : theme.spacing.sm}px;
    border-bottom: ${(props) => BorderWidthSelector(props)}px solid
      ${(props) => BorderColorSelector(props)};
    -webkit-transition: border 200ms ease-out;
    -moz-transition: border 200ms ease-out;
    -o-transition: border 200ms ease-out;
    transition: border 200ms ease-out;
  }
`

export const StyledTextControl = styled(FormControl)`
  display: flex;
  flex-grow: 4;
`

export const StyledSelectorControl = styled(FormControl)`
  display: flex;
  flex-grow: 1;
`

export const StyledSelect = styled(Select)`
  && {
    text-align: center;
    color: #8d92a3;
    margin-top: 0;
    background-color: white;
    padding: ${(props) => PaddingSelector(props)}
    border-top-left-radius: ${({ theme }) => theme.spacing.sm}px;
    border-bottom-left-radius: ${({ theme }) => theme.spacing.sm}px;
  }
  &&,
  svg {
    fill: #8d92a3;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  padding: 0;
  margin: 0;
`

export const StyledInput = styled(Input)`
  && {
    display: flex;
    color: #8d92a3;
    box-sizing: border-box;
    background-color: white;
    padding: ${(props) => PaddingSelector(props)}
    border-top-right-radius: ${({ theme }) => theme.spacing.sm}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacing.sm}px;
    padding-left: ${({ theme }) => theme.spacing.sm}px;
    box-shadow: -1px 0 0 0 #efefef;
    outline: none;
  }
`

export const SelectAndTextInput = ({
  selectInputName,
  textInputName,
  type,
  selectValue,
  textInputValue,
  touched,
  error,
  selections,
  variant,
  size,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  onAdd,
  flags,
}) => {
  const hasError = !!touched && !!error
  const [focused, setFocused] = useState(false)

  const handleRegionalBlur = useCallback(() => {
    setFocused(false)
  })

  const handlePhoneBlur = useCallback(() => {
    setFocused(false)
    return onBlur
  })

  const handleFocus = useCallback((event) => {
    setFocused(true)
    onFocus(event.target.name)
  })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !!onAdd) {
      event.preventDefault()
      onAdd(event.target.value)
    }
  }

  return (
    <StyledSelectAndTextInput
      focused={focused.toString()}
      error={hasError.toString()}
      variant={variant}
    >
      <StyledSelectorControl margin="none" required>
        <StyledSelect
          name={selectInputName}
          id={selectInputName}
          value={selectValue}
          variant={variant}
          size={size}
          onChange={onChange}
          onBlur={handleRegionalBlur}
          onFocus={handleFocus}
          disableUnderline
        >
          {selections.map((country) => (
            <StyledMenuItem
              key={`country-${country.name}`}
              value={country.isoCode}
            >
              <ListItemText
                primary={`${flags ? CountryFlags[country.isoCode] : ''}\t+${
                  country.phoneCode
                }`}
              />
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledSelectorControl>
      <StyledTextControl margin="none" required focused={focused.toString()}>
        <StyledInput
          id={textInputName}
          name={textInputName}
          type={type}
          placeholder={placeholder}
          variant={variant}
          onBlur={handlePhoneBlur}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          value={textInputValue}
          error={hasError}
          onFocus={handleFocus}
          size={size}
          disableUnderline
        />
      </StyledTextControl>
    </StyledSelectAndTextInput>
  )
}

SelectAndTextInput.propTypes = {
  selectInputName: PropTypes.string,
  textInputName: PropTypes.string,
  type: PropTypes.string,
  onAdd: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  selections: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectValue: PropTypes.string,
  textInputValue: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  flags: PropTypes.bool,
}

SelectAndTextInput.defaultProps = {
  error: '',
  values: {},
  touched: false,
  countries: [],
  selections: [],
  type: 'text',
  variant: 'standard',
  size: 'small',
  onFocus: () => {},
  onAdd: () => {},
  onChange: () => {},
  onBlur: () => {},
  flags: false,
}

export default SelectAndTextInput
