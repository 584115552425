import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import styled from 'styled-components'

export const StyledForm = styled.form``

export const StyledPathwayTitle = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledPathwayMessage = styled(TextField)`
  overflow: hidden;
`

export const Actions = styled.footer`
  display: flex;
  width: 320px;
  margin: 0 auto;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.md}px 0
    ${({ theme }) => theme.spacing.xl}px;
`

export function EditPathwayForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onCancel,
}) {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <Typography variant="h2">Edit pathway</Typography>

      <StyledFields>
        <StyledPathwayTitle
          id="title"
          name="title"
          label="Pathway title"
          type="text"
          value={values.title || ''}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.title && !!errors.title}
          helperText={touched.title ? errors.title : ''}
          fullWidth
        />

        <StyledPathwayMessage
          id="message"
          name="message"
          label="Pathway message"
          type="text"
          value={values.message || ''}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.message && !!errors.message}
          helperText={touched.message ? errors.message : ''}
          fullWidth
          multiline
        />
      </StyledFields>

      <Actions>
        <Button
          variant="outlined"
          type="button"
          color="primary"
          fullWidth
          size="small"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          color="primary"
          size="small"
        >
          Save
        </Button>
      </Actions>
    </StyledForm>
  )
}

EditPathwayForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  onCancel: PropTypes.func,
}

EditPathwayForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default EditPathwayForm
