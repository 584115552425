import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Sidebar from 'components/molecules/Sidebar'
import NavItem from 'components/molecules/NavItem'
import {
  ORG_DASHBOARD_APP_BOOSTERS,
  ORG_DASHBOARD_APP_PATHWAYS,
  ORG_DASHBOARD_APP_SURVEY,
  ORG_DASHBOARD_APP_CONTACTS,
  ORG_DASHBOARD_APP_REFLECTIONS,
  ORG_DASHBOARD_APP_ORGANISATION,
} from 'constants/routes'
import { replaceRouteParams } from 'utils/routes'

export const StyledLayout = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
`

export const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.lg}px;
  overflow: hidden;
  flex-grow: 1;
`

export function AppLayout({ children, orgUuid }) {
  return (
    <StyledLayout>
      <Sidebar>
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_ORGANISATION, {
            orgUuid,
          })}
          text="Organisation"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_CONTACTS, {
            orgUuid,
          })}
          text="Contacts"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_SURVEY, { orgUuid })}
          text="Survey"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_REFLECTIONS, {
            orgUuid,
          })}
          text="Reflections"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_BOOSTERS, { orgUuid })}
          text="Boosters"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_PATHWAYS, { orgUuid })}
          text="Pathways"
        />
      </Sidebar>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node,
  orgUuid: PropTypes.string.isRequired,
}

export default AppLayout
