import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Typography from '@weareroam/cake-ui-v1/Typography'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import GroupInnerLayout from 'layouts/GroupInnerLayout'
import EditGroupNameForm from 'components/organisms/EditGroupNameForm'
import Table from '@weareroam/cake-ui-v1/Table'
import TableHead from '@weareroam/cake-ui-v1/TableHead'
import TableBody from '@weareroam/cake-ui-v1/TableBody'
import TableRow from '@weareroam/cake-ui-v1/TableRow'
import TableCell from '@weareroam/cake-ui-v1/TableCell'
import Button from '@weareroam/cake-ui-v1/Button'
import MoreActions from 'components/molecules/MoreActions'
import Modal from 'components/molecules/Modal'
import DeleteGroupForm from 'components/organisms/DeleteGroupForm'
import RemoveAdminForm from 'components/organisms/RemoveAdminForm'
import ProgressLoader from 'components/atoms/ProgressLoader'
import ContentContainer from 'components/atoms/ContentContainer'
import authorisedRoles from 'components/hoc/authorisedRoles'
import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
} from 'constants/form'

export const StyledLayout = styled(AdminDashboardLayout)``

export const StyledTable = styled(Table)`
  margin: ${({ theme }) => theme.spacing.xxl}px 0;
`
export const LastCell = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledModal = styled(Modal)`
  width: 500px;
`

export const PendingText = styled(Typography)`
  font-style: italic;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  && {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`

// Don't allow group admins to see the delete group button
const ProtectedDeleteButton = authorisedRoles([
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
])(Button)

export function Settings({
  onUpdateNameSubmit,
  onDeleteGroupSubmit,
  onRemoveGroupAdminSubmit,
  isDeleteGroupModalOpen,
  isRemoveAdminModalOpen,
  setIsRemoveAdminModalOpen,
  setIsDeleteGroupModalOpen,
  groupAdmins,
  getAdminsProgress,
  setModalAdminId,
  group,
  adminEntities,
  modalAdminId,
  hasEditAccess,
}) {
  return (
    <StyledLayout noPadding>
      <StyledModal
        open={isDeleteGroupModalOpen}
        onClose={() => setIsDeleteGroupModalOpen(false)}
      >
        <DeleteGroupForm
          onCancel={() => setIsDeleteGroupModalOpen(false)}
          onSubmit={onDeleteGroupSubmit}
        />
      </StyledModal>
      <StyledModal
        open={isRemoveAdminModalOpen}
        onClose={() => setIsRemoveAdminModalOpen(false)}
      >
        <RemoveAdminForm
          onSubmit={(e) =>
            onRemoveGroupAdminSubmit(e, adminEntities[modalAdminId])
          }
          onCancel={() => setIsRemoveAdminModalOpen(false)}
          admin={adminEntities[modalAdminId]}
          group={group}
        />
      </StyledModal>
      <GroupInnerLayout>
        <ContentContainer>
          <Typography variant="subtitle1">Group Settings</Typography>

          <EditGroupNameForm
            onSubmit={onUpdateNameSubmit}
            group={group}
            disabled={!hasEditAccess}
          />

          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h4">Group Admins</Typography>
                </TableCell>
                <TableCell />
                <TableCell>
                  <LastCell />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupAdmins.map((admin) => {
                const actions = []

                if (hasEditAccess) {
                  actions.push({
                    key: 'remove-group-admin',
                    label: 'Remove group admin',
                    onClick: () => {
                      setModalAdminId(admin.userUuid)
                      setIsRemoveAdminModalOpen(true)
                    },
                  })
                }

                return (
                  <TableRow key={admin.uuid}>
                    <TableCell>
                      <Typography variant="body1">
                        {admin.accepted ? admin.fullName : 'Pending'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{admin.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <LastCell>
                        {!admin.accepted && (
                          <PendingText variant="body1">Pending</PendingText>
                        )}
                        {actions.length > 0 && (
                          <MoreActions color="primary" actions={actions} />
                        )}
                      </LastCell>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </StyledTable>

          {getAdminsProgress.inProgress && <ProgressLoader fullWidth />}

          <ProtectedDeleteButton
            type="button"
            color="primary"
            variant="text"
            onClick={() => setIsDeleteGroupModalOpen(true)}
          >
            Delete group
          </ProtectedDeleteButton>
        </ContentContainer>
      </GroupInnerLayout>
    </StyledLayout>
  )
}

Settings.propTypes = {
  onUpdateNameSubmit: PropTypes.func,
  onDeleteGroupSubmit: PropTypes.func,
  onAddGroupAdminSubmit: PropTypes.func,
  onRemoveGroupAdminSubmit: PropTypes.func,
  isDeleteGroupModalOpen: PropTypes.bool,
  isRemoveAdminModalOpen: PropTypes.bool,
  isAddGroupAdminModalOpen: PropTypes.bool,
  setIsAddGroupAdminModalOpen: PropTypes.func,
  setIsRemoveAdminModalOpen: PropTypes.func,
  setIsDeleteGroupModalOpen: PropTypes.func,
  eligibleGroupAdmins: PropTypes.arrayOf(PropTypes.object),
  groupAdmins: PropTypes.arrayOf(PropTypes.object),
  getAdminsProgress: PropTypes.object,
  setModalAdminId: PropTypes.func,
  group: PropTypes.object,
  adminEntities: PropTypes.object,
  modalAdminId: PropTypes.string,
  hasEditAccess: PropTypes.bool,
}

Settings.defaultProps = {
  eligibleGroupAdmins: [],
  groupAdmins: [],
  getAdminsProgress: {},
  adminEntities: {},
  group: {},
}

export default Settings
