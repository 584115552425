import { createSelector } from 'reselect'
import { CountryDialCodes } from 'constants/CountryDialCodes'

// selectors
export const getOrganisationsList = createSelector(
  (state) => state.organisations.entities,
  (organisations) => Object.values(organisations)
)

export const organisationDialCode = (code) => CountryDialCodes[code]
