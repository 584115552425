import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@weareroam/cake-ui-v1/TableRow'
import styled from 'styled-components'

export const StyledRow = styled(TableRow)`
  && {
    height: 60px;
  }

  &:nth-of-type(2n) {
    background-color: ${({ theme }) => theme.palette.background.main};
    border-radius: 11px;
  }
`

export function DataTableRow({ children }) {
  return <StyledRow>{children}</StyledRow>
}

DataTableRow.propTypes = {
  children: PropTypes.node,
}

export default DataTableRow
