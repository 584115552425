import * as authActions from 'modules/authentication/actions'
import { compose, withState, withHandlers } from 'recompose'
import { LOGIN } from 'constants/routes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { redirect } from 'modules/route'

export default compose(
  connect(
    // mapStateToProps
    () => {
      return {}
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        redirect,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withState('requestSent', 'setRequestSentState', false),
  withHandlers({
    onSubmit: ({ actions, setRequestSentState }) => async ({ email }) => {
      const response = await actions.resetPasswordRequest(email)

      if (response) {
        setRequestSentState(true)
      }
    },
    onLoginLinkClick: ({ actions }) => () => {
      actions.redirect(LOGIN)
    },
  })
)
