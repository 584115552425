import { compose, mapProps, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as groupActions from 'modules/groups/actions'
import { bindActionCreators } from 'redux'
import idx from 'idx'

export default compose(
  withRouter,
  mapProps((props) => {
    return {
      ...props,
      orgUuid: props.match.params.orgUuid,
      groupUuid: props.match.params.groupUuid,
    }
  }),
  connect(
    // mapStateToProps
    (state, { groupUuid }) => {
      return {
        groupName: idx(state, (_) => _.groups.entities[groupUuid].name),
      }
    },
    (dispatch) => {
      const actions = {
        ...groupActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getGroups(match.params.orgUuid)
    },
  })
)
