import { useCallback } from 'react'

/**
 * Mainly for usage with the `renderValue` prop from the `DropdownButton` or `Select` components
 * This returns a callback that renders a label for the Select filter based on the selected values.
 * If all values are selected, it will return 'All entities' otherwise it will return the number of entities
 * @param   {Array}    entities       The full list of entities
 * @param   {String}   entityName     The name of the entity to render in the label.
 * @returns {Function}                The callback that renders the label
 */
export default function useFilterLabel(entities, entityName, selected) {
  return useCallback(
    (selected) => {
      const numSelected = selected.reduce((acc, val) => {
        if (val) {
          // Only count values that are non falsey
          return acc + 1
        }

        return acc
      }, 0)

      if (!numSelected) {
        return `Select ${entityName}`
      }

      if (entities.length === numSelected) {
        return `All ${entityName}s`
      }

      if (numSelected === 1) {
        const matchingItems = entities.filter((e) => e.uuid === selected[0])

        if (matchingItems && matchingItems.length === 1) {
          const item = matchingItems[0]

          return `${item.name}`
        }
      }

      return `${numSelected} ${entityName}s`
    },
    [entities]
  )
}
