import React, { useEffect } from 'react'

export const AppDownloadRedirect = () => {
  useEffect(() => {
    window.location.assign('https://chnnl.app/app-download')
  })

  return <div />
}

export default AppDownloadRedirect
