import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ContentContainer from 'components/atoms/ContentContainer'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import GroupInnerLayout from 'layouts/GroupInnerLayout'
import MenuItem from 'components/molecules/MenuItem'
import DropdownFilter from 'components/molecules/DropdownFilter'
import WellbeingCharts from 'components/organisms/WellbeingCharts'
import WellbeingReports from 'components/organisms/WellbeingReports'
import Typography from '@weareroam/cake-ui-v1/Typography'
import useWellbeing from 'hooks/useWellbeing'
import { API_YEAR_MONTH_FORMAT, MONTH_FILTER_FORMAT } from 'constants/date'
import { format } from 'date-fns'

export const StyledLayout = styled(AdminDashboardLayout)``
export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export function Wellbeing({
  lineChart,
  barChart,
  reflectionReport,
  attributeReport,
  orgUuid,
  error,
  groupUuid,
  actions,
}) {
  const currentOrganisation = useMemo(() => {
    return [orgUuid]
  }, [])
  const {
    attribute,
    attributeMood,
    filteredMonth,
    months,
    graphMood,
    handleChangeGraphMood,
    handleChangeFilteredMonth,
    handleSetAttributeMood,
    handleSetAttribute,
  } = useWellbeing({
    onFetchMood: actions.getWellbeingMoodChart,
    onFetchMoodMonthly: actions.getWellbeingMonthlyMoodChart,
    onFetchReflectionReport: actions.getWellbeingReflectionReport,
    onFetchAttributeReport: actions.getWellbeingAttributeReport,
    filteredOrganisations: currentOrganisation,
    groupUuid: groupUuid,
  })

  return (
    <StyledLayout noPadding>
      <GroupInnerLayout>
        <ContentContainer>
          <StyledHeader>
            <Typography variant="subtitle1">Wellbeing report</Typography>
            <DropdownFilter
              label="View:"
              value={filteredMonth}
              onChange={handleChangeFilteredMonth}
              name="month"
            >
              {months.map((month, index) => {
                return (
                  <MenuItem
                    key={index}
                    color="primary"
                    value={format(month, API_YEAR_MONTH_FORMAT)}
                  >
                    {format(month, MONTH_FILTER_FORMAT)}
                  </MenuItem>
                )
              })}
            </DropdownFilter>
          </StyledHeader>

          <WellbeingCharts
            lineChart={lineChart}
            barChart={barChart}
            onChangeMood={handleChangeGraphMood}
            mood={graphMood}
            error={error}
            warning={reflectionReport && reflectionReport.warningMessage}
            filteredMonth={filteredMonth}
            title="Your group's"
          />

          <WellbeingReports
            reflectionReport={reflectionReport}
            attributeReport={attributeReport}
            filteredMonth={filteredMonth}
            onChangeMood={handleSetAttributeMood}
            mood={attributeMood}
            attribute={attribute}
            onChangeAttribute={handleSetAttribute}
          />
        </ContentContainer>
      </GroupInnerLayout>
    </StyledLayout>
  )
}

Wellbeing.propTypes = {
  lineChart: PropTypes.arrayOf(PropTypes.object),
  barChart: PropTypes.arrayOf(PropTypes.object),
  reflectionReport: PropTypes.arrayOf(PropTypes.object),
  attributeReport: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  orgUuid: PropTypes.string,
  groupUuid: PropTypes.string,
  actions: PropTypes.object,
}

Wellbeing.defaultProps = {
  actions: {},
}

export default Wellbeing
