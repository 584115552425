import idx from 'idx'
import { push } from 'connected-react-router'
import { clearSession } from '../utils/authentication'
import { LOGIN } from 'constants/routes'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR } from 'constants/ui'

export function authMiddleware({ dispatch }) {
  return (next) => async (action) => {
    // only worry about expiring token for async actions
    if (action && action.error) {
      const errorStatus = idx(action, (_) => _.error.response.status)

      if (errorStatus === 403) {
        clearSession()
        dispatch(
          addToast({
            message: `You don't have authorization to do this. Please login and try again.`,
            type: TOAST_TYPE_ERROR,
          })
        )
        return dispatch(push(LOGIN))
      }
    }
    return next(action)
  }
}

export default authMiddleware
