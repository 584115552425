import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Switch from '@weareroam/cake-ui-v1/Switch'

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const Left = styled.div`
  flex-grow: 1;
`

const Title = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

const StyledSwitch = styled(Switch)`
  && {
    transform: translateY(-14px);
  }
`

export function DetailedSwitch({
  id,
  title,
  description,
  isSelected,
  onChange,
}) {
  const handleSwitchChange = useCallback(
    () => {
      onChange(!isSelected)
    },
    [isSelected]
  )

  return (
    <StyledContainer>
      <Left>
        <Title variant="body1">{title}</Title>
        <Description variant="body2">{description}</Description>
      </Left>
      <StyledSwitch
        id={id}
        color="primary"
        onChange={handleSwitchChange}
        value={isSelected}
      />
    </StyledContainer>
  )
}

DetailedSwitch.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
  description: PropTypes.string,
}

export default DetailedSwitch
