import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StyledLink } from 'components/molecules/NavItem/NavItem'

export const StyledSidebar = styled.aside`
  min-width: 150px;
  max-width: 300px;
  position: relative;
  padding: ${({ theme }) => theme.spacing.lg}px 0;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 25vw;
    z-index: 10;
    transform: translateX(-25vw);
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }

  ${StyledLink} {
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export function Sidebar({ children, className }) {
  return (
    <StyledSidebar className={className}>
      <StyledNav>{children}</StyledNav>
    </StyledSidebar>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Sidebar
