import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import EditIcon from '@weareroam/cake-ui-v1-icons/Edit'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html'

export const StyledEditableText = styled(ContentEditable)`
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  color: ${({ theme }) => theme.palette.text.primary};
  outline: 0;
  cursor: text;

  &[contenteditable='true']:empty:before {
    content: attr(placeholder);
    display: block;
    font-weight: normal;
    color: ${({ theme }) => theme.palette.tertiary.light};
  }
`

export const StyledRoot = styled.div`
  display: inline-flex;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  ${StyledEditableText} {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  }
`

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.md}px;
  color: ${({ theme }) => theme.palette.tertiary.light};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export function EditableText({
  value,
  onChange,
  onBlur,
  placeholder,
  name,
  fullWidth,
}) {
  const [isEditable, setIsEditable] = useState(!value)
  const textRef = useRef(null)
  useEffect(
    () => {
      if (isEditable) {
        textRef.current.focus()
      } else {
        textRef.current.blur()
      }
    },
    [isEditable, value]
  )
  const handleOnBlur = useCallback(
    (e) => {
      // It should always be editable if there is no value
      // Otherwise the user needs to click the edit icon if there is already a value.
      const dirty = e.target.value || e.target.innerText
      const clean = sanitizeHtml(dirty.replace(/&[^&]*;/gi, ''), {
        allowedTags: [],
      })
      const event = {
        ...e,
        target: {
          ...e.target,
          innerText: clean,
          value: clean,
        },
      }
      if (onChange) {
        onChange({
          ...event,
          target: {
            ...event.target,
            // Add name manually to the event so it can be used by formik
            name,
          },
        })
      }
      if (onBlur) {
        onBlur()
      }
      setIsEditable(false)
    },
    [value]
  )

  const handleOnChange = useCallback(
    (e) => {
      const event = {
        ...e,
        target: {
          ...e.target,
          value: e.target.value.replace('<div><br></div>', ''),
        },
      }
      if (onChange) {
        onChange({
          ...event,
          target: {
            ...event.target,
            // Add name manually to the event so it can be used by formik
            name,
          },
        })
      }
      if (e.target.value.indexOf('<div><br></div>') > -1) {
        handleOnBlur(event)
      }
    },
    [name]
  )

  const handleOnEditClick = useCallback(() => {
    setIsEditable(true)
  }, [])

  return (
    <StyledRoot fullWidth={fullWidth}>
      <StyledEditableText
        placeholder={placeholder}
        contentEditable={isEditable}
        disabled={!isEditable}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        innerRef={textRef}
        html={sanitizeHtml(value)}
      />
      {!isEditable && <StyledEditIcon onClick={handleOnEditClick} />}
    </StyledRoot>
  )
}

EditableText.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default EditableText
