import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import ChangePasswordForm from 'components/organisms/ChangePasswordForm'
import SettingsPageContainer from 'components/molecules/SettingsPageContainer'

export const StyledLayout = styled(ChnnlDashboardLayout)``

export function Password({ handleSubmit }) {
  return (
    <StyledLayout>
      <SettingsPageContainer title="Change password">
        <ChangePasswordForm onSubmit={handleSubmit} />
      </SettingsPageContainer>
    </StyledLayout>
  )
}

Password.propTypes = {
  handleSubmit: PropTypes.func,
}

export default Password
