import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TableCell from '@weareroam/cake-ui-v1/TableCell'

export const StyledHeadCell = styled(TableCell)`
  && {
    padding: 0 ${({ theme }) => theme.spacing.sm}px;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    font-weight: ${({ theme }) => theme.weights.regular};
    color: ${({ theme }) => theme.palette.tertiary.main};
    vertical-align: middle;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      padding-right: ${({ theme }) => theme.spacing.sm}px;
    }
  }
`

export function DataTableHeadCell({ children, className }) {
  return <StyledHeadCell>{children}</StyledHeadCell>
}

DataTableHeadCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default DataTableHeadCell
