import { compose, withStateHandlers } from 'recompose'

export default compose(
  withStateHandlers(
    ({ suggestions }) => ({
      filteredSuggestions: [...suggestions],
    }),
    {
      updateFilteredSuggestions: (state, { suggestions }) => (query) => {
        return {
          // Filter the visible suggestions by the query
          filteredSuggestions: suggestions.filter((suggestion) => {
            // convert to lowercase before comparing values
            const label = suggestion.label.toLowerCase()
            const lcQuery = query.toLowerCase()

            return label.includes(lcQuery)
          }),
        }
      },
      clearSuggestions: (state, { suggestions }) => () => ({
        filteredSuggestions: [...suggestions],
      }),
    }
  )
)
