import React from 'react'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import EntityCard from 'components/molecules/EntityCard'
import Button from '@weareroam/cake-ui-v1/Button'
import PropTypes from 'prop-types'
import ExportIcon from '@weareroam/cake-ui-v1-icons/ImportExport'
import SearchField from 'components/atoms/SearchField'
import Modal from 'components/molecules/Modal'
import NewOrganisationForm from 'components/organisms/NewOrganisationForm'
import ResendNewOrganisationForm from 'components/organisms/ResendNewOrganisationForm'
import DeleteOrganisationForm from 'components/organisms/DeleteOrganisationForm'
import ContentContainer from 'components/atoms/ContentContainer'
import styled from 'styled-components'
import ProgressLoader from 'components/atoms/ProgressLoader'
import EmptyState from 'components/molecules/EmptyState'
import useEntitiesSearch from 'hooks/useEntitiesSearch'
import { ORG_DASHBOARD_OVERVIEW } from 'constants/routes'
import { replaceRouteParams } from 'utils/routes'

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledSearch = styled(SearchField)`
  width: 400px;
`

export const StyledDeleteModal = styled(Modal)`
  width: 450px;
`

export const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* Negative margin for items on the edges */
  margin: -${({ theme }) => theme.spacing.md}px;
  max-width: calc(100% + ${({ theme }) => theme.spacing.md * 2}px);
`

export const Organisation = styled(EntityCard)`
  width: 230px;
  margin: ${({ theme }) => theme.spacing.md}px;
`

export const StyledHeaderButtons = styled.div`
  display: flex;
`

export const NewOrganisationButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export function Organisations({
  onExport,
  setIsNewOrganisationModalOpen,
  isNewOrganisationModalOpen,
  onInviteNewOrganisation,
  setIsResendModalOpen,
  isResendModalOpen,
  setIsDeleteOrganisationModalOpen,
  isDeleteOrganisationModalOpen,
  onDeleteOrganisation,
  organisations,
  getOrganisationsProgress,
  modalOrganisationId,
  setModalOrganisationId,
  organisationEntities,
  onResendInvitation,
  onOrgExport,
}) {
  const {
    searchFilter,
    handleChangeSearchFilter,
    filteredEntities,
  } = useEntitiesSearch(organisations)

  return (
    <ChnnlDashboardLayout>
      {/* New organisation form modal */}
      <Modal
        open={isNewOrganisationModalOpen}
        onClose={() => setIsNewOrganisationModalOpen(false)}
        hasCloseButton
      >
        <NewOrganisationForm
          onSubmit={onInviteNewOrganisation}
          onClose={() => setIsNewOrganisationModalOpen(false)}
        />
      </Modal>

      {/* Resend invitation modal */}
      <Modal
        open={isResendModalOpen}
        onClose={() => setIsResendModalOpen(false)}
        hasCloseButton
      >
        <ResendNewOrganisationForm
          organisation={organisationEntities[modalOrganisationId]}
          onCancel={() => setIsResendModalOpen(false)}
          onSubmit={onResendInvitation}
        />
      </Modal>

      {/* Delete organisation modal */}
      <StyledDeleteModal
        open={isDeleteOrganisationModalOpen}
        onClose={() => setIsDeleteOrganisationModalOpen(false)}
        hasCloseButton
      >
        <DeleteOrganisationForm
          organisation={organisationEntities[modalOrganisationId]}
          onSubmit={onDeleteOrganisation}
          onCancel={() => setIsDeleteOrganisationModalOpen(false)}
        />
      </StyledDeleteModal>

      <div>
        <ContentContainer>
          <StyledHeader>
            <StyledSearch
              placeholder="Search"
              value={searchFilter}
              onChange={handleChangeSearchFilter}
            />
            <StyledHeaderButtons>
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={onExport}
              >
                <ExportIcon /> Export all data
              </Button>
              <NewOrganisationButton
                type="button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setIsNewOrganisationModalOpen(true)}
                id="new-organisation-button"
              >
                New organisation
              </NewOrganisationButton>
            </StyledHeaderButtons>
          </StyledHeader>
        </ContentContainer>
        <ContentContainer>
          {getOrganisationsProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : filteredEntities.length ? (
            <StyledItems>
              {filteredEntities.map((organisation) => {
                const isPending =
                  organisation.orgOwner && !organisation.orgOwner.accepted

                const deleteAction = {
                  key: 'delete',
                  label: 'Delete',
                  onClick: () => {
                    setIsDeleteOrganisationModalOpen(true)
                    setModalOrganisationId(organisation.uuid)
                  },
                }

                // actions for pending organisations
                const pendingActions = [
                  {
                    key: 'invitiation',
                    label: 'Resend invitiation',
                    dataQa: 'resend-invitiation-option',
                    onClick: () => {
                      setIsResendModalOpen(true)
                      setModalOrganisationId(organisation.uuid)
                    },
                  },
                  {
                    ...deleteAction,
                  },
                ]
                // actions for organisations that are not pending
                const regularActions = [
                  {
                    key: 'export',
                    label: 'Export data',
                    onClick: () => onOrgExport(organisation.uuid),
                  },
                  {
                    ...deleteAction,
                  },
                ]

                return (
                  <Organisation
                    dataQa={organisation.name}
                    key={organisation.uuid}
                    to={replaceRouteParams(ORG_DASHBOARD_OVERVIEW, {
                      orgUuid: organisation.uuid,
                    })}
                    title={organisation.name}
                    status={isPending ? 'pending' : null}
                    logo={organisation.logo}
                    footerText={`${organisation.seatsOccupied} of ${
                      organisation.seats
                    } seats`}
                    actions={isPending ? pendingActions : regularActions}
                  />
                )
              })}
            </StyledItems>
          ) : (
            <EmptyState
              title="Nothing to chnnl here yet."
              text="Get started by adding a new organisation"
            />
          )}
        </ContentContainer>
      </div>
    </ChnnlDashboardLayout>
  )
}

Organisations.propTypes = {
  onExport: PropTypes.func,
  onSubmit: PropTypes.func,
  isNewOrganisationModalOpen: PropTypes.bool,
  setIsNewOrganisationModalOpen: PropTypes.func,
  onInviteNewOrganisation: PropTypes.func,
  onResendInvitation: PropTypes.func,
  setIsResendModalOpen: PropTypes.func,
  isResendModalOpen: PropTypes.bool,
  isDeleteOrganisationModalOpen: PropTypes.bool,
  setIsDeleteOrganisationModalOpen: PropTypes.func,
  onDeleteOrganisation: PropTypes.func,
  organisations: PropTypes.arrayOf(PropTypes.object),
  getOrganisationsProgress: PropTypes.object,
  modalOrganisationId: PropTypes.string,
  setModalOrganisationId: PropTypes.func,
  organisationEntities: PropTypes.object,
  onOrgExport: PropTypes.func,
}

Organisations.defaultProps = {
  getOrganisationsProgress: {},
  organisations: [],
  organisationEntities: {},
}

export default Organisations
