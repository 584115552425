import { useState, useMemo, useCallback } from 'react'

export function useAdminsSearch(admins) {
  const [searchFilter, setSearchFilter] = useState('')

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
  }, [])

  const filteredAdmins = useMemo(
    () => {
      return admins.filter((admin) => {
        const lcSearch = searchFilter.toLowerCase()
        const lcName = admin.fullName ? admin.fullName.toLowerCase() : ''
        const lcEmail = admin.email.toLowerCase()

        return lcName.startsWith(lcSearch) || lcEmail.startsWith(lcSearch)
      })
    },
    [admins, searchFilter]
  )

  return {
    searchFilter,
    handleChangeSearchFilter,
    filteredAdmins,
  }
}

export default useAdminsSearch
