import { API_GROUP_MESSAGE_SUCCESS, API_MESSAGE_SUCCESS } from './actions'
import initialState from 'reducers/initialState'

// Reducer
export default function messagesReducer(
  state = initialState.messaging,
  action
) {
  switch (action.type) {
    case API_GROUP_MESSAGE_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.messages,
        },
      }
    case API_MESSAGE_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.data.entities.messages,
          ...state.entities,
        },
      }

    default:
      return state
  }
}
