import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  JOURNAL_TYPE_TO_REVIEW,
  JOURNAL_TYPE_SHARED,
  JOURNAL_TYPE_HIDDEN,
} from 'constants/form'
import { JOURNAL_TASK_DATE, JOURNAL_TASK_TIME } from 'constants/date'
import { format } from 'date-fns'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import MoreActions from 'components/molecules/MoreActions'
import JournalUnchecked from 'components/atoms/Icons/JournalUnchecked'
import JournalChecked from 'components/atoms/Icons/JournalChecked'
import JournalShared from 'components/atoms/Icons/JournalShared'
import JournalHidden from 'components/atoms/Icons/JournalHidden'

export const StyledJournalTask = styled.div`
  position: relative;
  background-color: ${({ theme, redFlagged }) =>
    redFlagged ? '#FFE0E0' : theme.palette.secondary.main};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

export const JournalState = styled.div`
  width: 5%;
  z-index: 2;
`

export const JournalEntry = styled.div`
  width: ${({ readOnly }) => (readOnly ? '75%' : '60%')};
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 0;
  }

  svg {
    width: 25px;
    height: 25px;
  }
`

export const JournalFrom = styled.div`
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const JournalDate = styled.div`
  text-align: right;
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const JournalActions = styled.div`
  width: 5%;
  text-align: right;
  z-index: 2;
`

export const EntryText = styled(Typography)`
  && {
    display: inline;
  }
`

export const EditText = styled(Typography)`
  && {
    display: inline;
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`

export function JournalTask({
  dataQa,
  className,
  status,
  entry,
  hasEntryBeenEdited,
  organisationName,
  groupName,
  date,
  createdAt,
  actions,
  onClick,
  checked,
  onCheck,
  name,
  value,
  readOnly,
  redFlagged,
}) {
  const handleOnClick = useCallback(
    () => {
      if (onClick) {
        onClick(value)
      }
    },
    [value]
  )

  return (
    <StyledJournalTask
      redFlagged={redFlagged}
      className={className}
      data-qa={dataQa}
    >
      {!readOnly && <ClickArea onClick={handleOnClick} />}
      {!readOnly && (
        <JournalState>
          {status === JOURNAL_TYPE_TO_REVIEW && (
            <StyledCheckbox
              icon={<JournalUnchecked />}
              checkedIcon={<JournalChecked />}
              onChange={onCheck}
              name={name}
              checked={checked}
              value={value}
            />
          )}
          {status === JOURNAL_TYPE_SHARED && <JournalShared />}
          {status === JOURNAL_TYPE_HIDDEN && <JournalHidden />}
        </JournalState>
      )}
      <JournalEntry readOnly={readOnly}>
        <EntryText variant="body1" component="span">
          {entry}
        </EntryText>
        {hasEntryBeenEdited && (
          <EditText variant="body2" component="span">
            Edited
          </EditText>
        )}
      </JournalEntry>
      <JournalFrom>
        <Typography variant="body2">
          <strong>{organisationName}</strong>
        </Typography>
        <Typography variant="body2">{groupName}</Typography>
      </JournalFrom>
      <JournalDate>
        <Typography variant="body2">
          <strong>{format(date, JOURNAL_TASK_DATE)}</strong>
        </Typography>
        {!readOnly && (
          <Typography variant="body2">
            {format(createdAt, JOURNAL_TASK_DATE)}{' '}
            {format(createdAt, JOURNAL_TASK_TIME)}
          </Typography>
        )}
      </JournalDate>
      {!readOnly && (
        <JournalActions>
          {actions && (
            <MoreActions
              direction="vertical"
              color="primary"
              actions={actions}
            />
          )}
        </JournalActions>
      )}
    </StyledJournalTask>
  )
}

JournalTask.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  status: PropTypes.oneOf([
    JOURNAL_TYPE_TO_REVIEW,
    JOURNAL_TYPE_SHARED,
    JOURNAL_TYPE_HIDDEN,
  ]),
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  entry: PropTypes.string,
  hasEntryBeenEdited: PropTypes.bool,
  organisationName: PropTypes.string,
  groupName: PropTypes.string,
  date: PropTypes.string,
  createdAt: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  redFlagged: PropTypes.bool,
}

export default JournalTask
