import React from 'react'
import styled from 'styled-components'
import MenuItemComponent from '@weareroam/cake-ui-v1/MenuItem'
import PropTypes from 'prop-types'
import { toClass } from 'recompose'

export const StyledMenuItem = styled(MenuItemComponent)`
  && {
    color: ${({ theme, color }) => theme.palette[color].main};
    text-transform: capitalize;
  }
`

export function MenuItem({
  children,
  color,
  className,
  value,
  onClick,
  dataQa,
}) {
  return (
    <StyledMenuItem
      color={color}
      className={className}
      onClick={onClick}
      value={value}
      data-qa={dataQa}
    >
      {children}
    </StyledMenuItem>
  )
}

MenuItem.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'quinary',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.any,
  dataQa: PropTypes.string,
}

MenuItem.defaultProps = {
  color: 'quinary',
}

// needs to be converted to a class so it can have a ref
export default toClass(MenuItem)
