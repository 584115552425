export const API_YEAR_MONTH_FORMAT = 'YYYY-MM'
export const MONTH_FILTER_FORMAT = 'MMMM YYYY'
export const JOURNAL_TASK_DATE = 'DD/MM/YY'
export const JOURNAL_TASK_TIME = 'h:mm:ss Z'
export const JOURNAL_ENTRY_DATE_TIME = 'Do MMMM YYYY, h:mma'
export const JOURNAL_ENTRY_TIMEZONE = 'Z'
export const MONTH_FORMAT = 'MMMM'
export const MEMBERS_JOINED_DATE = 'DD / MM / YY'
export const MESSAGE_DATE = 'DD / MM / YY'
export const MESSAGE_TIME = 'h:mm:ss Z'

export const LAUNCH_DATE = new Date(2019, 0)
