import { format } from 'date-fns'

export const LOCAL_TIME_FORMAT = 'YYYY-MM-DD'

export const getCurrentDate = () => {
  return new Date().toISOString()
}

export const formatDate = (date, formatType) => {
  return format(date, formatType)
}

export default getCurrentDate
