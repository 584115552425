import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import InfoIcon from '@weareroam/cake-ui-v1-icons/InfoRounded'
import Box from 'components/molecules/Box'
import TooltipHover from 'components/molecules/TooltipHover'
import WellbeingLineChart from 'components/organisms/WellbeingLineChart'
import WellbeingBarChart from 'components/organisms/WellbeingBarChart'
import DropdownButton from 'components/molecules/DropdownButton'
import MenuItem from 'components/molecules/MenuItem'
import { format } from 'date-fns'
import { MONTH_FILTER_FORMAT } from 'constants/date'
import {
  MOOD_OVERALL,
  MOOD_CHEERFUL,
  MOOD_CALM,
  MOOD_ACTIVE,
  MOOD_RESTED,
  MOOD_INTERESTED,
} from 'constants/form'

export const GraphBoxTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
  }
`
export const DateText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const Graphs = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const GraphBoxContent = styled.div`
  display: flex;
`

export const LeftGraphBox = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  width: 50%;
  padding: ${({ theme }) => theme.spacing.lg}px;
`
export const LeftText = styled.div`
  display: flex;
  align-items: center;
`
export const RightGraphBox = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.spacing.lg}px;
`

export const GraphText = styled(Typography)`
  && {
    display: inline;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const MoodText = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledLineChart = styled(WellbeingLineChart)`
  margin-top: ${({ theme }) => theme.spacing.xl}px;
  margin-left: -${({ theme }) => theme.spacing.lg}px;
`

export const StyledBarChart = styled(WellbeingBarChart)`
  margin-top: ${({ theme }) => theme.spacing.xl}px;
  margin-left: -${({ theme }) => theme.spacing.lg}px;
`

export const StyledMoodSelect = styled(DropdownButton)`
  margin: 0 ${({ theme }) => theme.spacing.sm}px;
`

export function WellbeingCharts({
  lineChart,
  barChart,
  filteredMonth,
  mood,
  warning,
  onChangeMood,
  title,
}) {
  return (
    <Graphs
      headerContent={
        <GraphBoxTitle variant="h4" component="div">
          {title} wellbeing&nbsp;
          <DateText variant="h4" component="span">
            for {format(filteredMonth, MONTH_FILTER_FORMAT)}
          </DateText>
          &nbsp;
          {warning && (
            <TooltipHover description={warning} color={'warning'}>
              <InfoIcon />
            </TooltipHover>
          )}
        </GraphBoxTitle>
      }
    >
      <GraphBoxContent>
        <LeftGraphBox>
          <LeftText>
            <GraphText variant="body1">Average</GraphText>
            <StyledMoodSelect
              name="mood"
              value={mood}
              onChange={onChangeMood}
              size="small"
              multiple={false}
              variant="outlined"
            >
              <MenuItem color="primary" value={MOOD_OVERALL}>
                {MOOD_OVERALL}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_CHEERFUL}>
                {MOOD_CHEERFUL}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_CALM}>
                {MOOD_CALM}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_ACTIVE}>
                {MOOD_ACTIVE}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_RESTED}>
                {MOOD_RESTED}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_INTERESTED}>
                {MOOD_INTERESTED}
              </MenuItem>
            </StyledMoodSelect>
            <GraphText variant="body1">
              mood in {format(filteredMonth, 'MMMM')}
            </GraphText>
          </LeftText>

          {barChart && barChart.length > 0 && (
            <StyledBarChart
              xAxisLabel="Wellbeing score"
              yAxisLabel="People"
              data={barChart.map((point, index) => ({
                name: index + 1,
                value: point.totalUsers,
              }))}
            />
          )}
        </LeftGraphBox>
        <RightGraphBox>
          <GraphText variant="body1">
            Monthly average <MoodText>{mood}</MoodText> mood over last 6 months
          </GraphText>
          {lineChart && lineChart.length > 0 && (
            <StyledLineChart
              xAxisLabel="Month"
              yAxisLabel="Wellbeing score"
              data={lineChart.map((point) => ({
                name: format(point.referToMonth || point.relativedate, 'MMM'),
                value:
                  point.averageMood !== undefined
                    ? point.averageMood
                    : point.averagemood,
                members:
                  point.totalUsers !== undefined
                    ? point.totalUsers
                    : point.totalusers,
              }))}
            />
          )}
        </RightGraphBox>
      </GraphBoxContent>
    </Graphs>
  )
}

WellbeingCharts.propTypes = {
  lineChart: PropTypes.arrayOf(PropTypes.object),
  barChart: PropTypes.arrayOf(PropTypes.object),
  mood: PropTypes.string,
  error: PropTypes.object,
  warning: PropTypes.string,
  onChangeMood: PropTypes.func,
  filteredMonth: PropTypes.string,
  title: PropTypes.string,
}

WellbeingCharts.defaultProps = {
  title: 'Your',
}

export default WellbeingCharts
