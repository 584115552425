import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ContentContainer from 'components/atoms/ContentContainer'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import GroupInnerLayout from 'layouts/GroupInnerLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Booster from 'components/molecules/Booster'
import BoostersForm from 'components/organisms/BoostersForm'

export const StyledLayout = styled(AdminDashboardLayout)``

export const OrganisationBoostersTitle = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.accents.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`
export const OrganisationBoosters = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl}px;
`

export const BoosterCategory = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.accents.main};
`

export const StyledBooster = styled(Booster)`
  padding: ${({ theme }) => theme.spacing.md}px 0;
`

export function Boosters({
  onUpdateBoosters,
  groupBoosterCategories,
  organisationBoosterCategories,
  updateBoostersProgress,
  getBoostersProgress,
  hasEditAccess,
}) {
  return (
    <StyledLayout noPadding>
      <GroupInnerLayout>
        <ContentContainer>
          <BoostersForm
            onSubmit={onUpdateBoosters}
            title="Group Boosters"
            disableEditting={!hasEditAccess}
            boosterCategories={groupBoosterCategories}
            updateProgress={updateBoostersProgress}
            progress={getBoostersProgress}
          />

          <OrganisationBoosters>
            <OrganisationBoostersTitle variant="h2">
              Organisation-wide boosters
            </OrganisationBoostersTitle>
            {organisationBoosterCategories.map((boosterCategory) => {
              return (
                <BoosterCategory key={boosterCategory.uuid}>
                  <Typography variant="h4">
                    {boosterCategory.categoryName}
                  </Typography>
                  {boosterCategory.boosters.map((booster) => {
                    return (
                      <StyledBooster
                        key={booster.uuid}
                        heading={booster.heading}
                        subheading={booster.subheading}
                        contactNumber={booster.contact}
                        extension={booster.extension}
                        email={booster.email}
                        website={booster.website}
                        description={booster.description}
                      />
                    )
                  })}
                </BoosterCategory>
              )
            })}
          </OrganisationBoosters>
        </ContentContainer>
      </GroupInnerLayout>
    </StyledLayout>
  )
}

Boosters.propTypes = {
  onUpdateBoosters: PropTypes.func,
  groupBoosterCategories: PropTypes.arrayOf(PropTypes.object),
  organisationBoosterCategories: PropTypes.arrayOf(PropTypes.object),
  getBoostersProgress: PropTypes.object,
  hasEditAccess: PropTypes.bool,
  updateBoostersProgress: PropTypes.object,
}

Boosters.defaultProps = {
  getBoostersProgress: {},
  organisationBoosterCategories: [],
}

export default Boosters
