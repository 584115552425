import { createSelector } from 'reselect'

import { getCountriesList } from '../countries/selectors'

export const getOrgCountriesList = createSelector(
  (state) => state.orgCountries.entities,
  (orgCountries) =>
    Object.values(orgCountries).sort(
      (a, b) => a.preferenceOrder - b.preferenceOrder
    )
)

// Only list countries that are not selected yet
export const getAvailableCountriesList = createSelector(
  getCountriesList,
  getOrgCountriesList,
  (countries, orgCountries) =>
    Object.values(countries).filter(
      (c) => !orgCountries.some((oC) => oC.country.isoCode === c.isoCode)
    )
)
