import styled from 'styled-components'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel'
import TextField from '@weareroam/cake-ui-v1/TextField'

export const StyledTextArea = styled(TextField)`
  textarea {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.tertiary.main};
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }

  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.lg}px 0;
  text-align: left;
`

const checkboxLabelClass = 'checkbox-label-class'
export const StyledCheckboxControl = styled(FormControlLabel).attrs({
  classes: {
    label: checkboxLabelClass,
  },
})`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  width: 100%;
  font-weight: inherit;

  .${checkboxLabelClass} {
    font-weight: inherit;
  }
`

export const AllGroups = styled.div`
  font-weight: ${({ theme }) => theme.weights.bold};
`

export const StyledGroups = styled.div`
  max-height: 225px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const Actions = styled.footer`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 140px;
  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`
export const StyledForm = styled.form`
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.lg}px;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export const GroupSelectionInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.tertiary.light};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing.md}px;
  text-align: center;
`

export const GroupSelectionError = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.error.light};
  color: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing.md}px;
  text-align: center;
`

// this works for our current restricted layout sizing, will get a bit funky if we go truely responsive
export const CharCount = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: right;
  position: absolute;
  top: 60%;
  right: 3%;
`
