export const ENV = process.env.REACT_APP_ENV

export const APP_SALT = process.env.REACT_APP_SALT
export const SAUCE_API_URL = process.env.REACT_APP_SAUCE_URL
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const BRANCH_KEY = process.env.REACT_APP_BRANCH_KEY
export const FEATURE_KEY = process.env.REACT_APP_FEATURE_KEY
export const SESSION_KEY = 'session'
export const PASSWORD_MIN_LENGTH = 8
export const REFRESH_TOKEN_KEY = 'refresh_token'
export const ROLE_KEY = 'user-role'
