import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from 'components/molecules/Box'
import ActivityResponseTime from 'components/organisms/ActivityResponseTime'
import ActivityBarGraph from 'components/organisms/ActivityBarGraph'
import { format } from 'date-fns'
import { MONTH_FILTER_FORMAT } from 'constants/date'

import Typography from '@weareroam/cake-ui-v1/Typography'

export const Graphs = styled(Box)``

export const GraphTitle = styled.div`
  display: flex;
  align-items: center;
`
export const GraphBoxContent = styled.div`
  display: flex;
`
export const Left = styled.div`
  flex-basis: 75%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing.lg}px;
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`
export const Right = styled.div`
  flex-basis: 25%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing.lg}px;
`
export const SectionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-left: ${({ theme, leftmargin }) =>
      leftmargin ? theme.spacing.xl + theme.spacing.unit : 0}px;
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`
export const SectionValue = styled(Typography)``
export const MonthLabel = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export function ActivityResponses({ data, month }) {
  return (
    <Graphs
      headerContent={
        <GraphTitle>
          <Typography variant="h4">
            Responses
            <MonthLabel> in {format(month, MONTH_FILTER_FORMAT)}</MonthLabel>
          </Typography>
        </GraphTitle>
      }
    >
      <GraphBoxContent>
        <Left width="75%">
          <SectionLabel leftmargin="true">Daily Responses</SectionLabel>
          <ActivityBarGraph
            data={data.dailyChart.rows.map((point) => ({
              name: point.label,
              value: point.value,
            }))}
            month={month}
          />
        </Left>
        <Right>
          <SectionLabel>
            Time taken to complete
            <br />
            daily response
          </SectionLabel>
          <ActivityResponseTime
            data={data.time.rows.map((point) => ({
              name: point.label,
              value: point.value,
            }))}
          />
        </Right>
      </GraphBoxContent>
    </Graphs>
  )
}

ActivityResponses.propTypes = {
  data: PropTypes.shape({
    dailyChart: PropTypes.object,
    time: PropTypes.object,
  }),
  month: PropTypes.string,
}

ActivityResponses.defaultProps = {
  data: {
    dailyChart: {
      rows: [],
    },
    time: {
      rows: [],
    },
  },
}

export default ActivityResponses
