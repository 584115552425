import uuid from 'uuid/v4'
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS'

export function addToast({ message, type, id = uuid() }) {
  return {
    type: ADD_TOAST,
    data: {
      message,
      type,
      id,
    },
  }
}

export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    data: {
      id,
    },
  }
}

export function removeAllToasts() {
  return {
    type: REMOVE_ALL_TOASTS,
  }
}
