import { useCallback, useState, useEffect, useRef } from 'react'
import { JOURNAL_TYPE_ALL } from 'constants/form'
import { PAGINATION_LIMIT } from 'constants/pagination'
import useDebouncedCallback from 'use-debounce/lib/callback'

export default function useJournals({
  filters,
  sortOptions,
  organisation,
  onDeleteJournal,
  onApproveJournals,
  onEditJournal,
  onRevertJournal,
  onFetchJournals,
  journalModalUuid,
}) {
  const [journalType, setJournalType] = useState(JOURNAL_TYPE_ALL)
  const [searchFilter, setSearchFilter] = useState('')
  const [pagination, setPagination] = useState({
    start: 0,
    count: 0,
    limit: PAGINATION_LIMIT,
  })
  const [journalsMarkedToApprove, setJournalsMarkedToApprove] = useState([])
  const prevSearch = useRef(null)

  const handleEditSubmit = useCallback(
    (form) => {
      onEditJournal({
        ...form,
        journalUuid: journalModalUuid,
      })
    },
    [journalModalUuid]
  )

  const handleDeleteJournal = useCallback(
    async () => {
      await onDeleteJournal(journalModalUuid)
      await fetchCallback()
    },
    [journalModalUuid]
  )

  const handleRevertJournal = useCallback(
    () => {
      onRevertJournal(journalModalUuid)
    },
    [journalModalUuid]
  )

  const handleApproveJournals = useCallback(
    async () => {
      await onApproveJournals(journalsMarkedToApprove)
      setJournalsMarkedToApprove([])
    },
    [journalsMarkedToApprove]
  )

  const handleChangeJournalType = useCallback((e) => {
    setJournalType(e.target.value)
  }, [])

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
    prevSearch.current = e.target.value
  }, [])

  const handleChangeJournalsMarkedToApprove = useCallback(
    (e) => {
      const clickedUuid = e.target.value

      if (journalsMarkedToApprove.includes(clickedUuid)) {
        // remove the uuid from the array
        setJournalsMarkedToApprove(
          journalsMarkedToApprove.filter((uuid) => uuid !== clickedUuid)
        )
      } else {
        // append the uuid to the array
        setJournalsMarkedToApprove([...journalsMarkedToApprove, clickedUuid])
      }
    },
    [journalsMarkedToApprove]
  )

  const handleChangePagination = useCallback(
    (newPageIndex, pagination) => {
      setPagination({
        ...pagination,
        start: newPageIndex * PAGINATION_LIMIT,
      })
    },
    [pagination.start]
  )

  // Search for journals and refetch when filters or pagination changes
  const fetchCallback = async () => {
    const response = await onFetchJournals(
      {
        start: pagination.start,
        limit: PAGINATION_LIMIT,
        searchFilter,
        sortOptions,
        journalType,
      },
      filters,
      organisation
    )
    if (response) {
      setPagination(response)
    }
  }

  const debouncedFetch = useDebouncedCallback(fetchCallback, 350)

  // Use debounced fetch for search filter change.
  useEffect(
    () => {
      // Don't run this on initial mount.
      if (prevSearch.current !== null) {
        debouncedFetch()
      }
    },
    [searchFilter]
  )

  // Don't debounce the search on button clicks
  useEffect(
    () => {
      fetchCallback()
    },
    [pagination.start, journalType, filters.value]
  )

  return {
    handleEditSubmit,
    handleDeleteJournal,
    handleApproveJournals,
    journalType,
    searchFilter,
    sortOptions,
    handleChangeJournalType,
    handleChangeSearchFilter,
    handleRevertJournal,
    pagination,
    handleChangePagination,
    journalsMarkedToApprove,
    handleChangeJournalsMarkedToApprove,
  }
}
