import React, { useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import IconButton from '@weareroam/cake-ui-v1/IconButton'
import CustomToolTip from '@weareroam/cake-ui-v1/TooltipWithCaret'
import TooltipItem from 'components/molecules/TooltipItem'
import PropTypes from 'prop-types'

const pulse = keyframes`
from {
    box-shadow: 0 0 0 0px rgba(232, 76, 61, 0);
  }
to {
    box-shadow: 0 0 0 5px rgba(232, 76, 61, 0.75);
  }
`

const animation = () => css`
  ${pulse} 1.25s 6 alternate;
`

const StyledButton = styled(IconButton)`
  && {
    padding: 0;
    margin-left: ${({ theme }) => theme.spacing.sm}px;
    animation: ${({ pulse }) => (pulse ? animation : 'none')};
    color: ${(props) => handleColor(props)};
    &:hover {
      animation: none;
    }
  }
`

const StyledCustomToolTip = styled(CustomToolTip)`
  position: absolute;
  bottom: 50px;
  z-index: 999;
  width: 350px;
  text-align: left;
  padding: ${({ theme }) => theme.spacing.sm}px;
  border-radius: ${({ theme }) => theme.spacing.xs}px;
  background-color: ${(props) => handleColor(props)};
  color: ${({ theme }) => theme.palette.text.secondary};
  border-color: ${(props) => handleColor(props)};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`

const StyledTooltipItem = styled(TooltipItem)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => handleColor(props)};
    border-radius: ${({ theme }) => theme.spacing.xs}px;
    & > * {
      display: block;
    }
    & span:nth-of-type(2) {
      margin-top: ${({ theme }) => theme.spacing.xs}px;
      font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    }
  }
`

export function handleColor({ theme, color }) {
  switch (color) {
    case 'error':
      return 'red'
    case 'primary':
      return theme.palette.primary.main
    case 'warning':
      return theme.palette.warning.main
    default:
      return 'grey'
  }
}

// we could even accept props for type={'error'|'info'|'success'} and this would indicate some styling (red, grey, green, primary, etc)
export function TooltipHover({
  title,
  description,
  children,
  placement,
  pulse,
  color,
}) {
  const [isIconToggled, toggleIcon] = useState(false)
  return (
    <StyledButton
      type="button"
      variant="text"
      pulse={pulse}
      color={color}
      onMouseEnter={() => toggleIcon(!isIconToggled)}
      onMouseLeave={() => toggleIcon(!isIconToggled)}
    >
      {children}
      {isIconToggled && (
        <StyledCustomToolTip placement={placement} title={title} color={color}>
          <StyledTooltipItem value={`${description}`} color={color} />
        </StyledCustomToolTip>
      )}
    </StyledButton>
  )
}

TooltipHover.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.string,
  pulse: PropTypes.bool,
  color: PropTypes.string,
}

TooltipHover.defaultProps = {
  title: '',
  placement: 'top',
  pulse: false,
}

export default TooltipHover
