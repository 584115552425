import initialState from 'reducers/initialState'
import {
  API_GET_GROUP_SIGNUP_LINK_SUCCESS,
  API_CREATE_GROUP_SIGNUP_LINK_SUCCESS,
  API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS,
} from './actions'

export default function groupSignUpLinkReducer(
  state = initialState.groupSignUpLinks,
  action
) {
  switch (action.type) {
    case API_GET_GROUP_SIGNUP_LINK_SUCCESS:
    case API_CREATE_GROUP_SIGNUP_LINK_SUCCESS:
      return {
        ...state,
        [action.signUpLink.groupUuid]: action.signUpLink,
      }
    case API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS:
      return initialState.groupSignUpLinks
    default:
      return state
  }
}
