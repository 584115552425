import React from 'react'
import NavItem from 'components/molecules/NavItem'
import PropTypes from 'prop-types'
import {
  CHNNL_DASHBOARD_WELLBEING,
  CHNNL_DASHBOARD_JOURNALS,
  CHNNL_DASHBOARD_SPEAKUP,
  CHNNL_DASHBOARD_CHATBOT,
  CHNNL_DASHBOARD_SETTINGS,
} from 'constants/routes'
import WellbeingIcon from 'components/atoms/Icons/Wellbeing'
import JournalsIcon from 'components/atoms/Icons/Journals'
import SpeakUpIcon from 'components/atoms/Icons/SpeakUp'
import DeltaIcon from 'components/atoms/Icons/Delta'
import SettingsIcon from 'components/atoms/Icons/Settings'
import styled from 'styled-components'
import { useFeature } from '@optimizely/react-sdk'

export const StyledSidebar = styled.aside`
  width: 60px;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const StyledNav = styled.nav``

export const StyledNavItem = styled(NavItem)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export function ChnnlAdminSidebar({ className }) {
  const [speakUpEnabled] = useFeature('dashboard_speakup')

  return (
    <StyledSidebar className={className}>
      <StyledNav>
        <StyledNavItem
          to={CHNNL_DASHBOARD_WELLBEING}
          icon={<WellbeingIcon />}
          direction="vertical"
        />
        <StyledNavItem
          to={CHNNL_DASHBOARD_JOURNALS}
          icon={<JournalsIcon />}
          direction="vertical"
        />
        {speakUpEnabled && (
          <StyledNavItem
            to={CHNNL_DASHBOARD_SPEAKUP}
            icon={<SpeakUpIcon />}
            direction="vertical"
          />
        )}
        <StyledNavItem
          to={CHNNL_DASHBOARD_CHATBOT}
          icon={<DeltaIcon height={30} />}
          direction="vertical"
        />
        <StyledNavItem
          to={CHNNL_DASHBOARD_SETTINGS}
          icon={<SettingsIcon />}
          direction="vertical"
        />
      </StyledNav>
    </StyledSidebar>
  )
}

ChnnlAdminSidebar.propTypes = {
  className: PropTypes.string,
}

export default ChnnlAdminSidebar
