import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Sidebar from 'components/molecules/Sidebar'
import NavItem from 'components/molecules/NavItem'
import {
  ORG_DASHBOARD_SETTINGS_ADMINS,
  ORG_DASHBOARD_SETTINGS_GENERAL,
  ORG_DASHBOARD_SETTINGS_PROFILE,
  // ORG_DASHBOARD_SETTINGS_NOTIFICATIONS,
} from 'constants/routes'
import { replaceRouteParams } from 'utils/routes'
import authorisedRoles from 'components/hoc/authorisedRoles'
import {
  ROLE_SUPER_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
} from 'constants/form'

export const StyledLayout = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
`

export const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.lg}px;
  overflow: hidden;
  flex-grow: 1;
`

// Don't allow super admins to access the Org admin profile page.
// Super admins have their own page to update their profile
const ProtectedProfileNavItem = authorisedRoles([
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
])(NavItem)

// Don't allow group admins to access the admins page
const ProtectedNavItem = authorisedRoles([
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
])(NavItem)

export function OrgSettingsLayout({ children, orgUuid }) {
  return (
    <StyledLayout>
      <Sidebar>
        <ProtectedNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_GENERAL, { orgUuid })}
          text="General"
        />
        <ProtectedNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_ADMINS, { orgUuid })}
          text="Admins"
        />
        <ProtectedProfileNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_PROFILE, { orgUuid })}
          text="Profile"
        />
        {/* <ProtectedProfileNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_NOTIFICATIONS, {
            orgUuid,
          })}
          text="Notifications"
        /> */}
      </Sidebar>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  )
}

OrgSettingsLayout.propTypes = {
  children: PropTypes.node,
  orgUuid: PropTypes.string,
}

export default OrgSettingsLayout
