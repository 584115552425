import { adminListSchema } from './schema'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { normalize } from 'normalizr'

import {
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
} from 'constants/form'

// Actions
export const API_GET_GROUP_ADMINS_REQUEST = 'API_GET_GROUP_ADMINS_REQUEST'
export const API_GET_GROUP_ADMINS_SUCCESS = 'API_GET_GROUP_ADMINS_SUCCESS'
export const API_GET_GROUP_ADMINS_ERROR = 'API_GET_GROUP_ADMINS_ERROR'

export const API_GET_ORG_ADMINS_REQUEST = 'API_GET_ORG_ADMINS_REQUEST'
export const API_GET_ORG_ADMINS_SUCCESS = 'API_GET_ORG_ADMINS_SUCCESS'
export const API_GET_ORG_ADMINS_ERROR = 'API_GET_ORG_ADMINS_ERROR'

export const API_INVITE_ORG_ADMIN_REQUEST = 'API_INVITE_ORG_ADMIN_REQUEST'
export const API_INVITE_ORG_ADMIN_SUCCESS = 'API_INVITE_ORG_ADMIN_SUCCESS'
export const API_INVITE_ORG_ADMIN_ERROR = 'API_INVITE_ORG_ADMIN_ERROR'

export const API_INVITE_GROUP_ADMIN_TO_GROUPS_REQUEST =
  'API_INVITE_GROUP_ADMIN_TO_GROUPS_REQUEST'
export const API_INVITE_GROUP_ADMIN_TO_GROUPS_SUCCESS =
  'API_INVITE_GROUP_ADMIN_TO_GROUPS_SUCCESS'
export const API_INVITE_GROUP_ADMIN_TO_GROUPS_ERROR =
  'API_INVITE_GROUP_ADMIN_TO_GROUPS_ERROR'

export const API_INVITE_GROUP_ADMINS_TO_GROUP_REQUEST =
  'API_INVITE_GROUP_ADMINS_TO_GROUP_REQUEST'
export const API_INVITE_GROUP_ADMINS_TO_GROUP_SUCCESS =
  'API_INVITE_GROUP_ADMINS_TO_GROUP_SUCCESS'
export const API_INVITE_GROUP_ADMINS_TO_GROUP_ERROR =
  'API_INVITE_GROUP_ADMINS_TO_GROUP_ERROR'

export const API_CHANGE_ADMIN_ROLE_REQUEST = 'API_CHANGE_ADMIN_ROLE_REQUEST'
export const API_CHANGE_ADMIN_ROLE_SUCCESS = 'API_CHANGE_ADMIN_ROLE_SUCCESS'
export const API_CHANGE_ADMIN_ROLE_ERROR = 'API_CHANGE_ADMIN_ROLE_ERROR'

export const API_UPDATE_ADMIN_REQUEST = 'API_UPDATE_ADMIN_REQUEST'
export const API_UPDATE_ADMIN_SUCCESS = 'API_UPDATE_ADMIN_SUCCESS'
export const API_UPDATE_ADMIN_ERROR = 'API_UPDATE_ADMIN_ERROR'

export const API_UPDATE_GROUP_ADMIN_REQUEST = 'API_UPDATE_GROUP_ADMIN_REQUEST'
export const API_UPDATE_GROUP_ADMIN_SUCCESS = 'API_UPDATE_GROUP_ADMIN_SUCCESS'
export const API_UPDATE_GROUP_ADMIN_ERROR = 'API_UPDATE_GROUP_ADMIN_ERROR'

export const API_REMOVE_GROUP_ADMIN_REQUEST = 'API_REMOVE_GROUP_ADMIN_REQUEST'
export const API_REMOVE_GROUP_ADMIN_SUCCESS = 'API_REMOVE_GROUP_ADMIN_SUCCESS'
export const API_REMOVE_GROUP_ADMIN_ERROR = 'API_REMOVE_GROUP_ADMIN_ERROR'

export const API_RESEND_INVITATION_REQUEST = 'API_RESEND_INVITATION_REQUEST'
export const API_RESEND_INVITATION_SUCCESS = 'API_RESEND_INVITATION_SUCCESS'
export const API_RESEND_INVITATION_ERROR = 'API_RESEND_INVITATION_ERROR'

export const API_DELETE_ADMIN_REQUEST = 'API_DELETE_ADMIN_REQUEST'
export const API_DELETE_ADMIN_SUCCESS = 'API_DELETE_ADMIN_SUCCESS'
export const API_DELETE_ADMIN_ERROR = 'API_DELETE_ADMIN_ERROR'

// Action creators
export function getGroupAdmins({ orgUuid, groupUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUP_ADMINS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/admin`
      )
      const data = normalize(response.data.data, adminListSchema)

      dispatch({
        type: API_GET_GROUP_ADMINS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_GROUP_ADMINS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the group admins.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getOrgAdmins(uuid) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ORG_ADMINS_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${uuid}/admin`
      )
      const data = normalize(response.data.data, adminListSchema)

      dispatch({
        type: API_GET_ORG_ADMINS_SUCCESS,
        data,
      })
    } catch (error) {
      dispatch({ type: API_GET_ORG_ADMINS_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error fetching the organisation admins.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function inviteGroupAdminToGroups({ email, canEdit, groups, orgUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_INVITE_GROUP_ADMIN_TO_GROUPS_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/admin`,
        {
          email,
          editRights: canEdit,
          groups,
        }
      )

      const data = normalize(response.data.data, adminListSchema)

      dispatch({ type: API_INVITE_GROUP_ADMIN_TO_GROUPS_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The invitation was successfully sent!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_INVITE_GROUP_ADMIN_TO_GROUPS_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error sending the invitation. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function inviteGroupAdminsToGroup({
  orgUuid,
  groupUuid,
  emails,
  userUuids,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_INVITE_GROUP_ADMINS_TO_GROUP_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/admin`,
        {
          email: emails,
          userUuid: userUuids,
        }
      )

      const data = normalize(response.data.data, adminListSchema)

      dispatch({ type: API_INVITE_GROUP_ADMINS_TO_GROUP_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The invitation was successfully sent!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_INVITE_GROUP_ADMINS_TO_GROUP_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error sending the invitation. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function inviteOrgAdmin({ email, orgUuid, canEdit }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_INVITE_ORG_ADMIN_REQUEST })

    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/admin`,
        {
          email,
          editRights: canEdit,
        }
      )

      const data = normalize(response.data.data, adminListSchema)

      dispatch({ type: API_INVITE_ORG_ADMIN_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The invitation was successfully sent!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_INVITE_ORG_ADMIN_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error sending the invitation. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function resendAdminInvitation({ orgUuid, uuid, isGroupAdmin }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESEND_INVITATION_REQUEST })

    try {
      await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/${
          isGroupAdmin ? 'group/admin' : 'admin'
        }/${uuid}/resend`
      )
      dispatch({ type: API_RESEND_INVITATION_SUCCESS })
      dispatch(
        addToast({
          message: 'An invitation was re-sent to this admin!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_RESEND_INVITATION_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error re-sending this invitation. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function changeAdminRole({
  orgUuid,
  adminUuid,
  canEdit,
  assignedGroups,
  role,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_CHANGE_ADMIN_ROLE_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/organisation/${orgUuid}/admin/change-admin-role`,
        {
          userUuid: adminUuid,
          editRights: canEdit,
          role,
          // Assigned groups is optional - used when the role is a groupAdmin
          ...(assignedGroups ? { assignedGroups } : {}),
        }
      )
      const data = normalize(response.data.data, adminListSchema)

      dispatch({
        type: API_CHANGE_ADMIN_ROLE_SUCCESS,
        data,
      })

      dispatch(
        addToast({
          message: 'The admin has been successfully updated',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_CHANGE_ADMIN_ROLE_ERROR })
      dispatch(
        addToast({
          message: 'There was an error updating the admin. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function updateAdmin({
  role,
  orgUuid,
  adminUuid,
  canEdit,
  assignedGroups,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_ADMIN_REQUEST })

    const adminUrl = () => {
      switch (role) {
        case ROLE_GROUP_ADMIN:
          return `organisation/${orgUuid}/group/admin/${adminUuid}`
        case ROLE_ORG_ADMIN:
        case ROLE_ORG_OWNER:
        default:
          return `organisation/${orgUuid}/admin/${adminUuid}`
      }
    }

    try {
      const response = await axios.put(`${SAUCE_API_URL}/${adminUrl()}`, {
        editRights: canEdit,
        // Assigned groups is optional - used when the role is a groupAdmin
        ...(assignedGroups ? { assignedGroups } : {}),
      })
      const data = normalize(response.data.data, adminListSchema)

      dispatch({ type: API_UPDATE_ADMIN_SUCCESS, data })
      dispatch(
        addToast({
          message: 'The admin has been successfully updated',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_UPDATE_ADMIN_ERROR })
      dispatch(
        addToast({
          message: 'There was an error updating the admin. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function removeAdminFromGroup({
  orgUuid,
  groupUuid,
  adminUuid,
  userUuid,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_REMOVE_GROUP_ADMIN_REQUEST })

    try {
      await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/admin/${adminUuid}`
      )

      dispatch({
        type: API_REMOVE_GROUP_ADMIN_SUCCESS,
        data: {
          uuid: userUuid,
          groupUuid,
        },
      })
      dispatch(
        addToast({
          message: 'Group admin was successfully removed',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_REMOVE_GROUP_ADMIN_ERROR })
      dispatch(
        addToast({
          message:
            'There was an error removing the group admin. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function deleteGroupAdmin({ uuid, orgUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_ADMIN_REQUEST })

    try {
      const response = await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/admin/${uuid}`
      )
      dispatch({ type: API_DELETE_ADMIN_SUCCESS, response })
      dispatch(
        addToast({
          message: 'Admin was deleted!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_DELETE_ADMIN_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error deleting this group admin. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function deleteOrgAdmin({ uuid, orgUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_DELETE_ADMIN_REQUEST })

    try {
      const response = await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/admin/${uuid}`
      )
      dispatch({ type: API_DELETE_ADMIN_SUCCESS, response })
      dispatch(
        addToast({
          message: 'Admin was deleted!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({ type: API_DELETE_ADMIN_ERROR, error })
      dispatch(
        addToast({
          message: 'There was an error deleting this admin. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
