import React from 'react'

export const FullChnnlLogo = (props) => (
  <svg width="66" height="52" viewBox="0 0 66 52" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path fill="#AAD5B7" d="M0 3h65.7V0H0z" />
      <path fill="#2985FC" d="M0 52h65.7v-3H0z" />
      <path
        fill="#828797"
        d="M65.9 15.5l-.7 1-.7-1h-.1V17h.1v-1l.6.7h.1l.6-.8V17h.2v-1.5h-.1zm-2.9 0v.2h.5V17h.2v-1.3h.5v-.2H63z"
      />
      <g fill="#828797">
        <path d="M0 26.5c0-3.7 2.8-6.3 6.7-6.3 2.5 0 4.8 1.1 5.9 3.3L10 24.8c-.7-1.2-1.9-2-3.3-2A3.6 3.6 0 0 0 3 26.5c0 2 1.6 3.7 3.7 3.7a4 4 0 0 0 3.4-2l2.4 1.5c-1 1.9-3.3 3.2-5.8 3.2-4 0-6.7-2.7-6.7-6.4M14.4 15.6h3V22c.7-1 2.1-1.9 4-1.9 2.5 0 4.9 1.7 4.9 5v7.5h-3V26c0-2.1-1.2-3.2-2.8-3.2-1.8 0-3 1.3-3 3.2v6.6h-3v-17zM29 20.5h3v1.7c.6-1.2 2-2 4-2 2.5 0 4.9 1.6 4.9 5v7.4h-3V26c0-2-1.2-3.2-2.8-3.2-1.8 0-3 1.3-3 3.2v6.6h-3V20.5zM43.6 20.5h3v1.7c.6-1.2 2-2 4-2 2.5 0 4.9 1.6 4.9 5v7.4h-3V26c0-2-1.2-3.2-2.8-3.2-1.8 0-3 1.3-3 3.2v6.6h-3.1V20.5zM63.2 30.1c-1.2-.3-2-1.3-2-3V15.5h-3v12.2c0 3.5 2.3 5 4.9 5h.1v-2.7z" />
      </g>
    </g>
  </svg>
)
