import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowUpward from 'components/atoms/Icons/ArrowUp'
import { SORT_DIRECTION_ASC, SORT_DIRECTION_DESC } from 'constants/form'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledSortHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`

export const HeadingLabel = styled(Typography)`
  && {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledIcon = styled(ArrowUpward)`
  && {
    transition: transform 0.4s ease;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    transform: ${({ sortDirection }) =>
      sortDirection === SORT_DIRECTION_DESC
        ? 'rotate(180deg)'
        : 'rotate(0deg)'};
  }
`

export function SortHeading({ children, onClick, className }) {
  return (
    <StyledSortHeading onClick={onClick} className={className}>
      <HeadingLabel variant="body1">{children}</HeadingLabel>
    </StyledSortHeading>
  )
}

SortHeading.propTypes = {
  sortDirection: PropTypes.oneOf([SORT_DIRECTION_ASC, SORT_DIRECTION_DESC]),
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default SortHeading
