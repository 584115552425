import { useCallback, useState, useEffect } from 'react'
import { API_YEAR_MONTH_FORMAT, LAUNCH_DATE } from 'constants/date'
import useMonthsSinceDate from 'hooks/useMonthsSinceDate'
import { ATTRIBUTE_GENDER, MOOD_OVERALL } from 'constants/form'
import { format } from 'date-fns'

/**
 * This custom hook is used for the wellbeing pages on the chnnl organisation and group wellbeing pages.
 * This provides functionality on managing filter states and recalling the specified callbacks when the filters change
 */
export default function useWellbeing(
  {
    onFetchMood,
    onFetchMoodMonthly,
    onFetchReflectionReport,
    onFetchAttributeReport,
    filteredOrganisations,
    groupUuid,
  } = {
    filteredOrganisations: [],
  }
) {
  const [attribute, setAttribute] = useState(ATTRIBUTE_GENDER)
  const [attributeMood, setAttributeMood] = useState(MOOD_OVERALL)
  const [graphMood, setGraphMood] = useState(MOOD_OVERALL)
  const [filteredMonth, setFilteredMonth] = useState(
    format(new Date(), API_YEAR_MONTH_FORMAT)
  )
  const months = useMonthsSinceDate(LAUNCH_DATE)

  const handleChangeFilteredMonth = useCallback((e) => {
    setFilteredMonth(e.target.value)
  }, [])

  const handleSetAttribute = useCallback((e) => {
    setAttribute(e.target.value)
  }, [])

  const handleSetAttributeMood = useCallback((e) => {
    setAttributeMood(e.target.value)
  }, [])

  const handleChangeGraphMood = useCallback((e) => {
    setGraphMood(e.target.value)
  }, [])

  // for fetching charts - refetch anytime a filter changes
  useEffect(
    () => {
      onFetchMood({
        filter: graphMood,
        orgs: filteredOrganisations,
        month: filteredMonth,
        groupUuid,
      })
      onFetchMoodMonthly({
        filter: graphMood,
        orgs: filteredOrganisations,
        month: filteredMonth,
        groupUuid,
      })
    },
    [filteredOrganisations, graphMood, filteredMonth, groupUuid]
  )

  // for fetching reflection report - refetch anytime a filter changes
  useEffect(
    () => {
      onFetchReflectionReport({
        month: filteredMonth,
        orgs: filteredOrganisations,
        groupUuid,
      })
    },
    [groupUuid, filteredOrganisations, filteredMonth]
  )

  // for fetching attribute report - refetch anytime a filter changes
  useEffect(
    () => {
      onFetchAttributeReport({
        month: filteredMonth,
        orgs: filteredOrganisations,
        groupUuid,
        filter: attributeMood,
        attribute: attribute,
      })
    },
    [filteredMonth, filteredOrganisations, groupUuid, attributeMood, attribute]
  )

  return {
    handleChangeGraphMood,
    handleChangeFilteredMonth,
    handleSetAttributeMood,
    handleSetAttribute,
    attribute,
    attributeMood,
    filteredMonth,
    months,
    graphMood,
  }
}
