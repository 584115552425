import React from 'react'
import idx from 'idx'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Cell, PieChart, Tooltip, ResponsiveContainer, Pie } from 'recharts'
import Typography from '@weareroam/cake-ui-v1/Typography'
import theme from 'styles/theme'

import CustomTooltip from 'components/molecules/Tooltip'
import TooltipItem from 'components/molecules/TooltipItem'

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`
export const StyledLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`
export const StyledTooltipItem = styled(TooltipItem)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      display: block;
    }
    & span:nth-of-type(2) {
      margin-top: ${({ theme }) => theme.spacing.xs}px;
      font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    }
  }
`
export const StyledStats = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md}px;
`
export const StyledRow = styled.div`
  width: 127px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`
export const StyledBadge = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 4px;
  background-color: ${({ background }) => background};
`
export const StyledRowName = styled(Typography)`
  && {
    flex: 1;
    text-align: left;
    padding-left: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const StyledRowValue = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

// eslint-disable-next-line
const PieColors = [
  theme.palette.quaternary.xlight,
  theme.palette.quaternary.light,
  theme.palette.quaternary.main,
  theme.palette.quaternary.dark,
  theme.palette.quaternary.xdark,
]

export function ActivityResponseTime({ data }) {
  return (
    <StyledContent>
      <ResponsiveContainer width="100%" height={100}>
        <PieChart width={100} height={100}>
          <Pie
            data={data}
            dataKey="value"
            innerRadius={20}
            outerRadius={50}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={PieColors[index % PieColors.length]}
              />
            ))}
          </Pie>
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={(values) => {
              const value = idx(values, (_) => _.payload[0].value)
              const name = idx(values, (_) => _.payload[0].name)

              return (
                <CustomTooltip>
                  <StyledTooltipItem value={`${value} %`} suffix={name} />
                </CustomTooltip>
              )
            }}
          />
        </PieChart>
      </ResponsiveContainer>
      <StyledStats>
        {data.map((section, index) => {
          return (
            <StyledRow key={`${section.name}-${index}`}>
              <StyledBadge background={PieColors[index]} />
              <StyledRowName>{section.name}</StyledRowName>
              <StyledRowValue>{section.value}%</StyledRowValue>
            </StyledRow>
          )
        })}
      </StyledStats>
    </StyledContent>
  )
}

ActivityResponseTime.propTypes = {
  data: PropTypes.array,
  children: PropTypes.node,
}

ActivityResponseTime.defaultProps = {
  data: [],
}

export default ActivityResponseTime
