import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MESSAGE_DATE, MESSAGE_TIME } from 'constants/date'
import { format } from 'date-fns'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledMessage = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 #ebebeb;
  padding: ${({ theme }) => theme.spacing.md}px 0;
  display: flex;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.md}px;
  }
`

export const MessageEntry = styled.div`
  width: 60%;
`

export const MessageTo = styled.div`
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const MessageDate = styled.div`
  text-align: right;
  width: 12.5%;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const EntryText = styled(Typography)`
  && {
    display: inline;
  }
`

export const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`

export function Message({ dataQa, className, onClick, message }) {
  const handleOnClick = useCallback(
    () => {
      if (onClick && message) {
        onClick(message.uuid)
      }
    },
    [message]
  )

  return (
    <StyledMessage className={className} data-qa={dataQa}>
      <ClickArea onClick={handleOnClick} />
      <MessageEntry>
        <EntryText variant="body1" component="span">
          {message.message}
        </EntryText>
      </MessageEntry>
      <MessageTo>
        <Typography variant="body2">{message.groupName}</Typography>
      </MessageTo>
      <MessageDate>
        <Typography variant="body2">
          <strong>{format(message.createdAt, MESSAGE_DATE)}</strong>
        </Typography>
        <Typography variant="body2">
          {format(message.createdAt, MESSAGE_TIME)}
        </Typography>
      </MessageDate>
    </StyledMessage>
  )
}

Message.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
  message: PropTypes.object,
}

export default Message
