import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import SearchField from 'components/atoms/SearchField'
import MenuItem from 'components/molecules/MenuItem'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import Button from '@weareroam/cake-ui-v1/Button'
import DropdownButton from 'components/molecules/DropdownButton'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import Message from 'components/molecules/Message'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SortHeading from 'components/molecules/SortHeading'
import Modal from 'components/molecules/Modal'
import MessageDetail from 'components/organisms/MessageDetail'
import SendMessageForm from 'components/organisms/SendMessageForm'
import Badge from '@weareroam/cake-ui-v1/Badge'
import Pagination from 'components/molecules/Pagination'
import { SORT_DIRECTION_ASC } from 'constants/form'
import EmptyState from 'components/molecules/EmptyState'
import MessagingEmptyState from 'components/atoms/Icons/MessagingEmptyState'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import useMessages from 'hooks/useMessages'
import useMessageModals from 'hooks/useMessageModals'

export const StyledLayout = styled(ChnnlDashboardLayout)``
export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderLeft = styled.div``
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`
export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

export const MessagesHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const MessageHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 67.5%;
  }
  &:nth-child(2) {
    width: 17.5%;
  }
  &:nth-child(3) {
    width: 13.5%;
  }
`

export const StyledBadge = styled(Badge)``

export const StyledBadgeContainer = styled.div`
  opacity: ${({ faded }) => (faded ? 1 : 0.4)};
  cursor: ${({ faded }) => (faded ? 'pointer' : null)};
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export const MessageList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const entryAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const MessageModal = styled(Modal)`
  width: 700px;
  &.open {
    animation: ${entryAnimation} 0.3s linear forwards;
  }
`

const AllGroupsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

const SendMessageButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledMessageModal = styled(Modal)`
  width: 600px;
  && {
    max-height: 700px;
    padding-left: ${({ theme }) => theme.spacing.md}px;
    padding-right: ${({ theme }) => theme.spacing.md}px;
    padding-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const HighlightedText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  cursor: pointer;
`

export function MessagingHistory({
  getMessagesProgress,
  actions,
  groups,
  messages,
  getGroupsProgress,
}) {
  const {
    filteredEntities: filteredGroups,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(groups, 'group', !getGroupsProgress.inProgress)

  const {
    isMessageModalOpen,
    handleOpenMessageModal,
    handleCloseMessageModal,
    messageModalUuid,
    isCreateMessageModalOpen,
    handleOpenNewMessageModal,
    handleCloseNewMessageModal,
  } = useMessageModals()

  const {
    searchFilter,
    pagination,
    handleChangePagination,
    handleChangeSearchFilter,
  } = useMessages({
    filters: { key: 'groups', value: filteredGroups },
    onFetchMessages: actions.getMessages,
  })

  const messageToReview = useMemo(
    () => {
      if (!messages) {
        return null
      }

      return messages.find((message) => message.uuid === messageModalUuid)
    },
    [messages, messageModalUuid]
  )

  const handleOnSubmit = React.useCallback(
    (payload) => {
      actions.sendMessage(payload)
      handleCloseNewMessageModal()
    },
    [actions, handleCloseNewMessageModal]
  )

  return (
    <>
      <StyledMessageModal
        hasCloseButton
        open={isCreateMessageModalOpen}
        onClose={handleCloseNewMessageModal}
      >
        <SendMessageForm
          onSubmit={handleOnSubmit}
          onCancel={handleCloseNewMessageModal}
          initialValues={{
            assignedGroups: groups,
          }}
          groups={groups}
        />
      </StyledMessageModal>
      <MessageModal
        open={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        className={isMessageModalOpen ? 'open' : 'closed'}
      >
        {messageToReview && (
          <MessageDetail
            message={messageToReview}
            onCancel={handleCloseMessageModal}
          />
        )}
      </MessageModal>

      <StyledHeader>
        <HeaderLeft>
          {groups.length > 1 && (
            <DropdownButton
              name="groups"
              value={filteredGroups}
              onChange={handleChangeFilteredEntities}
              renderValue={renderFilterLabel}
            >
              <MenuItem value={'TOGGLE_ALL'}>
                <Checkbox
                  color="primary"
                  checked={filteredGroups.length === groups.length}
                />
                <AllGroupsLabel primary="All groups" />
              </MenuItem>
              {groups.map((group) => {
                return (
                  <MenuItem key={group.uuid} value={group.uuid}>
                    <Checkbox
                      color="primary"
                      checked={filteredGroups.includes(group.uuid)}
                    />
                    <ListItemText primary={group.name} />
                  </MenuItem>
                )
              })}
            </DropdownButton>
          )}
        </HeaderLeft>
        <HeaderRight>
          <SearchField
            placeholder="Search"
            value={searchFilter}
            name="searchFilter"
            onChange={handleChangeSearchFilter}
          />
          <SendMessageButton
            variant="contained"
            size="small"
            color="primary"
            onClick={handleOpenNewMessageModal}
          >
            Send message
          </SendMessageButton>
        </HeaderRight>
      </StyledHeader>

      <MessagesHeader>
        <MessageHeaderItem>
          <SortHeading>Message</SortHeading>
        </MessageHeaderItem>
        <MessageHeaderItem>
          <SortHeading>Group</SortHeading>
        </MessageHeaderItem>
        <MessageHeaderItem>
          <SortHeading sortDirection={SORT_DIRECTION_ASC}>
            <strong>Date</strong> / Time
          </SortHeading>
        </MessageHeaderItem>
        <MessageHeaderItem />
      </MessagesHeader>

      {getMessagesProgress.inProgress &&
      (!messages || messages.length === 0) ? (
        <ProgressLoader fullWidth />
      ) : (
        <React.Fragment>
          <MessageList>
            {messages.map((message, index) => {
              return (
                <Message
                  message={message}
                  key={message.uuid}
                  dataQa={`message-${index}`}
                  onClick={handleOpenMessageModal}
                />
              )
            })}
          </MessageList>
          <StyledPagination
            pagination={pagination}
            onPageChange={handleChangePagination}
          />
        </React.Fragment>
      )}
      {getMessagesProgress.isComplete &&
      (!messages || messages.length === 0) ? (
        <EmptyState
          title="Nothing to chnnl here yet."
          childComponent={
            <span>
              Get started by{' '}
              <HighlightedText onClick={handleOpenNewMessageModal}>
                sending a message to your team!
              </HighlightedText>
            </span>
          }
          icon={<MessagingEmptyState />}
        />
      ) : null}
    </>
  )
}

MessagingHistory.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  getMessagesProgress: PropTypes.object,
  getGroupsProgress: PropTypes.object,
  actions: PropTypes.object,
  messages: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  groupUuid: PropTypes.string,
}

MessagingHistory.defaultProps = {
  organisations: [],
  getMessagesProgress: {},
  getGroupsProgress: {},
  actions: {},
  messages: [],
  groups: [],
}

export default MessagingHistory
