import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'
import EditActions from 'components/molecules/EditActions'

export const StyledPathwayContent = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.lg}px
    ${({ theme }) => theme.spacing.xl}px ${({ theme }) => theme.spacing.lg}px
    ${({ theme }) => theme.spacing.lg}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const Top = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  display: flex;
  align-items: flex-start;
`
export const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
`

export const Label = styled(Typography)`
  width: 25%;
  flex-shrink: 0;
  && {
    margin-right: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledEditActions = styled(EditActions)`
  position: absolute;
  right: ${({ theme }) => theme.spacing.md}px;
  top: 50%;
  transform: translateY(-50%);
`

export function PathwayContent({ title, message, onEdit, value }) {
  const handleOnEdit = useCallback(
    () => {
      if (onEdit) {
        onEdit(value)
      }
    },
    [value]
  )

  return (
    <StyledPathwayContent>
      <Top>
        <Label variant="body1">Pathway Title</Label>
        <Typography variant="body1">{title}</Typography>
      </Top>
      <Bottom>
        <Label variant="body1">Pathway Message</Label>
        <Typography variant="body1">{message}</Typography>
      </Bottom>
      {onEdit && <StyledEditActions onEdit={handleOnEdit} />}
    </StyledPathwayContent>
  )
}

PathwayContent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onEdit: PropTypes.func,
  value: PropTypes.any,
}

export default PathwayContent
