import * as messagingActions from 'modules/messaging/actions'
import * as groupActions from 'modules/groups/actions'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { hasEditRights } from 'modules/authentication/selectors'
import { getGroupMessagesList } from 'modules/messaging/selectors'

export default compose(
  connect(
    // mapStateToProps
    (
      state,
      {
        match: {
          params: { groupUuid },
        },
      }
    ) => {
      const group = state.groups.entities[groupUuid]

      const messages = getGroupMessagesList(state, groupUuid).map(
        (message) => ({
          ...message,
          groupName: group ? group.name : '',
        })
      )
      return {
        hasEditAccess: state.user.canEdit || hasEditRights(state),
        messages,
        ...(group ? { groups: [group] } : { groups: [] }),
      }
    },
    // mapDispatchToProps
    (dispatch, props) => {
      const {
        match: {
          params: { orgUuid: organisationUuid, groupUuid },
        },
      } = props

      const getMessages = (params, filters) =>
        messagingActions.getGroupMessageHistory({
          organisationUuid,
          groupUuid,
          params,
          filters,
        })

      const sendMessage = ({ message }) =>
        messagingActions.messageGroups({
          organisationUuid,
          groupUuids: [groupUuid],
          message,
        })

      const actions = {
        ...messagingActions,
        ...groupActions,
        getMessages,
        sendMessage,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  )
)
