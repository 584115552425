import React from 'react'
import PropTypes from 'prop-types'
import Button from '@weareroam/cake-ui-v1/Button'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function RemoveAdminForm({
  className,
  onSubmit,
  onCancel,
  admin,
  group,
}) {
  return (
    <form
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <StyledText variant="h4">
        Are you sure you want to remove{' '}
        {admin.fullName || admin.name || admin.email} as a group admin from
        <br />
        &lsquo;{group.name}&rsquo;?
      </StyledText>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}
        >
          No, Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          type="submit"
          size="small"
          color="primary"
        >
          Yes, remove
        </ActionButton>
      </StyledActions>
    </form>
  )
}

RemoveAdminForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  admin: PropTypes.object,
  group: PropTypes.object,
}

RemoveAdminForm.defaultProps = {
  admin: {},
  group: {},
}

export default RemoveAdminForm
