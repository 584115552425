import React from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import OrganisationOnboardingForm from 'components/organisms/OrganisationOnboardingForm'
import styled from 'styled-components'
import ProgressLoader from 'components/atoms/ProgressLoader'

export const StyledRoot = styled.div`
  text-align: center;
  width: 400px;
`

export const StyledContent = styled.div`
  width: 330px;
  margin: 0 auto;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledForm = styled(OrganisationOnboardingForm)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export function Organisation({
  onSubmit,
  organisation,
  uploadTotal,
  uploadProgress,
  getOrganisationProgress,
  onImageUploadError,
}) {
  return (
    <DefaultLayout>
      <StyledRoot>
        <StyledTitle variant="h2">About your organisation</StyledTitle>
        <StyledContent>
          {getOrganisationProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : (
            <StyledForm
              onSubmit={onSubmit}
              organisation={organisation}
              uploadTotal={uploadTotal}
              uploadProgress={uploadProgress}
              onImageUploadError={onImageUploadError}
            />
          )}
        </StyledContent>
      </StyledRoot>
    </DefaultLayout>
  )
}

Organisation.propTypes = {
  onSubmit: PropTypes.func,
  organisation: PropTypes.object,
  uploadTotal: PropTypes.number,
  uploadProgress: PropTypes.number,
  onImageUploadError: PropTypes.func,
  getOrganisationProgress: PropTypes.object,
}

Organisation.defaultProps = {
  getOrganisationProgress: {},
}

export default Organisation
