import { createSelector } from 'reselect'
import { ROLE_SUPER_ADMIN } from 'constants/form'

// selectors
export const isChnnlAdmin = createSelector(
  (state) => state.authentication.role,
  (role) => role === ROLE_SUPER_ADMIN
)

export const hasEditRights = createSelector(
  (state) => state.authentication.role,
  (role) => [ROLE_SUPER_ADMIN].includes(role)
)
