import * as authActions from 'modules/authentication/actions'
import * as exportActions from 'modules/export/actions'
import * as organisationsActions from 'modules/organisations/actions'
import * as wellbeingActions from 'modules/wellbeing/actions'
import * as activityActions from 'modules/activity/actions'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import idx from 'idx'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        activity: idx(state, (_) => _.activity),
        lineChart: state.wellbeing.moodMonthly,
        barChart: state.wellbeing.moodOverall,
        reflectionReport: state.wellbeing.reflectionReport,
        attributeReport: state.wellbeing.attributeReport,
        error: state.wellbeing.error,
        orgUuid: match.params.orgUuid,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...exportActions,
        ...organisationsActions,
        ...wellbeingActions,
        ...activityActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withHandlers({
    onExport: ({ actions, orgUuid }) => () => {
      actions.exportOrgData({ orgUuid })
    },
  })
)
