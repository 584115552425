import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@weareroam/cake-ui-v1/TextField'
import PasswordField from 'components/molecules/PasswordField'
import Button from '@weareroam/cake-ui-v1/Button'
import styled from 'styled-components'

export const StyledEmail = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
export const StyledPassword = styled(PasswordField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;

    input {
      background-color: transparent !important;
    }
  }
`

export function LoginForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <StyledEmail
        id="email"
        name="email"
        label="Email Address"
        type="email"
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.email && !!errors.email}
        helperText={touched.email ? errors.email : ''}
        fullWidth
      />
      <StyledPassword
        id="password"
        name="password"
        label="Password"
        type="password"
        value={values.password}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.password && !!errors.password}
        helperText={touched.password ? errors.password : ''}
        fullWidth
      />
      <Button
        variant="contained"
        type="submit"
        fullWidth
        color="primary"
        size="large"
        id="login-button"
      >
        Log in
      </Button>
    </form>
  )
}

LoginForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
}

LoginForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default LoginForm
