import * as authActions from 'modules/authentication/actions'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import idx from 'idx'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        user: idx(state, (_) => _.user),
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withHandlers({
    onLogout: ({ actions }) => () => {
      actions.logout()
    },
  })
)
