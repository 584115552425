import { compose, defaultProps } from 'recompose'
import { withFormik } from 'formik'
import uuid from 'uuid/v4'
import * as Yup from 'yup'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      uuid: uuid(),
      fullName: initialValues.fullName || '',
      position: initialValues.position || '',
      email: initialValues.email || '',
      contactNumber: initialValues.contactNumber || '',
      extension: initialValues.extension || '',
      website: initialValues.website || '',
      description: initialValues.description || '',
    }),
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required('Please enter a name'),
      email: Yup.string().email('Please enter a valid email'),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      // pass values to our onSubmit prop
      if (onSubmit) {
        onSubmit(values)
      }
    },
  })
)
