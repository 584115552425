import React from 'react'

export function Delta(props) {
  return (
    <svg width="232px" height="178px" viewBox="0 0 232 178" {...props}>
      <g
        id="UI-Update"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Animation-setup" transform="translate(-106.000000, -134.000000)">
          <g id="Group" transform="translate(103.346512, 131.346512)">
            <path
              d="M43.2025701,72.1574126 L43.6159908,72.1540634 L43.6159908,128.154063 C29.8088721,128.154063 18.6159908,116.961182 18.6159908,103.154063 L18.6159908,97.1540634 C18.6159908,83.4850158 29.5861337,72.3781824 43.2025701,72.1574126 L43.2025701,72.1574126 Z"
              id="ear-bg-l"
              fill="#EDFFF7"
            />
            <path
              d="M206.029412,72.1574126 L205.615991,72.1540634 L205.615991,128.154063 C219.42311,128.154063 230.615991,116.961182 230.615991,103.154063 L230.615991,97.1540634 C230.615991,83.4850158 219.645848,72.3781824 206.029412,72.1574126 L206.029412,72.1574126 Z"
              id="ear-bg-r"
              fill="#EDFFF7"
            />
            <rect
              id="head-bg"
              fill="#EDFFF7"
              x="42.9679908"
              y="20.1540634"
              width="163.296"
              height="160.272"
              rx="59"
            />
            <path
              d="M36.2025701,61.1574126 L36.6159908,61.1540634 L36.6159908,117.154063 C22.8088721,117.154063 11.6159908,105.961182 11.6159908,92.1540634 L11.6159908,86.1540634 C11.6159908,72.4850158 22.5861337,61.3781824 36.2025701,61.1574126 L36.2025701,61.1574126 Z"
              id="ear-l"
              stroke="#5C8267"
              strokeWidth="8.5349376"
            />
            <path
              d="M200.029412,61.1574126 L199.615991,61.1540634 L199.615991,117.154063 C213.42311,117.154063 224.615991,105.961182 224.615991,92.1540634 L224.615991,86.1540634 C224.615991,72.4850158 213.645848,61.3781824 200.029412,61.1574126 L200.029412,61.1574126 Z"
              id="ear-r"
              stroke="#5C8267"
              strokeWidth="8.5349376"
            />
            <circle
              id="ear-aerial-ball-r"
              fill="#5C8267"
              transform="translate(220.223991, 16.608000) rotate(11.000000) translate(-220.223991, -16.608000) "
              cx="220.223991"
              cy="16.608"
              r="13.608"
            />
            <line
              x1="214.277554"
              y1="63.5818833"
              x2="214.277554"
              y2="27.2938833"
              id="ear-aerial-r"
              stroke="#5C8267"
              strokeWidth="8.5349376"
              fill="#EDFFF7"
              transform="translate(214.277554, 45.437883) rotate(11.000000) translate(-214.277554, -45.437883) "
            />
            <circle
              id="ear-aerial-ball-l"
              fill="#5C8267"
              transform="translate(16.608000, 16.608000) scale(-1, 1) rotate(11.000000) translate(-16.608000, -16.608000) "
              cx="16.608"
              cy="16.608"
              r="13.608"
            />
            <line
              x1="22.5544372"
              y1="63.5818833"
              x2="22.5544372"
              y2="27.2938833"
              id="ear-aerial-l"
              stroke="#5C8267"
              strokeWidth="8.5349376"
              fill="#EDFFF7"
              transform="translate(22.554437, 45.437883) scale(-1, 1) rotate(11.000000) translate(-22.554437, -45.437883) "
            />
            <path
              d="M106.892791,128.792463 C106.892791,116.840368 97.2051619,107.154063 85.2534047,107.154063 C73.3036197,107.154063 63.6159908,116.840368 63.6159908,128.792463"
              id="eye-l"
              stroke="#5C8267"
              strokeWidth="8.5349376"
            />
            <path
              d="M173.892791,128.792463 C173.892791,116.840368 164.205162,107.154063 152.253405,107.154063 C140.30362,107.154063 130.615991,116.840368 130.615991,128.792463"
              id="eye-r"
              stroke="#5C8267"
              strokeWidth="8.5349376"
            />
            <rect
              id="head-bg"
              stroke="#5C8267"
              strokeWidth="8.5349376"
              x="36.6159908"
              y="9.15406336"
              width="163.296"
              height="160.272"
              rx="59"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Delta
