import { schema } from 'normalizr'

// Schema
export const groupSchema = new schema.Entity(
  'groups',
  {},
  { idAttribute: 'uuid' }
)
export const groupListSchema = [groupSchema]

export const groupMemberSchema = new schema.Entity(
  'members',
  {},
  { idAttribute: 'uuid' }
)
