import { useState, useCallback } from 'react'

export default function useMessageModal() {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
  const [messageModalUuid, setMessageModalUuid] = useState(null)

  const [isCreateMessageModalOpen, setIsCreateMessageModalOpen] = useState(
    false
  )
  const handleOpenNewMessageModal = useCallback(() =>
    setIsCreateMessageModalOpen(true)
  )
  const handleCloseNewMessageModal = useCallback(() =>
    setIsCreateMessageModalOpen(false)
  )
  const handleOpenMessageModal = useCallback((uuid) => {
    setMessageModalUuid(uuid)
    setIsMessageModalOpen(true)
  }, [])

  const handleCloseMessageModal = useCallback(() => {
    setIsMessageModalOpen(false)
    setMessageModalUuid(null)
  }, [])

  return {
    isMessageModalOpen,
    messageModalUuid,
    handleOpenMessageModal,
    handleCloseMessageModal,
    isCreateMessageModalOpen,
    handleOpenNewMessageModal,
    handleCloseNewMessageModal,
  }
}
