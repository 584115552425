import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

export function FilterLabel({ children, className }) {
  return (
    <StyledLabel variant="body2" className={className}>
      {children}
    </StyledLabel>
  )
}

FilterLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default FilterLabel
