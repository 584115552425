import { useMemo, useState, useCallback } from 'react'
import useFilterLabel from 'hooks/useFilterLabel'
import { TOGGLE_ALL } from 'constants/form'

/**
 * Mainly for usage with the organisations or groups filters that are used in the dashboard
 * Provides functionality for rendering the filter label and managing the state of the filter
 * @param {Array}    entities   The unfiltered list of entities
 * @returns {Object}            The object with the functions and values used for the filter
 */
export default function useEntitiesFilter(entities, entityName, entityLoaded) {
  const allEntities = useMemo(
    () => {
      return entities.map((entity) => entity.uuid)
    },
    [entities]
  )

  const [filteredEntities, setFilteredEntities] = useState(allEntities)

  // This handles the 'Toggle all' button at the top of the filter
  const handleToggleAllEntities = useCallback(
    () => {
      if (filteredEntities.length !== allEntities.length) {
        setFilteredEntities(allEntities)
      } else {
        setFilteredEntities([])
      }
    },
    [filteredEntities, allEntities]
  )

  const renderFilterLabel = useFilterLabel(
    entities,
    entityName,
    filteredEntities
  )

  // This updates the array of organisations when a menu item is clicked
  const handleChangeFilteredEntities = useCallback(
    (e) => {
      if (e === TOGGLE_ALL || e.target.value.includes(TOGGLE_ALL)) {
        handleToggleAllEntities()
      } else {
        setFilteredEntities(e.target.value)
      }
    },
    [handleToggleAllEntities]
  )

  const entitiesLoaded = useMemo(
    () => {
      if (entityLoaded) {
        if (allEntities.length === filteredEntities.length) {
          setFilteredEntities(allEntities)
        } else {
          handleChangeFilteredEntities(TOGGLE_ALL)
        }
      }
    },
    [entityLoaded]
  )

  return {
    renderFilterLabel,
    entitiesLoaded,
    handleChangeFilteredEntities,
    filteredEntities,
  }
}
