import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AutosuggestComponent from 'react-autosuggest'
import TextField from '@weareroam/cake-ui-v1/TextField'
import { toClass } from 'recompose'
import ChipInput from 'components/molecules/ChipInput'

// Convert the TextField to class since it is a SFC (no ref) but Autosuggest requires a ref
const TextFieldWithClass = toClass(TextField)

export const StyledAutosuggest = styled.div`
  position: relative;
`

export const StyledDropdown = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
  z-index: 5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  max-height: 250px;
  overflow-y: auto;

  ul {
    list-style-type: none;

    li:hover > div {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
`

export const StyledSuggestion = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.md}px;

  background-color: ${({ isActive }) =>
    isActive ? 'rgba(0, 0, 0, 0.1)' : 'white'};
`

export function Autosuggest(props) {
  const {
    className,
    filteredSuggestions,
    updateFilteredSuggestions,
    value,
    error,
    helperText,
    onChange,
    onBlur,
    placeholder,
    name,
    clearSuggestions,
    variant,
    chips,
    onDeleteChip,
    onAddChip,
    noneSelectedHelperText,
  } = props

  const fieldError = useMemo(
    () => {
      return error
        ? helperText
        : filteredSuggestions.length === 0 && value.length > 0
        ? noneSelectedHelperText
        : null
    },
    [error, value, helperText]
  )

  return (
    <StyledAutosuggest className={className}>
      <AutosuggestComponent
        suggestions={filteredSuggestions}
        onSuggestionsFetchRequested={({ value }) =>
          updateFilteredSuggestions(value)
        }
        focusInputOnSuggestionClick={false}
        onSuggestionsClearRequested={() => clearSuggestions()}
        getSuggestionValue={(suggestion) => suggestion.label}
        inputProps={{
          value,
          onChange,
          placeholder,
        }}
        renderInputComponent={({ ref, onChange: innerOnChange, ...other }) => {
          switch (variant) {
            case 'chip':
              return (
                <ChipInput
                  {...other}
                  inputRef={ref}
                  helperText={helperText}
                  error={error}
                  name={name}
                  onBlur={onBlur}
                  value={chips}
                  inputValue={value}
                  onUpdateInput={(e) => {
                    innerOnChange(e)
                    onChange(e)
                  }}
                  onAdd={onAddChip}
                  onDelete={onDeleteChip}
                />
              )
            case 'text':
            default:
              return (
                <TextFieldWithClass
                  {...other}
                  inputRef={ref}
                  fullWidth
                  type="text"
                  helperText={fieldError || null}
                  error={!!fieldError}
                  name={name}
                  onChange={innerOnChange}
                />
              )
          }
        }}
        shouldRenderSuggestions={() => true}
        renderSuggestionsContainer={({ containerProps, children }) => {
          return <StyledDropdown {...containerProps}>{children}</StyledDropdown>
        }}
        renderSuggestion={(suggestion) => {
          const isActive = suggestion.label === value
          return (
            <StyledSuggestion isActive={isActive}>
              {suggestion.label}
            </StyledSuggestion>
          )
        }}
      />
    </StyledAutosuggest>
  )
}

Autosuggest.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  chips: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  clearSuggestions: PropTypes.func,
  updateFilteredSuggestions: PropTypes.func,
  filteredSuggestions: PropTypes.array,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'chip']),
  onDeleteChip: PropTypes.func,
  onAddChip: PropTypes.func,
  noneSelectedHelperText: PropTypes.string,
}

Autosuggest.defaultProps = {
  variant: 'text',
  filteredSuggestions: [],
  noneSelectedHelperText: 'Please select a value',
}

export default Autosuggest
