import React from 'react'
import PropTypes from 'prop-types'
import DropzoneComponent from 'react-dropzone'
import UploadIcon from 'components/atoms/Icons/Upload'
import styled from 'styled-components'

export const StyledDropzone = styled.div`
  margin: 10px 0;
  width: 100%;
  height: auto;
  border-radius: 21px;
  border: 1px dashed #d0d0d0;
  padding: 25px 30px;
  text-align: center;
  cursor: pointer;
  outline: 0;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  background-color: ${({ dragActive }) =>
    dragActive ? 'rgba(53, 116, 250, 0.1)' : ''};
`

export const FilesText = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`

export const DragText = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 8px;
`

export const SizeText = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`

export const StyledText = styled.div`
  margin: 20px 0 10px 0;
`

export function Dropzone({ accept, onDrop, className }) {
  return (
    <DropzoneComponent multiple={false} accept={accept} onDrop={onDrop}>
      {({ getRootProps, isDragActive, getInputProps }) => (
        <StyledDropzone
          {...getRootProps()}
          dragActive={isDragActive}
          className={className}
          id="drag-and-drop-zone"
        >
          <UploadIcon />
          <input {...getInputProps()} />
          <StyledText>
            <DragText>Drag and drop</DragText>
            <FilesText>your files here or browse files</FilesText>
            <SizeText>240px 180px works best</SizeText>
          </StyledText>
        </StyledDropzone>
      )}
    </DropzoneComponent>
  )
}

Dropzone.defaultProps = {
  accept: 'image/*',
}

Dropzone.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

export default Dropzone
