import { compose } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ organisation }) => ({
      organisationName: organisation ? organisation.name : '',
      logo: null,
    }),
    validationSchema: Yup.object().shape({
      organisationName: Yup.string()
        .required('Please enter an organisation name')
        .max(50, 'Must be less than 50 characters long'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  })
)
