import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowBack from '@weareroam/cake-ui-v1-icons/ArrowBack'
import Typography from '@weareroam/cake-ui-v1/Typography'

import { Link } from 'react-router-dom'

export const StyledContainer = styled.div`
  width: 372px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
`

export const BackLink = styled(Link)`
  top: -4px;
  left: -200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
`

export const BackIcon = styled(ArrowBack)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: ${({ theme }) => theme.spacing.sm}px;
`

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xxl}px;
`

export function SettingsPageContainer({ children, title, className }) {
  return (
    <StyledContainer className={className}>
      <Typography variant="h4">{title}</Typography>
      <BackLink to="../settings">
        <BackIcon />
        <StyledText>Settings</StyledText>
      </BackLink>
      <Content>{children}</Content>
    </StyledContainer>
  )
}

SettingsPageContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default SettingsPageContainer
