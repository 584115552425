import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Typography } from '@weareroam/cake-ui-v1'
import EditActions from 'components/molecules/EditActions'

export const StyledBooster = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  border-bottom: ${({ showEscalationOption, theme }) =>
    !showEscalationOption ? `1px solid ${theme.palette.tertiary.light};` : ''};
  padding: ${({ theme }) => theme.spacing.lg}px;

  &:last-of-type {
    border-bottom: none;
  }
`

export const StyledConfig = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledConfigItem = styled.div`
  width: 100%
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing.md}px;
`

export const Left = styled.div`
  min-width: 200px;
  max-width: 200px;
  margin-right: ${({ theme }) => theme.spacing.lg}px;
`

export const Right = styled.div`
  flex-grow: 1;
  padding-right: 100px;
`

export const Subheading = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.xs}px;
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

export const Details = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  display: flex;
`

export const DetailsLeft = styled.div`
  min-width: 40%;
  margin-right: ${({ theme }) => theme.spacing.md}px;
`

export const DetailsRight = styled.div`
  min-width: 60%;
`

export const StyledEditActions = styled(EditActions)`
  position: absolute;
  right: ${({ theme }) => theme.spacing.md}px;
  top: 50%;
  transform: translateY(-50%);
`

export const StyledSwitch = styled.span`
  position: absolute;
  right: ${({ theme }) => theme.spacing.md}px;
  top: 50%;
`

export const ChatConfigurationDetail = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export function Booster({
  className,
  heading,
  subheading,
  contactNumber,
  extension,
  email,
  website,
  description,
  showEscalationOption,
  escalationOption,
  onEdit,
  onDelete,
  value,
  disabled,
}) {
  const handleOnEdit = useCallback(
    () => {
      if (onEdit) {
        onEdit(value)
      }
    },
    [value]
  )

  const handleOnDelete = useCallback(
    () => {
      if (onDelete) {
        onDelete(value)
      }
    },
    [value]
  )

  return (
    <>
      <StyledBooster
        showEscalationOption={showEscalationOption}
        className={className}
      >
        {(onDelete || onEdit) && !disabled && (
          <StyledEditActions onEdit={handleOnEdit} onDelete={handleOnDelete} />
        )}
        <Left>
          <Typography variant="body1">{heading}</Typography>
          <Subheading variant="body2">{subheading}</Subheading>
        </Left>
        <Right>
          <Details>
            <DetailsLeft>
              {contactNumber && (
                <React.Fragment>
                  <Typography variant="body1">{contactNumber}</Typography>
                  {extension && (
                    <Typography variant="body1">Extn {extension}</Typography>
                  )}
                </React.Fragment>
              )}
            </DetailsLeft>
            <DetailsRight>
              {email && (
                <Typography variant="body1">
                  <a href={`mailto:${email}`}>{email}</a>
                </Typography>
              )}
              {website && (
                <Typography variant="body1">
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    {website}
                  </a>
                </Typography>
              )}
            </DetailsRight>
          </Details>
          {description && (
            <Typography variant="body1">{description}</Typography>
          )}
        </Right>
      </StyledBooster>
      {showEscalationOption && (
        <StyledBooster className={className}>
          <StyledConfig>
            <Typography variant="h4">Configuration</Typography>
            <StyledConfigItem>
              <ChatConfigurationDetail variant="body2">
                This option will be displayed to a user when they are in need of
                urgent support
              </ChatConfigurationDetail>
              <StyledSwitch>
                <Switch color="primary" checked={escalationOption} disabled />
              </StyledSwitch>
            </StyledConfigItem>
          </StyledConfig>
        </StyledBooster>
      )}
    </>
  )
}

Booster.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  contactNumber: PropTypes.string,
  extension: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
  description: PropTypes.string,
  showEscalationOption: PropTypes.bool,
  escalationOption: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}

export default Booster
