import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { ROLE_ORG_OWNER } from 'constants/form'
import { hasEditRights } from 'modules/authentication/selectors'
import * as countriesActions from 'modules/countries/actions'
import * as orgCountriesActions from 'modules/orgCountries/actions'
import {
  getAvailableCountriesList,
  getOrgCountriesList,
} from 'modules/orgCountries/selectors'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      return {
        orgUuid: match.params.orgUuid,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
        isOrgOwner: state.authentication.role === ROLE_ORG_OWNER,
        availableCountries: getAvailableCountriesList(state),
        orgCountries: getOrgCountriesList(state),
        orgCountryEntities: state.orgCountries.entities,
        getOrgCountriesProgress: state.progress.getOrgCountries,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...countriesActions,
        ...orgCountriesActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withState('currentOrgCountryId', 'setCurrentOrgCountryId', null),
  withState('isAddOrgCountryModalOpen', 'setIsAddOrgCountryModalOpen', false),
  withState(
    'isDeleteOrgCountryModalOpen',
    'setIsDeleteOrgCountryModalOpen',
    false
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getCountries()
      actions.getOrgCountries({
        orgUuid: match.params.orgUuid,
      })
    },
  }),
  withHandlers({
    handleAddOrgCountry: ({
      actions,
      match,
      setIsAddOrgCountryModalOpen,
    }) => async ({ isoCode, preferenceOrder }) => {
      setIsAddOrgCountryModalOpen(false)
      await actions.addOrgCountry({
        orgUuid: match.params.orgUuid,
        isoCode,
        preferenceOrder,
      })
      actions.getOrgCountries({
        orgUuid: match.params.orgUuid,
      })
    },
    handleDeleteOrgCountry: ({
      actions,
      match,
      setIsDeleteOrgCountryModalOpen,
    }) => async (orgCountry) => {
      setIsDeleteOrgCountryModalOpen(false)
      await actions.deleteOrgCountry({
        orgUuid: match.params.orgUuid,
        isoCode: orgCountry.country.isoCode,
      })
      actions.getOrgCountries({
        orgUuid: match.params.orgUuid,
      })
    },
    onOrgCountryDragEnd: ({ actions, match }) => async ({
      allEntities,
      dragResult,
    }) => {
      // dropped outside the list or not moved
      if (
        !dragResult.destination ||
        dragResult.destination.index === dragResult.source.index
      ) {
        return
      }

      // Using splice to collate the new order
      const reorderedList = Array.from(allEntities)
      const [removed] = reorderedList.splice(dragResult.source.index, 1)
      reorderedList.splice(dragResult.destination.index, 0, removed)

      await actions.reorderOrgCountries({
        orgUuid: match.params.orgUuid,
        newOrder: reorderedList.map((oC) => oC.country.isoCode),
      })
      actions.getOrgCountries({
        orgUuid: match.params.orgUuid,
      })
    },
  })
)
