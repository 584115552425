import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Table from '@weareroam/cake-ui-v1/Table'
import DataTableBodyCell from 'components/atoms/DataTableBodyCell'
import DataTableHeadCell from 'components/atoms/DataTableHeadCell'
import EmptyState from 'components/molecules/EmptyState'
import Link from 'components/atoms/Link'
import StatusTag from 'components/atoms/StatusTag'
import TableRow from '@weareroam/cake-ui-v1/TableRow'
import DataTableRow from 'components/atoms/DataTableRow'
import TableBody from '@weareroam/cake-ui-v1/TableBody'
import TableHead from '@weareroam/cake-ui-v1/TableHead'
import TableButton from 'components/atoms/TableButton'
import PercentageTag from 'components/atoms/PercentageTag'
import styled from 'styled-components'
import {
  MOOD_OVERALL,
  MOOD_CHEERFUL,
  MOOD_CALM,
  MOOD_ACTIVE,
  MOOD_RESTED,
  MOOD_INTERESTED,
} from 'constants/form'
import { ORG_DASHBOARD_APP_REFLECTIONS } from 'constants/routes'
import { replaceRouteParams } from 'utils/routes'

export const StyledValueCell = styled(DataTableBodyCell).attrs({
  align: 'center',
})`
  && {
    width: 10%;
    color: ${({ theme, highlight }) =>
      highlight ? theme.palette.primary.main : theme.palette.tertiary.dark};
    border-left: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  }
`

export const SpacerRow = styled(TableRow)`
  && {
    height: 20px;
  }
`

export const TitleHeading = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledStatusTag = styled(StatusTag)`
  && {
    float: right;
  }
`

export const getSortIndex = (sortBy) => {
  switch (sortBy) {
    case MOOD_CHEERFUL:
      return 0
    case MOOD_CALM:
      return 1
    case MOOD_ACTIVE:
      return 2
    case MOOD_RESTED:
      return 3
    case MOOD_INTERESTED:
      return 4
    default:
      return 5
  }
}

export function ReflectionReport({ title, type, data, orgUuid }) {
  const [sortBy, setSortBy] = useState(MOOD_OVERALL)
  const sortedData = useMemo(
    () => {
      return data.slice().sort((a, b) => {
        const sortIndex = getSortIndex(sortBy)
        const aVal = a.items[sortIndex].effect
        const bVal = b.items[sortIndex].effect

        if (aVal > bVal) {
          return -1
        } else if (aVal < bVal) {
          return 1
        } else {
          return 0
        }
      })
    },
    [sortBy, data]
  )

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <DataTableHeadCell>
              <TitleHeading>
                <span>{title}</span>
                <span>Sort Effect % by:</span>
              </TitleHeading>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <TableButton
                onClick={setSortBy}
                isActive={sortBy === MOOD_OVERALL}
                value={MOOD_OVERALL}
              >
                Overall
              </TableButton>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <TableButton
                onClick={setSortBy}
                isActive={sortBy === MOOD_CHEERFUL}
                value={MOOD_CHEERFUL}
              >
                Cheerful
              </TableButton>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <TableButton
                onClick={setSortBy}
                isActive={sortBy === MOOD_CALM}
                value={MOOD_CALM}
              >
                Calm
              </TableButton>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <TableButton
                onClick={setSortBy}
                isActive={sortBy === MOOD_ACTIVE}
                value={MOOD_ACTIVE}
              >
                Active
              </TableButton>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <TableButton
                onClick={setSortBy}
                isActive={sortBy === MOOD_RESTED}
                value={MOOD_RESTED}
              >
                Rested
              </TableButton>
            </DataTableHeadCell>
            <DataTableHeadCell>
              <TableButton
                onClick={setSortBy}
                isActive={sortBy === MOOD_INTERESTED}
                value={MOOD_INTERESTED}
              >
                Interested
              </TableButton>
            </DataTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SpacerRow />
          {sortedData.map((row, index) => {
            return (
              <DataTableRow key={index}>
                <DataTableBodyCell>
                  {row.reflection.reflection}
                  {type === 'CUSTOM' && (
                    <StyledStatusTag themeColor="success">
                      {row.reflection.deletedAt == null ? 'ACTIVE' : 'INACTIVE'}
                    </StyledStatusTag>
                  )}
                </DataTableBodyCell>
                <StyledValueCell>
                  <PercentageTag
                    variant={row.items[5].effect >= 0 ? 'light' : 'dark'}
                  >
                    {row.items[5].effect.toFixed(2)}
                  </PercentageTag>
                </StyledValueCell>
                <StyledValueCell highlight={sortBy === MOOD_CHEERFUL}>
                  {row.items[0].effect.toFixed(2)}%
                </StyledValueCell>
                <StyledValueCell highlight={sortBy === MOOD_CALM}>
                  {row.items[1].effect.toFixed(2)}%
                </StyledValueCell>
                <StyledValueCell highlight={sortBy === MOOD_ACTIVE}>
                  {row.items[2].effect.toFixed(2)}%
                </StyledValueCell>
                <StyledValueCell highlight={sortBy === MOOD_RESTED}>
                  {row.items[3].effect.toFixed(2)}%
                </StyledValueCell>
                <StyledValueCell highlight={sortBy === MOOD_INTERESTED}>
                  {row.items[4].effect.toFixed(2)}%
                </StyledValueCell>
              </DataTableRow>
            )
          })}
        </TableBody>
      </Table>
      {type === 'CUSTOM' && sortedData.length < 1 && (
        <EmptyState
          title="Nothing to chnnl here yet."
          childComponent={
            <span>
              Get started by adding a
              <Link
                to={replaceRouteParams(ORG_DASHBOARD_APP_REFLECTIONS, {
                  orgUuid,
                })}
              >
                {' '}
                custom reflection.
              </Link>
            </span>
          }
        />
      )}
    </>
  )
}

ReflectionReport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  type: PropTypes.string,
  orgUuid: PropTypes.string.isRequired,
}

ReflectionReport.defaultProps = {
  data: [],
  title: 'Reflection',
  type: undefined,
}

export default ReflectionReport
