import { compose } from 'recompose'
import { withFormik } from 'formik'
import * as Yup from 'yup'

export default compose(
  withFormik({
    mapPropsToValues: () => ({ email: '' }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Please enter your email address')
        .email('Please enter a valid email address'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  })
)
