import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

export const API_GET_ADMIN_PROFILE_REQUEST = 'API_GET_ADMIN_PROFILE_REQUEST'
export const API_GET_ADMIN_PROFILE_SUCCESS = 'API_GET_ADMIN_PROFILE_SUCCESS'
export const API_GET_ADMIN_PROFILE_ERROR = 'API_GET_ADMIN_PROFILE_ERROR'

export const API_UPDATE_ADMIN_PROFILE_REQUEST =
  'API_UPDATE_ADMIN_PROFILE_REQUEST'
export const API_UPDATE_ADMIN_PROFILE_SUCCESS =
  'API_UPDATE_ADMIN_PROFILE_SUCCESS'
export const API_UPDATE_ADMIN_PROFILE_ERROR = 'API_UPDATE_ADMIN_PROFILE_ERROR'

export const API_GET_PROFILE_NOTIFICATIONS_REQUEST =
  'API_GET_PROFILE_NOTIFICATIONS_REQUEST'
export const API_GET_PROFILE_NOTIFICATIONS_SUCCESS =
  'API_GET_PROFILE_NOTIFICATIONS_SUCCESS'
export const API_GET_PROFILE_NOTIFICATIONS_ERROR =
  'API_GET_PROFILE_NOTIFICATIONS_ERROR'

export const API_UPDATE_PROFILE_NOTIFICATIONS_REQUEST =
  'API_UPDATE_PROFILE_NOTIFICATIONS_REQUEST'
export const API_UPDATE_PROFILE_NOTIFICATIONS_SUCCESS =
  'API_UPDATE_PROFILE_NOTIFICATIONS_SUCCESS'
export const API_UPDATE_PROFILE_NOTIFICATIONS_ERROR =
  'API_UPDATE_PROFILE_NOTIFICATIONS_ERROR'

export function getAdminProfile() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ADMIN_PROFILE_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/profile`)

      dispatch({
        type: API_GET_ADMIN_PROFILE_SUCCESS,
        data: response.data.data[0],
      })
    } catch (error) {
      dispatch({
        type: API_GET_ADMIN_PROFILE_ERROR,
        error,
      })

      dispatch(
        addToast({
          message: 'There was an error fetching your profile.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function updateProfile(data) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_ADMIN_PROFILE_REQUEST })

    try {
      const response = await axios.put(`${SAUCE_API_URL}/profile`, data)

      dispatch({
        type: API_UPDATE_ADMIN_PROFILE_SUCCESS,
        data: response.data.data[0],
      })
      dispatch(
        addToast({
          message: 'Your profile was updated!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({
        type: API_UPDATE_ADMIN_PROFILE_ERROR,
        error,
      })

      dispatch(
        addToast({
          message: 'There was an error updating your profile.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getProfileNotifications() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_PROFILE_NOTIFICATIONS_REQUEST })

    try {
      const response = await axios.get(`${SAUCE_API_URL}/profile/notifications`)

      dispatch({
        type: API_GET_PROFILE_NOTIFICATIONS_SUCCESS,
        data: response.data.data[0],
      })
    } catch (error) {
      dispatch({
        type: API_GET_PROFILE_NOTIFICATIONS_ERROR,
        error,
      })

      dispatch(
        addToast({
          message: 'There was an error fetching your profile notifications.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function updateProfileNotifications({ monthlyReport, emailUpdate }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_UPDATE_PROFILE_NOTIFICATIONS_REQUEST })

    try {
      const response = await axios.put(
        `${SAUCE_API_URL}/profile/notifications`,
        {
          monthlyReport,
          emailUpdate,
        }
      )

      dispatch({
        type: API_UPDATE_PROFILE_NOTIFICATIONS_SUCCESS,
        data: response.data.data[0],
      })

      dispatch(
        addToast({
          message: 'Your notifications have been updated!',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch({
        type: API_UPDATE_PROFILE_NOTIFICATIONS_ERROR,
        error,
      })

      dispatch(
        addToast({
          message: 'There was an error updating your profile notifications.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
