import * as groupsActions from 'modules/groups/actions'
import { getGroupsList } from 'modules/groups/selectors'
import {
  compose,
  withHandlers,
  withState,
  mapProps,
  lifecycle,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

export default compose(
  withRouter,
  mapProps((props) => {
    return {
      ...props,
      orgUuid: props.match.params.orgUuid,
    }
  }),
  connect(
    // mapStateToProps
    (state, { orgUuid }) => {
      const organisation = state.organisations.entities[orgUuid]

      return {
        groups: getGroupsList(state),
        name: state.user.firstName,
        inviteProgress: state.progress.inviteGroupMembers,
        seatsRemaining: organisation
          ? organisation.seats - organisation.seatsOccupied
          : undefined,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...groupsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withState('isInviteMembersModalOpen', 'setIsInviteMembersModalOpen', false),
  withState(
    'isInvitationSuccessModalOpen',
    'setIsInvitationSuccessModalOpen',
    false
  ),
  withState('numInvitationsSent', 'setNumInvitationsSent', 0),
  withHandlers({
    onInviteMembersSubmit: ({
      actions,
      orgUuid,
      groups,
      setNumInvitationsSent,
    }) => async ({ group, numbers }) => {
      const clickedGroup = groups.find((g) => g.name === group)
      setNumInvitationsSent(numbers.length)
      if (clickedGroup) {
        actions.inviteMembersToGroup({
          groupUuid: clickedGroup.uuid,
          orgUuid,
          numbers,
        })
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { actions, match, isInviteMembersModalOpen } = this.props

      if (!prevProps.isInviteMembersModalOpen && isInviteMembersModalOpen) {
        // fetch groups when they open the modal
        actions.getGroups(match.params.orgUuid)
      }
    },
  })
)
