import { useState, useMemo, useCallback } from 'react'

export function useEntitiesSearch(entities) {
  const [searchFilter, setSearchFilter] = useState('')

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
  }, [])

  const filteredEntities = useMemo(
    () => {
      return entities.filter((entity) => {
        const lcEntity = entity.name.toLowerCase()
        const lcSearchFilter = searchFilter.toLowerCase()

        return lcEntity.includes(lcSearchFilter)
      })
    },
    [searchFilter, entities]
  )

  return {
    searchFilter,
    handleChangeSearchFilter,
    filteredEntities,
  }
}

export default useEntitiesSearch
