import { createSelector } from 'reselect'
import { ROLE_GROUP_ADMIN } from 'constants/form'

export const getAdminsList = createSelector(
  (state) => state.admins.entities,
  (admins) => Object.values(admins)
)

export const getVerifiedAdmins = createSelector(
  (state) => getAdminsList(state),
  (admins) => admins.filter((admin) => admin.accepted)
)

export const getVerifiedGroupAdmins = createSelector(
  (state) => getVerifiedAdmins(state),
  (admins) => admins.filter((admin) => admin.role === ROLE_GROUP_ADMIN)
)

export const getGroupAdminsList = createSelector(
  [getAdminsList, (state, groupUuid) => groupUuid],
  (admins, groupUuid) => {
    return admins.filter((admin) => {
      const isGroupAdmin =
        admin.assignedGroups &&
        admin.assignedGroups.some((group) => group.uuid === groupUuid)

      return isGroupAdmin
    })
  }
)
