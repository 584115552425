export const ROLE_SUPER_ADMIN = 'superAdmin'
export const ROLE_ORG_OWNER = 'orgOwner'
export const ROLE_GROUP_ADMIN = 'groupAdmin'
export const ROLE_ORG_ADMIN = 'orgAdmin'

export const ADMIN_PERMISSION_VIEW_ONLY = 'VIEW_ONLY'
export const ADMIN_PERMISSION_CAN_EDIT = 'CAN_EDIT'

export const SORT_DIRECTION_DESC = 'SORT_DIRECTION_DESC'
export const SORT_DIRECTION_ASC = 'SORT_DIRECTION_ASC'

export const SORT_ALPHABETICAL = 'alphabetical'
export const SORT_LEAST_MEMBERS = 'leastMembers'
export const SORT_MOST_MEMBERS = 'mostMembers'
export const SORT_LEAST_ADMINS = 'leastAdmins'
export const SORT_MOST_ADMINS = 'mostAdmins'

export const JOURNAL_TYPE_ALL = 'JOURNAL_TYPE_ALL'
export const JOURNAL_TYPE_TO_REVIEW = 'JOURNAL_TYPE_TO_REVIEW'
export const JOURNAL_RED_FLAGGED = 'JOURNAL_RED_FLAGGED'
export const JOURNAL_TYPE_SHARED = 'JOURNAL_TYPE_SHARED'
export const JOURNAL_TYPE_HIDDEN = 'JOURNAL_TYPE_HIDDEN'

export const INCIDENT_TYPE_ALL = 'INCIDENT_TYPE_ALL'
export const INCIDENT_TYPE_TO_REVIEW = 'INCIDENT_TYPE_TO_REVIEW'
export const INCIDENT_TYPE_SHARED = 'INCIDENT_TYPE_SHARED'
export const INCIDENT_TYPE_HIDDEN = 'INCIDENT_TYPE_HIDDEN'

export const MOOD_OVERALL = 'Overall'
export const MOOD_CHEERFUL = 'Cheerful'
export const MOOD_CALM = 'Calm'
export const MOOD_ACTIVE = 'Active'
export const MOOD_RESTED = 'Restful'
export const MOOD_INTERESTED = 'Interested'

export const ATTRIBUTE_GENDER = 'gender'
export const ATTRIBUTE_AGE_RANGE = 'age'
export const ATTRIBUTE_ETHNICITY = 'ethnicity'

export const TOGGLE_ALL = 'TOGGLE_ALL'

export const OVERVIEW_TYPE_WELLBEING = 'OVERVIEW_TYPE_WELLBEING'
export const OVERVIEW_TYPE_APP_ACTIVITY = 'OVERVIEW_TYPE_APP_ACTIVITY'

export const CHATBOT_CATEGORY_TYPE = 'chat_bot'
