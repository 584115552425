import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@weareroam/cake-ui-v1/Button'
import styled from 'styled-components'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.md}px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function DeleteIncidentForm({ className, onSubmit, onCancel }) {
  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()

    if (onSubmit) {
      onSubmit()
    }
  }, [])

  return (
    <form
      onSubmit={handleOnSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <StyledTitle variant="h4">
        Are you sure you would like to delete this report entry?
      </StyledTitle>
      <StyledText variant="body1">
        This report entry will be permanently remove.
      </StyledText>
      <StyledActions>
        <Button
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}
        >
          No, cancel
        </Button>
        <Button variant="contained" type="submit" size="small" color="primary">
          Yes, delete
        </Button>
      </StyledActions>
    </form>
  )
}

DeleteIncidentForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default DeleteIncidentForm
