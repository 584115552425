import React from 'react'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import PropTypes from 'prop-types'

export function MemberTableFilter({
  statusFilter = '',
  handleChangeStatusFilter,
}) {
  return (
    <div>
      <ButtonSwitch
        value={''}
        name="statusFilter"
        isActive={statusFilter === ''}
        title="All Users in all states."
        onClick={handleChangeStatusFilter}
      >
        All
      </ButtonSwitch>
      <ButtonSwitch
        value={'active'}
        name="statusFilter"
        isActive={statusFilter === 'active'}
        title="Users who have installed the app, finished onboarding and are able to use chnnl."
        onClick={handleChangeStatusFilter}
      >
        Active
      </ButtonSwitch>
      <ButtonSwitch
        value={'pending'}
        name="statusFilter"
        isActive={statusFilter === 'pending'}
        title="Users who are yet to be migrated to phone-based accounts."
        onClick={handleChangeStatusFilter}
      >
        Pending
      </ButtonSwitch>
      <ButtonSwitch
        value={'new'}
        name="new"
        isActive={statusFilter === 'new'}
        title="Users who have been invited, but have not clicked the link in the invite."
        onClick={handleChangeStatusFilter}
      >
        New
      </ButtonSwitch>
      <ButtonSwitch
        value={'installed'}
        name="statusFilter"
        isActive={statusFilter === 'installed'}
        title="Users who installed the app on their device, but have not onboarded yet."
        onClick={handleChangeStatusFilter}
      >
        Installed
      </ButtonSwitch>
      <ButtonSwitch
        value={'clicked'}
        name="statusFilter"
        title="Users who clicked the invitation, but have not updated or installed the app."
        isActive={statusFilter === 'clicked'}
        onClick={handleChangeStatusFilter}
      >
        Text Clicked
      </ButtonSwitch>
    </div>
  )
}

MemberTableFilter.propTypes = {
  statusFilter: PropTypes.string,
  handleChangeStatusFilter: PropTypes.func,
}

export default MemberTableFilter
