import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@weareroam/cake-ui-v1/Dialog'
import CloseIcon from '@weareroam/cake-ui-v1-icons/Clear'
import styled from 'styled-components'

const paperClass = 'paper-class'

export const StyledPaper = styled(Dialog).attrs(() => ({
  classes: {
    paper: paperClass,
  },
}))`
  .${paperClass} {
    background: transparent;
    position: relative;
    text-align: center;
    box-shadow: none;
    overflow: visible;
  }
`

export const StyledIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const StyledCloseButton = styled.button`
  position: absolute;
  background: ${({ theme }) => theme.palette.primary.main};
  width: 55px;
  height: 55px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  z-index: 5;
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 1px 10px 0 rgba(0, 0, 0, 0.2);
`

export const PaperInner = styled.div`
  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.xxl}px;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 8px;
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

export function Modal({ open, onClose, children, hasCloseButton, className }) {
  return (
    <StyledPaper onClose={onClose} open={open}>
      {hasCloseButton && (
        <StyledCloseButton type="button" onClick={onClose}>
          <StyledIcon />
        </StyledCloseButton>
      )}
      <PaperInner className={className}>{children}</PaperInner>
    </StyledPaper>
  )
}

Modal.propTypes = {
  hasCloseButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Modal
