import React from 'react'
import DefaultLayout from 'layouts/DefaultLayout'
import Typography from '@weareroam/cake-ui-v1/Typography'
import LoginForm from 'components/organisms/LoginForm'
import PropTypes from 'prop-types'
import Link from 'components/atoms/Link'
import { RESET_PASSWORD } from 'constants/routes'
import styled from 'styled-components'

export const StyledContent = styled.div`
  text-align: center;
  width: 330px;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledForm = styled(LoginForm)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export function Login({ onSubmit }) {
  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">Log in to chnnl</StyledTitle>
        <StyledForm onSubmit={onSubmit} />
        <Link to={RESET_PASSWORD}>Forgot password</Link>
      </StyledContent>
    </DefaultLayout>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func,
}

export default Login
