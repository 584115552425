import initialState from 'reducers/initialState'
import {
  API_GET_SURVEY_QUESTIONS_SUCCESS,
  API_UPDATE_SURVEY_QUESTIONS_SUCCESS,
} from './actions'

export default function surveyReducer(state = initialState.survey, action) {
  switch (action.type) {
    case API_UPDATE_SURVEY_QUESTIONS_SUCCESS:
    case API_GET_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        typesOfDays: {
          ...state.typesOfDays,
          entities: {
            ...action.data.typesOfDays.entities.typesOfDays,
          },
          orderedIds: action.data.typesOfDays.result,
        },
        departments: {
          ...state.departments,
          entities: {
            ...action.data.departments.entities.departments,
          },
          orderedIds: action.data.departments.result,
        },
      }

    default:
      return state
  }
}
