import { compose, withState, withHandlers, defaultProps } from 'recompose'
import { withFormik } from 'formik'
import { isEmpty, withinMaxLength, withinMinLength } from 'utils/validation'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  // This is pretty much acting as a step wizard
  withState('isAssigningToGroup', 'setIsAssigningToGroup', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      message: initialValues.message || '',
      groupsSelected: initialValues.assignedGroups
        ? initialValues.assignedGroups.map((group) => group.uuid)
        : [],
    }),
    validate: ({ message, groupsSelected }, { isAssigningToGroup }) => {
      const errors = {}
      if (!withinMinLength(1, message)) {
        errors.message = 'Please enter a message to send to your team'
      }

      if (withinMinLength(1, message) && !withinMaxLength(240, message)) {
        errors.message = 'Please keep your message within 240 characters'
      }

      if (isAssigningToGroup && isEmpty(groupsSelected)) {
        errors.groupsSelected =
          'Please select at least one group to send your message to'
      }
      return errors
    },
    handleSubmit: (
      values,
      {
        props: { onSubmit, setIsAssigningToGroup, isAssigningToGroup },
        setSubmitting,
      }
    ) => {
      if (!isAssigningToGroup) {
        setIsAssigningToGroup(true)
      } else {
        setSubmitting(true)
        if (onSubmit) {
          onSubmit(values)
        }
      }
    },
  }),
  withHandlers({
    toggleAssignedToGroupCheckbox: ({ setFieldValue, values }) => (groupId) => {
      if (values.groupsSelected.includes(groupId)) {
        setFieldValue(
          'groupsSelected',
          values.groupsSelected.filter((id) => id !== groupId)
        )
      } else {
        setFieldValue('groupsSelected', [...values.groupsSelected, groupId])
      }
    },
    toggleAllAssignedToGroupCheckbox: ({
      setFieldValue,
      values,
      groups,
    }) => () => {
      if (values.groupsSelected.length === groups.length) {
        setFieldValue('groupsSelected', [])
      } else {
        setFieldValue('groupsSelected', groups.map((group) => group.uuid))
      }
    },
  })
)
