import initialState from 'reducers/initialState'
import { API_GET_ORG_ACTIVITY_SUCCESS } from './actions'

export default function activityReducer(state = initialState.activity, action) {
  switch (action.type) {
    case API_GET_ORG_ACTIVITY_SUCCESS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
