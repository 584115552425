import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR } from 'constants/ui'
import { SAUCE_API_URL } from 'config'
import axios from 'axios'
import { TOAST_TYPE_SUCCESS } from '../../constants/ui'

// Actions
export const API_GET_GROUP_SIGNUP_LINK = 'API_GET_GROUP_SIGNUP_LINK'
export const API_GET_GROUP_SIGNUP_LINK_ERROR = 'API_GET_GROUP_SIGNUP_LINK_ERROR'
export const API_GET_GROUP_SIGNUP_LINK_SUCCESS =
  'API_GET_GROUP_SIGNUP_LINK_SUCCESS'

export const API_CREATE_GROUP_SIGNUP_LINK = 'API_CREATE_GROUP_SIGNUP_LINK'
export const API_CREATE_GROUP_SIGNUP_LINK_ERROR =
  'API_CREATE_GROUP_SIGNUP_LINK__ERROR'
export const API_CREATE_GROUP_SIGNUP_LINK_SUCCESS =
  'API_CREATE_GROUP_SIGNUP_LINK_SUCCESS'

export const API_ARCHIVE_GROUP_SIGNUP_LINK = 'API_ARCHIVE_GROUP_SIGNUP_LINK'
export const API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS =
  'API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS'
export const API_ARCHIVE_GROUP_SIGNUP_LINK_ERROR =
  'API_ARCHIVE_GROUP_SIGNUP_LINK_ERROR'

// Action creators
export function createGroupSignUpLink({ groupUuid, orgUuid }) {
  return async function dispatcher(dispatch) {
    const errorMessage =
      'There was an error creating a group signup link this group. Please try again'
    dispatch({ type: API_CREATE_GROUP_SIGNUP_LINK })
    try {
      const response = await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/sign-up-link`,
        { provider: 'branch.io' }
      )

      if (response.data && response.data.data) {
        const data = response.data.data[0]
        dispatch({
          type: API_CREATE_GROUP_SIGNUP_LINK_SUCCESS,
          signUpLink: {
            groupUuid,
            uuid: data.signUpLink.id,
            linkUrl: data.signUpLink.linkUrl,
          },
        })
        dispatch(
          addToast({
            message: 'Group signup link created',
            type: TOAST_TYPE_SUCCESS,
          })
        )
      } else {
        throw new Error(errorMessage)
      }
    } catch (error) {
      dispatch({ type: API_CREATE_GROUP_SIGNUP_LINK_ERROR, error })
      dispatch(
        addToast({
          message: error.message || errorMessage,
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function archiveGroupSignUpLink({ groupUuid, orgUuid, uuid }) {
  return async function dipatcher(dispatch) {
    const errorMessage =
      'There was an error archiving the signup link to this group. Please try again'
    dispatch({ type: API_ARCHIVE_GROUP_SIGNUP_LINK })
    try {
      const response = await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/sign-up-link/${uuid}`
      )
      if (response.data) {
        const data = response.data.data[0]
        if (response.data && response.data.data && data && !data.success) {
          throw new Error(data.message)
        }
        dispatch({ type: API_ARCHIVE_GROUP_SIGNUP_LINK_SUCCESS })
        dispatch(
          addToast({
            message: 'Group signup link deleted',
            type: TOAST_TYPE_SUCCESS,
          })
        )
      } else {
        throw new Error(errorMessage)
      }
      return true
    } catch (error) {
      dispatch({ type: API_ARCHIVE_GROUP_SIGNUP_LINK_ERROR, error })
      dispatch(
        addToast({
          message: error.message || errorMessage,
          type: TOAST_TYPE_ERROR,
        })
      )
      return false
    }
  }
}

export function getGroupSignUpLink({ groupUuid, orgUuid }) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_GROUP_SIGNUP_LINK })
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/sign-up-link`
      )
      if (response.data && response.data.data) {
        const data = response.data.data[0]
        dispatch({
          type: API_GET_GROUP_SIGNUP_LINK_SUCCESS,
          signUpLink: {
            groupUuid,
            uuid: data.id,
            linkUrl: data.linkUrl,
          },
        })

        return true
      } else {
        throw new Error('Group has no Sign Up link')
      }
    } catch (error) {
      dispatch({ type: API_GET_GROUP_SIGNUP_LINK_ERROR, error })
    }
  }
}
