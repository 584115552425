import { SAUCE_API_URL, ENV } from 'config'
import idx from 'idx'
import axios from 'axios'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR } from 'constants/ui'
import { API_YEAR_MONTH_FORMAT } from 'constants/date'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import { MOOD_OVERALL } from 'constants/form'

// Actions
export const API_GET_WELLBEING_MONTHLY_MOOD_REQUEST =
  'API_GET_WELLBEING_MONTHLY_MOOD_REQUEST'
export const API_GET_WELLBEING_MONTHLY_MOOD_SUCCESS =
  'API_GET_WELLBEING_MONTHLY_MOOD_SUCCESS'
export const API_GET_WELLBEING_MONTHLY_MOOD_ERROR =
  'API_GET_WELLBEING_MONTHLY_MOOD_ERROR'

export const API_GET_WELLBEING_MOOD_REQUEST = 'API_GET_WELLBEING_MOOD_REQUEST'
export const API_GET_WELLBEING_MOOD_SUCCESS = 'API_GET_WELLBEING_MOOD_SUCCESS'
export const API_GET_WELLBEING_MOOD_ERROR = 'API_GET_WELLBEING_MOOD_ERROR'

export const API_GET_WELLBEING_REFLECTION_REPORT_REQUEST =
  'API_GET_WELLBEING_REFLECTION_REPORT_REQUEST'
export const API_GET_WELLBEING_REFLECTION_REPORT_SUCCESS =
  'API_GET_WELLBEING_REFLECTION_REPORT_SUCCESS'
export const API_GET_WELLBEING_REFLECTION_REPORT_ERROR =
  'API_GET_WELLBEING_REFLECTION_REPORT_ERROR'
export const API_GET_WELLBEING_REFLECTION_REPORT_WARNING =
  'API_GET_WELLBEING_REFLECTION_REPORT_WARNING'

export const API_GET_WELLBEING_ATTRIBUTE_REPORT_REQUEST =
  'API_GET_WELLBEING_ATTRIBUTE_REPORT_REQUEST'
export const API_GET_WELLBEING_ATTRIBUTE_REPORT_SUCCESS =
  'API_GET_WELLBEING_ATTRIBUTE_REPORT_SUCCESS'
export const API_GET_WELLBEING_ATTRIBUTE_REPORT_ERROR =
  'API_GET_WELLBEING_ATTRIBUTE_REPORT_ERROR'

// Action creators
export function getWellbeingMoodChart({
  filter = 'overall',
  orgs = [],
  month = format(new Date(), API_YEAR_MONTH_FORMAT),
  groupUuid,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_WELLBEING_MOOD_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/wellbeing/vs-average-mood`,
        {
          params: {
            orgs,
            mood: filter === MOOD_OVERALL ? null : filter,
            startDate: startOfMonth(month),
            endDate: endOfMonth(month),
            ...(groupUuid ? { groupUuids: [groupUuid] } : {}),
          },
        }
      )

      dispatch({
        type: API_GET_WELLBEING_MOOD_SUCCESS,
        data: response.data.data,
      })
    } catch (error) {
      const errorObj = idx(error, (_) => _.response.data) || {}
      dispatch({ type: API_GET_WELLBEING_MOOD_ERROR, error })

      dispatch(
        addToast({
          message:
            errorObj.status === 400
              ? errorObj.message
              : 'There was an error fetching monthly mood reports. Please try again',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function getWellbeingMonthlyMoodChart({
  filter = 'overall',
  orgs = [],
  month = format(new Date(), API_YEAR_MONTH_FORMAT),
  groupUuid,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_WELLBEING_MONTHLY_MOOD_REQUEST })

    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/wellbeing/vs-average-mood-monthly`,
        {
          params: {
            orgs,
            mood: filter === MOOD_OVERALL ? null : filter,
            startDate: startOfMonth(month),
            endDate: endOfMonth(month),
            ...(groupUuid ? { groupUuids: [groupUuid] } : {}),
          },
        }
      )

      dispatch({
        type: API_GET_WELLBEING_MONTHLY_MOOD_SUCCESS,
        data: response.data.data,
      })
    } catch (error) {
      const errorObj = idx(error, (_) => _.response.data) || {}
      dispatch({ type: API_GET_WELLBEING_MONTHLY_MOOD_ERROR, error })

      if (errorObj.status !== 400) {
        dispatch(
          addToast({
            message:
              'There was an error fetching monthly mood reports. Please try again',
            type: TOAST_TYPE_ERROR,
          })
        )
      }
    }
  }
}

export function getWellbeingReflectionReport({
  month = new Date(),
  orgs = [],
  groupUuid,
  orderBy = MOOD_OVERALL,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_WELLBEING_REFLECTION_REPORT_REQUEST })
    try {
      const API_URL =
        ENV === 'production' ? 'https://api.chnnl.app' : SAUCE_API_URL
      const response = await axios.get(
        `${API_URL}/wellbeing/wellbeing-vs-reflection`,
        {
          params: {
            orgs,
            startDate: startOfMonth(month),
            endDate: endOfMonth(month),
            orderBy,
            ...(groupUuid ? { groupUuids: [groupUuid] } : {}),
          },
        }
      )
      const responseData = response.data.data[0]
      dispatch({
        type: API_GET_WELLBEING_REFLECTION_REPORT_SUCCESS,
        data: responseData.data,
      })
    } catch (error) {
      const errorObj = idx(error, (_) => _.response.data) || {}
      dispatch({
        type: API_GET_WELLBEING_REFLECTION_REPORT_ERROR,
        error: errorObj,
      })

      if (errorObj.status !== 400) {
        dispatch(
          addToast({
            message:
              'There was an error fetching the wellbeing vs reflection report. Please try again',
            type: TOAST_TYPE_ERROR,
          })
        )
      }
    }
  }
}

export function getWellbeingAttributeReport({
  month = new Date(),
  orgs = [],
  groupUuid,
  filter,
  attribute,
}) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_WELLBEING_ATTRIBUTE_REPORT_REQUEST })

    try {
      const API_URL =
        ENV === 'production' ? 'https://api.chnnl.app' : SAUCE_API_URL
      const response = await axios.get(
        `${API_URL}/wellbeing/wellbeing-vs-attribute`,
        {
          params: {
            orgs,
            startDate: startOfMonth(month),
            endDate: endOfMonth(month),
            mood: filter === MOOD_OVERALL ? null : filter,
            attribute,
            ...(groupUuid ? { groupUuids: [groupUuid] } : {}),
          },
        }
      )
      dispatch({
        type: API_GET_WELLBEING_ATTRIBUTE_REPORT_SUCCESS,
        data: response.data.data[0].data,
      })
    } catch (error) {
      const errorObj = idx(error, (_) => _.response.data) || {}
      dispatch({ type: API_GET_WELLBEING_ATTRIBUTE_REPORT_ERROR, errorObj })

      if (errorObj.status !== 400) {
        dispatch(
          addToast({
            message:
              'There was an error fetching the wellbeing vs attribute report. Please try again',
            type: TOAST_TYPE_ERROR,
          })
        )
      }
    }
  }
}
