import React from 'react'
import PropTypes from 'prop-types'
import FileIcon from 'components/atoms/Icons/File'
import Typography from '@weareroam/cake-ui-v1/Typography'
import LinearProgress from '@weareroam/cake-ui-v1/LinearProgress'
import styled from 'styled-components'

export const StyledFilePreview = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const StyledUploadText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  }
`

export const StyledFileIcon = styled(FileIcon)`
  margin-right: ${({ theme }) => theme.spacing.md}px;
  width: 60px;
  height: 60px;
`

export const StyledContent = styled.div``

export function FilePreview({ name, className, total, progress }) {
  const completed = Math.min(
    total !== 0 ? (progress / total) * 100 : total,
    100
  )

  return (
    <StyledFilePreview className={className}>
      <StyledFileIcon />
      <StyledContent>
        <Typography variant="body1">{name}</Typography>
        {total && progress ? (
          <React.Fragment>
            <StyledUploadText variant="body2">
              {total === progress ? 'Completed' : 'Uploading'}
            </StyledUploadText>
            <LinearProgress variant="determinate" value={completed} />
          </React.Fragment>
        ) : null}
      </StyledContent>
    </StyledFilePreview>
  )
}

FilePreview.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  total: PropTypes.number,
  progress: PropTypes.number,
}

export default FilePreview
