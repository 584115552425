import * as routeModule from 'modules/route'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { LOGIN } from 'constants/routes'

export default compose(
  connect(
    // mapStateToProps
    () => {
      return {}
    },
    // mapDispatchToProps
    (dispatch) => {
      return {
        goToLogin: () => dispatch(routeModule.redirect(LOGIN)),
      }
    }
  )
)
