import { compose } from 'recompose'
import { withFormik } from 'formik'
import { PASSWORD_MIN_LENGTH } from 'config'
import * as Yup from 'yup'

export default compose(
  withFormik({
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required('Please enter your current password'),
      newPassword: Yup.string()
        .required('Please enter a new password')
        .min(
          PASSWORD_MIN_LENGTH,
          `Your password must be at least ${PASSWORD_MIN_LENGTH} characters`
        )
        .matches(
          /^(?=.*[!@#$%^&*]|.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          'Your password must contain one uppercase, lowercase and special character'
        )
        .notOneOf(
          [Yup.ref('oldPassword')],
          'Please enter a password different from your current password'
        ),
      confirmPassword: Yup.string()
        .required('Please repeat your password')
        .oneOf([Yup.ref('newPassword')], 'Your passwords do not match'),
    }),
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
        resetForm()
      }
    },
  })
)
