import React from 'react'

export function JournalHidden(props) {
  return (
    <svg width="22px" height="18px" viewBox="0 0 22 18" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="atom-/-journal-checkbox-/-hidden"
          transform="translate(-2.000000, -5.000000)"
          fill="#C4C5E3"
          fillRule="nonzero"
        >
          <g id="noun_hide_1337636" transform="translate(2.000000, 5.000000)">
            <path
              d="M20.83,8.442 C20.728,8.291 19.273,6.166 17.018,4.396 L19.707,1.707 L18.293,0.293 L15.327,3.259 C14.03,2.521 12.569,2 11,2 C5.559,2 1.347,8.18 1.17,8.442 L0.795,9 L1.17,9.558 C1.272,9.709 2.727,11.834 4.982,13.604 L2.293,16.293 L3.707,17.707 L6.673,14.741 C7.97,15.479 9.431,16 11,16 C16.441,16 20.653,9.82 20.83,9.558 L21.205,9 L20.83,8.442 Z M3.241,8.999 C4.332,7.589 7.457,4 11,4 C11.996,4 12.958,4.291 13.853,4.732 L13.019,5.566 C12.424,5.216 11.74,5 11,5 C8.794,5 7,6.794 7,9 C7,9.739 7.215,10.424 7.567,11.02 L6.407,12.179 C4.95,11.072 3.814,9.737 3.241,8.999 Z M13,9 C13,10.103 12.103,11 11,11 C10.822,11 10.653,10.969 10.489,10.926 L12.926,8.488 C12.969,8.653 13,8.822 13,9 Z M9,9 C9,7.897 9.897,7 11,7 C11.178,7 11.347,7.031 11.511,7.074 L9.074,9.512 C9.031,9.347 9,9.178 9,9 Z M11,14 C10.004,14 9.042,13.709 8.147,13.268 L8.981,12.434 C9.576,12.784 10.26,13 11,13 C13.206,13 15,11.206 15,9 C15,8.261 14.785,7.576 14.433,6.98 L15.593,5.821 C17.05,6.927 18.186,8.262 18.759,9.001 C17.668,10.411 14.543,14 11,14 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default JournalHidden
