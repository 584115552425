import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from 'components/molecules/Box'
import { format } from 'date-fns'
import { MONTH_FILTER_FORMAT } from 'constants/date'
import Typography from '@weareroam/cake-ui-v1/Typography'

export const Graphs = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const GraphTitle = styled.div`
  display: flex;
  align-items: center;
`
export const GraphBoxContent = styled.div`
  display: flex;
`
export const GraphBox = styled.div`
  flex-basis: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg}px;
  border-right: 1px solid
    ${({ theme, noLine }) =>
      noLine ? 'transparent' : theme.palette.tertiary.light};
`
export const SectionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const SectionValue = styled(Typography)``
export const MonthLabel = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export function ActivityHighLevel({ data, month }) {
  return (
    <Graphs
      headerContent={
        <GraphTitle>
          <Typography variant="h4">
            High-level stats
            <MonthLabel> in {format(month, MONTH_FILTER_FORMAT)}</MonthLabel>
          </Typography>
        </GraphTitle>
      }
    >
      <GraphBoxContent>
        <GraphBox>
          <SectionLabel>Registered devices</SectionLabel>
          <SectionValue variant="h2">{data.registered}</SectionValue>
        </GraphBox>

        <GraphBox>
          <SectionLabel>Total responses</SectionLabel>
          <SectionValue variant="h2">{data.responses}</SectionValue>
        </GraphBox>

        <GraphBox>
          <SectionLabel>Avg responses / day</SectionLabel>
          <SectionValue variant="h2">{data.averagePerDay}</SectionValue>
        </GraphBox>

        <GraphBox noLine>
          <SectionLabel>Avg responses / device</SectionLabel>
          <SectionValue variant="h2">{data.averagePerDevice}</SectionValue>
        </GraphBox>
      </GraphBoxContent>
    </Graphs>
  )
}

ActivityHighLevel.propTypes = {
  data: PropTypes.shape({
    responses: PropTypes.number,
    registered: PropTypes.number,
    averagePerDay: PropTypes.number,
    averagePerDevice: PropTypes.number,
  }),
  month: PropTypes.string,
}

ActivityHighLevel.defaultProps = {
  data: {},
}

export default ActivityHighLevel
