import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import styled from 'styled-components'
import Box from 'components/molecules/Box'
import RowButton from 'components/atoms/RowButton'
import Modal from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import DragDropList from 'components/molecules/DragDropList'
import EditableText from 'components/molecules/EditableText'
import StatusTag from 'components/atoms/StatusTag'
import ProgressLoader from 'components/atoms/ProgressLoader'
import NavigationPrompt from 'react-router-navigation-prompt'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'
import { deepEqual } from 'fast-equals'

export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  margin-top: ${({ theme }) => theme.spacing.sm}px;
`

export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

export const UpdateButton = styled(Button)`
  width: 140px;
`

export const TypesOfDaysSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const OrganisationDepartmentsSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const TypesOfDays = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const OrganisationDepartments = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const Error = styled(StatusTag)`
  margin-top: ${({ theme }) => theme.spacing.md}px;
`

export const StyledDisplayTitle = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  }
`

export function SurveyQuestionsForm({
  className,
  handleChange,
  values,
  errors,
  touched,
  handleSubmit,
  setIsConfirmUpdateModalOpen,
  isConfirmUpdateModalOpen,
  onDragEnd,
  onAddDayType,
  onAddDepartment,
  onDeleteDayType,
  onDeleteDepartment,
  progress,
  initialValues,
  disableEditting,
}) {
  const handleCloseConfirmationModal = useCallback(
    () => setIsConfirmUpdateModalOpen(false),
    []
  )

  const handleTypeOfDayDragEnd = useCallback(
    (result) => onDragEnd(result, 'typesOfDays'),
    []
  )

  const handleOrganisationDepartmentsDragEnd = useCallback(
    (result) => onDragEnd(result, 'organisationDepartments'),
    []
  )

  const hasUnsavedChanges = useMemo(
    () => {
      return (
        !deepEqual(values.typesOfDays, initialValues.typesOfDays) ||
        !deepEqual(
          values.organisationDepartments,
          initialValues.organisationDepartments
        )
      )
    },
    [
      values.typesOfDays,
      initialValues.typesOfDays,
      values.organisationDepartments,
      initialValues.departments,
    ]
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}
    >
      <NavigationPrompt when={hasUnsavedChanges}>
        {({ onConfirm, onCancel }) => (
          <Modal open>
            <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
          </Modal>
        )}
      </NavigationPrompt>
      <ConfirmUpdatesModal
        open={isConfirmUpdateModalOpen}
        onClose={handleCloseConfirmationModal}
      >
        <ConfirmUpdatesForm
          onCancel={handleCloseConfirmationModal}
          onSubmit={handleSubmit}
          title="Confirm survey updates"
          content={
            <React.Fragment>
              All survey changes will be <br /> updates and visible in the app.
            </React.Fragment>
          }
        />
      </ConfirmUpdatesModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h2">Survey questions</Typography>
          <DescriptionText variant="body1">
            Options for the initial questions of the survey
          </DescriptionText>
        </HeaderLeft>
        {!disableEditting && (
          <UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!hasUnsavedChanges}
          >
            Update
          </UpdateButton>
        )}
      </StyledHeader>

      {progress.inProgress ? (
        <ProgressLoader fullWidth />
      ) : (
        <React.Fragment>
          <TypesOfDaysSection>
            <Typography variant="subtitle1">Types of days</Typography>
            <DescriptionText variant="body2">
              Type of work and leave days that your organisation provides
              employees.
            </DescriptionText>
            <DragDropList
              disabled={disableEditting}
              onDragEnd={handleTypeOfDayDragEnd}
            >
              {(provided) => (
                <TypesOfDays>
                  {values.typesOfDays
                    ? values.typesOfDays.map((typeOfDay, typeOfDayIndex) => {
                        return (
                          <Box
                            key={typeOfDay.uuid}
                            draggableId={typeOfDay.uuid}
                            draggableIndex={typeOfDayIndex}
                            isDraggable={!disableEditting}
                            isEditable={!disableEditting}
                            onDeleteClick={() =>
                              onDeleteDayType(typeOfDayIndex)
                            }
                            errors={
                              touched.typesOfDays &&
                              errors.typesOfDays &&
                              touched.typesOfDays[typeOfDayIndex] &&
                              errors.typesOfDays[typeOfDayIndex]
                                ? Object.values(
                                    errors.typesOfDays[typeOfDayIndex]
                                  )
                                : []
                            }
                            headerContent={
                              disableEditting ? (
                                <StyledDisplayTitle>
                                  {typeOfDay.type}
                                </StyledDisplayTitle>
                              ) : (
                                <EditableText
                                  value={typeOfDay.type}
                                  name={`typesOfDays[${typeOfDayIndex}].type`}
                                  onChange={handleChange}
                                  fullWidth
                                  placeholder="New type of day"
                                />
                              )
                            }
                          />
                        )
                      })
                    : null}
                  {provided.placeholder}
                </TypesOfDays>
              )}
            </DragDropList>
            {!disableEditting && (
              <RowButton onClick={onAddDayType}>+ Add day type</RowButton>
            )}
            {touched.typesOfDays && typeof errors.typesOfDays === 'string' ? (
              <Error themeColor="error" fullWidth>
                {errors.typesOfDays}
              </Error>
            ) : null}
          </TypesOfDaysSection>

          <OrganisationDepartmentsSection>
            <Typography variant="subtitle1">
              Organisation departments
            </Typography>
            <DescriptionText variant="body2">
              Departments of your organisation that your employees work in.
            </DescriptionText>
            <DragDropList onDragEnd={handleOrganisationDepartmentsDragEnd}>
              {(provided) => (
                <OrganisationDepartments>
                  {values.organisationDepartments
                    ? values.organisationDepartments.map(
                        (department, departmentIndex) => {
                          return (
                            <Box
                              key={department.uuid}
                              draggableId={department.uuid}
                              draggableIndex={departmentIndex}
                              isDraggable={!disableEditting}
                              isEditable={!disableEditting}
                              onDeleteClick={() =>
                                onDeleteDepartment(departmentIndex)
                              }
                              errors={
                                touched.organisationDepartments &&
                                errors.organisationDepartments &&
                                touched.organisationDepartments[
                                  departmentIndex
                                ] &&
                                errors.organisationDepartments[departmentIndex]
                                  ? Object.values(
                                      errors.organisationDepartments[
                                        departmentIndex
                                      ]
                                    )
                                  : []
                              }
                              headerContent={
                                disableEditting ? (
                                  <StyledDisplayTitle>
                                    {department.department}
                                  </StyledDisplayTitle>
                                ) : (
                                  <EditableText
                                    value={department.department}
                                    name={`organisationDepartments[${departmentIndex}].department`}
                                    onChange={handleChange}
                                    placeholder="New department"
                                    fullWidth
                                  />
                                )
                              }
                            />
                          )
                        }
                      )
                    : null}
                  {provided.placeholder}
                </OrganisationDepartments>
              )}
            </DragDropList>
            {!disableEditting && (
              <RowButton onClick={onAddDepartment}>+Add department</RowButton>
            )}
            {touched.organisationDepartments &&
            typeof errors.organisationDepartments === 'string' ? (
              <Error themeColor="error" fullWidth>
                {errors.organisationDepartments}
              </Error>
            ) : null}
          </OrganisationDepartmentsSection>
        </React.Fragment>
      )}
    </StyledForm>
  )
}

SurveyQuestionsForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  className: PropTypes.string,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  isConfirmUpdateModalOpen: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onAddDayType: PropTypes.func,
  onAddDepartment: PropTypes.func,
  onDeleteDayType: PropTypes.func,
  onDeleteDepartment: PropTypes.func,
  progress: PropTypes.object,
  disableEditting: PropTypes.bool,
  initialValues: PropTypes.object,
}

SurveyQuestionsForm.defaultProps = {
  values: {},
  progress: {},
  errors: {},
  touched: {},
  initialValues: {},
}

export default SurveyQuestionsForm
