import * as authActions from 'modules/authentication/actions'
import * as surveyActions from 'modules/survey/actions'
import {
  getDepartmentsList,
  getTypesOfDaysList,
} from 'modules/survey/selectors'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { hasEditRights } from 'modules/authentication/selectors'

export default compose(
  connect(
    // mapStateToProps
    (state) => {
      return {
        departments: getDepartmentsList(state),
        typesOfDays: getTypesOfDaysList(state),
        getSurveyProgress: state.progress.getSurvey,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
        existingDepartmentIds: state.survey.departments.orderedIds,
        existingDayTypesIds: state.survey.typesOfDays.orderedIds,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...surveyActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getSurveyQuestions(match.params.orgUuid)
    },
  }),
  withHandlers({
    onUpdateSurveyQuestions: ({
      actions,
      match,
      existingDayTypesIds,
      existingDepartmentIds,
    }) => ({ organisationDepartments, typesOfDays }) => {
      actions.updateSurveyQuestions({
        orgUuid: match.params.orgUuid,
        departments: organisationDepartments.map((department) => {
          if (!existingDepartmentIds.includes(department.uuid)) {
            // remove uuid for new departments
            return {
              department: department.department,
            }
          }

          return department
        }),
        dayTypes: typesOfDays.map((dayType) => {
          if (!existingDayTypesIds.includes(dayType.uuid)) {
            // remove uuid for new day types
            return {
              type: dayType.type,
            }
          }

          return dayType
        }),
      })
    },
  })
)
