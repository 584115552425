import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'
import { subMinutes } from 'date-fns'

import { getSessionToken } from 'utils/authentication'
import { formatDate, getCurrentDate, LOCAL_TIME_FORMAT } from '../../utils/date'

export function exportJournalData({ orgUuid }) {
  return async function dispatcher(dispatch) {
    try {
      const date = formatDate(
        subMinutes(getCurrentDate(), 1),
        LOCAL_TIME_FORMAT
      )
      window.location.assign(
        `${SAUCE_API_URL}/organisation/${orgUuid}/journal/export/chnnl-journal-export-${date}?token=${getSessionToken()}`
      )

      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          message:
            'There was an error exporting journal data. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function exportIncidentData({ orgUuid }) {
  return async function dispatcher(dispatch) {
    try {
      const date = formatDate(
        subMinutes(getCurrentDate(), 1),
        LOCAL_TIME_FORMAT
      )
      window.location.assign(
        `${SAUCE_API_URL}/organisation/${orgUuid}/shared-incident/export/chnnl-incident-export-${date}?token=${getSessionToken()}`
      )

      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          message:
            'There was an error exporting incident data. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function exportAllOrgData() {
  return async function dispatcher(dispatch) {
    try {
      const date = formatDate(
        subMinutes(getCurrentDate(), 1),
        LOCAL_TIME_FORMAT
      )

      window.location.assign(
        `${SAUCE_API_URL}/organisation/export/chnnl-reports-${date}?token=${getSessionToken()}`
      )

      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          message: 'There was an error exporting your data. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}

export function exportOrgData({ orgUuid }) {
  return async function dispatcher(dispatch) {
    try {
      const date = formatDate(
        subMinutes(getCurrentDate(), 1),
        LOCAL_TIME_FORMAT
      )
      window.location.assign(
        `${SAUCE_API_URL}/organisation/${orgUuid}/export/chnnl-reports-${date}?token=${getSessionToken()}`
      )
      dispatch(
        addToast({
          message: 'Success! Your download will begin shortly.',
          type: TOAST_TYPE_SUCCESS,
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          message:
            'There was an error exporting your org data. Please try again.',
          type: TOAST_TYPE_ERROR,
        })
      )
    }
  }
}
